import { isEmpty } from 'lodash'
import PropTypes from 'prop-types'
import React, { PureComponent } from 'react'
import { AccentButton, MultilineInput } from '..'
import { Editor } from './Styled'
import styled from 'styled-components'

const StyledButton = styled(AccentButton)`
  margin-top: 14px;
  align-self: flex-start;
`

export class CommentEditor extends PureComponent {
  static propTypes = {
    title: PropTypes.string,
    placeholder: PropTypes.string,
    onCreateComment: PropTypes.func
  }

  static defaultProps = {
    title: 'Comment'
  }

  inputRef = React.createRef()

  onButtonPress = () => {
    if (this.inputRef.current) {
      const value = this.inputRef.current.getValue()
      if (!isEmpty(value)) {
        this.onCreateComment(value)
        this.inputRef.current.clear()
        this.inputRef.current.focus()
      }
    }
  }

  onCreateComment = value => {
    if (!isEmpty(value)) {
      this.props.onCreateComment(value)
    }
  }

  render() {
    const { placeholder, title } = this.props
    return (
      <Editor>
        <MultilineInput
          ref={this.inputRef}
          placeholder={placeholder}
          value={''}
          fontSize="medium"
          backgroundVisibility="visible"
          borderVisibility="focus"
          autoFocus={false}
          autoSelect={false}
          submitOnBlur={false}
          onChange={this.onCreateComment}
        />
        <StyledButton
          title={title}
          accessibilityLabel={title}
          onPress={this.onButtonPress}
        />
      </Editor>
    )
  }
}
