export const titles = {
  LandingPage: '',
  TermsOfUse: 'Terms Of Use',
  PrivacyPolicy: 'Privacy Policy',
  CookiePolicy: 'Cookie Policy',
  WrongPage: 'Wrong Page',
  AuthTokenExpired: 'Token Expired',
  RecipesList: 'Recipes',
  MyRecipesList: 'My Recipes',
  BatchesList: 'Batches',
  EquipmentProfile: 'Equipment',
  Settings: 'Settings',
  AdminPage: 'Admin Page',
  PageNotFound: 'Page Not Found',
}

export const sharingDialog = {
  share: {
    title: 'Share recipe by URL',
    message: 'Share the recipe by providing a link to others.',
  },
  publish: {
    title: 'Publish a recipe to the library',
    message:
      'When checked, the recipe will appear in the searchable database and in your profile under “public recipes”.',
  },
}

export const cloneButton = {
  title: 'Clone',
  htmlTitle: 'Clone the recipe',
}

export const brewButton = {
  title: 'Brew!',
  htmlTitle: 'Record batch session',
  noFermentables: 'Add at least one fermentable to start brewing.',
}

export const deleteConfirmation = {
  title: {
    batch: 'Delete Batch',
    recipe: 'Delete Recipe',
    equipment: 'Delete Equipment',
  },
  message: {
    batch:
      'The batch will be permanently deleted. This action is irreversible and can not be undone. ',
    recipe:
      'The recipe will be permanently deleted, including shares and links. This action is irreversible and can not be undone.',
    equipment:
      'The equipment set will be permanently deleted. This action is irreversible and can not be undone. ',
  },
  confirmationMessage: {
    batch: 'Confirm that I want to delete the batch',
    recipe: 'Confirm that I want to delete the recipe ',
    equipment: 'Confirm that I want to delete the equipment set ',
  },
}

export const publicRecipe = {
  infoMessage:
    'You are viewing a recipe shared by another user. Clone the recipe to be able to save changes.',
}

export const feedback = {
  empty: 'Share your experience using BrewComputer.',
}

export const noRecipes = {
  title: 'You have no recipes',
  message: 'Create a new recipe, or clone one of the',
  link: 'public recipes',
}

export const noPublicRecipes = {
  title: 'No recipes found',
  message1: 'No recipes found, try another search request.',
  message2:
    'The search takes into account the recipe name, style and ingredients.',
}

export const noBatches = {
  title: 'You have no batches',
  message: 'Create a new batch from your recipe of one of the',
  link: 'public recipes',
}

export const noEquipment = {
  title: 'You have no equipment',
  message: 'Click the button to add new equipment profile',
  link: 'Add a new equipment set ...',
}

export const recipeNotFound = {
  title: 'Recipe not found',
  message: 'Create a new one or explore the',
  link: 'public recipe list',
}

export const batchNotFound = {
  title: 'Batch not found',
  message: 'Create a new batch from your recipe or one ot the',
  link: 'public recipes',
}

export const landingPage = {
  title: 'Beer brewing tools online.',
  message:
    'Create recipes, record brew sessions, manage equipment, share recipes with friends.',
  alt: "The homebrewer's main tool",
  authButtons: {
    noLogin: 'Try Without Login',
    google: 'Sign in with Google',
    email: 'Sign in with Email',
  },
}

export const needLogin = {
  equipment: 'Sign in to manage equipment profiles',
  batch: 'Sign in to start brewing',
  settings: 'Sign in to configure your profile',
  recipe: 'Sign in to save a recipe',
}

export const wrongPage = {
  title: 'Oops! Something went wrong =(',
  message: 'Try reloading the page.',
}

export const pageNotFound = {
  title: 'Oops!',
  message1:
    "We can't seem to find the page you're looking for. Try starting with the list of",
  link: ' public recipes',
  message2: ' or create a new recipe.',
}

export const emailAuth = {
  request: {
    title: 'Sign in with email',
    message:
      'Enter your email to sign in. If you do not have an account, we will create it for you',
  },
  success: {
    title: 'Check your inbox',
    messages: ['We sent an email to ', 'Click the link to sign in'],
    warning: 'Gmail may place it in the Promotions tab',
  },
  error: {
    title: 'Something went wrong!',
    message: 'Please, try again ...',
  },
  expired: {
    title: 'Sorry, your verification link has expired!',
    message: 'Click the button bellow to send a new link.',
  },
}

export const searchPlaceholders = {
  publicRecipes: 'Recipes, beer styles, ingredients...',
  myRecipes: 'Search for a recipe...',
  batches: 'Search for a batch...',
}

export const batchCheckListLabels = {
  planning_total_water: (volume) =>
    `Based on your equipment profile and recipe, ${volume} of water is required.`,
  planning_equipment_check:
    'Prepare your equipment: hydrometer, sanitizer, fuel, fermenter, kettle, airlock, hop bags, hoses, funnel, towels, etc.',
  pre_brew_equipment_check:
    'Ensure that valves are closed on the HT (hot liquor tank), mash tun and brew kettle. ',
  pre_brew_water_heat:
    'Start heating strike water in the HTL or secondary kettle. ',
  pre_brew_water_profile: 'Add brewing salts as the recipe or style requires. ',
  pre_brew_grains_mill:
    'Measure out the grain and mill it if not already milled. ',
  pre_brew_workspace_clean:
    'Prepare the workspace, clean up and setup the mash tun and kettle.',
  pre_brew_hop_prepare: 'Prepare hop additions.',
  pre_brew_yeast_prepare:
    'If you are using liquid yeast, take it out of the refrigerator.',

  mash_measure_ph: 'Take a pH reading of the mash.',

  mash_measure_end_of_running_gravity: 'Measure the end of running gravity',
  mash_measure_bre_boil_gravity:
    'Take a pre-boil gravity sample. Write down its volume after it cools down.',

  boil_measure_pre_boil_volume: (volume) =>
    `To get the desired batch size, you need a preboil volume of ${volume}. Measure the pre-boil volume.`,
  boil_start: (duration) => `Set the boil timer to ${duration}.`,

  boil_chiller_prepare: 'Sanitize and install the chiller.',
  boil_end: 'Boil is over, remove the heat source.',

  fermentation_cool_wort: `Cool down the wort.`,
  fermentation_equipment_sanitize:
    'Sanitize the primary fermenter, cork, airlock, aeration stone/hose, funnel, and wine thief.',
  fermentation_transfer: `When the wort is cooled, move it to the fermenter.`,
  fermentation_measure_og: 'Measure gravity.',
  fermentation_aerate: 'Shake the wort to aerate it or use an aeration stone.',
  fermentation_airlock:
    'Connect an airlock or blow-off tube for high gravity or dark beers.',
  fermentation_equipment_clean: 'Clean up equipment.',
}

// TODO: public? recipes
// TODO: sign in/ continue?? UX
