import React, { PureComponent } from 'react'
import PropTypes from 'prop-types'
import { clamp, map, isNumber, inRange } from 'lodash'
import { Indicator } from '../indicator/Indicator'
import { Wrapper } from './Wrapper'

const clampRangePercentage = (rangeSize, rangeMax) => {
  if (rangeSize + rangeMax > 100) {
    return rangeSize - (rangeSize + rangeMax - 100)
  } else {
    return rangeSize
  }
}

const getBoundariesText = (minStyleValue, maxStyleValue) => {
  if (!isNumber(minStyleValue) || !isNumber(maxStyleValue)) {
    return null
  }
  return `${minStyleValue}\n${String.fromCharCode(0x2013)}\n${maxStyleValue}`
}

export class StyleBars extends PureComponent {
  renderIndicator = props => {
    const {
      key,
      minScaleValue,
      maxScaleValue,
      minStyleValue,
      maxStyleValue,
      value,
      unit,
      units,
      caption
    } = props

    const shrink = 0.75
    const scaleHeight = 100 //%

    const minScaleAdoptedValue =
      minScaleValue + (minStyleValue - minScaleValue) * shrink
    const maxScaleAdoptedValue =
      maxScaleValue - (maxScaleValue - maxStyleValue) * shrink

    const scaleValuesDiff = maxScaleAdoptedValue - minScaleAdoptedValue

    let rangeMax = 0
    let rangeSize = 0
    let actualValue = 0

    if (minStyleValue !== 0 && maxStyleValue !== 0) {
      rangeMax =
        (scaleHeight * (maxScaleAdoptedValue - maxStyleValue)) / scaleValuesDiff
      rangeSize =
        (scaleHeight * (maxStyleValue - minStyleValue)) / scaleValuesDiff
      actualValue = clamp(
        (scaleHeight * (maxScaleAdoptedValue - value)) / scaleValuesDiff,
        0,
        scaleHeight
      )
    }

    const rangePercentage = Math.round(
      clampRangePercentage(rangeSize, rangeMax)
    )
    const rangePosition = Math.round(rangeMax)
    const markPosition = Math.round(actualValue)

    const boundariesText = getBoundariesText(minStyleValue, maxStyleValue)

    const onChangeUnit = selectedUnit => {
      this.props.onChangeUnit({ [key]: selectedUnit })
    }

    return (
      <Indicator
        key={caption}
        rangePercentage={rangePercentage}
        rangePosition={rangePosition}
        inRange={inRange(value, minStyleValue, maxStyleValue)}
        markPosition={markPosition}
        caption={caption}
        value={isNumber(value) ? value.toString() : ''}
        unit={unit}
        units={units}
        boundaries={boundariesText}
        onChangeUnit={onChangeUnit}
      />
    )
  }

  render() {
    return <Wrapper>{map(this.props.data, this.renderIndicator)}</Wrapper>
  }
}

StyleBars.propTypes = {
  data: PropTypes.arrayOf(
    PropTypes.shape({
      caption: PropTypes.string,
      value: PropTypes.number,
      unit: PropTypes.string,
      units: PropTypes.array,
      minStyleValue: PropTypes.number,
      maxStyleValue: PropTypes.number,
      minScaleValue: PropTypes.number,
      maxScaleValue: PropTypes.number
    })
  ).isRequired,
  onChangeUnit: PropTypes.func
}
