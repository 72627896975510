import React from 'react'
import { map, find } from 'lodash'
import styled from 'styled-components'
import { colors } from '../colors/colors'
import { textStyles } from '../texts/textStyles'
import { MeasurableEditor } from '../editors/MeasurableEditor'
import { Editor } from '../editors/Styled'
import { Card } from '..'
import figmaColors from '../colors/figmaColors'

export const measurableToString = measurable => {
  if (
    measurable == null ||
    !Number.isFinite(measurable.value) ||
    measurable.value === 0
  ) {
    return '-'
  }
  let label = measurable.unit
  if (measurable.units != null) {
    const convertedUnit = find(measurable.units, { unit: measurable.unit })
    if (convertedUnit != null) {
      label = convertedUnit.label
    }
  }
  return `${measurable.value} ${label}`
}

export const Tab = styled(Card)`
  flex-direction: row;
  flex-wrap: wrap;
  padding-bottom: 32px;
`

export const VerticalGroup = styled.div`
  flex: 1;
  flex-direction: column;
`
export const StyledSection = styled.div`
  padding: 32px 32px 0 32px;
  @media (max-width: 576px) {
    padding: 32px 20px 0 20px;
  }
  :first-child {
    padding-top: 0;
  }
`

export const Column = styled.div`
  flex: 1;
  box-sizing: border-box;
  flex-direction: column;
  background-color: ${colors.WHITE};
  margin-top: 32px;
  border-right: 1px solid ${colors.DIVIDER};
  @media (max-width: 576px) {
    min-width: 100%;
    border-right: none;
  }

  @media (min-width: 577px) and (max-width: 992px) {
    min-width: 50%;
    border-right: none;
    :last-child {
      flex-direction: row;
    }
    :last-child > ${StyledSection} {
      flex: 1;
      padding-top: 0;
    }
    :first-child {
      border-right: 1px solid ${colors.DIVIDER};
    }
  }

  @media (min-width: 992px) {
    :last-child {
      border-right: none;
    }
  }
`

export const Form = styled.div`
  ${Editor} {
    margin-bottom: 16px;
  }
`

export const SectionTitle = styled.div`
  ${textStyles.H2};
  color: ${colors.BLACK};
`

export const Title = styled(SectionTitle)`
  margin-bottom: 12px;
`

export const Section = ({ title, children }) => (
  <StyledSection>
    <Title>{title}</Title>
    {children}
  </StyledSection>
)

export const RecipeSectionTitle = styled.div`
  flex-direction: row;
  justify-content: space-between;
`

export const BatchRecipeLink = styled.div`
  a {
    ${textStyles.H2};
    font-weight: normal;
    color: ${figmaColors.blue_500};
  }
`

BatchRecipeLink.displayName = 'BatchRecipeLink'

export const RecipeSection = ({ title, recipeLink, children }) => (
  <StyledSection>
    <RecipeSectionTitle>
      <Title>{title}</Title>
      <BatchRecipeLink>{recipeLink}</BatchRecipeLink>
    </RecipeSectionTitle>
    {children}
  </StyledSection>
)

export const CheckListTitle = styled(SectionTitle)`
  margin: 0 32px 12px 32px;
`

export const CheckListContainer = styled.div`
  margin-bottom: 12px;
`

export const FermentationContainer = styled.div`
  margin-bottom: 10px;
`

export const FermentationSteps = styled.div`
  margin-top: 10px;
`

export const ListItem = styled.div`
  flex-direction: row;
  justify-content: space-between;
  margin-bottom: 10px;
`

export const Text = styled.div`
  ${textStyles.body2};
  color: ${colors.BLACK};
`

export const TableText = styled.div`
  ${textStyles.body2};
  color: ${colors.TABLE_BLACK};
  text-align: ${props => props.textAlign || 'left'};
  font-weight: ${props => props.fontWeight || 'normal'};
`

export const TableRow = ({ texts: [firstItem, ...otherItems], isHeader }) => (
  <ListItem>
    <TableText style={{ flex: 3 }} fontWeight={isHeader ? 'bold' : 'normal'}>
      {firstItem}
    </TableText>
    {map(otherItems, (text, index) => (
      <TableText
        key={index}
        style={{ flex: 1 }}
        fontWeight="bold"
        textAlign="right">
        {text}
      </TableText>
    ))}
  </ListItem>
)

export const MeasuredValueEditor = ({ title, measuredValue, onChange }) => {
  const { value, unit, units } = measuredValue
  return (
    <MeasurableEditor
      title={title}
      value={value}
      unit={unit}
      units={units}
      onChange={onChange}
      valueZeroEmpty={true}
    />
  )
}
