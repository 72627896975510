import React, { PureComponent } from 'react'
import { Input } from '../input/Input'
import { Editor, Title, InputContainer } from './Styled'
import PropTypes from 'prop-types'

export class TextEditor extends PureComponent {
  static displayName = 'TextEditor'
  static propTypes = {
    type: PropTypes.oneOf(['text', 'number']),
    fontSize: PropTypes.oneOf(['small', 'normal', 'large', 'huge']),
    textAlign: PropTypes.oneOf(['left', 'right']),
    title: PropTypes.string,
    placeholder: PropTypes.string,
    value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    isBorderHidden: PropTypes.bool,
    disabled: PropTypes.bool,
    multiline: PropTypes.bool,
    className: PropTypes.string,
    onChange: PropTypes.func
  }

  static defaultProps = {
    type: 'text',
    textAlign: 'right',
    fontSize: 'normal'
  }

  state = {
    value: null,
    focused: false
  }

  onChange = text => {
    this.setState({ value: text })
  }

  onFocus = () => {
    this.setState({ focused: true })
  }

  onBlur = () => {
    this.setState({ focused: false })
    this.change()
  }

  onSubmit = text => {
    this.setState({ value: text }, this.change)
  }

  change = () => {
    if (this.state.value != null && this.state.value !== this.props.value) {
      this.props.onChange(this.state.value)
    }
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevProps.value !== this.props.value) {
      if (this.props.value == this.state.value) {
        //eslint-disable-next-line
        this.setState({ value: null })
      }
    }
  }

  render() {
    const { focused } = this.state
    const {
      title,
      disabled,
      multiline,
      placeholder,
      type,
      fontSize,
      textAlign,
      selectOnFocus,
      isBorderHidden,
      className
    } = this.props
    const value = this.state.value == null ? this.props.value : this.state.value
    const inputMode = type === 'number' ? 'decimal' : 'text'

    return (
      <Editor
        className={className}
        focused={focused}
        isBorderHidden={isBorderHidden}>
        <InputContainer>
          <Title focused={focused}>{title}</Title>
          <Input
            inputProps={{ 'aria-label': title, inputMode }}
            type={type}
            multiline={multiline}
            value={value}
            disabled={disabled}
            fontSize={fontSize}
            textAlign={textAlign}
            selectOnFocus={selectOnFocus}
            onChange={this.onChange}
            placeholder={placeholder}
            onSubmit={this.onSubmit}
            onFocus={this.onFocus}
            onBlur={this.onBlur}
          />
        </InputContainer>
      </Editor>
    )
  }
}
