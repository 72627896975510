import React from 'react'
import { Page } from './PolicyPage'
import ScrollToTop from './ScrollToTop'
import { Link } from 'react-router-dom'
import { links } from '../../links'
import TitleConstructor from '../TitleConstructor'
import { titles } from '../../texts/index'

export const TermsOfUse = () => {
  return (
    <Page>
      <ScrollToTop />
      <TitleConstructor pageTitle={titles.TermsOfUse} />
      <h1 id="BrewComputer-Website-Terms-of-Use">
        BrewComputer Website Terms of Use
      </h1>
      <p>Last updated: June 13, 2017</p>
      <h2 id="Introduction">Introduction</h2>
      <p>
        Please read these Terms of Use (“Terms”, “Terms of Use”) carefully
        before using the{' '}
        <Link to={links.index} target="_blank" rel="noopener">
          brewcomputer.com
        </Link>{' '}
        website (the “Service”) operated by Devsoft Baltic OÜ (“DEVSOFT”, “us”,
        “we”, or “our”).
        <br /> Your access to and use of the Service is conditioned on your
        acceptance of and compliance with these Terms. These Terms apply to all
        visitors, users and others who access or use the Service.
        <br /> By accessing or using the Service you agree to be bound by these
        Terms. If you disagree with any part of the terms then you may not
        access the Service.
      </p>
      <h2 id="Purchases">Purchases</h2>
      <p>
        If you wish to purchase any product or service made available through
        the Service (“Purchase”), you may be asked to supply certain information
        relevant to your Purchase including, without limitation, your credit
        card number, the expiration date of your credit card, your billing
        address, and your shipping information.
        <br /> You represent and warrant that: (i) you have the legal right to
        use any credit card(s) or other payment method(s) in connection with any
        Purchase; and that (ii) the information you supply to us is true,
        correct and complete. You expressly agree that DEVSOFT is not
        responsible for any loss or damage arising from the submission of false
        or inaccurate information.
        <br /> By submitting such information, you grant us the right to provide
        the information to third parties for purposes of facilitating the
        completion of Purchases.
        <br /> We reserve the right to refuse or cancel your order at any time
        for certain reasons including but not limited to: product or service
        availability, errors in the description or price of the product or
        service, error in your order or other reasons. You expressly agree that
        DEVSOFT cannot accept any liability for loss or damage arising out of
        such cancellation.
        <br /> We reserve the right to refuse or cancel your order if fraud or
        an unauthorised or illegal transaction is suspected.
      </p>
      <h2 id="Availability-Errors-and-Inaccuracies">
        Availability, Errors and Inaccuracies
      </h2>
      <p>
        We are constantly updating our offerings of products and services on the
        Service. The products or services available on our Service may be
        mispriced, described inaccurately, or unavailable, and we may experience
        delays in updating information on the Service and in our advertising on
        other web sites. You expressly agree that any such offer of a product or
        service does not constitute a legal offer capable of attracting legal
        consequences.
        <br /> We cannot and do not guarantee the accuracy or completeness of
        any information, including prices, product images, specifications,
        availability, and services. We reserve the right to change or update
        information and to correct errors, inaccuracies, or omissions at any
        time without prior notice. Section “Availability, Errors and
        Inaccuracies” is without prejudice to existing statutory rights.
      </p>
      <h2 id="Content">Content</h2>
      <p>
        Our Service allows you to post, link, store, share and otherwise make
        available certain information, text, graphics, videos, or other material
        (“Content”). You are responsible for the Content that you post to the
        Service, including its legality, reliability, and appropriateness.
        <br /> By posting Content to the Service, you grant us the right and
        license to use, modify, publicly perform, publicly display, reproduce,
        and distribute such Content on and through the Service. You retain any
        and all of your rights to any Content you submit, post or display on or
        through the Service and you are responsible for protecting those rights.
        <br /> You represent and warrant that: (i) the Content is yours (you own
        it) or you have the right to use it and grant us the rights and license
        as provided in these Terms, and (ii) the posting of your Content on or
        through the Service does not violate the privacy rights, publicity
        rights, copyrights, contract rights or any other rights of any person.
      </p>
      <h2 id="Accounts">Accounts</h2>
      <p>
        When you create an account with us, you must provide us information that
        is accurate, complete, and current at all times. Failure to do so
        constitutes a breach of the Terms, which may result in immediate
        termination of your account on our Service.
        <br /> You are responsible for safeguarding the password that you use to
        access the Service and for any activities or actions under your
        password, whether your password is with our Service or a third-party
        service.
        <br /> You agree not to disclose your password to any third party. You
        must notify us immediately upon becoming aware of any breach of security
        or unauthorized use of your account.
        <br /> You may not use as a username the name of another person or
        entity or that is not lawfully available for use, a name or trade mark
        that is subject to any rights of another person or entity other than you
        without appropriate authorization, or a name that is otherwise
        offensive, vulgar or obscene. You expressly agree that we cannot be held
        liable for any loss or damage arising out of any misrepresentations you
        make in this regard.
      </p>
      <h2 id="Intellectual-Property">Intellectual Property</h2>
      <p>
        The Service and its original content (excluding Content provided by
        users), features and functionality are and will remain the exclusive
        property of DEVSOFT and its licensors. The Service is protected by
        copyright, trademark, and other laws of both the Republic of Estonia and
        foreign countries. Our trademarks and trade dress may not be used in
        connection with any product or service without the prior written consent
        of DEVSOFT.
        <br /> When you upload content, you give to DEVSOFT a worldwide,
        non-exclusive, royalty-free, transferable licence (with right to
        sub-licence) to use, reproduce, distribute, prepare derivative works of,
        display, and perform that Content in connection with the provision of
        the Service and otherwise in connection with the provision of the
        Service and DEVSOFT business.
      </p>
      <h2 id="Links-To-Other-Web-Sites">Links To Other Web Sites</h2>
      <p>
        Our Service may contain links to third-party web sites or services that
        are not owned or controlled by DEVSOFT.
        <br /> DEVSOFT has no control over, and assumes no responsibility for,
        the content, privacy policies, or practices of any third party web sites
        or services. You further acknowledge and agree that DEVSOFT shall not be
        responsible or liable, directly or indirectly, for any damage or loss
        caused or alleged to be caused by or in connection with use of or
        reliance on any such content, goods or services available on or through
        any such web sites or services.
        <br /> We strongly advise you to read the terms and conditions and
        privacy policies of any third-party web sites or services that you
        visit.
      </p>
      <h2 id="Termination">Termination</h2>
      <p>
        We may terminate or suspend your account immediately, without prior
        notice or liability, for any reason whatsoever, including without
        limitation if you breach the Terms.
        <br /> Upon termination, your right to use the Service will immediately
        cease. If you wish to terminate your account, you may simply discontinue
        using the Service.
        <br /> All provisions of the Terms which by their nature should survive
        termination shall survive termination, including, without limitation,
        ownership provisions, warranty disclaimers, indemnity and limitations of
        liability.
      </p>
      <h2 id="Indemnification">Indemnification</h2>
      <p>
        You agree to defend, indemnify and hold harmless DEVSOFT and its
        licensee and licensors, and their employees, contractors, agents,
        officers and directors, from and against any and all claims, damages,
        obligations, losses, liabilities, costs or debt, and expenses (including
        but not limited to attorney’s fees), resulting from or arising out of a)
        your use and access of the Service, by you or any person using your
        account and password; b) a breach of these Terms, or c) Content posted
        on the Service.
      </p>
      <h2 id="Limitation-Of-Liability">Limitation Of Liability</h2>
      <p>
        In no event shall DEVSOFT, nor its directors, employees, partners,
        agents, suppliers, or affiliates, be liable for any indirect,
        incidental, special, consequential or punitive damages, including
        without limitation, loss of profits, data, use, goodwill, or other
        intangible losses, resulting from (i) your access to or use of or
        inability to access or use the Service; (ii) any conduct or content of
        any third party on the Service; (iii) any content obtained from the
        Service; and (iv) unauthorized access, use or alteration of your
        transmissions or content, whether based on warranty, contract, tort
        (including negligence) or any other legal theory, whether or not we have
        been informed of the possibility of such damage, and even if a remedy
        set forth herein is found to have failed of its essential purpose.
      </p>
      <h2 id="Disclaimer">Disclaimer</h2>
      <p>
        Your use of the Service is at your sole risk. The Service is provided on
        an “AS IS” and “AS AVAILABLE” basis. The Service is provided without
        warranties of any kind, whether express or implied, including, but not
        limited to, implied warranties of merchantability, fitness for a
        particular purpose, non-infringement or course of performance.
        <br /> DEVSOFT its subsidiaries, affiliates, and its licensors do not
        warrant that a) the Service will function uninterrupted, secure or
        available at any particular time or location; b) any errors or defects
        will be corrected; c) the Service is free of viruses or other harmful
        components; or d) the results of using the Service will meet your
        requirements.
      </p>
      <h2 id="Exclusions">Exclusions</h2>
      <p>
        Without limiting the generality of the foregoing and notwithstanding any
        other provision of these terms, under no circumstances will DEVSOFT ever
        be liable to you or any other person for any indirect, incidental,
        consequential, special, punitive or exemplary loss or damage arising
        from, connected with, or relating to your use of the Service, these
        Terms, the subject matter of these Terms, the termination of these Terms
        or otherwise, including but not limited to personal injury, loss of
        data, business, markets, savings, income, profits, use, production,
        reputation or goodwill, anticipated or otherwise, or economic loss,
        under any theory of liability (whether in contract, tort, strict
        liability or any other theory or law or equity), regardless of any
        negligence or other fault or wrongdoing (including without limitation
        gross negligence and fundamental breach) by DEVSOFT or any person for
        whom DEVSOFT is responsible, and even if DEVSOFT has been advised of the
        possibility of such loss or damage being incurred.
      </p>
      <h2 id="Governing-Law">Governing Law</h2>
      <p>
        These Terms shall be governed and construed in accordance with the laws
        of Republic of Estonia, without regard to its conflict of law
        provisions.
        <br /> Our failure to enforce any right or provision of these Terms will
        not be considered a waiver of those rights. If any provision of these
        Terms is held to be invalid or unenforceable by a court, the remaining
        provisions of these Terms will remain in effect. These Terms constitute
        the entire agreement between us regarding our Service, and supersede and
        replace any prior agreements we might have between us regarding the
        Service.
      </p>
      <h2 id="Changes">Changes</h2>
      <p>
        We reserve the right, at our sole discretion, to modify or replace these
        Terms at any time. If a revision is material we will try to provide at
        least 30 days notice prior to any new terms taking effect. What
        constitutes a material change will be determined at our sole discretion.
        <br /> By continuing to access or use our Service after those revisions
        become effective, you agree to be bound by the revised terms. If you do
        not agree to the new terms, you must stop using the service.
      </p>
      <h2 id="Privacy-Policy">Privacy Policy</h2>
      <p>
        Please refer to our Privacy Policy. You agree that they constitute part
        of these terms. You must read our Privacy Policy before you use the
        Service.
      </p>
      <h2 id="Contact-Us">Contact Us</h2>
      <p>If you have any questions about these Terms, please contact us.</p>
    </Page>
  )
}
