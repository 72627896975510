import { useCallback } from 'react'
import { useDispatch } from 'react-redux'
import {
  showAlert as showAlertAction,
  hideAlert as hideAlertAction
} from '../redux/actions/alert'

export const useAlert = () => {
  const dispatch = useDispatch()

  const showAlert = useCallback(({ type, title, message }) => {
    dispatch(showAlertAction({ type, title, message }))
  })

  const hideAlert = useCallback(() => {
    dispatch(hideAlertAction())
  })

  return { showAlert, hideAlert }
}
