import React, { Fragment, useCallback, useEffect, useState } from 'react'
import { LoadingIndicator } from '../components/LoadingIndicator'
import { RecipesListView } from '../components/lists/RecipesListView'
import { useSelector } from 'react-redux'
import { isEmpty, includes, toLower, filter, size, slice } from 'lodash'
import { RecipeListControls } from './RecipeListControls'
import { Pagination } from '@brewcomputer/ui'
import { RECIPES_PAGE_SIZE } from '../../common/constants'
import { useCountMyRecipes, useGetMyRecipes } from '../hooks/queries'
import { useRemoveRecipe } from '../hooks/commands'
import { searchPlaceholders } from '../texts'

const getProps = state => {
  const recipes = state.myRecipes.data
  const isLoading = state.myRecipes.isLoading && isEmpty(recipes)
  return {
    recipes,
    isLoading
  }
}

export const MyRecipes = () => {
  const { isLoading, recipes } = useSelector(getProps)

  const [q, setQ] = useState('')
  const [page, setPage] = useState(0)

  const onSearch = useCallback(search => {
    setQ(search)
    setPage(0)
  }, [])

  const goToPage = useCallback(pageNum => {
    setPage(pageNum)
  }, [])

  const filteredRecipes = filter(recipes, recipe =>
    includes(toLower(recipe.name), toLower(q))
  )
  const recipesOnPage = slice(
    filteredRecipes,
    page * RECIPES_PAGE_SIZE,
    Math.min(size(filteredRecipes), (page + 1) * RECIPES_PAGE_SIZE)
  )

  const removeRecipe = useRemoveRecipe()

  const { request: getMyRecipes } = useGetMyRecipes()
  const { request: countMyRecipes } = useCountMyRecipes()

  useEffect(() => {
    getMyRecipes()
    countMyRecipes()
  }, [])

  return (
    <Fragment>
      <RecipeListControls
        showButtons={true}
        searchPlaceholder={searchPlaceholders.myRecipes}
        onSearch={onSearch}
      />
      {isLoading ? (
        <LoadingIndicator />
      ) : (
        <RecipesListView
          recipes={recipesOnPage}
          onRemoveRecipe={removeRecipe}
        />
      )}
      <Pagination
        pageSize={RECIPES_PAGE_SIZE}
        currentPage={page}
        itemsCount={size(filteredRecipes)}
        goToPage={goToPage}
      />
    </Fragment>
  )
}
