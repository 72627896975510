import { createCommandActions, createQueryActions } from './common'

export const userGetQuery = createQueryActions('USER_GET')

export const userEditAction = createCommandActions(
  'USER_EDIT',
  ({ aggregateId, payload, error }) => ({
    userId: aggregateId,
    changes: payload,
    error
  })
)

export const userEditUnitSettingsAction = createCommandActions(
  'USER_EDIT_UNIT_SETTINGS',
  ({ aggregateId, payload, error }) => ({
    userId: aggregateId,
    changes: payload,
    error
  })
)

export const userAddEquipmentAction = createCommandActions(
  'USER_ADD_EQUIPMENT',
  ({ aggregateId, payload, error }) => ({
    userId: aggregateId,
    data: payload.data,
    id: payload.id,
    error
  })
)

export const userEditEquipmentAction = createCommandActions(
  'USER_EDIT_EQUIPMENT',
  ({ aggregateId, payload, error }) => ({
    userId: aggregateId,
    changes: payload.changes,
    id: payload.id,
    error
  })
)

export const userRemoveEquipmentAction = createCommandActions(
  'USER_REMOVE_EQUIPMENT',
  ({ aggregateId, payload, error }) => ({
    userId: aggregateId,
    id: payload.id,
    error
  })
)
