import React, { PureComponent } from 'react'
import { colors } from '../colors/colors'

export class ExportIcon extends PureComponent {
  static defaultProps = {
    fill: colors.ACCENT_BLUE
  }
  render() {
    return (
      <svg
        width="18"
        height="18"
        fill="none"
        xmlns="http://www.w3.org/2000/svg">
        <g clipPath="url(#clip0)">
          <path
            d="M14.512 7.53A5.617 5.617 0 0 0 9 3a5.622 5.622 0 0 0-4.987 3.03A4.496 4.496 0 0 0 0 10.5C0 12.982 2.018 15 4.5 15h9.75c2.07 0 3.75-1.68 3.75-3.75a3.732 3.732 0 0 0-3.488-3.72zM12.75 9.75L9 13.5 5.25 9.75H7.5v-3h3v3h2.25z"
            fill={this.props.fill}
          />
        </g>
        <defs>
          <clipPath id="clip0">
            <path fill="#fff" d="M0 0h18v18H0z" />
          </clipPath>
        </defs>
      </svg>
    )
  }
}
