import PropTypes from 'prop-types'
import { isEmpty } from 'lodash'
import React, { PureComponent } from 'react'
import styled, { css } from 'styled-components'
import figmaColors from '../colors/figmaColors'
import { AccentButton } from '../buttons/AccentButton'
import { MultilineInput } from '../multilineInput/MultilineInput'

const Editor = styled.div`
  padding: 16px 24px;
  flex-shrink: 0;
  flex-grow: 0;
  background-color: ${figmaColors.white};
`

const inputStyle = css`
  margin-bottom: 8px;
`

const Input = styled(MultilineInput)`
  ${inputStyle};
`

const InputPlaceholder = styled.div`
  ${inputStyle};
  margin-bottom: 12px;
`

export class ChatInput extends PureComponent {
  static propTypes = {
    placeholder: PropTypes.string,
    buttonTitle: PropTypes.string,
    value: PropTypes.string,
    showInput: PropTypes.bool,
    onSendMessage: PropTypes.func
  }
  static defaultProps = {
    placeholder: 'Type your message here...',
    buttonTitle: 'Send message'
  }

  inputRef = React.createRef()

  onButtonPress = () => {
    if (this.inputRef.current) {
      const value = this.inputRef.current.getValue()
      if (!isEmpty(value)) {
        this.onSendMessage(value)
        this.inputRef.current.clear()
        this.inputRef.current.focus()
      }
    }
  }

  onSendMessage = value => {
    this.props.onSendMessage(value)
  }

  render() {
    const { placeholder, buttonTitle, showInput } = this.props
    return (
      <Editor>
        {showInput ? (
          <Input
            ref={this.inputRef}
            value={''}
            fontSize="medium"
            backgroundVisibility="visible"
            borderVisibility="focus"
            backgroundColor={figmaColors.blue_gray_50}
            submitOnBlur={false}
            placeholder={placeholder}
            onChange={this.onSendMessage}
          />
        ) : (
          <InputPlaceholder>&nbsp;</InputPlaceholder>
        )}
        <AccentButton title={buttonTitle} onPress={this.onButtonPress} />
      </Editor>
    )
  }
}
