import React, { Fragment } from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { isEmpty } from 'lodash'
import { RecipeSection, TableText } from './Controls'
import figmaColors from '../colors/figmaColors'

const Row = styled.div`
  flex-direction: row;
  justify-content: space-between;
  margin-bottom: 10px;
`

const Divider = styled.div`
  height: 0;
  margin-top: 10px;
  border-bottom: 1px solid ${figmaColors.blue_gray_100};
`

const LookupContainer = styled.div`
  align-items: flex-start;
  margin: 20px 0;
`

const Prop = ({ name, value }) => (
  <Row>
    <TableText>{name}</TableText>
    <TableText fontWeight="bold">{value}</TableText>
  </Row>
)

export const BatchRecipe = ({
  name,
  style,
  original_gravity,
  final_gravity,
  alcohol_by_volume,
  ibu_estimate,
  color_estimate,
  equipment,
  renderRecipeLink,
  renderEquipmentLookup
}) => {
  return (
    <RecipeSection title="Recipe" recipeLink={renderRecipeLink()}>
      <Prop name="Name" value={name} />
      <Prop name="Style" value={style} />
      <Prop name="Original Gravity" value={original_gravity} />
      <Prop name="Final Gravity" value={final_gravity} />
      <Prop name="ABV" value={alcohol_by_volume} />
      <Prop name="IBU" value={ibu_estimate} />
      <Prop name="Color" value={color_estimate} />
      <Divider />
      <LookupContainer>{renderEquipmentLookup()}</LookupContainer>
      {!isEmpty(equipment) && (
        <Fragment>
          <Prop name="Name" value={equipment.name} />
          <Prop name="Boil Volume" value={equipment.maximum_volume} />
          <Prop name="Boil Rate" value={equipment.boil_rate_per_hour} />
          <Prop name="Loss" value={equipment.loss} />
        </Fragment>
      )}
    </RecipeSection>
  )
}

BatchRecipe.propTypes = {
  name: PropTypes.string,
  style: PropTypes.string,
  original_gravity: PropTypes.string,
  final_gravity: PropTypes.string,
  alcohol_by_volume: PropTypes.string,
  ibu_estimate: PropTypes.string,
  color_estimate: PropTypes.string,
  renderEquipmentLookup: PropTypes.any,
  renderRecipeLink: PropTypes.any
}

BatchRecipe.displayName = 'BatchRecipe'
