import produce from 'immer'
import { map, get, isEmpty } from 'lodash'
import { recipesSearch, RECIPES_SEARCH_CLEAR } from '../actions/recipe'

const initialState = {
  isLoading: false,
  isEmpty: false,
  query: '',
  result: []
}

const reducer = produce((draft, action) => {
  switch (action.type) {
    case recipesSearch.types.request: {
      draft.isLoading = true
      draft.query = get(action, 'payload.query.args.query', '')
      break
    }
    case recipesSearch.types.success: {
      draft.isLoading = false
      if (isEmpty(action.payload.result)) {
        draft.result = []
        draft.isEmpty = true
      } else {
        draft.result = map(action.payload.result, 'recipeId')
        draft.isEmpty = false
      }
      break
    }
    case recipesSearch.types.failure: {
      draft.isLoading = false
      break
    }
    case RECIPES_SEARCH_CLEAR: {
      draft.isLoading = false
      draft.isEmpty = false
      draft.result = []
      draft.query = ''
    }
  }
}, initialState)

export default reducer
