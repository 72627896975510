import { map } from 'lodash'
import styled from 'styled-components'
import React, { useContext, useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import { LoadingIndicator } from '../LoadingIndicator'
import { useSelector } from 'react-redux'
import { useGetUploads } from '../../hooks/queries'
import { ResolveContext } from '@resolve-js/react-hooks'
import { getCDNBasedUrl, getToken } from '@resolve-js/module-uploader'
import { getRecipeLink } from '../../links'
import { listItemStyle } from './styles'

const DIRECTORY = 'images'

const Item = styled.div`
  ${listItemStyle};
  padding: 8px 0;

  img,
  a {
    margin: 0 12px;
  }
`

export const AdminUploads = () => {
  const { request: getUploads, selector } = useGetUploads({})
  const { cdnUrl: CDNUrl } = useContext(ResolveContext)
  const [token, setToken] = useState(null)

  useEffect(() => {
    getUploads()
    getToken({ dir: DIRECTORY }).then((staticToken) => {
      setToken(staticToken)
    })
  }, [])

  const { data, status } = useSelector(selector)

  if (status !== 'ready' || token == null) {
    return <LoadingIndicator />
  }

  return (
    <div>
      {map(data, (item) => (
        <Item key={item.id}>
          <img
            width={128}
            src={getCDNBasedUrl({
              CDNUrl,
              dir: DIRECTORY,
              uploadId: item.id,
              token,
            })}
          />
          <Link to={`/admin/user/${item.authorId}`}>Go To User</Link>
          <Link to={getRecipeLink(item.recipeId)}>Go To Recipe</Link>
        </Item>
      ))}
    </div>
  )
}
