import React, { Fragment, useEffect, useCallback } from 'react'
import { LoadingIndicator } from '../components/LoadingIndicator'
import { RecipesListView } from '../components/lists/RecipesListView'
import { useSelector, useDispatch } from 'react-redux'
import {
  useGetPublicRecipes,
  useCountPublicRecipes,
  useSearchRecipes
} from '../hooks/queries'
import { isEmpty } from 'lodash'
import { recipesGoToPage, recipesSearchClear } from '../redux/actions/recipe'
import { RecipeListControls } from './RecipeListControls'
import { Pagination } from '@brewcomputer/ui'
import { RECIPES_PAGE_SIZE } from '../../common/constants'
import { searchPlaceholders } from '../texts'

const userIdSelector = state => state.user.id

const searchSelector = state => state.recipesSearch

const recipesSelector = state => {
  const { isLoading, data, page, count } = state.publicRecipes
  return {
    recipes: data,
    page,
    count,
    isLoading: isLoading && isEmpty(data)
  }
}

export const PublicRecipes = () => {
  const dispatch = useDispatch()

  const userId = useSelector(userIdSelector)
  const { isLoading, recipes, page, count } = useSelector(recipesSelector)
  const search = useSelector(searchSelector)

  const goToPage = useCallback(pageNum => {
    dispatch(recipesGoToPage(pageNum))
  }, [])

  const { request: getPublicRecipes } = useGetPublicRecipes({
    pageNumber: page,
    pageSize: RECIPES_PAGE_SIZE,
    ids: search.result
  })
  const { request: countPublicRecipes } = useCountPublicRecipes({
    ids: search.result
  })

  useEffect(
    () => {
      if (!search.isEmpty) {
        getPublicRecipes()
        countPublicRecipes()
      }
    },
    [page, search.result, search.query]
  )

  const { request: searchRecipes } = useSearchRecipes()

  const onSearch = useCallback(q => {
    if (isEmpty(q)) {
      dispatch(recipesSearchClear())
    } else {
      searchRecipes(q)
    }
  }, [])

  return (
    <Fragment>
      <RecipeListControls
        showButtons={false}
        searchTimeout={500}
        lastQuery={search.query}
        searchPlaceholder={searchPlaceholders.publicRecipes}
        onSearch={onSearch}
      />
      {isLoading ? (
        <LoadingIndicator />
      ) : (
        <RecipesListView userId={userId} isPublicRecipes recipes={recipes} />
      )}
      <Pagination
        currentPage={page}
        pageSize={RECIPES_PAGE_SIZE}
        itemsCount={count}
        goToPage={goToPage}
      />
    </Fragment>
  )
}
