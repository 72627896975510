import { has, isEmpty, map, defaults } from 'lodash'
import { convert } from 'brewcalc'
import {
  beautifyUnit,
  getUnitsList,
  batchVolumeUnits,
  fermentableAmountUnits,
  cultureAmountUnits,
  miscAmountUnits,
} from '../beerjson/units'

export const convertAmount = (measurable, convertTo) =>
  convertMeasurable(measurable, convertTo, {
    customUnitList: fermentableAmountUnits,
  })

export const convertCultureAmount = (measurable) =>
  convertMeasurable(measurable, null, {
    convertUnitListValues: false,
    customUnitList: cultureAmountUnits,
  })

export const convertMiscAmount = (measurable) =>
  convertMeasurable(measurable, null, {
    convertUnitListValues: false,
    customUnitList: miscAmountUnits,
  })

export const convertBatchVolume = (measurable, convertTo) =>
  convertMeasurable(measurable, convertTo, { customUnitList: batchVolumeUnits })

const defaultOptions = {
  createUnitList: true,
  convertUnitListValues: true,
  customUnitList: null,
}

export const convertMeasurable = (measurable, convertTo, options = {}) => {
  if (!has(measurable, 'value') || !has(measurable, 'unit')) {
    return measurable
  }

  if (measurable.value == null) {
    return {
      ...measurable,
      unit: convertTo,
    }
  }

  const { precision, createUnitList, convertUnitListValues, customUnitList } =
    defaults(options, defaultOptions)

  let units
  if (createUnitList) {
    const unitList = customUnitList || getUnitsList(measurable.unit)
    units = map(unitList, (unit) => ({
      value: convertUnitListValues
        ? convert(measurable.value, measurable.unit, unit, precision)
        : undefined,
      label: beautifyUnit(unit),
      unit,
    }))
  }

  if (isEmpty(convertTo)) {
    return {
      ...measurable,
      units,
    }
  }

  return {
    value: convert(measurable.value, measurable.unit, convertTo, precision),
    unit: convertTo,
    units,
  }
}
