import { isNil } from 'lodash'
import React, { Fragment, PureComponent } from 'react'
import { convert, round } from '@brewcomputer/utils'
import {
  LayoutGroup,
  renderMeasurableFieldEditor,
  renderNumberFieldEditor,
  ToolTitle
} from './ToolsUtils'

const get = (value, defaultValue) => {
  return isNil(value) ? defaultValue : value
}

export class RefractometerTool extends PureComponent {
  constructor(props) {
    super(props)
    const obj = {
      calibration: {
        refractometerReading: { value: 20, unit: 'brix' },
        hydrometerReading: { value: 1.0715, unit: 'sg' },
        correctionFactor: null
      },
      unfermentedWort: {
        originalGravityReading: { value: 15, unit: 'brix' },
        originalGravityCorrected: { value: null, unit: 'sg' }
      },
      fermentingdWort: {
        originalGravityReading: { value: 20, unit: 'brix' },
        finalGravityReading: { value: 10, unit: 'brix' },
        originalGravityCorrected: { value: null, unit: 'sg' },
        finalGravityCorrected: { value: null, unit: 'sg' },
        alcoholByVolume: { value: null, unit: '%' },
        alcoholByWeight: { value: null, unit: '%' }
      }
    }
    this._calculateCorrectionFactor(obj)
    this._calculateUnfermentedWort(obj)
    this._calculateFermentingdWort(obj)
    this.state = { obj: obj }
  }

  _calculateCorrectionFactor = obj => {
    const correctionFactor =
      convert(
        get(obj.calibration.refractometerReading.value, 0),
        obj.calibration.refractometerReading.unit,
        'brix',
        10
      ) /
      convert(
        get(obj.calibration.hydrometerReading.value, 0),
        obj.calibration.hydrometerReading.unit,
        'brix',
        10
      )
    obj.calibration.correctionFactor = round(correctionFactor, 5)
  }

  _calculateUnfermentedWort = obj => {
    const correctionFactor = obj.calibration.correctionFactor

    const originalGravityBrix =
      convert(
        obj.unfermentedWort.originalGravityReading.value,
        obj.unfermentedWort.originalGravityReading.unit,
        'brix',
        10
      ) / correctionFactor
    obj.unfermentedWort.originalGravityCorrected.value = convert(
      originalGravityBrix,
      'brix',
      obj.unfermentedWort.originalGravityCorrected.unit,
      4
    )
  }

  _calculateFermentingdWort = obj => {
    const correctionFactor = obj.calibration.correctionFactor

    const originalGravityBrix =
      convert(
        obj.fermentingdWort.originalGravityReading.value,
        obj.fermentingdWort.originalGravityReading.unit,
        'brix',
        10
      ) / correctionFactor
    const finalGravityBrix =
      convert(
        obj.fermentingdWort.finalGravityReading.value,
        obj.fermentingdWort.finalGravityReading.unit,
        'brix',
        10
      ) / correctionFactor
    const originalGravityCorrected = originalGravityBrix
    const finalGravityCorrected =
      1.0 -
      0.0044993 * originalGravityBrix +
      0.011774 * finalGravityBrix +
      0.00027581 * Math.pow(originalGravityBrix, 2) -
      0.0012717 * Math.pow(finalGravityBrix, 2) -
      0.00000728 * Math.pow(originalGravityBrix, 3) +
      0.000063293 * Math.pow(finalGravityBrix, 3)
    const alcoholByVolume =
      ((1 / 0.8192) *
        (originalGravityBrix -
          (0.1808 * originalGravityBrix +
            0.8192 *
              (668.72 * finalGravityCorrected -
                463.37 -
                205.347 * Math.pow(finalGravityCorrected, 2))))) /
      (2.0665 - 0.010665 * originalGravityBrix)
    const alcoholByWeight = alcoholByVolume / 1.26

    obj.fermentingdWort.originalGravityCorrected.value = convert(
      originalGravityCorrected,
      'brix',
      obj.fermentingdWort.originalGravityCorrected.unit,
      4
    )
    obj.fermentingdWort.finalGravityCorrected.value = convert(
      finalGravityCorrected,
      'sg',
      obj.fermentingdWort.finalGravityCorrected.unit,
      4
    )
    obj.fermentingdWort.alcoholByVolume.value = round(alcoholByVolume, 2)
    obj.fermentingdWort.alcoholByWeight.value = round(alcoholByWeight, 2)
  }

  _calculate = (obj, fieldName) => {
    if (
      fieldName === 'calibration.refractometerReading' ||
      fieldName === 'calibration.hydrometerReading'
    ) {
      this._calculateCorrectionFactor(obj)
    } else if (fieldName === 'calibration.correctionFactor') {
      obj.calibration.refractometerReading.value = 0
      obj.calibration.hydrometerReading.value = 0
    }
    this._calculateUnfermentedWort(obj)
    this._calculateFermentingdWort(obj)
  }

  _onChange = (obj, fieldName) => {
    const newObj = Object.assign({}, obj)
    this._calculate(newObj, fieldName)
    this.setState({ obj: newObj })
  }

  render() {
    const { obj } = this.state
    return (
      <Fragment>
        <ToolTitle text={'Refractometer'} />

        <LayoutGroup caption={'Calibration'}>
          {renderMeasurableFieldEditor(
            'Refractometer Reading',
            obj,
            'calibration.refractometerReading',
            false,
            this._onChange
          )}
          {renderMeasurableFieldEditor(
            'Hydrometer Reading',
            obj,
            'calibration.hydrometerReading',
            false,
            this._onChange
          )}
          {renderNumberFieldEditor(
            'Wort Correction Factor',
            obj,
            'calibration.correctionFactor',
            this._onChange,
            false
          )}
        </LayoutGroup>

        <LayoutGroup caption={'Unfermented Wort Gravity'}>
          {renderMeasurableFieldEditor(
            'Original Gravity Reading',
            obj,
            'unfermentedWort.originalGravityReading',
            false,
            this._onChange
          )}
          {renderMeasurableFieldEditor(
            'Original Gravity Corrected',
            obj,
            'unfermentedWort.originalGravityCorrected',
            true,
            null
          )}
        </LayoutGroup>

        <LayoutGroup caption={'Fermenting Wort Gravity'}>
          {renderMeasurableFieldEditor(
            'Original Gravity Reading',
            obj,
            'fermentingdWort.originalGravityReading',
            false,
            this._onChange
          )}
          {renderMeasurableFieldEditor(
            'Final Gravity Reading',
            obj,
            'fermentingdWort.finalGravityReading',
            false,
            this._onChange
          )}
          {renderMeasurableFieldEditor(
            'Original Gravity Corrected',
            obj,
            'fermentingdWort.originalGravityCorrected',
            true,
            null
          )}
          {renderMeasurableFieldEditor(
            'Final Gravity Corrected',
            obj,
            'fermentingdWort.finalGravityCorrected',
            true,
            null
          )}
          {renderMeasurableFieldEditor(
            'Alcohol by Volume',
            obj,
            'fermentingdWort.alcoholByVolume',
            true,
            null
          )}
          {renderMeasurableFieldEditor(
            'Alcohol by Weight',
            obj,
            'fermentingdWort.alcoholByWeight',
            true,
            null
          )}
        </LayoutGroup>
      </Fragment>
    )
  }
}
