import React, { PureComponent } from 'react'
import styled from 'styled-components'

const StyledWrapper = styled.div`
  flex-direction: row;
`

export class Wrapper extends PureComponent {
  render() {
    return <StyledWrapper>{this.props.children}</StyledWrapper>
  }
}
