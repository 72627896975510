/* global window requestAnimationFrame */
import { throttle } from 'lodash'
import React, { PureComponent } from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'

const Container = styled.div`
  position: relative;
  height: 0px;
  visibility: ${props => (props.collapsed ? 'hidden' : 'initial')};
  overflow: ${props => (props.collapsed ? 'hidden' : props.contentOverflow)};
  transition-property: height;
  transition-duration: ${props => props.duration}ms;
  transition-timing-function: ease-in-out;
`

Container.propTypes = {
  duration: PropTypes.number,
  collapsed: PropTypes.bool,
  contentOverflow: PropTypes.oneOf(['hidden', 'visible'])
}

export class CollapsibleView extends PureComponent {
  static propTypes = {
    collapsed: PropTypes.bool,
    duration: PropTypes.number,
    children: PropTypes.node
  }

  static defaultProps = {
    collapsed: true,
    duration: 100
  }

  constructor(props) {
    super(props)
    this._container = null
    this.state = {
      overflow: 'hidden'
    }
  }

  getRef = ref => {
    this._container = ref
  }

  componentDidMount() {
    window.addEventListener('resize', this._handleLayoutChange)
  }

  componentDidUpdate(prevProps) {
    if (!this.props.collapsed && prevProps.collapsed) {
      this._container.style.height = this._container.scrollHeight + 'px'
    } else if (!prevProps.collapsed && this.props.collapsed) {
      this._container.style.height = '0px'
    }
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this._handleLayoutChange)
  }

  _handleLayoutChange = throttle(() => {
    if (!this.props.collapsed) {
      this._container.style.height = 'auto'
      requestAnimationFrame(() => {
        if (this._container) {
          this._container.style.height = this._container.scrollHeight + 'px'
        }
      })
    }
  }, 500)

  _onTransitionEnd = () => {
    const { collapsed } = this.props
    const { overflow } = this.state

    if (!collapsed && overflow === 'hidden') {
      this.setState({ overflow: 'visible' })
    } else if (collapsed && overflow === 'visible') {
      this.setState({ overflow: 'hidden' })
    }
  }

  render() {
    const { collapsed, duration, children } = this.props
    const { overflow } = this.state
    return (
      <Container
        ref={this.getRef}
        collapsed={collapsed}
        duration={duration}
        contentOverflow={overflow}
        onTransitionEnd={this._onTransitionEnd}>
        {children}
      </Container>
    )
  }
}
