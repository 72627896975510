import React, { useState, useEffect, useCallback } from 'react'
import { map, filter, concat } from 'lodash'
import { useSelector } from 'react-redux'
import { Lookup } from '@brewcomputer/ui'
import { createIngredientLabel, findById, isMatch } from './common'
import { useGetIngredients } from '../../hooks/queries'

const zeroConcentration = {
  value: 0,
  unit: 'ppm',
}

const defaultIngredient = {
  fermentable_additions: {
    name: 'My Fermentable',
    type: 'grain',
    origin: '',
    supplier: '',
    grain_group: 'base',
    yield: {
      fine_grind: {
        value: 95,
        unit: '%',
      },
      fine_coarse_difference: {
        value: 1.5,
        unit: '%',
      },
      potential: {
        value: 1.0437,
        unit: 'sg',
      },
    },
    color: {
      value: 12.5,
      unit: 'SRM',
    },
  },
  hop_additions: {
    name: 'My Hop',
    origin: '',
    form: 'pellet',
    alpha_acid: {
      value: 14.75,
      unit: '%',
    },
    beta_acid: {
      value: 5.6,
      unit: '%',
    },
    percent_lost: {
      value: 0,
      unit: '%',
    },
  },
  culture_additions: {
    name: 'My Culture',
    type: 'ale',
    form: 'dry',
    laboratory: '',
    attenuation: {
      minimum: {
        value: 75,
        unit: '%',
      },
      maximum: {
        value: 75,
        unit: '%',
      },
    },
  },
  miscellaneous_additions: {
    name: 'My Spice',
    type: 'spice',
    use_for: 'Flavor',
  },
  water_additions: {
    name: 'My Water Profile',
    calcium: zeroConcentration,
    magnesium: zeroConcentration,
    sodium: zeroConcentration,
    chloride: zeroConcentration,
    sulfate: zeroConcentration,
    bicarbonate: zeroConcentration,
  },
}

export const IngredientLookup = ({
  title,
  ingredientName,
  ingredientType,
  onSelect,
}) => {
  const { request: getIngredients, selector } =
    useGetIngredients(ingredientType)

  useEffect(() => {
    getIngredients()
  }, [])

  const { data: ingredients } = useSelector(selector)

  const fixedItem = {
    label: `Add a new ${ingredientName}...`,
    value: 'create_new_item',
    isFixed: true,
  }

  const items = map(ingredients, (ingredient) => ({
    label: createIngredientLabel(ingredient, ingredientType),
    value: ingredient.id,
  }))

  const placeholder = `Select ${ingredientName}`

  const [filterString, setFilterString] = useState('')
  const filteredItems = filter(items, (item) =>
    isMatch(item.label, filterString)
  )

  const onSelectIngredient = useCallback((ingredientId) => {
    if (ingredientId === fixedItem.value) {
      onSelect(defaultIngredient[ingredientType])
    } else {
      onSelect(findById(ingredients, ingredientId))
    }
  })

  const allItems = concat([fixedItem], filteredItems)

  return (
    <Lookup
      text={title}
      items={allItems}
      onChange={onSelectIngredient}
      search
      searchPlaceholder={placeholder}
      searchQuery={filterString}
      onSearchChange={setFilterString}
    />
  )
}

IngredientLookup.displayName = 'IngredientLookup'
