export default {
  blue_500: '#109bf1',
  blue_50: '#e3f3fc',
  blue_100: '#bbe0fa',
  blue_200: '#8fcdf7',
  blue_300: '#63b8f4',
  blue_400: '#3ea9f3',
  blue_600: '#058de3',
  blue_700: '#007bd1',
  blue_800: '#006abf',
  blue_900: '#004ca1',
  primary_50: '#e3f3fc',
  primary_100: '#bbe0fa',
  primary_200: '#8fcdf7',
  primary_300: '#63b8f4',
  primary_400: '#3ea9f3',
  primary_500: '#109bf1',
  primary_600: '#058de3',
  primary_700: '#007bd1',
  primary_800: '#006abf',
  primary_900: '#004ca1',
  black: '#192a3e',
  white: '#ffffff',
  dark_blue_50: '#e6f2f6',
  dark_blue_100: '#c3deeb',
  dark_blue_200: '#a1c9de',
  dark_blue_300: '#84b4d0',
  dark_blue_400: '#70a5c6',
  dark_blue_500: '#6197be',
  dark_blue_600: '#568ab2',
  dark_blue_700: '#4b79a1',
  dark_blue_800: '#42698f',
  dark_blue_900: '#334d6e',
  gray_50: '#f9f9f9',
  gray_100: '#f5f5f5',
  gray_200: '#eeeeee',
  gray_300: '#e0e0e0',
  gray_400: '#bdbdbd',
  gray_500: '#9e9e9e',
  gray_600: '#757575',
  gray_700: '#616161',
  gray_800: '#424242',
  gray_900: '#212121',
  blue_gray_50: '#edf1f7',
  blue_gray_100: '#d3dce5',
  blue_gray_200: '#b9c5d2',
  blue_gray_300: '#9dadbe',
  blue_gray_400: '#889aae',
  blue_gray_500: '#73889f',
  blue_gray_600: '#65798d',
  blue_gray_700: '#546575',
  blue_gray_800: '#45515f',
  blue_gray_900: '#323c47',
  green_50: '#e5f9ec',
  green_100: '#c1f0d0',
  green_200: '#97e6b2',
  green_300: '#64dd92',
  green_400: '#2ed479',
  green_500: '#00cb5f',
  green_600: '#00bb55',
  green_700: '#00a847',
  green_800: '#00963b',
  green_900: '#007527',
  red_50: '#fbeaef',
  red_100: '#f6cad3',
  red_200: '#eb949c',
  red_300: '#e16975',
  red_400: '#ea4255',
  red_500: '#ea333d',
  red_600: '#e3313d',
  red_700: '#d22d36',
  red_800: '#c62a2f',
  red_900: '#b82625',
  amber_50: '#fefce7',
  amber_100: '#fdf6c5',
  amber_200: '#fcf0a0',
  amber_300: '#fbeb7e',
  amber_400: '#fae565',
  amber_500: '#fae252',
  amber_600: '#f6d04c',
  amber_700: '#f1b944',
  amber_800: '#eca13c',
  amber_900: '#e57a30'
}
