import React from 'react'
import PropTypes from 'prop-types'
import figmaColors from '../colors/figmaColors'

export const ChatIcon = ({ fill }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="64"
    height="64"
    fill="none"
    viewBox="0 0 64 64">
    <mask id="a" fill="#fff">
      <path
        fillRule="evenodd"
        d="M8 0a8 8 0 00-8 8v36a8 8 0 008 8h30.25l7.05 9.4a4 4 0 007.2-2.4v-7H56a8 8 0 008-8V8a8 8 0 00-8-8H8z"
        clipRule="evenodd"
      />
    </mask>
    <path
      fill={fill}
      d="M38.25 52l3.2-2.4-1.2-1.6h-2v4zm7.05 9.4l-3.2 2.4 3.2-2.4zm7.2-9.4v-4h-4v4h4zM4 8a4 4 0 014-4v-8A12 12 0 00-4 8h8zm0 36V8h-8v36h8zm4 4a4 4 0 01-4-4h-8A12 12 0 008 56v-8zm30.25 0H8v8h30.25v-8zM48.5 59l-7.05-9.4-6.4 4.8 7.05 9.4 6.4-4.8zm0 0a.3.3 0 010 .05.17.17 0 01.02-.05l.02-.02-.02.01a.17.17 0 01-.06.02h.02a.18.18 0 01.05.02s0 .01 0 0L48.5 59l-6.4 4.8c4.61 6.15 14.4 2.89 14.4-4.8h-8zm0-7v7h8v-7h-8zm7.5-4h-3.5v8H56v-8zm4-4a4 4 0 01-4 4v8a12 12 0 0012-12h-8zm0-36v36h8V8h-8zm-4-4a4 4 0 014 4h8A12 12 0 0056-4v8zM8 4h48v-8H8v8z"
      mask="url(#a)"
    />
    <path fill={fill} d="M16 24h32v4H16zM16 32h32v4H16zM16 16h32v4H16z" />
  </svg>
)

ChatIcon.propTypes = {
  fill: PropTypes.string
}

ChatIcon.defaultProps = {
  fill: figmaColors.blue_gray_300
}
