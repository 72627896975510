import React, { PureComponent } from 'react'
import { ResponsiveTable } from '../responsiveTable/ResponsiveTable'
import { map, isEmpty } from 'lodash'
import { Ingredient } from './Ingredient'
import {
  getCultureFields,
  getFermentableFields,
  getHopFields,
  getMiscFields,
  getWaterProfileFields,
} from '@brewcomputer/utils'

const ingredientFieldsExtractors = {
  fermentable_additions: getFermentableFields,
  culture_additions: getCultureFields,
  hop_additions: getHopFields,
  miscellaneous_additions: getMiscFields,
  water_additions: getWaterProfileFields,
}

export class IngredientsTable extends PureComponent {
  renderRow = (ingredient, itemIndex, visibleColumnsCount) => {
    const { ingredientType, editIngredient, removeIngredient } = this.props

    return (
      <Ingredient
        key={ingredient.id}
        id={ingredient.id}
        ingredientType={ingredientType}
        ingredient={ingredientFieldsExtractors[ingredientType](ingredient)}
        visibleColumnsCount={visibleColumnsCount}
        onEditIngredient={editIngredient}
        onRemoveIngredient={removeIngredient}
      />
    )
  }

  render() {
    const { ingredients, ingredientType } = this.props

    if (isEmpty(ingredients)) {
      return null
    }

    const columnTitles = map(
      ingredientFieldsExtractors[ingredientType]({}),
      'title'
    )

    return (
      <ResponsiveTable
        columnTitles={columnTitles}
        data={ingredients}
        renderRow={this.renderRow}
      />
    )
  }
}

IngredientsTable.displayName = 'IngredientsTable'
