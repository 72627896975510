import { isEmpty, size, toLower } from 'lodash'
import React, { Fragment, PureComponent } from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import figmaColors from '../colors/figmaColors'

const StyledText = styled.span`
  white-space: pre;
`

const StyledHighlight = styled.span`
  white-space: pre;
  color: ${props => props.highlightColor || figmaColors.amber_500};
`

export class TextHighlight extends PureComponent {
  render() {
    const { text, highlightColor, highlightedText } = this.props

    if (isEmpty(highlightedText)) {
      return text
    }

    const highlightedTextIndex = toLower(text).indexOf(toLower(highlightedText))

    if (highlightedTextIndex < 0) {
      return text
    }

    const highlightedTextEndIndex = highlightedTextIndex + size(highlightedText)

    return (
      <Fragment>
        <StyledText>{text.slice(0, highlightedTextIndex)}</StyledText>
        <StyledHighlight color={highlightColor}>
          {text.slice(highlightedTextIndex, highlightedTextEndIndex)}
        </StyledHighlight>
        <StyledText>{text.slice(highlightedTextEndIndex)}</StyledText>
      </Fragment>
    )
  }
}

TextHighlight.propTypes = {
  text: PropTypes.string,
  highlightedText: PropTypes.string,
  highlightColor: PropTypes.string
}
