import React, { Fragment, PureComponent } from 'react'
import { convert } from '@brewcomputer/utils'
import { renderNumberValueEditor, ToolTitle } from './ToolsUtils'

export class WeightUnitsTool extends PureComponent {
  constructor(props) {
    super(props)
    this.state = this._calculateState(1000, 'g')
  }

  _convert = (value, from, to) => {
    return from == to ? value : convert(value, from, to, 3)
  }

  _calculateState = (value, from) => {
    return {
      g: this._convert(value, from, 'g'),
      kg: this._convert(value, from, 'kg'),
      oz: this._convert(value, from, 'oz'),
      lb: this._convert(value, from, 'lb')
    }
  }

  _onChangeG = value => {
    this.setState(this._calculateState(value, 'g'))
  }

  _onChangeKg = value => {
    this.setState(this._calculateState(value, 'kg'))
  }

  _onChangeOz = value => {
    this.setState(this._calculateState(value, 'oz'))
  }

  _onChangeLb = value => {
    this.setState(this._calculateState(value, 'lb'))
  }

  render() {
    return (
      <Fragment>
        <ToolTitle text={'Weight Units'} />

        {renderNumberValueEditor('Grams (g)', this.state.g, this._onChangeG)}
        {renderNumberValueEditor(
          'Kilograms (kg)',
          this.state.kg,
          this._onChangeKg
        )}
        {renderNumberValueEditor(
          'Ounces (oz)',
          this.state.oz,
          this._onChangeOz
        )}
        {renderNumberValueEditor(
          'Pounds (lb)',
          this.state.lb,
          this._onChangeLb
        )}
      </Fragment>
    )
  }
}
