import { assign, get } from 'lodash'
import { setCachedUnitSettings } from '../../localStorage/unitSettings'

import { userEditUnitSettingsAction, userGetQuery } from '../actions/user'

const getUnitSettings = state => get(state, 'user.unitSettings', {})

const cachedUnitSettings = ({ getState }) => next => action => {
  switch (action.type) {
    case userGetQuery.types.success: {
      setCachedUnitSettings(action.payload.result.unitSettings)
      break
    }
    case userEditUnitSettingsAction.types.request: {
      const { changes } = action.payload
      const prevUnitSettings = getUnitSettings(getState())
      const unitSettings = assign({}, prevUnitSettings, changes)
      setCachedUnitSettings(unitSettings)
      break
    }
  }

  next(action)
}

export default cachedUnitSettings
