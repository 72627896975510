import { map } from 'lodash'
import { batchCheckListLabels } from '../../texts'
import { measurableToString } from './utils'

export const getCheckList = (checkListKeys, batchCheckList) =>
  map(checkListKeys, key => ({
    key,
    label: batchCheckListLabels[key],
    checked: !!batchCheckList[key]
  }))

// Planning

export const createWaterCheckList = (batchCheckList, totalWater) => [
  {
    key: 'planning_total_water',
    label: batchCheckListLabels.planning_total_water(
      measurableToString(totalWater)
    ),
    checked: !!batchCheckList.planning_total_water
  }
]

export const createEquipmentCheckList = batchCheckList => [
  {
    key: 'planning_equipment_check',
    label: batchCheckListLabels.planning_equipment_check,
    checked: !!batchCheckList.planning_equipment_check
  }
]

// Brewing
const preBrewKeys = [
  'pre_brew_equipment_check',
  'pre_brew_water_heat',
  'pre_brew_water_profile',
  'pre_brew_grains_mill',
  'pre_brew_workspace_clean',
  'pre_brew_hop_prepare',
  'pre_brew_yeast_prepare'
]
export const createPreBrewCheckList = batchCheckList =>
  getCheckList(preBrewKeys, batchCheckList)

const mashMeasureKeys = [
  'mash_measure_end_of_running_gravity',
  'mash_measure_bre_boil_gravity'
]
export const createMashMeasuredCheckList = batchCheckList =>
  getCheckList(mashMeasureKeys, batchCheckList)

export const createPreBoilCheckList = (
  batchCheckList,
  preBoilVolume,
  boilTime
) => {
  return [
    {
      key: 'boil_measure_pre_boil_volume',
      label: batchCheckListLabels.boil_measure_pre_boil_volume(
        measurableToString(preBoilVolume)
      ),
      checked: !!batchCheckList.boil_measure_pre_boil_volume
    },
    {
      key: 'boil_start',
      label: batchCheckListLabels.boil_start(measurableToString(boilTime)),
      checked: !!batchCheckList.boil_start
    }
  ]
}

const boilKeys = ['boil_chiller_prepare', 'boil_end']
export const createBoilCheckList = batchCheckList =>
  getCheckList(boilKeys, batchCheckList)

// Fermentation
const preFermentationKeys = [
  'fermentation_cool_wort',
  'fermentation_equipment_sanitize',
  'fermentation_transfer'
]
export const createPreFermentationCheckList = batchCheckList =>
  getCheckList(preFermentationKeys, batchCheckList)

const fermentationKeys = [
  'fermentation_measure_og',
  'fermentation_aerate',
  'fermentation_airlock',
  'fermentation_equipment_clean'
]
export const createFermentationCheckList = batchCheckList =>
  getCheckList(fermentationKeys, batchCheckList)
