import { recipesViewModelLoad } from '../redux/actions/recipe'
import {
  batchesViewModelLoad,
  batchesEventReceived,
} from '../redux/actions/batch'

import { useReduxViewModel } from '@resolve-js/redux'

export const useRecipesViewModel = (recipeId) =>
  useReduxViewModel(
    {
      name: 'RecipesVm',
      aggregateIds: [recipeId],
      aggregateArgs: {},
    },
    {
      actions: {
        stateUpdate: (query, state, initial) => {
          const actionPayload = {
            aggregateIds: query.aggregateIds,
            aggregateArgs: query.aggregateArgs,
            result: state,
          }
          return initial
            ? recipesViewModelLoad.onRequest(actionPayload)
            : recipesViewModelLoad.onSuccess(actionPayload)
        },
      },
    }
  )

export const useBatchesViewModel = (batchId) =>
  useReduxViewModel(
    {
      name: 'BatchesVm',
      aggregateIds: [batchId],
      aggregateArgs: {},
    },
    {
      actions: {
        stateUpdate: (query, state, initial) =>
          batchesViewModelLoad({
            aggregateIds: query.aggregateIds,
            aggregateArgs: query.aggregateArgs,
            result: state,
            initial,
          }),
        eventReceived: (query, event) => batchesEventReceived(event),
      },
    }
  )
