import React, { Fragment } from 'react'
import { links } from '../links'
import { renderTabLink } from '../components/navigator/Links'
import { PageTitle, Tabs, TabLink } from '@brewcomputer/ui'
import { Route, Switch } from 'react-router-dom'
import { MyRecipes } from './MyRecipes'
import { PublicRecipes } from './PublicRecipes'

export const RecipesList = () => (
  <Fragment>
    <PageTitle title="Recipes" />
    <Tabs>
      <TabLink
        title="Public Recipes"
        href={links.recipes}
        renderLink={renderTabLink}
      />
      <TabLink
        title="My Recipes"
        href={links.myRecipes}
        renderLink={renderTabLink}
      />
    </Tabs>
    <Switch>
      <Route path={links.myRecipes} component={MyRecipes} />
      <Route path={links.recipes} component={PublicRecipes} />
    </Switch>
  </Fragment>
)
