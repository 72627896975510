import { assign } from 'lodash'
import {
  RECIPE_CREATED,
  RECIPE_EDITED,
  RECIPE_REMOVED,
  RECIPE_PRIVACY_EDITED,
  RECIPE_INGREDIENT_ADDED,
  RECIPE_INGREDIENT_EDITED,
  RECIPE_INGREDIENT_REMOVED,
  RECIPE_PROCEDURE_EDITED,
  RECIPE_EQUIPMENT_EDITED,
  RECIPE_BEER_STYLE_EDITED,
  RECIPE_BATCH_EDITED,
  RECIPE_BATCH_MEASURED_EDITED,
  RECIPE_BATCH_STATUS_CHANGED,
  RECIPE_SCALED,
  RECIPE_IMAGE_ADDED,
  RECIPE_IMAGE_REMOVED,
} from '../events'
import {
  addIngredient,
  editIngredient,
  editRecipeFields,
  removeIngredient,
  editRecipeBeerStyle,
  editRecipeProcedure,
  editRecipeEquipment,
  editRecipeBatch,
  editRecipeBatchMeasured,
  editRecipePrivacy,
  scaleRecipe,
  addRecipeImage,
  removeRecipeImage,
} from '../../common/recipe'
import { recalcRecipe } from '@brewcomputer/utils'

export default {
  Init: () => null,
  [RECIPE_CREATED]: (state, { payload: recipe, aggregateId }) => {
    return recalcRecipe(assign({}, recipe, { id: aggregateId }))
  },
  [RECIPE_EDITED]: (state, { payload: changes }) => {
    return recalcRecipe(editRecipeFields(state, changes))
  },
  [RECIPE_REMOVED]: () => {
    return null
  },
  [RECIPE_PRIVACY_EDITED]: (state, { payload }) => {
    return editRecipePrivacy(state, payload)
  },
  [RECIPE_INGREDIENT_ADDED]: (state, { payload: changes }) => {
    return recalcRecipe(addIngredient(state, changes))
  },
  [RECIPE_INGREDIENT_EDITED]: (state, { payload: changes }) => {
    return recalcRecipe(editIngredient(state, changes))
  },
  [RECIPE_INGREDIENT_REMOVED]: (state, { payload: changes }) => {
    return recalcRecipe(removeIngredient(state, changes))
  },
  [RECIPE_PROCEDURE_EDITED]: (state, { payload: { procedureType, data } }) => {
    return recalcRecipe(editRecipeProcedure(state, { procedureType, data }))
  },
  [RECIPE_EQUIPMENT_EDITED]: (state, { payload: equipment }) => {
    return recalcRecipe(editRecipeEquipment(state, equipment))
  },
  [RECIPE_BEER_STYLE_EDITED]: (state, { payload: beerStyle }) => {
    return editRecipeBeerStyle(state, beerStyle)
  },
  [RECIPE_BATCH_EDITED]: (state, { payload: changes }) => {
    return editRecipeBatch(state, changes)
  },
  [RECIPE_BATCH_MEASURED_EDITED]: (state, { payload }) => {
    return recalcRecipe(editRecipeBatchMeasured(state, payload))
  },
  [RECIPE_BATCH_STATUS_CHANGED]: (state, { payload: changes }) => {
    return editRecipeBatch(state, changes)
  },
  [RECIPE_SCALED]: (state, { payload: changes }) => {
    return recalcRecipe(scaleRecipe(state, changes))
  },
  [RECIPE_IMAGE_ADDED]: (state, { payload }) => {
    return addRecipeImage(state, payload)
  },
  [RECIPE_IMAGE_REMOVED]: (state, { payload }) => {
    return removeRecipeImage(state, payload)
  },
}
