import React, { useEffect } from 'react'
import { useSelector } from 'react-redux'
import { Link } from 'react-router-dom'
import {
  Tab,
  Column,
  BatchInfo,
  BatchRecipe,
  BatchCheckList
} from '@brewcomputer/ui/src/batchView/BatchView'
import { EquipmentLookup } from '../lookups/EquipmentLookup'
import { getBatchRecipeLink } from '../../links'
import {
  getBatchInfo,
  getBatchRecipePreview,
  getIngredientCheckList,
  getWaterCheckList,
  getEquipmentCheckList
} from './selectors'
import { useCheckIngredient, useCheckItem } from './hooks'

export const BatchPlanningView = ({
  batchId,
  onEditBatch,
  onEditEquipment,
  onMount
}) => {
  const batchInfo = useSelector(getBatchInfo(batchId))
  const batchRecipe = useSelector(getBatchRecipePreview(batchId))

  const ingredientChecklist = useSelector(getIngredientCheckList(batchId))
  const waterCheckList = useSelector(getWaterCheckList(batchId))
  const equipmentCheckList = useSelector(getEquipmentCheckList(batchId))

  const onCheckItem = useCheckItem(batchId)
  const onCheckIngredient = useCheckIngredient(batchId)

  useEffect(() => {
    onMount()
  }, [])

  return (
    <Tab>
      <Column>
        <BatchInfo {...batchInfo} onEditBatch={onEditBatch} />
      </Column>
      <Column>
        <BatchRecipe
          {...batchRecipe}
          renderRecipeLink={() => (
            <Link to={getBatchRecipeLink(batchId)}>Edit Recipe</Link>
          )}
          renderEquipmentLookup={() => (
            <EquipmentLookup onSelect={onEditEquipment} />
          )}
        />
      </Column>
      <Column>
        <BatchCheckList
          title="Ingredients"
          checkList={ingredientChecklist}
          onCheckItem={onCheckIngredient}
        />
        <BatchCheckList
          title="Water"
          checkList={waterCheckList}
          onCheckItem={onCheckItem}
        />
        <BatchCheckList
          title="Equipment Check"
          checkList={equipmentCheckList}
          onCheckItem={onCheckItem}
        />
      </Column>
    </Tab>
  )
}

BatchPlanningView.displayName = 'BatchPlanningView'
