import React, { PureComponent } from 'react'
import { colors } from '../colors/colors'

export class PrintIcon extends PureComponent {
  static defaultProps = {
    fill: colors.ACCENT_BLUE
  }
  render() {
    const { fill } = this.props
    return (
      <svg
        width="18"
        height="18"
        fill="none"
        xmlns="http://www.w3.org/2000/svg">
        <rect x="1" y="5" width="16" height="9" rx="2" fill={fill} />
        <path d="M3.5 9.5h11v6h-11v-6z" fill="#fff" stroke={fill} />
        <path d="M4 3a1 1 0 0 1 1-1h8a1 1 0 0 1 1 1v1H4V3z" fill={fill} />
        <path fill="#fff" d="M12 7h2v1h-2z" />
        <path fill={fill} d="M5 11h8v1H5zM5 13h4v1H5z" />
      </svg>
    )
  }
}
