import styled, { css } from 'styled-components'
import { colors } from '../colors/colors'
import figmaColors from '../colors/figmaColors'
import { textStyles } from '../texts/textStyles'

const getPadding = ({ fitted }) => (fitted ? 'initial' : '8px 32px')

const getBackground = ({ checked }) =>
  checked ? figmaColors.blue_50 : figmaColors.white

export const Label = styled.label`
  display: flex;
  flex-direction: row;
  align-items: center;
  cursor: pointer;
  padding: ${getPadding};
  background: ${getBackground};
`

export const InputContainer = styled.div`
  display: inline-block;
  vertical-align: middle;
`

export const Icon = styled.svg`
  fill: none;
  stroke: white;
  stroke-width: 2px;
`
export const HiddenCheckbox = styled.input.attrs({ type: 'checkbox' })`
  border: 0;
  clip: rect(0 0 0 0);
  clippath: inset(50%);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  white-space: nowrap;
  width: 1px;
`

const checkedState = css`
  border: 0px;
  background: ${colors.ACCENT_BLUE};
`

export const StyledCheckbox = styled.div`
  width: 16px;
  height: 16px;
  transition: all 150ms;
  border-radius: 4px;

  border: 2px solid #d5d5d5;
  box-sizing: border-box;
  background: ${colors.BACKGROUND};

  ${props => (props.checked ? checkedState : '')} ${HiddenCheckbox}:focus + & {
    box-shadow: 0 0 0 3px ${colors.ACCENT_BLUE_HOVER_SHADOW};
  }

  ${Icon} {
    visibility: ${props => (props.checked ? 'visible' : 'hidden')};
  }
`

export const Text = styled.span`
  ${textStyles.body2};
  color: ${figmaColors.black};
  margin-left: 24px;
`
