import PropTypes from 'prop-types'
import { isEmpty, get } from 'lodash'
import { ExportLink, PrintLink, ShareLink } from './recipeInfo/Links'
import { LinkContainer, Section } from './Styled'
import { AccentButton, Share as SharingDialog } from '@brewcomputer/ui'
import { brewButton, cloneButton, sharingDialog } from '../../texts'
import React, { useCallback, useState } from 'react'
import { getRecipe } from '../../selectors/recipe'
import { useSelector } from 'react-redux'
import { useEditRecipePrivacy } from '../../hooks/commands'

export const RecipeButtons = ({
  recipeId,
  allowSharing,
  allowCloning,
  allowBrewing,
  isLoading,
  onCloneRecipe,
  onBrewRecipe,
}) => {
  const [opened, togglePopup] = useState(false)
  const openPopup = useCallback(() => {
    togglePopup(true)
  }, [])
  const closePopup = useCallback(() => {
    togglePopup(false)
  }, [])

  const recipe = useSelector(getRecipe(recipeId))

  const { name, shared, published, ingredients } = recipe
  const noFermentables = isEmpty(get(ingredients, 'fermentable_additions'))

  const brewButtonDisabled = isLoading || noFermentables
  const brewButtonTitle = noFermentables
    ? brewButton.noFermentables
    : brewButton.htmlTitle

  const onEditPrivacy = useEditRecipePrivacy(recipeId)

  return (
    <Section>
      {allowSharing && (
        <SharingDialog
          visible={opened}
          title={`Share ${name}`}
          shareSectionTitle={sharingDialog.share.title}
          shareSectionMessage={sharingDialog.share.message}
          publishSectionTitle={sharingDialog.publish.title}
          publishSectionMessage={sharingDialog.publish.message}
          href={window.location.href}
          published={published}
          shared={shared}
          onChange={onEditPrivacy}
          onClose={closePopup}
        />
      )}

      <ExportLink recipeId={recipeId} />
      <PrintLink recipeId={recipeId} />

      {allowCloning && (
        <LinkContainer>
          <AccentButton
            disabled={isLoading}
            htmlTitle={cloneButton.htmlTitle}
            title={cloneButton.title}
            onPress={onCloneRecipe}
          />
        </LinkContainer>
      )}
      {allowSharing && <ShareLink shared={shared} onPress={openPopup} />}
      {allowBrewing && (
        <AccentButton
          disabled={brewButtonDisabled}
          htmlTitle={brewButtonTitle}
          title={brewButton.title}
          onPress={onBrewRecipe}
        />
      )}
    </Section>
  )
}

RecipeButtons.propTypes = {
  recipeId: PropTypes.string,
  allowSharing: PropTypes.bool,
  allowCloning: PropTypes.bool,
  allowBrewing: PropTypes.bool,
  onCloneRecipe: PropTypes.func,
  onBrewRecipe: PropTypes.func,
}
