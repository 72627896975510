import React from 'react'
import { colors } from '../colors/colors'

export const BatchesIcon = ({ fill, ...props }) => (
  <svg
    {...props}
    width="24"
    height="24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M11 2a1 1 0 0 1 1-1h1a1 1 0 0 1 1 1h4a2 2 0 0 1 2 2H4a2 2 0 0 1 2-2h5zm7 18V7H6v13h12zM4 5v3H0v1l4 2v9a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2v-9l4-2V8h-4V5H4zm5 5a1 1 0 0 1 2 0v7a1 1 0 1 1-2 0v-7zm5-1a1 1 0 0 0-1 1v7a1 1 0 1 0 2 0v-7a1 1 0 0 0-1-1z"
      fill={fill}
    />
  </svg>
)

BatchesIcon.defaultProps = {
  fill: colors.GRAY
}
