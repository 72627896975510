import { isEmpty, set, find } from 'lodash'
import React, { PureComponent } from 'react'
import PropTypes from 'prop-types'
import { TextEditor } from '@brewcomputer/ui/src/editors/TextEditor'
import { MeasurableEditor } from '@brewcomputer/ui/src/editors/MeasurableEditor'
import { SelectEditor } from '@brewcomputer/ui/src/editors/SelectEditor'

export class ResponsiveEditor extends PureComponent {
  onChange = value => {
    const changes = {}
    set(changes, this.props.path, value)
    this.props.onChange(changes)
  }

  render() {
    const { collapsed, disabled, disableConversion } = this.props

    const title = collapsed ? this.props.title : null

    if (!isEmpty(this.props.text)) {
      return (
        <TextEditor
          type={'text'}
          title={title}
          value={this.props.text}
          textAlign={'left'}
          disabled={disabled}
          onChange={this.onChange}
        />
      )
    }

    if (!isEmpty(this.props.measurable)) {
      return (
        <MeasurableEditor
          title={title}
          value={this.props.measurable.value}
          unit={this.props.measurable.unit}
          units={this.props.measurable.units}
          valueReadOnly={disabled}
          changeOnConvert={disableConversion}
          onChange={this.onChange}
        />
      )
    }

    if (!isEmpty(this.props.selectedValue) || !isEmpty(this.props.items)) {
      return disabled ? (
        <TextEditor
          type={'text'}
          title={title}
          value={
            find(this.props.items, i => i.value === this.props.selectedValue)
              .label
          }
          textAlign={'left'}
          disabled={disabled}
        />
      ) : (
        <SelectEditor
          title={title}
          selectedValue={this.props.selectedValue}
          items={this.props.items}
          alignment={'left'}
          onChange={this.onChange}
        />
      )
    }
    return null
  }
}

ResponsiveEditor.propTypes = {
  title: PropTypes.string,
  text: PropTypes.string,
  measurable: PropTypes.shape({
    value: PropTypes.number,
    unit: PropTypes.string
  }),
  items: PropTypes.array,
  selectedValue: PropTypes.string,
  collapsed: PropTypes.bool,
  disabled: PropTypes.bool,
  disableConversion: PropTypes.bool,
  path: PropTypes.string,
  onChange: PropTypes.func
}

ResponsiveEditor.displayName = 'ResponsiveEditor'
