const size_px = '1px'
const size_1 = '4px'
const size_2 = '8px'
const size_3 = '12px'
const size_4 = '16px'
const size_5 = '20px'
const size_6 = '24px'
const size_8 = '32px'
const size_10 = '40px'
const size_12 = '48px'
const size_16 = '64px'
const size_20 = '80px'
const size_24 = '96px'
const size_32 = '128px'

export {
  size_px,
  size_1,
  size_2,
  size_3,
  size_4,
  size_5,
  size_6,
  size_8,
  size_10,
  size_12,
  size_16,
  size_20,
  size_24,
  size_32
}
