import React, { useCallback } from 'react'
import styled from 'styled-components'
import { RecipeDataSection } from './RecipeDataSection'
import { ClearButton } from '@brewcomputer/ui'

const Preview = styled.div`
  max-width: 50%;
  position: relative;

  @media (max-width: 576px) {
    max-width: 100%;
  }

  img {
    max-width: 100%;
  }
`

const RemoveButton = styled(ClearButton)`
  position: absolute;
  top: 4px;
  right: 4px;
`

export const RecipeImage = ({ imageId, src, alt, allowRemove, onRemove }) => {
  const onPressRemove = useCallback(() => {
    onRemove(imageId)
  }, [imageId])

  if (src == null) {
    return null
  }

  return (
    <RecipeDataSection caption="Images">
      <Preview>
        <img src={src} alt={alt} />
        {allowRemove && (
          <RemoveButton
            accessibilityLabel="Remove image"
            onPress={onPressRemove}
          />
        )}
      </Preview>
    </RecipeDataSection>
  )
}
