import { isNil, map, groupBy, isEmpty, every } from 'lodash'
import React, { Fragment, PureComponent } from 'react'
import styled from 'styled-components'
import figmaColors from '../colors/figmaColors'
import { textStyles } from '../texts/textStyles'
import { optionStyle } from './Common'
import { TextHighlight } from './TextHighlight'

const Container = styled.div`
  padding: 4px 0;
  max-height: 45vh;
  overflow-y: auto;
  overflow-x: hidden;
`

const StyledOption = styled.div`
  flex-direction: row;
  flex-shrink: 0;
  align-items: center;
  padding: 0 12px;
  ${optionStyle};
`

const OptionsGroup = styled.div`
  padding-bottom: 4px;
  flex-shrink: 0;
  & > ${StyledOption} {
    padding: 0 20px;
  }
`

const GroupTitle = styled.div`
  ${textStyles.smallText};
  color: ${figmaColors.blue_gray_50};
  padding: 8px 12px 3px 12px;
  flex-direction: row;
`

const EmptyResult = styled.div`
  ${textStyles.smallText};
  color: ${figmaColors.white};
  padding: 20px 35px;
`

export class Option extends PureComponent {
  static displayName = 'Option'

  onSelect = () => {
    this.props.onSelect(this.props.value)
  }

  onHover = () => {
    this.props.onHover(this.props.value)
  }

  render() {
    const { id, selected, hovered, children } = this.props

    return (
      // Do not use the onMouseOver instead of the onMouseMove!
      <StyledOption
        id={id}
        selected={selected}
        hovered={hovered}
        onClick={this.onSelect}
        onMouseMove={this.onHover}>
        {children}
      </StyledOption>
    )
  }
}

export class Options extends PureComponent {
  static displayName = 'Options'

  componentDidMount() {
    const { selectedValue } = this.props
    this.scrollToItem(selectedValue, true)
  }

  scrollToItem = (itemValue, center) => {
    const item = document.getElementById(itemValue)
    if (!isNil(item)) {
      item.scrollIntoView({ block: center ? 'center' : 'nearest' })
    }
  }

  renderOptions = (items) => {
    const {
      highlightedText,
      selectedValue,
      hoveredValue,
      onOptionSelect,
      onOptionHover,
    } = this.props

    const fixedOnly = every(items, (item) => item.isFixed)

    return (
      <Fragment>
        {map(items, (item) => (
          <Option
            id={item.value}
            key={item.value}
            value={item.value}
            selected={item.value === selectedValue}
            hovered={item.value === hoveredValue}
            onSelect={onOptionSelect}
            onHover={onOptionHover}>
            {item.isFixed ? (
              item.label
            ) : (
              <TextHighlight
                highlightedText={highlightedText}
                text={item.label}
              />
            )}
          </Option>
        ))}
        {fixedOnly && this.renderEmpty()}
      </Fragment>
    )
  }

  renderGroups = (groups) => {
    const { highlightedText } = this.props
    return map(groups, (group, groupName) => (
      <OptionsGroup key={groupName}>
        <GroupTitle>
          <TextHighlight text={groupName} highlightedText={highlightedText} />
        </GroupTitle>
        {this.renderOptions(group)}
      </OptionsGroup>
    ))
  }

  renderEmpty = () => {
    return <EmptyResult>No results found.</EmptyResult>
  }

  render() {
    const { id, items, showGroups } = this.props

    const content = isEmpty(items)
      ? this.renderEmpty()
      : showGroups
      ? this.renderGroups(groupBy(items, 'group'))
      : this.renderOptions(items)

    return <Container id={id}>{content}</Container>
  }
}
