import React from 'react'

export const LoginIcon = ({ color }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="32"
    height="32"
    fill="none"
    viewBox="0 0 32 32">
    <path
      fill={color}
      d="M11 7a2 2 0 00-2 2v3h2V9h10v14H11v-3H9v3c0 1.1.9 2 2 2h10a2 2 0 002-2V9a2 2 0 00-2-2H11z"
    />
    <path
      fill={color}
      d="M12.59 20.24L15.83 17H6v-2h9.83l-3.24-3.24L14 10.34l4.95 4.95a1 1 0 010 1.42L14 21.66l-1.41-1.42z"
    />
  </svg>
)

LoginIcon.defaultProps = {
  color: '#109CF1'
}
