export default {
  name: 'American Light Lager',
  category: 'Standard American Beer',
  category_number: 1,
  category_id: '1',
  style_id: '1A',
  style_letter: 'A',
  style_guide: 'BJCP2015',
  type: 'beer',
  original_gravity: {
    minimum: {
      unit: 'sg',
      value: 1.028
    },
    maximum: {
      unit: 'sg',
      value: 1.04
    }
  },
  final_gravity: {
    minimum: {
      unit: 'sg',
      value: 0.998
    },
    maximum: {
      unit: 'sg',
      value: 1.008
    }
  },
  international_bitterness_units: {
    minimum: {
      unit: 'IBUs',
      value: 8
    },
    maximum: {
      unit: 'IBUs',
      value: 12
    }
  },
  color: {
    minimum: {
      unit: 'SRM',
      value: 2
    },
    maximum: {
      unit: 'SRM',
      value: 3
    }
  },
  alcohol_by_volume: {
    minimum: {
      unit: '%',
      value: 2.8
    },
    maximum: {
      unit: '%',
      value: 4.2
    }
  }
}
