// TODO anonymous
export const roles = {
  ANONYMOUS: 'ANONYMOUS',
  USER: 'USER',
  ADMIN: 'ADMIN',
  ROOT: 'ROOT'
}

// TODO root id
export const Root = {
  id: '00000000-0000-0000-0000-000000000000',
  role: roles.ROOT,
  name: 'Brewcomputer'
}

export const isCorrectRole = role =>
  role === roles.USER || role === roles.ADMIN || role === roles.ANONYMOUS

export const isAnonymous = user => user.role === roles.ANONYMOUS
export const isAdmin = user => user.role === roles.ADMIN
export const isRoot = user => user.role === roles.ROOT
