import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { colors } from '..'
import { textStyles } from '..'
import {
  InfoIcon,
  SuccessIcon,
  WarningIcon,
  ErrorIcon,
  SearchClearIcon
} from '../icons'
import figmaColors from '../colors/figmaColors'
import { size_1, size_2, size_3, size_4, size_px } from '../spacing/spacing'

const mainColor = {
  info: figmaColors.blue_500,
  success: figmaColors.green_500,
  warning: figmaColors.amber_500,
  error: figmaColors.red_500
}

const backgroundColor = {
  info: figmaColors.blue_50,
  success: figmaColors.green_50,
  warning: figmaColors.amber_50,
  error: figmaColors.red_50
}

const buttonColor = {
  info: figmaColors.blue_700,
  success: figmaColors.green_700,
  warning: figmaColors.amber_700,
  error: figmaColors.red_700
}

const buttonHoverColor = {
  info: figmaColors.blue_400,
  success: figmaColors.green_400,
  warning: figmaColors.amber_400,
  error: figmaColors.red_400
}

const buttonActiveColor = {
  info: figmaColors.blue_600,
  success: figmaColors.green_600,
  warning: figmaColors.amber_600,
  error: figmaColors.red_600
}

const getColor = colorTypeMap => ({ alertType }) => colorTypeMap[alertType]

const Container = styled.div`
  border-color: ${getColor(mainColor)};
  background-color: ${getColor(backgroundColor)};
  align-self: stretch;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding: ${size_4} ${size_4} ${size_4} ${size_3};
  border-width: ${size_px} ${size_px} ${size_px} ${size_1};
  border-radius: ${size_1};
  border-style: solid;
  overflow: hidden;
`

const Body = styled.div`
  flex-direction: row;
  align-items: center;
  svg {
    flex-shrink: 0;
    flex-grow: 0;
  }
`

const Message = styled.div`
  margin-left: ${size_2};
  display: inline-block;
  ${textStyles.body2};
  color: ${colors.BLACK};
`

const CloseButton = styled.button`
  background-color: transparent;
  margin-left: ${size_4};
  & svg path {
    transition-duration: 200ms;
  }
  :hover svg path,
  :focus svg path {
    fill: ${getColor(buttonHoverColor)};
  }
  :active svg path {
    fill: ${getColor(buttonActiveColor)};
  }
`

CloseButton.displayName = 'CloseButton'

const Bold = styled.span`
  font-weight: bold;
  margin-right: ${size_1};
`

const Icon = ({ alertType }) => {
  const color = getColor(buttonActiveColor)({ alertType })
  switch (alertType) {
    case 'info':
      return <InfoIcon color={color} />
    case 'success':
      return <SuccessIcon color={color} />
    case 'warning':
      return <WarningIcon color={color} />
    case 'error':
      return <ErrorIcon color={color} />
  }
}

export const Alert = ({ type, title, message, onClose }) => (
  <Container role="alert" aria-live="assertive" alertType={type}>
    <Body>
      <Icon alertType={type} />
      <Message>
        <Bold>{title}</Bold>
        {message}
      </Message>
    </Body>
    <CloseButton alertType={type} aria-label="Close Alert" onClick={onClose}>
      <SearchClearIcon fill={buttonColor[type]} />
    </CloseButton>
  </Container>
)

Alert.displayName = 'Alert'

Alert.propTypes = {
  type: PropTypes.oneOf(['info', 'success', 'warning', 'error']),
  title: PropTypes.string,
  message: PropTypes.string,
  onClose: PropTypes.func
}

Alert.defaultProps = {
  type: 'error'
}
