import { isNil } from 'lodash'
import React, { PureComponent } from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import {
  TemperatureUnitsIcon,
  GravityUnitsIcon,
  PressureUnitsIcon,
  WeightUnitsIcon,
  VolumeUnitsIcon,
  WeightToVolumeIcon,
  AlcoholIcon,
  RefractometerIcon,
  YeastIcon,
  CarbonationIcon,
  BackIcon,
  PopupTitle,
  BackTitle,
  MenuItemTitle,
  ClearButton,
  colors
} from '@brewcomputer/ui'

import { TemperatureUnitsTool } from './TemperatureUnitsTool'
import { GravityUnitsTool } from './GravityUnitsTool'
import { PressureUnitsTool } from './PressureUnitsTool'
import { WeightUnitsTool } from './WeightUnitsTool'
import { VolumeUnitsTool } from './VolumeUnitsTool'
import { AlcoholTool } from './AlcoholTool'
import { WeightToVolumeTool } from './WeightToVolumeTool'
import { RefractometerTool } from './RefractometerTool'
import { YeastTool } from './YeastTool'
import { CarbonationTool } from './CarbonationTool'

const Root = styled.div`
  padding: 27px 24px;
  flex-direction: column;
  flex-shrink: 0;
`

const TitleBar = styled.div`
  height: 26px;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 20px;
`

const ToolItem = styled.button`
  cursor: pointer;
  flex-direction: row;
  align-items: center;
  justify-content: start;
  background-color: transparent;
  margin-bottom: 20px;
`
ToolItem.displayName = 'ToolItem'

const ToolItemTitle = styled(MenuItemTitle)`
  margin-left: 16px;
  &:hover,
  &.active {
    color: ${colors.ACCENT_GRAY_HOVER};
  }
`
ToolItemTitle.displayName = 'ToolItemTitle'

const BackButton = styled.button`
  flex-direction: row;
  align-items: center;
  justify-content: start;
  background-color: transparent;
`

const BackButtonTitle = styled(BackTitle)`
  margin-left: 12px;
`

const toolDescriptions = [
  {
    id: 'temperatureUnits',
    title: 'Temperature Units',
    component: TemperatureUnitsTool,
    icon: TemperatureUnitsIcon
  },
  {
    id: 'gravityUnits',
    title: 'Gravity Units',
    component: GravityUnitsTool,
    icon: GravityUnitsIcon
  },
  {
    id: 'pressureUnits',
    title: 'Pressure Units',
    component: PressureUnitsTool,
    icon: PressureUnitsIcon
  },
  {
    id: 'weightUnits',
    title: 'Weight Units',
    component: WeightUnitsTool,
    icon: WeightUnitsIcon
  },
  {
    id: 'volumeUnits',
    title: 'Volume Units',
    component: VolumeUnitsTool,
    icon: VolumeUnitsIcon
  },

  {
    id: 'weightToVolume',
    title: 'Weight to Volume',
    component: WeightToVolumeTool,
    icon: WeightToVolumeIcon
  },
  {
    id: 'alcohol',
    title: 'Alcohol',
    component: AlcoholTool,
    icon: AlcoholIcon
  },
  {
    id: 'refractometer',
    title: 'Refractometer',
    component: RefractometerTool,
    icon: RefractometerIcon
  },
  {
    id: 'yeast',
    title: 'Yeast',
    component: YeastTool,
    icon: YeastIcon
  },
  {
    id: 'carbonation',
    title: 'Carbonation',
    component: CarbonationTool,
    icon: CarbonationIcon
  }
]

export class ToolsPanel extends PureComponent {
  static propTypes = {
    onCloseButtonPress: PropTypes.func
  }

  constructor(props) {
    super(props)
    this.state = {
      currentToolId: null
    }
  }

  renderToolItem = (toolId, toolTitle, Icon) => {
    return (
      <ToolItem
        key={toolId}
        onClick={() => {
          this.setState({ currentToolId: toolId })
        }}>
        <Icon />
        <ToolItemTitle>{toolTitle}</ToolItemTitle>
      </ToolItem>
    )
  }

  renderToolItems = () => {
    const { onCloseButtonPress } = this.props
    return (
      <Root>
        <TitleBar>
          <PopupTitle>{'Tools'}</PopupTitle>
          <ClearButton onPress={onCloseButtonPress} />
        </TitleBar>
        {toolDescriptions.map(toolDescription => {
          return this.renderToolItem(
            toolDescription.id,
            toolDescription.title,
            toolDescription.icon
          )
        })}
      </Root>
    )
  }

  renderTool = toolId => {
    const { onCloseButtonPress } = this.props
    const toolDescription = toolDescriptions.find(toolDescription => {
      return toolDescription.id === toolId
    })
    const ToolComponent = !isNil(toolDescription)
      ? toolDescription.component
      : null
    return (
      <Root>
        <TitleBar>
          <BackButton
            onClick={() => {
              this.setState({ currentToolId: null })
            }}>
            <BackIcon />
            <BackButtonTitle>{'Tools'}</BackButtonTitle>
          </BackButton>
          <ClearButton onPress={onCloseButtonPress} />
        </TitleBar>
        <ToolComponent />
      </Root>
    )
  }

  render() {
    const { currentToolId } = this.state
    return isNil(currentToolId)
      ? this.renderToolItems()
      : this.renderTool(currentToolId)
  }
}
