import { capitalize, find, includes, toLower } from 'lodash'

export const createIngredientLabel = (ingredient, ingredientType) => {
  if (ingredientType === 'fermentable_additions') {
    return `${ingredient.name} (${ingredient.color.value} ${ingredient.color.unit})`
  } else if (ingredientType === 'hop_additions') {
    return `${ingredient.name} (${ingredient.alpha_acid.value} ${ingredient.alpha_acid.unit})`
  } else if (ingredientType === 'culture_additions') {
    return `${ingredient.name} (${ingredient.laboratory} ${ingredient.product_id})`
  } else if (ingredientType === 'miscellaneous_additions') {
    return `${ingredient.name} (${capitalize(ingredient.type)})`
  }
  return ingredient.name
}

export const isMatch = (string, substring) =>
  includes(toLower(string), toLower(substring))

export const findById = (collection, id) => find(collection, { id })
