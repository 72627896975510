import React from 'react'
import { colors } from '../colors/colors'

export const ProfilesIcon = ({ fill, ...props }) => (
  <svg
    {...props}
    width="24"
    height="24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M18 4H6v16h12V4zM4 4v1H2a2 2 0 0 0-2 2v10a2 2 0 0 0 2 2h2v1a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2v-1h2a2 2 0 0 0 2-2V7a2 2 0 0 0-2-2h-2V4a2 2 0 0 0-2-2H6a2 2 0 0 0-2 2zM2 7h2v10H2V7zm20 10h-2V7h2v10zM8 7h8v2H8V7zm8 4H8v2h8v-2zm0 4H8v2h8v-2z"
      fill={fill}
    />
  </svg>
)

ProfilesIcon.defaultProps = {
  fill: colors.GRAY
}
