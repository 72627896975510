import { map, isEmpty, find } from 'lodash'
import React, { Fragment, useState, useCallback } from 'react'
import { useSelector } from 'react-redux'
import { EquipmentSet, List, AccentButton } from '@brewcomputer/ui'
import { getEquipmentProfileFields } from '@brewcomputer/utils'
import { convertEquipment } from '@brewcomputer/utils'
import { LoadingIndicator } from '../LoadingIndicator'
import { NoEquipment } from '../specialPages/EmptyList'
import { DeleteConfirmation } from '../DeleteConfirmation'
import { PageTitle } from '@brewcomputer/ui'
import styled from 'styled-components'
import {
  useAddEquipment,
  useEditEquipment,
  useUserRemoveEquipment
} from '../../hooks/commands'

const Header = styled.div`
  flex-direction: row;
  align-items: flex-start;
  justify-content: space-between;
  @media (max-width: 576px) {
    flex-direction: column;
    margin-bottom: 10px;
    align-items: stretch;
  }
`

export const UserEquipment = ({}) => {
  const user = useSelector(state => state.user)
  const equipment = useSelector(state => state.equipment)

  if (isEmpty(user) || user.isLoading) {
    return <LoadingIndicator />
  }

  const [itemToDelete, setItemToDelete] = useState(null)

  const addEquipment = useAddEquipment(user.id)
  const editEquipment = useEditEquipment(user.id)
  const removeEquipment = useUserRemoveEquipment(user.id)

  const data = map(equipment, (profile, id) => ({
    ...getEquipmentProfileFields(convertEquipment(profile, user.unitSettings)),
    name: profile.name,
    id
  }))

  const showDeleteConfirmation = useCallback(
    id => {
      const { name } = find(data, { id })
      setItemToDelete({ id, name })
    },
    [data]
  )

  const closeDeleteConfirmation = useCallback(() => {
    setItemToDelete(null)
  })

  const onRemoveConfirmed = useCallback(() => {
    removeEquipment(itemToDelete.id)
    setItemToDelete(null)
  })

  const renderEquipmentSet = profile => {
    return (
      <EquipmentSet
        id={profile.id}
        name={profile.name}
        equipmentItems={profile.equipment_items}
        onChange={editEquipment}
        onRemove={showDeleteConfirmation}
      />
    )
  }

  return (
    <Fragment>
      <List
        data={data}
        ListHeaderComponent={
          <Header>
            <PageTitle title="My Equipment" />
            <AccentButton title="Add Equipment" onPress={addEquipment} />
          </Header>
        }
        ListEmptyComponent={<NoEquipment />}
        renderItem={renderEquipmentSet}
      />
      <DeleteConfirmation
        visible={itemToDelete != null}
        type="equipment"
        name={itemToDelete != null ? itemToDelete.name : ''}
        onConfirm={onRemoveConfirmed}
        onReject={closeDeleteConfirmation}
      />
    </Fragment>
  )
}
