import { omit, merge } from 'lodash'
import {
  userGetQuery,
  userAddEquipmentAction,
  userEditEquipmentAction,
  userRemoveEquipmentAction
} from '../actions/user'

export default function equipment(state = {}, action) {
  switch (action.type) {
    case userGetQuery.types.success: {
      return action.payload.result.equipment
    }

    case userAddEquipmentAction.types.request: {
      return {
        ...state,
        [action.payload.id]: action.payload.data
      }
    }
    case userEditEquipmentAction.types.request: {
      const updatedEquipment = merge(
        {},
        state[action.payload.id],
        action.payload.changes
      )
      return { ...state, [action.payload.id]: updatedEquipment }
    }
    case userRemoveEquipmentAction.types.request: {
      return omit(state, action.payload.id)
    }
  }

  return state
}
