import React from 'react'
import PropTypes from 'prop-types'
import { SectionTitle } from '@brewcomputer/ui'
import styled from 'styled-components'

const Container = styled.div`
  margin-top: 22px;
`

const Header = styled.div`
  position: relative;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 10px;
`

export const RecipeDataSection = ({ caption, button, children }) => (
  <Container>
    <Header>
      <SectionTitle>{caption}</SectionTitle>
      {button}
    </Header>
    {children}
  </Container>
)

RecipeDataSection.propTypes = {
  caption: PropTypes.string,
  button: PropTypes.element
}

RecipeDataSection.displayName = 'RecipeDataSection'
