import { get } from 'lodash'
import React, { useCallback } from 'react'
import { AuthorName, LookupContainer, PickerContainer, Section } from './Styled'
import { Picker } from '@brewcomputer/ui'
import { BeerStyleLookup } from '../lookups/BeerStyleLookup'
import { getRecipe } from '../../selectors/recipe'
import { useSelector } from 'react-redux'
import { useEditBeerStyle, useEditRecipe } from '../../hooks/commands'

const types = [
  {
    label: 'All Grain',
    value: 'all grain',
  },
  {
    label: 'Extract',
    value: 'extract',
  },
  {
    label: 'Partial Mash',
    value: 'partial mash',
  },
]

const getRecipeFields = (recipeId) => (state) => {
  const recipe = getRecipe(recipeId)(state)
  return {
    author: recipe.author,
    type: recipe.type,
    styleId: get(recipe, 'style.style_id'),
    styleName: get(recipe, 'style.name'),
  }
}

export const RecipePickers = ({ recipeId }) => {
  const editRecipe = useEditRecipe(recipeId)
  const onSelectType = useCallback((type) => {
    editRecipe({ type })
  })
  const onSelectStyle = useEditBeerStyle(recipeId)

  const { author, styleId, type, styleName } = useSelector(
    getRecipeFields(recipeId)
  )

  return (
    <Section>
      <PickerContainer>
        <Picker
          type={'lookup'}
          items={types}
          selectedValue={type}
          onChange={onSelectType}
        />
      </PickerContainer>
      <LookupContainer>
        <BeerStyleLookup
          selectedStyleId={styleId}
          currentStyleName={styleName}
          onSelect={onSelectStyle}
        />
      </LookupContainer>
      <AuthorName>{`by ${author}`}</AuthorName>
    </Section>
  )
}
