const { get } = require('lodash')

function getEnvVariable(varName, onVarEmpty = () => {}) {
  const envVarValue = get(process, ['env', varName])
  if (envVarValue == null) {
    return onVarEmpty()
  }
  return envVarValue
}

const getJwtSecret = () =>
  getEnvVariable('JWT_SECRET', () => {
    throw new Error('JWT_SECRET environment variable is not defined!')
  })
const getGoogleClientSecret = () =>
  getEnvVariable('GOOGLE_CLIENT_SECRET', () => {
    console.warn('GOOGLE_CLIENT_SECRET environment variable is not defined!')
  })

const getBrewOrigin = () =>
  getEnvVariable('BREW_ORIGIN', () => {
    console.warn('BREW_ORIGIN environment variable is not defined!')
  })

const getElasticNode = () =>
  getEnvVariable('ELASTIC_SEARCH_NODE', () => {
    console.warn('ELASTIC_SEARCH_NODE environment variable is not defined!')
  })

const getElasticAccessKey = () =>
  getEnvVariable('ELASTIC_ACCESS_KEY', () => {
    console.warn('ELASTIC_ACCESS_KEY environment variable is not defined!')
  })

const getElasticSecretKey = () =>
  getEnvVariable('ELASTIC_SECRET_KEY', () => {
    console.warn('ELASTIC_SECRET_KEY environment variable is not defined!')
  })

const getElasticRegion = () =>
  getEnvVariable('ELASTIC_REGION', () => {
    console.warn('ELASTIC_REGION environment variable is not defined!')
  })

const getGaId = () =>
  getEnvVariable('GA_ID', () => {
    //console.warn('GA_ID environment variable is not defined!')
  })

const getFullstoryFlag = () => getEnvVariable('FULLSTORY_FLAG', () => {})

module.exports = {
  getJwtSecret,
  getGoogleClientSecret,
  getBrewOrigin,
  getElasticRegion,
  getElasticSecretKey,
  getElasticAccessKey,
  getElasticNode,
  getGaId,
  getFullstoryFlag
}
