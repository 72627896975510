import React, { PureComponent } from 'react'
import PropTypes from 'prop-types'
import styled, { css } from 'styled-components'
import { Modal } from '../modal/Modal'
import { Checkbox } from '../checkbox/Checkbox'
import { TextEditor } from '../editors/TextEditor'
import { textStyles } from '../texts/textStyles'
import { colors } from '../colors/colors'

const sectionDisabled = css`
  opacity: 0.6;
`

const Section = styled.div`
  border-top: 1px solid ${colors.DIVIDER};
  padding: 24px;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  ${props => props.disabled && sectionDisabled};
`

Section.displayName = 'Section'

const SectionTitle = styled.div`
  ${textStyles.H3};
`

const SectionBody = styled.div`
  flex-grow: 1;
  flex-shrink: 1;
  margin-right: 16px;
`

const Message = styled.div`
  ${textStyles.body2};
`

const RecipeHref = styled(TextEditor)`
  margin-top: 16px;
`

export class Share extends PureComponent {
  onShare = ({ checked }) => {
    const shared = checked
    const payload = { shared }
    if (!shared && this.props.published) {
      payload.published = false
    }
    this.props.onChange(payload)
  }

  onPublish = ({ checked }) => {
    this.props.onChange({
      published: checked
    })
  }

  render() {
    const {
      title,
      shareSectionTitle,
      shareSectionMessage,
      publishSectionTitle,
      publishSectionMessage,
      href,
      shared,
      published,
      visible,
      onClose
    } = this.props
    return (
      <Modal
        title={title}
        onClose={onClose}
        visible={visible}
        button={{ title: 'Done', label: 'Close' }}
        onButtonPress={onClose}>
        <Section key="share">
          <SectionBody>
            <SectionTitle>{shareSectionTitle}</SectionTitle>
            <Message>{shareSectionMessage}</Message>
            {shared && (
              <RecipeHref
                textAlign="left"
                value={href}
                selectOnFocus
                onChange={() => {}}
              />
            )}
          </SectionBody>
          <Checkbox checked={shared} onChange={this.onShare} />
        </Section>
        <Section key="publish" disabled={!shared}>
          <SectionBody>
            <SectionTitle>{publishSectionTitle}</SectionTitle>
            <Message>{publishSectionMessage}</Message>
          </SectionBody>
          <Checkbox
            checked={published}
            disabled={!shared}
            onChange={this.onPublish}
          />
        </Section>
      </Modal>
    )
  }
}

Share.propTypes = {
  visible: PropTypes.bool,
  title: PropTypes.string,
  shareSectionTitle: PropTypes.string,
  shareSectionMessage: PropTypes.string,
  publishSectionTitle: PropTypes.string,
  publishSectionMessage: PropTypes.string,
  href: PropTypes.string,
  shared: PropTypes.bool,
  published: PropTypes.bool,
  onChange: PropTypes.func,
  onClose: PropTypes.func
}

Share.displayName = 'Share'
