import React from 'react'
import styled from 'styled-components'
import { textStyles } from '../texts/textStyles'
import figmaColors from '../colors/figmaColors'

const Link = styled.div`
  padding: 4px 10px;
  border-radius: 4px;
  cursor: pointer;
  ${textStyles.title};
  color: ${figmaColors.blue_gray_500};

  .active > & {
    color: ${figmaColors.white};
    background-color: ${figmaColors.blue_500};
  }
`

export const TabLink = ({ title, href, renderLink }) => {
  return renderLink(title, href, <Link>{title}</Link>)
}

TabLink.displayName = 'TabLink'

TabLink.defaultProps = {
  title: '',
  renderLink: (title, href, children) => (
    <a title={title} href={href}>
      {children}
    </a>
  )
}
