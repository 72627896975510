import PropTypes from 'prop-types'

export const pickerPropTypes = {
  type: PropTypes.oneOf(['default', 'unit', 'lookup']),
  alignment: PropTypes.oneOf(['left', 'right']),
  autoFocus: PropTypes.bool,
  focusOnSelect: PropTypes.bool,
  items: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.arrayOf(PropTypes.string)
      ]),
      value: PropTypes.string
    })
  ),
  selectedValue: PropTypes.any,
  selectedLabel: PropTypes.any,
  onChange: PropTypes.func
}

export const pickerDefaultProps = {
  type: 'default',
  alignment: 'right',
  autoFocus: false,
  focusOnSelect: true,
  selectedLabel: null
}
