import { calculateRecipeBeerJSON, srmToCss, convert } from 'brewcalc'
import { assign, get, update, set, map, isEmpty } from 'lodash'
import { calcBatch } from './calcBatch'

export const calcRGBColor = (srmColor) => {
  if (srmColor) {
    return srmToCss(srmColor.value)
  }
  return 'rgb(255, 255, 255)'
}

export const recalcRecipe = (recipe) => {
  const { stats, volumes, mash, boil } = calculateRecipeBeerJSON(
    recipe,
    recipe.mash,
    get(recipe, 'equipment.equipment_items', {})
  )
  const {
    original_gravity,
    final_gravity,
    alcohol_by_volume,
    color_estimate,
    ibu_estimate,
  } = stats

  const result = assign({}, recipe, {
    original_gravity,
    final_gravity,
    alcohol_by_volume,
    color_estimate,
    ibu_estimate,
    volumes,
    color_css: calcRGBColor(color_estimate),
    mash: mash || recipe.mash,
    boil: boil || recipe.boil,
  })

  if (recipe.batch != null) {
    result.batch = calcBatch(recipe)
  }

  return result
}

const scaleIngredients = (scaleFactor, ingredients) => {
  if (isEmpty(ingredients)) {
    return ingredients
  }

  return map(ingredients, (i) => ({
    ...i,
    amount: {
      unit: i.amount.unit,
      value: +(scaleFactor * i.amount.value).toFixed(2),
    },
  }))
}

export const scaleRecipe = (
  recipe,
  {
    from: { batch_size: initial_batch_size },
    to: { batch_size: target_batch_size },
  }
) => {
  if (initial_batch_size.value === 0 || target_batch_size.value === 0) {
    return recipe
  }

  const scaleFactor =
    target_batch_size.value /
    convert(
      initial_batch_size.value,
      initial_batch_size.unit,
      target_batch_size.unit
    )

  update(recipe, 'ingredients.fermentable_additions', (fermentable_additions) =>
    scaleIngredients(scaleFactor, fermentable_additions)
  )
  update(recipe, 'ingredients.hop_additions', (hop_additions) =>
    scaleIngredients(scaleFactor, hop_additions)
  )
  update(
    recipe,
    'ingredients.miscellaneous_additions',
    (miscellaneous_additions) =>
      scaleIngredients(scaleFactor, miscellaneous_additions)
  )

  set(recipe, 'batch_size', target_batch_size)

  return recipe
}
