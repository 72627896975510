import React, { useMemo, useState, useCallback } from 'react'
import { forEach } from 'lodash'
import { getFormUpload } from '@resolve-js/module-uploader'
import FileUploadProgress from 'react-fileupload-progress'
import { AccentButton, Dropzone } from '@brewcomputer/ui'
import { RecipeDataSection } from './RecipeDataSection'
import styled from 'styled-components'

const Form = styled.div``

const UploadButton = styled(AccentButton)`
  margin-top: 16px;
  align-self: flex-start;
`

const Preview = styled.img`
  max-width: 50%;
`

const ProgressBar = styled.div`
  box-sizing: content-box;
  height: 16px;
  position: relative;
  padding: 16px 0;
  box-shadow: inset 0 -1px 1px rgba(255, 255, 255, 0.3);

  & > span {
    display: block;
    height: 100%;
    border-top-right-radius: 8px;
    border-bottom-right-radius: 8px;
    border-top-left-radius: 16px;
    border-bottom-left-radius: 16px;
    background-color: rgb(43, 194, 83);
    background-image: linear-gradient(
      center bottom,
      rgb(43, 194, 83) 37%,
      rgb(84, 240, 84) 69%
    );
    box-shadow: inset 0 2px 9px rgba(255, 255, 255, 0.3),
      inset 0 -2px 6px rgba(0, 0, 0, 0.4);
    position: relative;
    overflow: hidden;
  }
`

const DIRECTORY = 'images'

const FormRenderer = ({ file, onSubmit }) => (
  <Form>
    <Preview src={file != null ? URL.createObjectURL(file) : null} />
    <UploadButton title="Upload" onClick={onSubmit}>
      Upload
    </UploadButton>
  </Form>
)

const ProgressRenderer = ({ progress }) => {
  if (progress >= 0) {
    return (
      <ProgressBar>
        <span style={{ width: `${progress}%` }} />
      </ProgressBar>
    )
  }
  return null
}

export const RecipeImageUploader = ({ onUploadSuccess }) => {
  const [file, setFile] = useState(null)
  const [formFields, setFormFields] = useState(null)
  const [uploadUrl, setUploadUrl] = useState(null)
  const [uploadId, setUploadId] = useState(null)
  const [showResult, setShowResult] = useState(false)

  const upload = useCallback((fileFromDropzone) => {
    if (fileFromDropzone) {
      setFile(fileFromDropzone)
      getFormUpload({ dir: DIRECTORY }).then(({ form, uploadId: id }) => {
        setUploadUrl(form.url)
        setUploadId(id)
        const formData = new FormData()
        forEach(form.fields, (value, key) => {
          formData.append(key, value)
        })
        formData.append('Content-Type', fileFromDropzone.type)
        formData.append('file', fileFromDropzone)
        setFormFields(formData)
      })
    }
  }, [])

  const onLoad = useCallback(() => {
    setShowResult(true)
    onUploadSuccess(uploadId)
  }, [uploadId])

  const formGetter = useMemo(() => () => formFields, [formFields])

  return (
    <RecipeDataSection caption="Images">
      {file == null && (
        <Dropzone
          message="Drop your file here or click to select a file"
          onFileChange={upload}
        />
      )}
      {uploadUrl && !showResult && (
        <FileUploadProgress
          url={uploadUrl}
          method="post"
          formGetter={formGetter}
          formRenderer={(onSubmit) => (
            <FormRenderer file={file} onSubmit={onSubmit} />
          )}
          progressRenderer={(progress, hasError) => (
            <ProgressRenderer progress={progress} />
          )}
          onLoad={onLoad}
        />
      )}
    </RecipeDataSection>
  )
}
