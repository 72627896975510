import { toLower, includes } from 'lodash'
import React, { useState, useCallback, Fragment } from 'react'
import isEmail from 'validator/lib/isEmail'
import { Modal, colors, textStyles } from '@brewcomputer/ui'
import { EmailInput, Email } from './Styled'
import { useSendMagicLink } from '../../hooks/auth'
import { useSelector } from 'react-redux'
import { LoadingIndicator } from '../LoadingIndicator'
import { emailAuth } from '../../texts'
import styled from 'styled-components'

const Container = styled.div`
  border-top: 1px solid ${colors.DIVIDER};
  padding: 24px;
`

export const Message = styled.div`
  ${textStyles.body1};
`

const Controls = styled.div`
  margin-top: 20px;
  align-self: stretch;
`

export const Loader = styled(LoadingIndicator)`
  flex-grow: 0;
  flex-shrink: 0;
  padding: 0;
`

export const MailWarning = styled.span`
  ${textStyles.smallText}
`

const getTitle = ({ success, error }) => {
  if (success) {
    return emailAuth.success.title
  }
  if (error) {
    return emailAuth.error.title
  }
  return emailAuth.request.title
}

const isGmail = (email) => {
  const lowerCaseEmail = toLower(email)
  return (
    includes(lowerCaseEmail, 'gmail.com') ||
    includes(lowerCaseEmail, 'googlemail.com')
  )
}

const getMessage = ({ success, error }, email) => {
  if (success) {
    const [message1, message2] = emailAuth.success.messages
    return (
      <Fragment>
        <span>
          {message1}
          <Email>{email}.</Email>
        </span>
        <span>{message2}</span>
        {isGmail(email) && (
          <MailWarning>{emailAuth.success.warning}</MailWarning>
        )}
      </Fragment>
    )
  }
  if (error) {
    return emailAuth.error.message
  }
  return emailAuth.request.message
}

export const EmailAuth = ({ visible, onClose }) => {
  const [email, setEmail] = useState('')
  const [warning, setWarning] = useState(false)
  const { loading, success, error } = useSelector((state) => state.auth)
  const user = useSelector((state) => state.user)
  const showInput = !loading && !success && !error

  const onChangeEmail = useCallback((text) => {
    setEmail(text)
    if (warning) {
      setWarning(false)
    }
  })

  const sendEmail = useSendMagicLink()

  const verifyAndSendEmail = useCallback(() => {
    if (isEmail(email)) {
      setWarning(false)
      sendEmail({ email, userId: user.id })
    } else {
      setWarning(true)
    }
  })

  const buttonProps = showInput
    ? {
        title: 'Continue',
        label: 'Continue authentication',
      }
    : null

  return (
    <Modal
      title={getTitle({ success, error })}
      button={buttonProps}
      onButtonPress={verifyAndSendEmail}
      visible={visible}
      onClose={onClose}>
      <Container>
        {loading ? (
          <Loader />
        ) : (
          <Message>{getMessage({ success, error }, email)}</Message>
        )}
        {showInput && (
          <Controls>
            <EmailInput
              autoFocus
              type="email"
              placeholder="you@domain.com"
              warning={warning}
              value={email}
              onChange={onChangeEmail}
              onSubmit={verifyAndSendEmail}
            />
          </Controls>
        )}
      </Container>
    </Modal>
  )
}
