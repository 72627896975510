import styled, { createGlobalStyle } from 'styled-components'
import { textStyles, Input, colors } from '@brewcomputer/ui'

export const Background = createGlobalStyle`
  .page {
    background-color: ${(props) => props.backgroundColor};
  }
  .page > main {
    flex-grow: 1;
    flex-shrink: 1;
  }
`

export const Container = styled.div`
  flex-grow: 1;
  flex-shrink: 1;
  align-items: center;
  justify-content: center;
`

export const AuthContainer = styled(Container)`
  padding-bottom: 60px;
`

export const Title = styled.h1`
  ${textStyles.H1};
  text-align: center;
  margin-top: 80px;
  font-weight: bold;
`

export const AuthTitle = styled(Title)`
  margin-top: 0;
  margin-bottom: 16px;
`

export const TextMessage = styled.div`
  ${textStyles.body1};
  max-width: 471px;
  text-align: center;
  margin-bottom: ${(props) => props.marginBottom};
  margin-top: 0;
`

export const Email = styled.span`
  font-weight: 500;
`

export const EmailInput = styled(Input)`
  box-sizing: border-box;
  padding: 8px 16px;
  background-color: ${colors.BACKGROUND};
  border-radius: 4px;
  border-bottom: 1px solid
    ${({ warning }) => (warning ? colors.NEGATIVE_RED : 'transparent')};
  margin-bottom: 12px;

  :focus {
    border-bottom: 1px solid
      ${({ warning }) => (warning ? colors.NEGATIVE_RED : colors.ACCENT_BLUE)};
  }
`

export const NameInput = styled(EmailInput)`
  margin: 16px 0;
  background-color: ${colors.WHITE};
`

export const Footer = styled.footer`
  padding: 30px 0;
  align-items: center;
`

export const Links = styled.div`
  flex-direction: row;
  align-items: center;
  justify-content: center;
  ${textStyles.smallText};
  margin-bottom: 16px;
  a {
    text-decoration: underline;
    color: ${colors.ACCENT_BLUE};
  }
  a:first-child {
    margin-right: 24px;
  }
`

export const Copyright = styled.div`
  color: ${colors.BLACK};
  ${textStyles.smallText};
`
