import PropTypes from 'prop-types'
import React, { PureComponent } from 'react'
import styled from 'styled-components'
import { colors } from '../colors/colors'
import { textStyles } from '../texts/textStyles'

const getBackgroundColor = ({ isOwn }) =>
  isOwn ? colors.CHAT_OWN_MESSAGE_BACKGROUND : colors.CHAT_MESSAGE_BACKGROUND

const getAlignment = ({ isOwn }) => (isOwn ? 'flex-end' : 'flex-start')

const getBorderRadius = ({ isOwn }) =>
  `8px 8px ${isOwn ? 0 : '8px'} ${isOwn ? '8px' : 0}`

const Container = styled.div`
  position: relative;
  max-width: 245px;
  padding: 0 12px;
  margin: 5px 0;
  flex-shrink: 0;
  align-self: ${getAlignment};
`

const MessageBox = styled.div`
  padding: 8px 12px;
  border-radius: ${getBorderRadius};
  background-color: ${getBackgroundColor};
`

const getTriangleBorder = ({ isOwn }) =>
  isOwn
    ? `border-left: 12px solid ${colors.CHAT_OWN_MESSAGE_BACKGROUND}`
    : `border-right: 12px solid ${colors.CHAT_MESSAGE_BACKGROUND}`

const getTrianglePosition = ({ isOwn }) => (isOwn ? `right: 0` : `left: 0`)

const Triangle = styled.div`
  position: absolute;
  bottom: 0;
  ${getTrianglePosition};
  width: 0;
  height: 0;
  border-top: 12px solid transparent;
  ${getTriangleBorder};
`

const Text = styled.div`
  ${textStyles.body2};
  color: ${colors.WHITE};
  margin-bottom: 4px;
  word-break: break-all;
`

const getInfoColor = ({ isOwn }) =>
  isOwn ? colors.CHAT_OWN_INFO_COLOR : colors.CHAT_INFO_COLOR

const Info = styled.div`
  ${textStyles.smallText};
  color: ${getInfoColor};
`

export class Message extends PureComponent {
  static propTypes = {
    id: PropTypes.string,
    isOwn: PropTypes.bool,
    read: PropTypes.bool,
    last: PropTypes.bool,
    author: PropTypes.string,
    message: PropTypes.string,
    date: PropTypes.string,
    onRead: PropTypes.func
  }

  messageRef = React.createRef()

  componentDidMount() {
    const { id, isOwn, last, read, onRead } = this.props
    if (!read && !isOwn && onRead) {
      onRead(id)
    }
    if (last && this.messageRef.current) {
      setTimeout(() => {
        this.messageRef.current.scrollIntoView()
      }, 100)
    }
  }

  render() {
    const { author, date, message, isOwn } = this.props
    return (
      <Container ref={this.messageRef} isOwn={isOwn}>
        <MessageBox isOwn={isOwn}>
          <Text>{message}</Text>
          <Info isOwn={isOwn}>{`${author}, ${date}`}</Info>
        </MessageBox>
        <Triangle isOwn={isOwn} />
      </Container>
    )
  }
}
