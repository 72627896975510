import { capitalize, map, split, join } from 'lodash'
import styled from 'styled-components'
import { Link as RouterLink } from 'react-router-dom'
import { colors } from '@brewcomputer/ui'
import { beautifyUnit } from '@brewcomputer/utils'

export const Header = styled.div`
  border-bottom: 1px solid ${colors.DIVIDER};
  @media (max-width: 992px) {
    display: none;
  }
`
export const Separator = styled.div`
  @media (min-width: 992px) {
    border-bottom: 1px solid ${colors.DIVIDER};
  }
  @media (max-width: 992px) {
    margin-bottom: 4px;
  }
`

export const Link = styled(RouterLink)`
  position: relative;
  flex-direction: column;
  align-items: stretch;
  justify-content: center;
  display: flex;
  flex-grow: 1;
  :hover,
  :focus {
    box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.1);
    z-index: 100;
  }

  & > button {
    position: absolute;
    padding: 5px;
    right: 14px;
  }
`

export const capitalizeWords = str =>
  join(map(split(str, ' '), word => capitalize(word)), ' ')

export const beautifyMeasurable = measurable => ({
  value: measurable.value,
  unit: beautifyUnit(measurable.unit)
})
