import styled from 'styled-components'
import {
  avatarStyle,
  containerStyle,
  editorStyle,
  infoStyle,
  messageStyle,
  textsStyle
} from './style'

export const Container = styled.div`
  ${containerStyle};
`

export const Avatar = styled.img`
  ${avatarStyle};
`

export const Texts = styled.div`
  ${textsStyle};
`

export const Info = styled.div`
  ${infoStyle};
`

export const Message = styled.div`
  ${messageStyle};
`

export const Editor = styled.div`
  ${editorStyle};
`
