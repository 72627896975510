import styled, { css } from 'styled-components'
import { colors } from '../colors/colors'
import { textStyles } from './textStyles'

export const H1 = styled.div`
  font-size: 2rem;
  color: ${colors.BLACK};
`

export const H2 = styled.div`
  font-size: 1rem;
`

export const H3 = styled.div`
  font-size: 1rem;
  font-weight: bold;
  color: ${colors.sectionTitle};
`

export const PopupTitle = styled.div`
  ${textStyles.H2};
  color: ${colors.BLACK};
`

export const BackTitle = styled.div`
  ${textStyles.title3};
  color: ${colors.BLACK};
`

export const SectionTitle = styled.div`
  ${textStyles.H2};
  color: ${colors.BLACK};
`

export const TableColumnTitle = styled.div`
  ${textStyles.title};
  color: ${colors.BLACK};
`

export const MenuItemTitle = styled.div`
  ${textStyles.title2};
  color: ${colors.BLACK};
`

const singleLine = css`
  white-space: nowrap;
`

export const Text = styled.div`
  display: inline;
  font-size: 13px;
  line-height: 19px;
  color: ${colors.BLACK};
  ${props => props.numberOfLines === 1 && singleLine};
`

export const SmallText = styled(Text)`
  font-size: 0.875rem;
`
