import { get, has, map, update } from 'lodash'
import {
  recipeAddIngredientAction,
  recipeEditAction,
  recipeEditBeerStyleAction,
  recipeEditEquipmentAction,
  recipeEditIngredientAction,
  recipeEditPrivacyAction,
  recipeEditProcedureAction,
  recipeRemoveIngredientAction,
  recipesMyGetQuery,
  recipesPublicGetQuery,
  recipesViewModelLoad,
  recipeChangeOptimistic,
  recipeCreateAction,
  recipeScaleAction
} from '../actions/recipe'
import {
  batchChangeStatusAction,
  batchCreateAction,
  batchEditAction,
  batchEditMeasuredAction,
  batchesGetQuery
} from '../actions/batch'
import { convertRecipe } from '@brewcomputer/utils'
import { getRecipe } from '../../selectors/recipe'
import recipeViewModel from '../../../domain/view-models/recipe.projection'
import {
  RECIPE_BATCH_EDITED,
  RECIPE_BATCH_MEASURED_EDITED,
  RECIPE_BATCH_STATUS_CHANGED,
  RECIPE_BEER_STYLE_EDITED,
  RECIPE_EDITED,
  RECIPE_EQUIPMENT_EDITED,
  RECIPE_INGREDIENT_ADDED,
  RECIPE_INGREDIENT_EDITED,
  RECIPE_INGREDIENT_REMOVED,
  RECIPE_PRIVACY_EDITED,
  RECIPE_PROCEDURE_EDITED,
  RECIPE_SCALED
} from '../../../domain/events'

const optimisticRecipeActions = {
  [recipeEditAction.types.request]: RECIPE_EDITED,
  [recipeAddIngredientAction.types.request]: RECIPE_INGREDIENT_ADDED,
  [recipeEditIngredientAction.types.request]: RECIPE_INGREDIENT_EDITED,
  [recipeRemoveIngredientAction.types.request]: RECIPE_INGREDIENT_REMOVED,
  [recipeEditBeerStyleAction.types.request]: RECIPE_BEER_STYLE_EDITED,
  [recipeEditProcedureAction.types.request]: RECIPE_PROCEDURE_EDITED,
  [recipeEditEquipmentAction.types.request]: RECIPE_EQUIPMENT_EDITED,
  [batchEditAction.types.request]: RECIPE_BATCH_EDITED,
  [batchEditMeasuredAction.types.request]: RECIPE_BATCH_MEASURED_EDITED,
  [batchChangeStatusAction.types.request]: RECIPE_BATCH_STATUS_CHANGED,
  [recipeEditPrivacyAction.types.request]: RECIPE_PRIVACY_EDITED,
  [recipeScaleAction.types.request]: RECIPE_SCALED
}

const getUnitSettings = state => get(state, 'user.unitSettings', {})

const convertRecipes = ({ getState, dispatch }) => next => action => {
  const unitSettings = getUnitSettings(getState())

  switch (action.type) {
    case recipesMyGetQuery.types.success:
    case recipesPublicGetQuery.types.success:
    case batchesGetQuery.types.success: {
      update(action, 'payload.result', recipes =>
        map(recipes, recipe => convertRecipe(recipe, unitSettings))
      )
      next(action)
      break
    }
    case recipesViewModelLoad.types.success:
    case recipeCreateAction.types.success:
    case batchCreateAction.types.success: {
      update(action, 'payload.recipe', recipe =>
        convertRecipe(recipe, unitSettings)
      )
      next(action)
      break
    }
  }

  // dispatch view-model optimistic action
  if (has(optimisticRecipeActions, action.type)) {
    const { recipeId, ...rest } = action.payload
    const sourceRecipe = getRecipe(recipeId)(getState())
    if (sourceRecipe != null) {
      const eventType = optimisticRecipeActions[action.type]
      const recipe = recipeViewModel[eventType](sourceRecipe, {
        payload: rest
      })
      dispatch(
        recipeChangeOptimistic({
          recipeId,
          recipe: convertRecipe(recipe, unitSettings)
        })
      )
    }
  }

  next(action)
}

export default convertRecipes
