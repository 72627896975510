export default {
  Init: () => 0,
  FEEDBACK_CREATED: (state, { aggregateId, payload }) => {
    if (payload.authorId !== aggregateId) {
      return state + 1
    }
    return state
  },
  FEEDBACK_UPDATED: (state, { payload }) => {
    if (payload.content.read && state > 0) {
      return state - 1
    }
    return state
  }
}
