import { useCallback } from 'react'
import { useDispatch } from 'react-redux'
import {
  emailAuthSuccess,
  emailAuthRequest,
  emailAuthFailure
} from '../redux/actions/auth'
import { email as emailEndpoints } from '../../auth/endpoints'
import fetch from 'isomorphic-fetch'

const createOptions = payload => ({
  method: 'POST',
  credentials: 'same-origin',
  body: JSON.stringify(payload),
  headers: { 'Content-Type': 'application/json' }
})

export const useSendMagicLink = () => {
  const dispatch = useDispatch()

  return useCallback(async ({ email, userId }) => {
    dispatch(emailAuthRequest({ email, userId }))
    try {
      const response = await fetch(
        emailEndpoints.email_sender_url,
        createOptions({ email, userId })
      )
      const result = await response.json()
      if (!response.ok) {
        return dispatch(emailAuthFailure({ email, userId, error: result }))
      }
      dispatch(emailAuthSuccess(result))
    } catch (error) {
      dispatch(emailAuthFailure({ email, userId, error }))
    }
  })
}
