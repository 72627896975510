import styled from 'styled-components'
import { colors } from '../colors/colors'
import { textStyles } from '../texts/textStyles'

export const BottomLink = styled.div`
  color: ${colors.GRAY};
  height: 100%;
  justify-content: center;
  align-items: center;
  ${textStyles.smallText};
  font-weight: 500;
  .active > & {
    color: ${colors.ACCENT_BLUE};
  }
  .active > & svg path {
    fill: ${colors.ACCENT_BLUE};
  }
`
