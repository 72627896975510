import produce from 'immer'
import {
  assign,
  concat,
  map,
  merge,
  filter,
  omit,
  update,
  has,
  pull,
} from 'lodash'
import { scaleRecipe as scaleRecipeUtils } from '@brewcomputer/utils'

const clearPayload = (payload) => omit(payload, 'commandId')

const oldIngredientTypesMap = {
  fermentable: 'fermentable_additions',
  hop: 'hop_additions',
  culture: 'culture_additions',
  misc: 'miscellaneous_additions',
}

export const transformIngredientType = (ingredientType) => {
  if (has(oldIngredientTypesMap, ingredientType)) {
    return oldIngredientTypesMap[ingredientType]
  }
  return ingredientType
}

const updateRecipeIngredients = (recipe, ingredientType, updater) =>
  produce(recipe, (draft) => {
    update(
      draft,
      ['ingredients', transformIngredientType(ingredientType)],
      updater
    )
  })

export const addIngredient = (recipe, { data, ingredientType, id }) =>
  updateRecipeIngredients(recipe, ingredientType, (ingredients) =>
    ingredients == null
      ? [{ ...data, id }]
      : concat(ingredients, { ...data, id })
  )

export const editIngredient = (recipe, { changes, ingredientType, id }) =>
  updateRecipeIngredients(recipe, ingredientType, (ingredients) =>
    map(ingredients, (ingredient) =>
      ingredient.id === id ? merge(ingredient, changes) : ingredient
    )
  )

export const removeIngredient = (recipe, { ingredientType, id }) =>
  updateRecipeIngredients(recipe, ingredientType, (ingredients) =>
    filter(ingredients, (ingredient) => ingredient.id !== id)
  )

export const editRecipeFields = (recipe, payload) =>
  produce(recipe, (draft) => {
    merge(draft, clearPayload(payload))
  })

export const editRecipeBeerStyle = (recipe, payload) =>
  produce(recipe, (draft) => {
    draft.style = clearPayload(payload)
  })

export const editRecipeProcedure = (recipe, { procedureType, data }) =>
  produce(recipe, (draft) => {
    draft[procedureType] = data
  })

export const editRecipeEquipment = (recipe, payload) =>
  produce(recipe, (draft) => {
    draft.equipment = clearPayload(payload)
  })

export const editRecipeBatch = (recipe, payload) =>
  produce(recipe, (draft) => {
    assign(draft.batch, clearPayload(payload))
  })

export const editRecipeBatchMeasured = (recipe, payload) =>
  produce(recipe, (draft) => {
    update(draft, 'batch.measuredValues', (measured = {}) =>
      assign(measured, payload)
    )
  })

export const editRecipePrivacy = (recipe, payload) =>
  produce(recipe, (draft) => {
    if (payload.public != null) {
      draft.shared = payload.public
    } else {
      assign(draft, payload)
    }
  })

export const scaleRecipe = (recipe, payload) =>
  produce(recipe, (draft) => {
    scaleRecipeUtils(draft, payload)
  })

export const addRecipeImage = (recipe, payload) =>
  produce(recipe, (draft) => {
    if (draft.images != null) {
      draft.images.push(payload.uploadId)
    } else {
      draft.images = [payload.uploadId]
    }
  })

export const removeRecipeImage = (recipe, payload) =>
  produce(recipe, (draft) => {
    if (draft.images != null) {
      pull(draft.images, payload.uploadId)
      if (draft.images.length === 0) {
        delete draft.images
      }
    }
  })
