import { includes, isEmpty, reduce } from 'lodash'
import { convertBatchVolume } from './convertMeasurable'

const convertEquipmentItem = (equipmentItem, unitSettings) => {
  return {
    ...equipmentItem,
    ...reduce(
      equipmentItem,
      (result, value, key) => {
        if (includes(['maximum_volume', 'loss', 'boil_rate_per_hour'], key)) {
          result[key] = convertBatchVolume(value, unitSettings.volume)
        } else {
          result[key] = value
        }
        return result
      },
      {}
    )
  }
}

export const convertEquipment = (equipment, unitSettings) => {
  if (isEmpty(equipment) || isEmpty(equipment.equipment_items)) {
    return undefined
  }

  return {
    ...equipment,
    equipment_items: {
      hlt: convertEquipmentItem(equipment.equipment_items.hlt, unitSettings),
      mash_tun: convertEquipmentItem(
        equipment.equipment_items.mash_tun,
        unitSettings
      ),
      brew_kettle: convertEquipmentItem(
        equipment.equipment_items.brew_kettle,
        unitSettings
      ),
      fermenter: convertEquipmentItem(
        equipment.equipment_items.fermenter,
        unitSettings
      )
    }
  }
}
