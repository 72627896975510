import React, { Component } from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'

const componentWidth = 32
const knobSize = 16

const Button = styled.button`
  position: relative;
  width: ${componentWidth}px;
  height: ${knobSize}px;
  justify-content: center;
  align-items: stretch;
  background-color: transparent;

  &:focus .boolean-input-knob {
    box-shadow: rgba(0, 0, 0, 0.4) 0px 1px 4px 1px;
  }
`

const Knob = styled.div`
  position: absolute;
  left: 0;
  top: 0;
  width: ${knobSize}px;
  height: ${knobSize}px;
  border-radius: 8px;
  box-shadow: rgba(0, 0, 0, 0.4) 0px 1px 3px 0px;
  background-color: rgb(255, 255, 255);
  z-index: 100;
  transform: translateX(
    ${props => (props.value ? componentWidth - knobSize + 1 : 0)}px
  );
  transition-duration: 100ms;
`

const Bar = styled.div`
  height: 12px;
  border-radius: 6px;
  transition-duration: 100ms;
  background-color: ${props => (props.value ? '#3498DB' : '#CDCDCD')};
`

export class BooleanInput extends Component {
  rootViewRef = null

  focus() {
    this.rootViewRef.focus()
  }

  onPress = () => {
    this.toggle()
  }

  onKeyPress = e => {
    const { value } = this.props
    if (!e.ctrlKey && !e.altKey && !e.shiftKey) {
      if (e.keyCode === 37) {
        if (value) {
          this.toggle()
        }
      } else if (e.keyCode === 39) {
        if (!value) {
          this.toggle()
        }
      }
    }
  }

  toggle = () => {
    const { value, onChange } = this.props
    onChange(!value)
  }

  createRootViewRef = ref => {
    this.rootViewRef = ref
  }

  render() {
    const { value, className } = this.props

    return (
      <Button
        className={className}
        onClick={this.onPress}
        onKeyDown={this.onKeyPress}
        ref={this.createRootViewRef}
        tabIndex={0}>
        <Knob className="boolean-input-knob" value={value} />
        <Bar value={value} />
      </Button>
    )
  }
}

BooleanInput.propTypes = {
  value: PropTypes.any,
  className: PropTypes.any,
  onChange: PropTypes.func
}
