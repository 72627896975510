import produce from 'immer'
import { remove } from 'lodash'
import {
  batchesGetQuery,
  batchesCountQuery,
  batchRemoveAction
} from '../actions/batch'

export const initialState = {
  isLoading: true,
  data: [],
  count: 0
}

const reducer = produce((draft, action) => {
  switch (action.type) {
    case batchesGetQuery.types.request: {
      draft.isLoading = true
      return draft
    }
    case batchesGetQuery.types.success: {
      draft.data = action.payload.result
      draft.isLoading = false
      return draft
    }
    case batchesGetQuery.types.failure: {
      draft.isLoading = false
      return draft
    }
    case batchesCountQuery.types.success: {
      draft.count = action.payload.result
      return draft
    }
    case batchRemoveAction.types.request: {
      const { recipeId } = action.payload
      remove(draft.data, { id: recipeId })
      return draft
    }
  }
}, initialState)

export default reducer
