import { isNil } from 'lodash'
import React, { Fragment, PureComponent } from 'react'
import { convert, round } from '@brewcomputer/utils'
import {
  LayoutGroup,
  renderMeasurableFieldEditor,
  renderNumberFieldEditor,
  renderPickerFieldEditor,
  renderDateFieldEditor,
  ToolTitle
} from './ToolsUtils'

const rateItems = [
  { value: '0.35', label: 'Ale - 0.35' },
  { value: '0.5', label: 'Ale - 0.5' },
  { value: '0.75', label: 'Ale - 0.75' },
  { value: '1.0', label: 'Ale, High Gravity Ale - 1.0' },
  { value: '1.25', label: 'Ale, High Gravity Ale - 1.25' },
  { value: '1.5', label: 'Lager - 1.5' },
  { value: '1.75', label: 'Lager - 1.75' },
  { value: '2.0', label: 'High Gravity Lager - 2.0' }
]

const aerationTypes = [
  { value: 'none', label: 'C.White - None' },
  { value: 'shaking', label: 'C.White - Shaking' },
  { value: 'stir1', label: 'C.White - Stir' },
  { value: 'stir2', label: 'Braukaiser - Stir' }
]

const millisecondsPerDay = 24 * 60 * 60 * 1000
const yeastDayViability = 0.7

export class YeastTool extends PureComponent {
  constructor(props) {
    super(props)
    const productionDate = isNil(props.productionDate)
      ? new Date()
      : props.productionDate
    let obj = {
      batch: {
        volume: { value: 25, unit: 'l' },
        originalGravity: { value: 1.05, unit: 'sg' },
        rate: rateItems[2].value,
        targetCellsAmount: 0
      },
      dryYeast: {
        density: 10,
        targetWeight: { value: 0, unit: 'g' },
        packageWeight: { value: 11.5, unit: 'g' },
        targetPackageAmount: 0,
        availableCellsAmount: 0,
        difference: 0
      },
      liquidYeast: {
        packageCellsAmount: 100,
        productionDate: productionDate,
        viability: { value: 75, unit: '%' },
        targetPackageAmount: 0,
        availableCellsAmount: 0,
        difference: 0
      },
      yeastStarter: {
        startingCellsAmount: 100,
        step1: {
          volume: { value: 1, unit: 'l' },
          originalGravity: { value: 1.037, unit: 'sg' },
          targetDME: { value: 0, unit: 'g' },
          aerationType: aerationTypes[1].value,
          availableCellsAmount: 0
        },
        step2: {
          volume: { value: 1.5, unit: 'l' },
          originalGravity: { value: 1.037, unit: 'sg' },
          targetDME: { value: 0, unit: 'g' },
          aerationType: aerationTypes[1].value,
          availableCellsAmount: 0
        },
        step3: {
          volume: { value: 2.0, unit: 'l' },
          originalGravity: { value: 1.037, unit: 'sg' },
          targetDME: { value: 0, unit: 'g' },
          aerationType: aerationTypes[1].value,
          availableCellsAmount: 0
        }
      }
    }
    this._calculate(obj)
    this.state = { obj: obj }
  }

  _calculateLiquidYeastViability(obj) {
    const yeastAgeInDays = Math.floor(
      (Date.now() - obj.liquidYeast.productionDate) / millisecondsPerDay
    )
    let viability = 100 - yeastAgeInDays * yeastDayViability
    if (viability < 0) {
      viability = 0
    } else if (viability > 100) {
      viability = 100
    }
    obj.liquidYeast.viability.value = round(viability, 1)
  }

  _calculateYeastStarterStep = (startingCellsAmount, step) => {
    const originalGravity = convert(
      step.originalGravity.value,
      step.originalGravity.unit,
      'sg',
      10
    )
    const volumeMl = convert(step.volume.value, step.volume.unit, 'ml', 10)
    const volumeL = convert(step.volume.value, step.volume.unit, 'l', 10)
    const targetDME =
      ((((originalGravity - 1) / 0.045) * volumeMl) / 1000) * 120
    let growthRate =
      12.54793776 * Math.pow(startingCellsAmount / volumeL, -0.4594858324) -
      0.9994994906
    if (step.aerationType === 'shaking') {
      growthRate = growthRate + 0.5
    } else if (step.aerationType === 'stir1') {
      growthRate = growthRate + 1.0
    } else if (step.aerationType === 'stir2') {
      growthRate = growthRate + 0.9
    }

    step.targetDME.value = convert(targetDME, 'g', step.targetDME.unit, 1)
    step.availableCellsAmount = round((1 + growthRate) * startingCellsAmount, 1)
  }

  _calculate = (obj, fieldName) => {
    const targetCellsAmount =
      (Number(obj.batch.rate) *
        1000000 *
        convert(obj.batch.volume.value, obj.batch.volume.unit, 'ml', 10) *
        convert(
          obj.batch.originalGravity.value,
          obj.batch.originalGravity.unit,
          'plato',
          10
        )) /
      1000000000

    if (fieldName === 'liquidYeast.productionDate') {
      this._calculateLiquidYeastViability(obj)
    }

    const dryYeastTargetWeight = targetCellsAmount / obj.dryYeast.density
    const dryYeastTargetPackageAmount = Math.ceil(
      dryYeastTargetWeight /
        convert(
          obj.dryYeast.packageWeight.value,
          obj.dryYeast.packageWeight.unit,
          'g',
          10
        )
    )
    const dryYeastAvailableCellsAmount =
      dryYeastTargetPackageAmount *
      convert(
        obj.dryYeast.packageWeight.value,
        obj.dryYeast.packageWeight.unit,
        'g',
        10
      ) *
      obj.dryYeast.density

    const liquidYeastTargetPackageAmount = Math.ceil(
      targetCellsAmount /
        obj.liquidYeast.packageCellsAmount /
        (obj.liquidYeast.viability.value / 100)
    )
    const liquidYeastAvailableCellsAmount =
      liquidYeastTargetPackageAmount *
      obj.liquidYeast.packageCellsAmount *
      (obj.liquidYeast.viability.value / 100)

    obj.batch.targetCellsAmount = round(targetCellsAmount, 1)
    obj.dryYeast.targetWeight.value = convert(
      dryYeastTargetWeight,
      'g',
      obj.dryYeast.targetWeight.unit,
      1
    )
    obj.dryYeast.targetPackageAmount = dryYeastTargetPackageAmount
    obj.dryYeast.availableCellsAmount = round(dryYeastAvailableCellsAmount, 1)
    obj.dryYeast.difference = round(
      dryYeastAvailableCellsAmount - targetCellsAmount,
      1
    )

    obj.liquidYeast.targetPackageAmount = liquidYeastTargetPackageAmount
    obj.liquidYeast.availableCellsAmount = round(
      liquidYeastAvailableCellsAmount,
      1
    )
    obj.liquidYeast.difference = round(
      liquidYeastAvailableCellsAmount - targetCellsAmount,
      1
    )

    this._calculateYeastStarterStep(
      obj.yeastStarter.startingCellsAmount,
      obj.yeastStarter.step1
    )
    this._calculateYeastStarterStep(
      obj.yeastStarter.step1.availableCellsAmount,
      obj.yeastStarter.step2
    )
    this._calculateYeastStarterStep(
      obj.yeastStarter.step2.availableCellsAmount,
      obj.yeastStarter.step3
    )
  }

  _onChange = (obj, fieldName) => {
    const newObj = Object.assign({}, obj)
    this._calculate(newObj, fieldName)
    this.setState({ obj: newObj })
  }

  render() {
    const { obj } = this.state
    return (
      <Fragment>
        <ToolTitle text={'Yeast'} />

        <LayoutGroup caption={'Batch'}>
          {renderMeasurableFieldEditor(
            'Volume',
            obj,
            'batch.volume',
            false,
            this._onChange
          )}
          {renderMeasurableFieldEditor(
            'Original Gravity',
            obj,
            'batch.originalGravity',
            false,
            this._onChange
          )}
          {renderPickerFieldEditor(
            'Rate (million cells / ml / °P)',
            obj,
            'batch.rate',
            rateItems,
            this._onChange
          )}
          {renderNumberFieldEditor(
            'Rate (million cells / ml / °P)',
            obj,
            'batch.rate',
            this._onChange,
            false
          )}
          {renderNumberFieldEditor(
            'Target Cells Amount (billion cells)',
            obj,
            'batch.targetCellsAmount',
            null,
            true
          )}
        </LayoutGroup>

        <LayoutGroup caption={'Dry Yeast'}>
          {renderNumberFieldEditor(
            'Density (billion cells / g)',
            obj,
            'dryYeast.density',
            this._onChange,
            false
          )}
          {renderMeasurableFieldEditor(
            'Target Weight',
            obj,
            'dryYeast.targetWeight',
            true,
            null
          )}
          {renderMeasurableFieldEditor(
            'Package Weight',
            obj,
            'dryYeast.packageWeight',
            false,
            this._onChange
          )}
          {renderNumberFieldEditor(
            'Target Package Amount',
            obj,
            'dryYeast.targetPackageAmount',
            null,
            true
          )}
          {renderNumberFieldEditor(
            'Available Cells Amount (billion cells)',
            obj,
            'dryYeast.availableCellsAmount',
            null,
            true
          )}
          {renderNumberFieldEditor(
            'Difference (billion cells)',
            obj,
            'dryYeast.difference',
            null,
            true
          )}
        </LayoutGroup>

        <LayoutGroup caption={'Liquid Yeast'}>
          {renderNumberFieldEditor(
            'Package Cells Amount (billion cells / pkg)',
            obj,
            'liquidYeast.packageCellsAmount',
            this._onChange,
            false
          )}
          {renderDateFieldEditor(
            'Production Date',
            obj,
            'liquidYeast.productionDate',
            this._onChange,
            false
          )}
          {renderMeasurableFieldEditor(
            'Viability',
            obj,
            'liquidYeast.viability',
            false,
            this._onChange
          )}
          {renderNumberFieldEditor(
            'Target Package Amount',
            obj,
            'liquidYeast.targetPackageAmount',
            null,
            true
          )}
          {renderNumberFieldEditor(
            'Available Cells Amount (billion cells)',
            obj,
            'liquidYeast.availableCellsAmount',
            null,
            true
          )}
          {renderNumberFieldEditor(
            'Difference (billion cells)',
            obj,
            'liquidYeast.difference',
            null,
            true
          )}
        </LayoutGroup>

        <LayoutGroup caption={'Yeast Starter'}>
          {renderNumberFieldEditor(
            'Starting Cells Amount (billion cells)',
            obj,
            'yeastStarter.startingCellsAmount',
            this._onChange,
            false
          )}

          <LayoutGroup caption={'Step 1'} marginBottom={0}>
            {renderMeasurableFieldEditor(
              'Volume',
              obj,
              'yeastStarter.step1.volume',
              false,
              this._onChange
            )}
            {renderMeasurableFieldEditor(
              'Original Gravity',
              obj,
              'yeastStarter.step1.originalGravity',
              false,
              this._onChange
            )}
            {renderMeasurableFieldEditor(
              'Target DME',
              obj,
              'yeastStarter.step1.targetDME',
              true,
              null
            )}
            {renderPickerFieldEditor(
              'Aeration Type',
              obj,
              'yeastStarter.step1.aerationType',
              aerationTypes,
              this._onChange
            )}
            {renderNumberFieldEditor(
              'Available Cells Amount (billion cells)',
              obj,
              'yeastStarter.step1.availableCellsAmount',
              null,
              true
            )}
          </LayoutGroup>

          <LayoutGroup caption={'Step 2'} marginBottom={0}>
            {renderMeasurableFieldEditor(
              'Volume',
              obj,
              'yeastStarter.step2.volume',
              false,
              this._onChange
            )}
            {renderMeasurableFieldEditor(
              'Original Gravity',
              obj,
              'yeastStarter.step2.originalGravity',
              false,
              this._onChange
            )}
            {renderMeasurableFieldEditor(
              'Target DME',
              obj,
              'yeastStarter.step2.targetDME',
              true,
              null
            )}
            {renderPickerFieldEditor(
              'Aeration Type',
              obj,
              'yeastStarter.step2.aerationType',
              aerationTypes,
              this._onChange
            )}
            {renderNumberFieldEditor(
              'Available Cells Amount (billion cells)',
              obj,
              'yeastStarter.step2.availableCellsAmount',
              null,
              true
            )}
          </LayoutGroup>

          <LayoutGroup caption={'Step 3'} marginBottom={0}>
            {renderMeasurableFieldEditor(
              'Volume',
              obj,
              'yeastStarter.step3.volume',
              false,
              this._onChange
            )}
            {renderMeasurableFieldEditor(
              'Original Gravity',
              obj,
              'yeastStarter.step3.originalGravity',
              false,
              this._onChange
            )}
            {renderMeasurableFieldEditor(
              'Target DME',
              obj,
              'yeastStarter.step3.targetDME',
              true,
              null
            )}
            {renderPickerFieldEditor(
              'Aeration Type',
              obj,
              'yeastStarter.step3.aerationType',
              aerationTypes,
              this._onChange
            )}
            {renderNumberFieldEditor(
              'Available Cells Amount (billion cells)',
              obj,
              'yeastStarter.step3.availableCellsAmount',
              null,
              true
            )}
          </LayoutGroup>
        </LayoutGroup>
      </Fragment>
    )
  }
}
