import React from 'react'
import { isEmpty, set } from 'lodash'
import { hydrate } from 'react-dom'
import { createBrowserHistory } from 'history'
import {
  ResolveReduxProvider,
  createResolveStore,
  internal,
} from '@resolve-js/redux'
import { Router as BrowserRouter } from 'react-router-dom'
import { HelmetProvider } from 'react-helmet-async'
import getRedux from './getRedux'

import { Router } from './routes'
import { getCachedUnitSettings } from './localStorage/unitSettings'
import { isAdmin, isAnonymous } from '../domain/users'

const entryPoint = (clientContext) => {
  const { clientImports, rootPath } = clientContext

  const history = createBrowserHistory({ basename: rootPath })

  const initialState = internal.deserializeInitialState(
    [],
    window.__INITIAL_STATE__
  )
  set(initialState, 'user.unitSettings', getCachedUnitSettings())

  const store = createResolveStore(clientContext, {
    initialState: initialState,
    redux: getRedux(clientImports, history),
  })

  const appContainer = document.getElementsByClassName('app-container')[0]

  const { jwt, user } = initialState

  hydrate(
    <ResolveReduxProvider context={clientContext} store={store}>
      <HelmetProvider>
        <BrowserRouter history={history}>
          <Router
            isAuthorized={!isEmpty(jwt)}
            isAdmin={isAdmin(user)}
            isAnonymous={isAnonymous(user)}
          />
        </BrowserRouter>
      </HelmetProvider>
    </ResolveReduxProvider>,
    appContainer
  )
}

export default entryPoint
