import { Confirm } from '@brewcomputer/ui'
import React from 'react'
import PropTypes from 'prop-types'
import { deleteConfirmation } from '../texts'

export const DeleteConfirmation = ({
  visible,
  type,
  name,
  onConfirm,
  onReject
}) => {
  return (
    <Confirm
      visible={visible}
      title={deleteConfirmation.title[type]}
      message={deleteConfirmation.message[type]}
      confirmationMessage={`${
        deleteConfirmation.confirmationMessage[type]
      } "${name}"`}
      buttonTitle={'Delete'}
      onConfirm={onConfirm}
      onClose={onReject}
    />
  )
}

DeleteConfirmation.propTypes = {
  visible: PropTypes.bool,
  type: PropTypes.oneOf(['recipe', 'batch', 'equipment']),
  name: PropTypes.string,
  onConfirm: PropTypes.func,
  onReject: PropTypes.func
}
