import { includes, isEmpty, map, reduce } from 'lodash'
import { convertMeasurable, convertBatchVolume } from './convertMeasurable'

const convertSteps = (steps, volumeUnit, temperatureUnit) =>
  map(steps, step =>
    reduce(
      step,
      (res, measurable, fieldName) => {
        if (includes(fieldName, 'temperature')) {
          res[fieldName] = convertMeasurable(measurable, temperatureUnit)
        } else if (fieldName === 'amount') {
          res[fieldName] = convertMeasurable(
            measurable,
            volumeUnit,
            null,
            false
          )
        } else if (includes(fieldName, 'time')) {
          res[fieldName] = convertMeasurable(measurable)
        } else {
          res[fieldName] = measurable
        }
        return res
      },
      {}
    )
  )

export const convertMash = (mash, { temperature, volume }) => {
  if (isEmpty(mash)) {
    return mash
  }
  return {
    ...mash,
    grain_temperature: convertMeasurable(mash.grain_temperature, temperature),
    mash_steps: convertSteps(mash.mash_steps, volume, temperature)
  }
}

export const convertBoil = (boil, { temperature, volume, time }) => {
  if (isEmpty(boil)) {
    return boil
  }
  return {
    ...boil,
    pre_boil_size: convertBatchVolume(boil.pre_boil_size, volume),
    boil_time: convertMeasurable(boil.boil_time, time),
    boil_steps: convertSteps(boil.boil_steps, volume, temperature)
  }
}

export const convertFermentation = (fermentation, { temperature, volume }) => {
  if (isEmpty(fermentation)) {
    return fermentation
  }
  return {
    ...fermentation,
    fermentation_steps: convertSteps(
      fermentation.fermentation_steps,
      volume,
      temperature
    )
  }
}
