import React, { useCallback } from 'react'
import { map, concat } from 'lodash'
import { useSelector } from 'react-redux'
import { Lookup } from '@brewcomputer/ui'
import { noEquipment } from '../../texts/index'

const selector = (state) => {
  return {
    items: map(state.equipment, (profile, id) => ({
      label: profile.name,
      value: id,
    })),
    equipment: state.equipment,
  }
}

export const EquipmentLookup = ({ onSelect, onCreate }) => {
  const { items, equipment } = useSelector(selector)

  const fixedItem = {
    label: noEquipment.link,
    value: 'create_new_equipment_profile',
    isFixed: true,
  }

  const selectEquipment = useCallback(
    (equipmentId) => {
      if (equipmentId === fixedItem.value) {
        onCreate()
      } else {
        onSelect(equipment[equipmentId])
      }
    },
    [equipment]
  )

  const allItems = concat([fixedItem], items)

  return (
    <Lookup
      text="Select Equipment"
      items={allItems}
      onChange={selectEquipment}
    />
  )
}

EquipmentLookup.displayName = 'EquipmentLookup'
