import React from 'react'

export const GoogleIcon = () => (
  <svg width="24" height="24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M4.005 12.01c-.07 3.338 2.276 6.523 5.504 7.562 3.04 1 6.779.252 8.802-2.299 1.477-1.787 1.812-4.17 1.652-6.396-2.603-.023-5.207-.016-7.807-.012-.004.909 0 1.814.004 2.723 1.559.042 3.118.023 4.677.054-.393 1.32-1.29 2.55-2.65 3.028-2.627 1.134-5.98-.348-6.806-3.048-.998-2.585.636-5.705 3.333-6.458 1.672-.576 3.395.073 4.826.925a36.551 36.551 0 002.07-2.093c-1.626-1.333-3.715-2.15-5.863-1.97-4.214.049-7.902 3.86-7.742 7.984z"
      fill="#fff"
    />
    <path
      d="M4.005 12.01c-.16-4.125 3.527-7.936 7.741-7.985 2.148-.18 4.238.637 5.863 1.97a36.67 36.67 0 01-2.07 2.093c-1.43-.852-3.153-1.501-4.826-.925-2.697.753-4.33 3.873-3.332 6.458.826 2.7 4.178 4.182 6.805 3.048 1.36-.477 2.258-1.707 2.651-3.028-1.559-.03-3.118-.012-4.677-.054-.004-.909-.008-1.814-.004-2.723 2.6-.004 5.203-.011 7.807.012.16 2.226-.175 4.61-1.653 6.396-2.023 2.551-5.76 3.3-8.801 2.3-3.227-1.04-5.574-4.224-5.504-7.562z"
      fill="#fff"
    />
  </svg>
)

export const EmailIcon = () => (
  <svg width="24" height="24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M4.003 7.884L12 11.882l7.997-3.998A2 2 0 0018 6H6a2 2 0 00-1.997 1.884z"
      fill="#fff"
    />
    <path
      d="M20 10.118l-8 4-8-4V16a2 2 0 002 2h12a2 2 0 002-2v-5.882z"
      fill="#fff"
    />
  </svg>
)

export const AnonymousIcon = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="24"
    height="24"
    fill="none"
    viewBox="0 0 24 24">
    <path
      fill="#fff"
      fillRule="evenodd"
      d="M12 5c-4.22 0-7.8 2.94-9 7 1.2 4.06 4.78 7 9 7s7.8-2.94 9-7c-1.2-4.06-4.78-7-9-7zm0 11a4 4 0 100-8 4 4 0 000 8z"
      clipRule="evenodd"
    />
    <circle cx="12" cy="12" r="2" fill="#fff" />
  </svg>
)
