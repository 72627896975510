import React from 'react'

const AlertIcon = ({ d, color, ...props }) => (
  <svg
    {...props}
    fill="none"
    width={24}
    height={24}
    viewBox="0 0 24 24"
    stroke={color}
    xmlns="http://www.w3.org/2000/svg">
    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d={d} />
  </svg>
)

export const InfoIcon = ({ color }) => (
  <AlertIcon
    color={color}
    d="M13 16h-1v-4h-1m1-4h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z"
  />
)

export const SuccessIcon = ({ color }) => (
  <AlertIcon color={color} d="M9 12l2 2 4-4m6 2a9 9 0 11-18 0 9 9 0 0118 0z" />
)

export const WarningIcon = ({ color }) => (
  <AlertIcon
    color={color}
    d="M12 9v2m0 4h.01m-6.938 4h13.856c1.54 0 2.502-1.667 1.732-3L13.732 4c-.77-1.333-2.694-1.333-3.464 0L3.34 16c-.77 1.333.192 3 1.732 3z"
  />
)

export const ErrorIcon = ({ color }) => (
  <AlertIcon
    color={color}
    d="M10 14l2-2m0 0l2-2m-2 2l-2-2m2 2l2 2m7-2a9 9 0 11-18 0 9 9 0 0118 0z"
  />
)
