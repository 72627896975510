import PropTypes from 'prop-types'

export const propTypes = {
  rangePosition: PropTypes.number,
  rangePercentage: PropTypes.number,
  markPosition: PropTypes.number,
  caption: PropTypes.string,
  value: PropTypes.string,
  unit: PropTypes.string,
  units: PropTypes.array,
  boundaries: PropTypes.string
}
