import React, { Fragment, Component } from 'react'
import { map, isEmpty, isNil } from 'lodash'
import PropTypes from 'prop-types'
import styled from 'styled-components'

const Separator = styled.div`
  flex-direction: column;
  align-self: stretch;
  &:last-child {
    display: none;
  }
`

const ListContainer = styled.div`
  flex-direction: column;
  align-items: stretch;
`

export class List extends Component {
  static propTypes = {
    data: PropTypes.array,
    renderItem: PropTypes.func,
    ListHeaderComponent: PropTypes.object,
    ListEmptyComponent: PropTypes.object,
    ItemSeparatorComponent: PropTypes.object
  }

  static defaultProps = {
    ListHeaderComponent: null,
    ListEmptyComponent: null,
    ItemSeparatorComponent: null
  }

  renderHeader = () => {
    const { ListHeaderComponent } = this.props
    if (isNil(ListHeaderComponent)) {
      return null
    }
    return ListHeaderComponent
  }

  renderSeparator = () => {
    const { ItemSeparatorComponent } = this.props
    if (isNil(ItemSeparatorComponent)) {
      return null
    }
    return <Separator>{ItemSeparatorComponent}</Separator>
  }

  renderEmptyList = () => {
    const { ListEmptyComponent } = this.props
    if (isNil(ListEmptyComponent)) {
      return null
    }
    return ListEmptyComponent
  }

  renderData = () => {
    const { data, renderItem } = this.props
    if (isEmpty(data)) {
      return this.renderEmptyList()
    }
    return map(data, (item, index) => {
      return (
        <Fragment key={index}>
          {renderItem(item, index)}
          {this.renderSeparator()}
        </Fragment>
      )
    })
  }

  render() {
    const { style } = this.props

    return (
      <ListContainer style={style}>
        {this.renderHeader()}
        {this.renderData()}
      </ListContainer>
    )
  }
}
