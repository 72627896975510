import {
  RECIPE_CREATED,
  RECIPE_BATCH_CHECK_LIST_CHECKED,
  RECIPE_INGREDIENT_CHECK_LIST_CHECKED,
  RECIPE_PROCEDURE_CHECK_LIST_CHECKED,
  RECIPE_BATCH_STATUS_CHANGED
} from '../events'

import produce from 'immer'
import { assign, update } from 'lodash'

export default {
  Init: () => ({
    checkList: {}
  }),

  [RECIPE_CREATED]: (state, { payload: recipe, aggregateId }) => {
    if (recipe.batch != null) {
      const {
        authorId,
        batch: { status }
      } = recipe
      return { authorId, status, id: aggregateId }
    }
    return state
  },

  [RECIPE_INGREDIENT_CHECK_LIST_CHECKED]: (
    state,
    { payload: { ingredientType, id, changes } }
  ) =>
    produce(state, draft => {
      update(draft, [ingredientType, id], ingredient =>
        assign(ingredient, changes)
      )
    }),

  [RECIPE_PROCEDURE_CHECK_LIST_CHECKED]: (
    state,
    { payload: { procedureType, steps } }
  ) =>
    produce(state, draft => {
      update(draft, procedureType, prevSteps => assign(prevSteps, steps))
    }),

  [RECIPE_BATCH_CHECK_LIST_CHECKED]: (state, { payload: changes }) =>
    produce(state, draft => {
      update(draft, 'checkList', checkList => assign(checkList, changes))
    }),

  [RECIPE_BATCH_STATUS_CHANGED]: (state, { payload: { status } }) =>
    produce(state, draft => {
      draft.status = status
    })
}
