import React from 'react'
import { colors } from '../colors/colors'

export const ShareIcon = ({ fill, shared, ...props }) =>
  shared ? (
    <svg
      {...props}
      width="18"
      height="18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <rect x="1" y="1" width="16" height="16" rx="4" fill="#109CF1" />
      <path
        d="M7.5 10.615l5.3-5.3L13.985 6.5 7.5 12.985 4.215 9.7 5.4 8.515l2.1 2.1z"
        fill="#fff"
      />
    </svg>
  ) : (
    <svg
      {...props}
      width="18"
      height="18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <g clipPath="url(#clip0)" fill="#109BF1">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M12 7a1 1 0 001 1h1a1 1 0 011 1v4a1 1 0 01-1 1H4a1 1 0 01-1-1V9a1 1 0 011-1h1a1 1 0 000-2H4a3 3 0 00-3 3v4a3 3 0 003 3h10a3 3 0 003-3V9a3 3 0 00-3-3h-1a1 1 0 00-1 1z"
        />
        <path d="M8.2 1.994h1.6V11.2a.8.8 0 01-1.6 0V1.994z" />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M9 0h-.002l-1.13 1.13v.003l-2.19 2.19A.8.8 0 106.81 4.454l1.484-1.483a1 1 0 011.414 0l1.484 1.483a.8.8 0 001.131-1.131l-2.19-2.19v-.001-.002L9.002 0h-.001-.002z"
        />
      </g>
      <defs>
        <clipPath id="clip0">
          <path fill="#fff" d="M0 0h18v18H0z" />
        </clipPath>
      </defs>
    </svg>
  )

ShareIcon.defaultProps = {
  fill: colors.ACCENT_BLUE
}
