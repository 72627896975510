import React from 'react'
import { colors } from '../colors/colors'

export const FeedbackIcon = ({ fill, ...props }) => (
  <svg
    {...props}
    width="24"
    height="24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg">
    <path
      fill={fill}
      fillRule="evenodd"
      d="M18 6H6v9h12V6zM6 4a2 2 0 00-2 2v9a2 2 0 002 2h9.883l2.349 2.659c.599.678 1.768.278 1.768-.604V6a2 2 0 00-2-2H6zm2 5a1 1 0 011-1h6a1 1 0 110 2H9a1 1 0 01-1-1zm0 3a1 1 0 011-1h4a1 1 0 110 2H9a1 1 0 01-1-1z"
      clipRule="evenodd"
    />
  </svg>
)

FeedbackIcon.defaultProps = {
  fill: colors.GRAY
}
