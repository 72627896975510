import { isEmpty, reduce } from 'lodash'
import { convertIngredients } from './convertIngredients'
import {
  convertMash,
  convertBoil,
  convertFermentation,
} from './convertProcedures'
import { convertBatchVolume } from './convertMeasurable'
import { convertEquipment } from './convertEquipment'
import { convertStats } from './convertStats'
import { convertBatch } from './convertBatch'

export const convertVolumes = (volumes, unitSettings) => {
  if (isEmpty(volumes)) {
    return volumes
  }
  return reduce(
    volumes,
    (res, value, key) => {
      res[key] = convertBatchVolume(value, unitSettings.volume)
      return res
    },
    {}
  )
}

export const convertRecipe = (recipe, unitSettings) => {
  const result = {
    ...recipe,
    ...convertStats(recipe, unitSettings),
    volumes: convertVolumes(recipe.volumes, unitSettings),
  }

  if (recipe.batch_size) {
    result.batch_size = convertBatchVolume(
      recipe.batch_size,
      unitSettings.volume
    )
  }
  if (recipe.ingredients) {
    result.ingredients = convertIngredients(recipe, unitSettings)
  }
  if (recipe.mash) {
    result.mash = convertMash(recipe.mash, unitSettings)
  }
  if (recipe.boil) {
    result.boil = convertBoil(recipe.boil, unitSettings)
  }
  if (recipe.fermentation) {
    result.fermentation = convertFermentation(recipe.fermentation, unitSettings)
  }
  if (recipe.equipment) {
    result.equipment = convertEquipment(recipe.equipment, unitSettings)
  }
  if (recipe.batch) {
    result.batch = convertBatch(recipe.batch, unitSettings)
  }

  return result
}
