import React, { PureComponent } from 'react'
import { inputDefaultProps, inputPropTypes } from './propTypes'
import { inputStyle } from './style'
import styled, { css } from 'styled-components'

const inputReset = css`
  border-radius: 0;
  background-color: transparent;
  -moz-appearance: textfield;
  &::-webkit-inner-spin-button,
  &::-webkit-outer-spin-button {
    -webkit-appearance: none;
  }
  &:disabled {
    background: transparent;
  }
`
const textAreaReset = css`
  resize: none;
`
const transition = css`
  transition-duration: 200ms;
`

const StyledTextArea = styled.textarea`
  ${inputStyle};
  ${textAreaReset};
  ${transition};
`

const StyledInput = styled.input`
  ${inputStyle};
  ${inputReset};
  ${transition};
  @media (max-width: 576px) {
    font-size: 16px;
  }
`

export class Input extends PureComponent {
  static propTypes = inputPropTypes
  static defaultProps = inputDefaultProps

  constructor(props) {
    super(props)
    this._ref = React.createRef()
  }

  onChange = e => {
    this.props.onChange(e.target.value)
  }

  onKeyPress = e => {
    if (e.charCode === 13 && !e.shiftKey) {
      e.preventDefault()
      this.props.onSubmit(e.target.value)
    }
  }

  disableScroll = e => {
    e.preventDefault()
  }

  onFocus = () => {
    const { type, selectOnFocus, onFocus } = this.props
    if (type === 'number') {
      this._ref.current.addEventListener('wheel', this.disableScroll)
    }
    if (selectOnFocus) {
      requestAnimationFrame(() => this._ref.current.select())
    }
    if (onFocus) {
      onFocus()
    }
  }

  onBlur = () => {
    const { type, onBlur } = this.props
    if (type === 'number') {
      this._ref.current.removeEventListener('wheel', this.disableScroll)
    }
    if (onBlur) {
      onBlur()
    }
  }

  focus = () => {
    this._ref.current.focus()
  }

  render() {
    const {
      type,
      name,
      placeholder,
      focused,
      disabled,
      autoFocus,
      multiline,
      value,
      fontSize,
      textAlign,
      className,
      inputProps
    } = this.props

    const Component = multiline ? StyledTextArea : StyledInput

    return (
      <Component
        ref={this._ref}
        className={className}
        name={name}
        focused={focused}
        disabled={disabled}
        value={value}
        font={fontSize}
        align={textAlign}
        type={type}
        autoFocus={autoFocus}
        placeholder={placeholder}
        onChange={this.onChange}
        onKeyPress={this.onKeyPress}
        onFocus={this.onFocus}
        onBlur={this.onBlur}
        {...inputProps}
      />
    )
  }
}
