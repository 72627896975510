import { map, isEmpty, pick } from 'lodash'
import React, { PureComponent } from 'react'
import shortId from 'shortid'
import { CommentsTreeRenderless } from '@resolve-js/module-comments'
import { SectionTitle, Message, Chat, ClearButton } from '@brewcomputer/ui'
import { feedback } from '../../texts'
import styled from 'styled-components'
import moment from 'moment'

const Container = styled.div`
  margin-top: 3px;
  height: 100%;
  overflow: auto;
`

const Title = styled(SectionTitle)`
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  margin: 24px;
`

const getRelativeDate = date => moment(date).fromNow()

export class Feedback extends PureComponent {
  onSendMessage = createComment => message => {
    const { author, treeId } = this.props
    createComment(treeId, {
      commentId: shortId.generate(),
      authorId: author.id,
      content: {
        text: message,
        author: pick(author, ['id', 'avatar', 'name']),
        createdAt: Date.now()
      }
    })
  }

  onReadMessage = (updateFeedback, content) => id => {
    const { author, treeId } = this.props
    updateFeedback(treeId, {
      commentId: id,
      authorId: author.id,
      content: {
        ...content,
        read: true
      }
    })
  }

  renderComments = ({ comments, createFeedback, updateFeedback }) => {
    const { author, showInput, hideTitle, onClose } = this.props
    return (
      <Container>
        {!hideTitle && (
          <Title>
            Feedback
            <ClearButton onPress={onClose} />
          </Title>
        )}
        <Chat
          emptyChatMessage={feedback.empty}
          showInput={showInput}
          onSendMessage={this.onSendMessage(createFeedback)}>
          {!isEmpty(comments) &&
            map(comments.children, (comment, i, col) => {
              if (comment.content.author == null) {
                return null
              }
              return (
                <Message
                  key={comment.commentId}
                  id={comment.commentId}
                  isOwn={comment.content.author.id === author.id}
                  last={i === col.length - 1}
                  author={comment.content.author.name}
                  read={comment.content.read}
                  date={getRelativeDate(comment.content.createdAt)}
                  message={comment.content.text}
                  onRead={this.onReadMessage(updateFeedback, comment.content)}
                />
              )
            })}
        </Chat>
      </Container>
    )
  }

  render() {
    const { treeId, showInput } = this.props
    return (
      <CommentsTreeRenderless
        treeId={treeId}
        showInput={showInput}
        readModelName="Feedbacks"
        reducerName="feedbacks"
        aggregateName="Feedback"
        createComment="createFeedback"
        updateComment="updateFeedback"
        removeComment="removeFeedback">
        {this.renderComments}
      </CommentsTreeRenderless>
    )
  }
}
