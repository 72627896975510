import { css } from 'styled-components'
import { colors } from '../colors/colors'
import { textStyles } from '../texts/textStyles'

const getInputFontStyle = fontSize => {
  switch (fontSize) {
    case 'huge':
      return textStyles.H1
    case 'large':
      return textStyles.H2
    case 'small':
      return textStyles.smallText
    default:
      return textStyles.body2
  }
}

//https://stackoverflow.com/questions/262158/disabled-input-text-color
const safariDisabledInputStyle = css`
  -webkit-text-fill-color: ${colors.INPUT_DISABLED};
  opacity: 1; /* required on iOS */
`

export const inputStyle = css`
  width: 100%;
  ${props => getInputFontStyle(props.font)};
  padding-bottom: 3px;
  color: ${props =>
    props.disabled ? colors.INPUT_DISABLED : colors.TABLE_BLACK};
  text-align: ${props => props.align};
  ${props => props.disabled && safariDisabledInputStyle};
`
