import React from 'react'
import PropTypes from 'prop-types'
import { Checkbox } from '../checkbox/Checkbox'
import { List } from '../list/List'

export const CheckList = ({ list, onCheckItem }) => {
  return (
    <List
      data={list}
      renderItem={(item, index) => (
        <Checkbox
          checked={item.checked}
          label={item.label}
          onChange={({ checked }) =>
            onCheckItem({ checked, key: item.key, index })
          }
        />
      )}
    />
  )
}

CheckList.propTypes = {
  list: PropTypes.arrayOf(
    PropTypes.shape({
      checked: PropTypes.bool,
      label: PropTypes.string,
      key: PropTypes.string
    })
  ),
  onCheckItem: PropTypes.func
}
