import { css } from 'styled-components'
import { colors } from '../colors/colors'
import { textStyles } from '../texts/textStyles'

export const deleteButtonStyle = css`
  align-items: center;
  justify-content: center;
  width: 30px;
  height: 30px;
  background-color: transparent;
`

const getBgColor = ({ disabled, negative }) => {
  if (disabled) {
    return colors.BUTTON_DISABLED
  }
  if (negative) {
    return colors.BUTTON_RED
  }
  return colors.ACCENT_BLUE
}

const smallButton = css`
  padding: 4px 12px;
  background-color: ${props =>
    props.disabled ? colors.BUTTON_DISABLED : colors.ACCENT_GRAY};
`

const normalButton = css`
  padding: 0 32px;
  height: 42px;
  background-color: ${getBgColor};
`

const withArrow = css`
  flex-direction: row;
  justify-content: space-between;
`

export const arrowContainerStyle = css`
  margin-left: 16px;
`

export const accentButtonStyle = css`
  align-items: center;
  justify-content: center;
  border-radius: 4px;
  ${props => (props.size === 'small' ? smallButton : normalButton)};
  ${props => (props.showArrow ? withArrow : null)};
`

export const accentButtonTitleStyle = css`
  ${props =>
    props.size === 'small' ? textStyles.smallText : textStyles.body2};
  font-weight: 500;
  text-align: center;
  color: #ffffff;
`
