import PropTypes from 'prop-types'
import { get, isEmpty } from 'lodash'
import React, { useEffect, Fragment, useContext, useState } from 'react'
import { useSelector, shallowEqual } from 'react-redux'
import {
  useBatchCreate,
  useCloneRecipe,
  useEditRecipe,
  useScaleRecipe,
  useRecipeAddImage,
  useRecipeRemoveImage,
} from '../hooks/commands'
import { useCountBatches } from '../hooks/queries'
import { useRecipesViewModel } from '../hooks/viewModel'
import { getRecipe, getStats } from '../selectors/recipe'
import { isAnonymous } from '../../domain/users'
import { Alert } from '@brewcomputer/ui'
import PageTitle from '../components/TitleConstructor'
import { NeedLogin } from '../components/specialPages/NeedLogin'
import { RecipeNotFound } from '../components/specialPages/RecipeNotFound'
import { LoadingIndicator } from '../components/LoadingIndicator'
import { RecipeLayout } from '../components/recipeView/RecipeLayout'
import { TopControls } from '../components/recipeView/Layouts'
import { RecipeNameEditor } from '../components/recipeView/RecipeNameEditor'
import { RecipeButtons } from '../components/recipeView/RecipeButtons'
import { RecipePickers } from '../components/recipeView/RecipePickers'
import { RecipeJsonLd } from '../components/recipeView/RecipeJsonLd'
import { RecipeImageUploader } from '../components/recipeView/RecipeImageUploader'
import { BatchInfo } from '../components/recipeView/BatchInfo'
import { RecipeData } from '../components/recipeView/RecipeData'
import { Comments } from '../components/comments/Comments'
import { needLogin, publicRecipe } from '../texts'
import { RecipeImage } from '../components/recipeView/RecipeImage'
import { ResolveContext } from '@resolve-js/react-hooks'
import { getCDNBasedUrl, getToken } from '@resolve-js/module-uploader'

export const getRecipeId = (props) => get(props, 'match.params.recipeId')

const DIRECTORY = 'images'

const RecipeCalculator = (props) => {
  const recipeId = getRecipeId(props)
  const recipe = useSelector(getRecipe(recipeId), shallowEqual)
  const stats = useSelector(getStats(recipeId), shallowEqual)
  const user = useSelector((state) => state.user)
  const batchesCount = useSelector((state) => get(state, 'batches.count'))

  const { cdnUrl: CDNUrl } = useContext(ResolveContext)
  const [token, setToken] = useState(null)

  const {
    authorId,
    isCreated,
    batch_size,
    efficiency,
    color_css,
    ingredients,
    images = [],
  } = recipe

  const isLoading = isEmpty(recipe) || recipe.isLoading
  const isError = recipe.isError || user.isError
  const isOwnRecipe = authorId === user.id

  const { connect, dispose } = useRecipesViewModel(recipeId)
  const { request: countBatches } = useCountBatches()

  const editRecipe = useEditRecipe(recipeId)
  const scaleRecipe = useScaleRecipe(recipeId)

  const cloneRecipe = useCloneRecipe(recipe, user)
  const brewRecipe = useBatchCreate(recipe, user, batchesCount, isOwnRecipe)

  const onImageUpload = useRecipeAddImage(recipeId)
  const onImageRemove = useRecipeRemoveImage(recipeId)

  const isUserAuthorized = !props.unauthorized
  const isUserRegistered = isUserAuthorized && !isAnonymous(user)
  const isBatch = !isEmpty(recipe.batch) || recipe.hasBatch

  useEffect(() => {
    if (isUserRegistered) {
      countBatches()
    }
  }, [])

  useEffect(() => {
    if (!isCreated) {
      connect()
      return () => {
        dispose()
      }
    }
  }, [recipeId, isCreated])

  useEffect(() => {
    getToken({ dir: DIRECTORY }).then((staticToken) => {
      setToken(staticToken)
    })
  }, [])

  if (isLoading) {
    return <LoadingIndicator />
  }

  if (isError) {
    return <RecipeNotFound />
  }

  const isViewModelLoading = isEmpty(ingredients)

  const showLoginButtons =
    !isUserRegistered || (isAnonymous(user) && isOwnRecipe)

  const recipeImage = images[0]

  let recipeImageUrl = null
  if (recipeImage != null && token != null) {
    recipeImageUrl = getCDNBasedUrl({
      CDNUrl,
      dir: DIRECTORY,
      uploadId: recipeImage,
      token,
    })
  }

  const showAlert = isUserRegistered && authorId != null && !isOwnRecipe

  return (
    <Fragment>
      {showLoginButtons && <NeedLogin title={needLogin.recipe} inset />}
      <RecipeJsonLd recipe={recipe} image={recipeImageUrl} />
      {showAlert && (
        <Alert type="info" title="" message={publicRecipe.infoMessage} />
      )}
      <PageTitle pageTitle={recipe.name} />
      <RecipeNameEditor recipeId={recipeId} name={recipe.name} />
      <TopControls
        Left={<RecipePickers recipeId={recipeId} />}
        Right={
          <RecipeButtons
            recipeId={recipeId}
            allowSharing={isOwnRecipe && !isBatch}
            allowCloning={isUserAuthorized && !isOwnRecipe}
            allowBrewing={isUserRegistered && !isBatch}
            isLoading={isViewModelLoading}
            onBrewRecipe={brewRecipe}
            onCloneRecipe={cloneRecipe}
          />
        }
      />
      <RecipeLayout stats={stats} color={color_css}>
        <BatchInfo
          recipeId={recipeId}
          batchSize={batch_size}
          efficiency={efficiency}
          onScaleRecipe={scaleRecipe}
          onEditRecipe={editRecipe}
        />
        <RecipeData
          user={user}
          recipeId={recipeId}
          isUserRegistered={isUserRegistered}
          isLoading={isViewModelLoading}
        />
        {recipeImage != null && (
          <RecipeImage
            imageId={recipeImage}
            src={recipeImageUrl}
            alt={recipe.name}
            allowRemove={isOwnRecipe}
            onRemove={onImageRemove}
          />
        )}
        {isOwnRecipe && isUserRegistered && recipeImage == null && (
          <RecipeImageUploader onUploadSuccess={onImageUpload} />
        )}
        {!isViewModelLoading && (
          <Comments
            author={user}
            treeId={recipeId}
            showEditor={isUserRegistered}
          />
        )}
      </RecipeLayout>
    </Fragment>
  )
}

RecipeCalculator.propTypes = {
  match: PropTypes.object,
  unauthorized: PropTypes.bool,
}

export default RecipeCalculator
