import PropTypes from 'prop-types'
import React, { useCallback } from 'react'
import { isEmpty } from 'lodash'
import { useSelector } from 'react-redux'
import {
  useAddIngredient,
  useEditIngredient,
  useRemoveIngredient,
} from '../../hooks/commands'
import { IngredientsTable } from './IngredientsTable'
import { RecipeDataSection } from '../recipeView/RecipeDataSection'
import { IngredientLookup } from '../lookups/IngredientLookup'
import { getIngredients } from '../../selectors/recipe'

const sectionTitles = {
  fermentable_additions: 'Fermentables',
  culture_additions: 'Yeast culture',
  hop_additions: 'Hops',
  miscellaneous_additions: 'Miscellaneous',
  water_additions: 'Water Profiles',
}

const lookupTitles = {
  fermentable_additions: 'Fermentable',
  culture_additions: 'Yeast',
  hop_additions: 'Hop',
  miscellaneous_additions: 'Misc',
  water_additions: 'Water Profile',
}

export const Ingredients = ({ recipeId, ingredientType, replace = false }) => {
  const ingredients = useSelector(getIngredients(recipeId, ingredientType))

  const addIngredient = useAddIngredient(recipeId, ingredientType)
  const editIngredient = useEditIngredient(recipeId, ingredientType)
  const removeIngredient = useRemoveIngredient(recipeId, ingredientType)

  const ingredientName = lookupTitles[ingredientType]
  const needReplace = replace && !isEmpty(ingredients)

  const lookupTitle = needReplace
    ? `Change ${ingredientName}`
    : `+ ${ingredientName}`

  const replaceIngredient = useCallback(
    (changes) => {
      if (!isEmpty(ingredients)) {
        editIngredient({ id: ingredients[0].id, changes, ingredientType })
      }
    },
    [ingredientType, ingredients]
  )

  const onSelect = needReplace ? replaceIngredient : addIngredient

  return (
    <RecipeDataSection
      caption={sectionTitles[ingredientType]}
      button={
        <IngredientLookup
          title={lookupTitle}
          ingredientName={lookupTitles[ingredientType]}
          ingredientType={ingredientType}
          onSelect={onSelect}
        />
      }>
      <IngredientsTable
        ingredientType={ingredientType}
        ingredients={ingredients}
        editIngredient={editIngredient}
        removeIngredient={removeIngredient}
      />
    </RecipeDataSection>
  )
}

Ingredients.propTypes = {
  recipeId: PropTypes.string.isRequired,
  ingredientType: PropTypes.oneOf([
    'fermentable_additions',
    'hop_additions',
    'culture_additions',
    'miscellaneous_additions',
    'water_additions',
  ]).isRequired,
}

Ingredients.displayName = 'Ingredients'
