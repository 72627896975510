import produce from 'immer'
import { update, get } from 'lodash'
import {
  BATCHES_VIEW_MODEL_LOAD,
  BATCHES_EVENT_RECEIVED,
  BATCH_CHECK_LIST_EDITED_OPTIMISTIC
} from '../actions/batch'
import viewModel from '../../../domain/view-models/batch.projection'

export const initialState = {}

const reducer = produce((draft, action) => {
  switch (action.type) {
    case BATCHES_VIEW_MODEL_LOAD: {
      const { batchId, result, initial } = action.payload
      if (initial) {
        draft[batchId] = result
        return draft
      }
      const loaded = get(draft, [batchId, 'loaded'], false)
      if (!loaded) {
        draft[batchId] = { ...result, loaded: true }
      }
      return draft
    }

    case BATCHES_EVENT_RECEIVED: {
      const { aggregateId, type, payload } = action.payload
      update(draft, aggregateId, batch => viewModel[type](batch, { payload }))
      return draft
    }

    case BATCH_CHECK_LIST_EDITED_OPTIMISTIC: {
      const { recipeId, state } = action.payload
      draft[recipeId] = state
      return draft
    }
  }
}, initialState)

export default reducer
