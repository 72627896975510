import React from 'react'
import figmaColors from '../colors/figmaColors'
import { TrashIcon } from '../icons/TrashIcon'
import styled from 'styled-components'
import PropTypes from 'prop-types'

const StyledButton = styled.button`
  background-color: transparent;
  & svg path {
    transition-duration: 200ms;
  }
  :hover svg path,
  :active svg path,
  :focus svg path {
    fill: ${figmaColors.red_500};
  }
`

export const DeleteButton = ({
  accessibilityLabel,
  onPress,
  ...buttonProps
}) => {
  return (
    <StyledButton
      aria-label={accessibilityLabel}
      title={accessibilityLabel}
      onClick={onPress}
      {...buttonProps}>
      <TrashIcon fill={figmaColors.blue_gray_300} />
    </StyledButton>
  )
}

DeleteButton.propTypes = {
  accessibilityLabel: PropTypes.string,
  onPress: PropTypes.func
}
