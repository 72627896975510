export const defaultEquipment = {
  name: 'Stainless Kegs',
  equipment_items: {
    hlt: {
      name: 'HLT',
      form: 'HLT',
      maximum_volume: {
        value: 56.78,
        unit: 'l'
      },
      loss: {
        value: 3.79,
        unit: 'l'
      }
    },
    mash_tun: {
      name: 'Mash Tun',
      form: 'Mash Tun',
      maximum_volume: {
        value: 56.78,
        unit: 'l'
      },
      // TODO: grain absorption
      loss: {
        value: 0,
        unit: 'l'
      }
    },
    brew_kettle: {
      name: 'Brew Kettle',
      form: 'Brew Kettle',
      maximum_volume: {
        value: 56.78,
        unit: 'l'
      },
      boil_rate_per_hour: {
        value: 7.57,
        unit: 'l'
      },
      loss: {
        value: 7.57,
        unit: 'l'
      }
    },
    fermenter: {
      name: 'Fermenter',
      form: 'Fermenter',
      maximum_volume: {
        value: 56.78,
        unit: 'l'
      },
      loss: {
        value: 3.79,
        unit: 'l'
      }
    }
  }
}
