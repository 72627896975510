import styled from 'styled-components'
import React, { useEffect } from 'react'
import { useDropzone } from 'react-dropzone'
import { textStyles } from '../texts/textStyles'
import figmaColors from '../colors/figmaColors'

const getBorderColor = (props) => {
  if (props.rejected) {
    return figmaColors.red_500
  }
  if (props.accepted) {
    return figmaColors.green_500
  }
  return figmaColors.gray_300
}

const StyledDropzone = styled.div`
  ${textStyles.body2};
  border: 2px dashed ${getBorderColor};
  border-radius: 4px;
  div {
    padding: 12px;
    cursor: pointer;
    transition-duration: 200ms;
    :hover,
    :focus {
      background-color: ${figmaColors.gray_200};
    }
  }
  p {
    text-align: center;
  }
`

export const Dropzone = ({ message, onFileChange }) => {
  const {
    acceptedFiles,
    getRootProps,
    getInputProps,
    isDragReject,
    isDragAccept,
  } = useDropzone({
    maxFiles: 1,
    maxSize: 20000000,
    accept: 'image/jpeg, image/png',
  })

  const file = acceptedFiles?.[0]

  useEffect(() => {
    if (file) {
      onFileChange(file)
    }
    return () => onFileChange(null)
  }, [file, onFileChange])

  return (
    <StyledDropzone rejected={isDragReject} accepted={isDragAccept}>
      <div {...getRootProps()}>
        <input {...getInputProps()} />
        <p>{message}</p>
      </div>
    </StyledDropzone>
  )
}
