import styled from 'styled-components'
import { AccentButton } from '../buttons/AccentButton'
import { Form } from '../form/Form'

export const Container = styled.div``

export const Controls = styled.div`
  padding: 24px 24px 0 24px;
`

export const Buttons = styled.div`
  margin-top: 4px;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
`

export const UnitsButton = styled(AccentButton)`
  margin-right: 8px;
  &:last-child {
    margin-right: 0;
  }
`
UnitsButton.displayName = 'UnitsButton'

export const StyledForm = styled(Form)`
  padding-top: 24px;
  padding-left: 24px;
  padding-bottom: 8px;
`
