import React, { Fragment, PureComponent } from 'react'
import { convert, round } from '@brewcomputer/utils'
import {
  LayoutGroup,
  renderMeasurableFieldEditor,
  ToolTitle
} from './ToolsUtils'

export class AlcoholTool extends PureComponent {
  constructor(props) {
    super(props)
    const obj = {
      originalGravity: { value: 1.05, unit: 'sg' },
      finalGravity: { value: 1.01, unit: 'sg' },
      alcoholByVolume: { value: 0, unit: '%' },
      alcoholByWeight: { value: 0, unit: '%' }
    }
    this._calculate(obj)
    this.state = { obj: obj }
  }

  _calculate = obj => {
    const originalGravity = convert(
      obj.originalGravity.value,
      obj.originalGravity.unit,
      'sg',
      10
    )
    const finalGravity = convert(
      obj.finalGravity.value,
      obj.finalGravity.unit,
      'sg',
      10
    )
    const alcoholByVolume = (originalGravity - finalGravity) * 131.25
    const alcoholByWeight = (originalGravity - finalGravity) * 104.1285
    obj.alcoholByVolume.value = round(alcoholByVolume, 2)
    obj.alcoholByWeight.value = round(alcoholByWeight, 2)
  }

  _onChange = obj => {
    const newObj = Object.assign({}, obj)
    this._calculate(newObj)
    this.setState({ obj: newObj })
  }

  render() {
    return (
      <Fragment>
        <ToolTitle text={'Alcohol'} />

        <LayoutGroup caption={'Gravity'}>
          {renderMeasurableFieldEditor(
            'Original Gravity',
            this.state.obj,
            'originalGravity',
            false,
            this._onChange
          )}
          {renderMeasurableFieldEditor(
            'Final Gravity',
            this.state.obj,
            'finalGravity',
            false,
            this._onChange
          )}
        </LayoutGroup>
        <LayoutGroup caption={'Alcohol'}>
          {renderMeasurableFieldEditor(
            'Alcohol by Volume',
            this.state.obj,
            'alcoholByVolume',
            true,
            null
          )}
          {renderMeasurableFieldEditor(
            'Alcohol by Weight',
            this.state.obj,
            'alcoholByWeight',
            true,
            null
          )}
        </LayoutGroup>
      </Fragment>
    )
  }
}
