import React, { PureComponent } from 'react'
import PropTypes from 'prop-types'
import { BatchListItem, BatchesListHeader } from '@brewcomputer/ui'
import { NoBatches } from '../specialPages/EmptyList'
import { List } from './List'

export class BatchesListView extends PureComponent {
  renderItem = ({ batch, id, ...recipe }) => (
    <BatchListItem
      number={batch.number}
      name={batch.name}
      color={recipe.color_css}
      beerStyle={recipe.style.name}
      type={recipe.type}
      status={batch.status}
      date={new Date(batch.date).toDateString()}
    />
  )

  render() {
    const { batches, onRemoveBatch } = this.props
    return (
      <List
        data={batches}
        type="batch"
        renderItem={this.renderItem}
        ListEmpty={<NoBatches />}
        ListHeader={<BatchesListHeader />}
        onRemoveItem={onRemoveBatch}
      />
    )
  }
}

BatchesListView.propTypes = {
  batches: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
      status: PropTypes.string,
      info: PropTypes.shape({
        name: PropTypes.string
      }),
      recipe: PropTypes.shape({
        style: PropTypes.shape({
          name: PropTypes.string
        }),
        type: PropTypes.string
      })
    })
  ),
  onRemoveBatch: PropTypes.func
}
