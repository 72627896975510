import React, { PureComponent } from 'react'
import Header from '../../containers/Header'
import { Link } from 'react-router-dom'
import { links } from '../../links'
import { Logo, colors } from '@brewcomputer/ui'
import styled from 'styled-components'
import { SocialLinks } from './SocialLinks'

const Page = styled.main`
  flex-grow: 1;
  flex-shrink: 0;
  position: relative;
  padding-top: 60px;
  background-color: ${colors.BACKGROUND};

  @media (max-width: 576px) {
    padding-left: 16px;
    padding-right: 16px;
  }

  @media (min-width: 577px) and (max-width: 992px) {
    padding-left: 32px;
    padding-right: 32px;
  }

  @media (min-width: 992px) {
    padding-left: 40px;
    padding-right: 40px;
  }
`

const Bar = styled.nav`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: 100;
  height: 60px;
  padding: 0 40px;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  background-color: ${colors.WHITE};
`

export class Unauthorized extends PureComponent {
  render() {
    const { children } = this.props
    return (
      <Page className="page">
        <Header />
        <Bar id="top-nav-bar">
          <Link to={links.index}>
            <Logo />
          </Link>
          <SocialLinks />
        </Bar>
        {children}
      </Page>
    )
  }
}
