import React from 'react'
import styled from 'styled-components'
import figmaColors from '../colors/figmaColors'

const Svg = styled.svg`
  & .clip-path {
    fill: ${figmaColors.gray_100};
  }
  & .main-path {
    transition-duration: 200ms;
    fill: ${figmaColors.blue_gray_300};
  }
`

export const FacebookIcon = () => (
  <Svg
    xmlns="http://www.w3.org/2000/svg"
    width="18"
    height="18"
    fill="none"
    viewBox="0 0 18 18">
    <g clipPath="url(#clip0)">
      <path
        className="main-path"
        d="M13.5 0h-2.34C8.54 0 6.85 1.74 6.85 4.43v2.04H4.5c-.2 0-.37.17-.37.37V9.8c0 .2.17.37.37.37h2.35v7.46c0 .2.16.37.36.37h3.07c.2 0 .36-.16.36-.37v-7.46h2.75c.2 0 .37-.17.37-.37V6.84a.37.37 0 00-.37-.37h-2.75V4.74c0-.83.2-1.25 1.29-1.25h1.57c.2 0 .37-.17.37-.37V.37c0-.2-.17-.37-.37-.37z"
      />
    </g>
    <defs>
      <clipPath id="clip0">
        <path className="clip-path" d="M0 0h18v18H0z" />
      </clipPath>
    </defs>
  </Svg>
)

export const InstagramIcon = () => (
  <Svg
    xmlns="http://www.w3.org/2000/svg"
    width="18"
    height="18"
    fill="none"
    viewBox="0 0 18 18">
    <path
      className="main-path"
      fillRule="evenodd"
      d="M5.29.05C6.25.01 6.56 0 9 0s2.75.01 3.71.05c.96.05 1.61.2 2.19.42.59.23 1.09.54 1.59 1.04s.8 1 1.04 1.6c.22.57.37 1.22.42 2.18.04.96.05 1.27.05 3.71s-.01 2.75-.05 3.71a6.6 6.6 0 01-.42 2.19c-.23.59-.54 1.09-1.04 1.59s-1 .8-1.6 1.04a6.6 6.6 0 01-2.18.42c-.96.04-1.27.05-3.71.05s-2.75-.01-3.71-.05a6.6 6.6 0 01-2.19-.42c-.59-.23-1.09-.54-1.59-1.04s-.8-1-1.04-1.6a6.6 6.6 0 01-.42-2.18C.01 11.75 0 11.44 0 9s.01-2.75.05-3.71c.05-.96.2-1.61.42-2.19.23-.59.54-1.09 1.04-1.59s1-.8 1.6-1.04A6.6 6.6 0 015.28.05zm7.31 3.06a1.2 1.2 0 112.42 0 1.2 1.2 0 01-2.42 0zM14 9A5 5 0 114 9a5 5 0 0110 0zm-5 3a3 3 0 100-6 3 3 0 000 6z"
      clipRule="evenodd"
    />
  </Svg>
)

export const TwitterIcon = () => (
  <Svg
    xmlns="http://www.w3.org/2000/svg"
    width="18"
    height="18"
    fill="none"
    viewBox="0 0 18 18">
    <g clipPath="url(#clip0)">
      <path
        className="main-path"
        d="M6.72 16.38A11.2 11.2 0 011.07 15L0 14.43l1.15-.38c1.27-.41 2.03-.67 2.99-1.07A3.96 3.96 0 012.1 10.6l-.27-.82.22.04A4 4 0 01.9 6.95l.05-.71.43.16a3.98 3.98 0 01.05-3.85l.37-.68.5.6A9.14 9.14 0 008.22 5.8c-.1-.67-.03-1.31.21-1.89.28-.66.78-1.23 1.44-1.64a4.3 4.3 0 012.5-.61c.92.06 1.77.4 2.44 1.01.33-.08.57-.18.9-.3l.7-.25 1.03-.36-.68 1.92.14-.01 1.1-.05-.65.89-.06.08-1 1.37c-.23.3-.34.68-.32 1.1.08 1.54-.11 2.94-.56 4.15a7.35 7.35 0 01-1.98 2.92 8.72 8.72 0 01-4.13 2c-.81.17-1.68.25-2.58.25z"
      />
    </g>
    <defs>
      <clipPath id="clip0">
        <path className="clip-path" d="M0 0h18v18H0z" />
      </clipPath>
    </defs>
  </Svg>
)
