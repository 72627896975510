import PropTypes from 'prop-types'
import React, { PureComponent } from 'react'
import { Container, Avatar, Texts, Info, Message } from './Styled'

export class Comment extends PureComponent {
  static propTypes = {
    author: PropTypes.shape({
      id: PropTypes.string,
      name: PropTypes.string,
      avatar: PropTypes.string
    }),
    message: PropTypes.string,
    date: PropTypes.string
  }

  render() {
    const { author, date, message } = this.props
    return (
      <Container>
        <Avatar src={author.avatar} />
        <Texts>
          <Info>{`${author.name}, ${date}`}</Info>
          <Message>{message}</Message>
        </Texts>
      </Container>
    )
  }
}
