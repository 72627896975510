import React from 'react'
import { Container, Background, Title, TextMessage } from './Styled'
import { Link } from 'react-router-dom'
import { links } from '../../links'
import { recipeNotFound } from '../../texts'

export const RecipeNotFound = () => (
  <Container>
    <Background />
    <Title>{recipeNotFound.title}</Title>
    <TextMessage marginBottom="35px">
      {recipeNotFound.message}
      <Link to={links.recipes}>{recipeNotFound.link}</Link>
    </TextMessage>
  </Container>
)
