import { renderTabLink } from '../navigator/Links'
import { getBatchLink } from '../../links'
import React from 'react'
import styled from 'styled-components'
import TitleConstructor from '../TitleConstructor'
import { MultilineInput, Tabs, TabLink } from '@brewcomputer/ui'

const Header = styled.div`
  flex-direction: column;
  align-items: stretch;
`

export const BatchHeader = ({ batchId, name, status, onEditName }) => {
  return (
    <Header>
      <TitleConstructor pageTitle={`Batch ${status}`} />
      <MultilineInput
        id="batch_name"
        autoFocus={false}
        autoSelect={false}
        value={name}
        onChange={onEditName}
      />
      <Tabs>
        <TabLink
          href={getBatchLink(batchId, 'planning')}
          title="Planning"
          renderLink={renderTabLink}
        />
        <TabLink
          href={getBatchLink(batchId, 'brewing')}
          title="Brewing"
          renderLink={renderTabLink}
        />
        <TabLink
          href={getBatchLink(batchId, 'fermentation')}
          title="Fermentation"
          renderLink={renderTabLink}
        />
      </Tabs>
    </Header>
  )
}

BatchHeader.displayName = 'BatchHeader'
