import React, { Fragment, PureComponent } from 'react'
import { convert } from '@brewcomputer/utils'
import { LayoutGroup, renderNumberValueEditor, ToolTitle } from './ToolsUtils'

export class VolumeUnitsTool extends PureComponent {
  constructor(props) {
    super(props)
    this.state = this._calculateState(1000, 'ml')
  }

  _convert = (value, from, to) => {
    return from == to ? value : convert(value, from, to, 2)
  }

  _calculateState = (value, from) => {
    return {
      ml: this._convert(value, from, 'ml'),
      l: this._convert(value, from, 'l'),
      floz: this._convert(value, from, 'floz'),
      cup: this._convert(value, from, 'cup'),
      pt: this._convert(value, from, 'pt'),
      qt: this._convert(value, from, 'qt'),
      gal: this._convert(value, from, 'gal'),
      bbl: this._convert(value, from, 'bbl'),
      ifloz: this._convert(value, from, 'ifloz'),
      ipt: this._convert(value, from, 'ipt'),
      iqt: this._convert(value, from, 'iqt'),
      igal: this._convert(value, from, 'igal'),
      ibbl: this._convert(value, from, 'ibbl')
    }
  }

  _onChangeMl = value => {
    this.setState(this._calculateState(value, 'ml'))
  }

  _onChangeL = value => {
    this.setState(this._calculateState(value, 'l'))
  }

  _onChangeFloz = value => {
    this.setState(this._calculateState(value, 'floz'))
  }

  _onChangeCup = value => {
    this.setState(this._calculateState(value, 'cup'))
  }

  _onChangePt = value => {
    this.setState(this._calculateState(value, 'pt'))
  }

  _onChangeQt = value => {
    this.setState(this._calculateState(value, 'qt'))
  }

  _onChangeGal = value => {
    this.setState(this._calculateState(value, 'gal'))
  }

  _onChangeBbl = value => {
    this.setState(this._calculateState(value, 'bbl'))
  }

  _onChangeIfloz = value => {
    this.setState(this._calculateState(value, 'ifloz'))
  }

  _onChangeIpt = value => {
    this.setState(this._calculateState(value, 'ipt'))
  }

  _onChangeIqt = value => {
    this.setState(this._calculateState(value, 'iqt'))
  }

  _onChangeIgal = value => {
    this.setState(this._calculateState(value, 'igal'))
  }

  _onChangeIbbl = value => {
    this.setState(this._calculateState(value, 'ibbl'))
  }

  render() {
    return (
      <Fragment>
        <ToolTitle text={'Volume Units'} />

        <LayoutGroup caption={'Metric Volume Units'}>
          {renderNumberValueEditor(
            'Milliliters (ml)',
            this.state.ml,
            this._onChangeMl
          )}
          {renderNumberValueEditor('Liters (l)', this.state.l, this._onChangeL)}
        </LayoutGroup>

        <LayoutGroup caption={'U.S. Volume Units'}>
          {renderNumberValueEditor(
            'Fluid Ounces (fl oz)',
            this.state.floz,
            this._onChangeFloz
          )}
          {renderNumberValueEditor(
            'Cups (cup)',
            this.state.cup,
            this._onChangeCup
          )}
          {renderNumberValueEditor(
            'Pints (pt)',
            this.state.pt,
            this._onChangePt
          )}
          {renderNumberValueEditor(
            'Quarts (qt)',
            this.state.qt,
            this._onChangeQt
          )}
          {renderNumberValueEditor(
            'Gallons (gal)',
            this.state.gal,
            this._onChangeGal
          )}
          {renderNumberValueEditor(
            'Barrels (barrel)',
            this.state.bbl,
            this._onChangeBbl
          )}
        </LayoutGroup>

        <LayoutGroup caption={'Imperial Volume Units'}>
          {renderNumberValueEditor(
            'Imperial Fluid Ounces (imp fl oz)',
            this.state.ifloz,
            this._onChangeIfloz
          )}
          {renderNumberValueEditor(
            'Imperial Pints (imp pt)',
            this.state.ipt,
            this._onChangeIpt
          )}
          {renderNumberValueEditor(
            'Imperial Quarts (imp qt)',
            this.state.iqt,
            this._onChangeIqt
          )}
          {renderNumberValueEditor(
            'Imperial Gallons (imp gal)',
            this.state.igal,
            this._onChangeIgal
          )}
          {renderNumberValueEditor(
            'Imperial Barrels (imp barrel)',
            this.state.ibbl,
            this._onChangeIbbl
          )}
        </LayoutGroup>
      </Fragment>
    )
  }
}
