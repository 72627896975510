import { findIndex, get, size, isFunction } from 'lodash'
import React, { PureComponent } from 'react'
import { Options } from './Options'
import { pickerPropTypes, pickerDefaultProps } from './propTypes'
import { optionRelativeHeight, Dropdown } from '../lookup/Common'
import { colors } from '../colors/colors'
import {
  unitButtonStyle,
  buttonTextStyle,
  defaultButtonStyle,
  buttonStyle
} from './style'
import { AccentButton } from '../buttons/AccentButton'
import { DropdownArrow } from '../icons/DropdownArrow'
import { Manager, Reference, Popper } from 'react-popper'

import styled, { css } from 'styled-components'

const Default = css`
  ${buttonTextStyle};
`

const DefaultLeft = css`
  ${Default};
  ${defaultButtonStyle};
  justify-content: space-between;
`

const DefaultRight = css`
  ${Default};
  ${defaultButtonStyle};
  justify-content: flex-end;
`

const Unit = css`
  ${unitButtonStyle};
  ${buttonTextStyle};
  white-space: nowrap;
  transition-duration: 200ms;
  &:hover,
  &:focus {
    color: ${colors.WHITE};
    background-color: ${colors.ACCENT_BLUE};
  }
  &:disabled {
    cursor: default;
    color: ${colors.TABLE_BLACK};
    background-color: transparent;
  }
`

const LookupButton = styled(AccentButton)`
  padding: 0 12px;
  height: 28px;
  justify-content: space-between;
  flex-direction: row;
`
LookupButton.displayName = 'LookupButton'

function getTypeStyles(type, alignment) {
  switch (type) {
    case 'default':
      return alignment === 'left' ? DefaultLeft : DefaultRight
    case 'unit':
      return Unit
  }
}

const pickerButtonOptionHeightDiff = 6

const getContainerHeight = ({ type }) =>
  type === 'lookup'
    ? optionRelativeHeight
    : optionRelativeHeight - pickerButtonOptionHeightDiff

const Container = styled.div`
  height: ${getContainerHeight}px;
  justify-content: center;
`

const PickerButton = styled.button`
  ${buttonStyle};
  justify-content: center;
  align-items: center;
  white-space: nowrap;
  ${props => getTypeStyles(props.type, props.alignment)};
  svg {
    margin-left: 4px;
  }
`
PickerButton.displayName = 'PickerButton'

const StyledDropdown = styled(Dropdown)`
  margin: 0;
  z-index: 1100;
`
// TODO: shift !!
export class Picker extends PureComponent {
  static displayName = 'Picker'
  static propTypes = pickerPropTypes
  static defaultProps = pickerDefaultProps

  constructor(props) {
    super(props)
    this.state = {
      opened: false
    }
    this._buttonRef = React.createRef()
  }

  toggle = () => {
    if (!this.state.opened) {
      document.addEventListener('click', this.onDocumentClick, false)
    } else {
      document.removeEventListener('click', this.onDocumentClick, false)
    }
    this.setState(prevState => ({ opened: !prevState.opened }))
  }

  getOffsetModifier = selectedItemIndex => {
    const { type, alignment } = this.props
    const xOffset = type === 'default' && alignment === 'left' ? -12 : 0
    const yOffset = type === 'lookup' ? 0 : pickerButtonOptionHeightDiff / 2

    return {
      offset: `${xOffset}, -${(selectedItemIndex + 1) * optionRelativeHeight -
        yOffset}px`
    }
  }

  getShiftModifier = () => {
    if (this.props.type === 'lookup') {
      return {
        fn: data => ({
          ...data,
          styles: { minWidth: data.offsets.reference.width }
        })
      }
    } else {
      return {
        enabled: true
      }
    }
  }

  getPlacement = () => {
    if (this.props.type === 'default' && this.props.alignment === 'left') {
      return 'bottom-start'
    } else if (this.props.type === 'lookup') {
      return 'bottom-start'
    } else {
      return 'bottom-end'
    }
  }

  onButtonClick = e => {
    const { onFocus } = this.props
    if (isFunction(onFocus)) {
      onFocus()
    }
    this.toggle()
  }

  onButtonKeyDown = e => {
    if (!this.state.opened && (e.keyCode === 40 || e.keyCode === 38)) {
      e.preventDefault()
      this.toggle()
    }
  }

  onDocumentClick = () => {
    const { onBlur } = this.props
    if (isFunction(onBlur)) {
      onBlur()
    }
    this.toggle()
  }

  onDropdownSelect = value => {
    const { selectedValue, onChange } = this.props
    if (isFunction(onChange) && value !== selectedValue) {
      onChange(value)
    }
    this.toggle()
    if (this._buttonRef.current && this.props.focusOnSelect) {
      this._buttonRef.current.focus()
    }
  }

  onDropdownClose = () => {
    this.toggle()
    if (this._buttonRef.current && this.props.focusOnSelect) {
      this._buttonRef.current.focus()
    }
  }

  renderPickerButton = (selectedItemLabel, disabled) => {
    const { autoFocus, type, alignment, focused, onFocus, onBlur } = this.props

    if (type === 'lookup') {
      return (
        <LookupButton
          size="small"
          showArrow
          title={selectedItemLabel}
          ref={this._buttonRef}
          disabled={disabled}
          onPress={this.onButtonClick}
          onKeyDown={this.onButtonKeyDown}
          onFocus={onFocus}
          onBlur={onBlur}
        />
      )
    }

    return (
      <PickerButton
        ref={this._buttonRef}
        focused={focused}
        autoFocus={autoFocus}
        type={type}
        alignment={alignment}
        disabled={disabled}
        onClick={this.onButtonClick}
        onKeyDown={this.onButtonKeyDown}
        onFocus={onFocus}
        onBlur={onBlur}>
        {selectedItemLabel}
        {type === 'default' && <DropdownArrow />}
      </PickerButton>
    )
  }

  render() {
    const {
      selectedValue,
      selectedLabel,
      items,
      type,
      alignment,
      focused
    } = this.props

    const selectedItemIndex = findIndex(items, { value: selectedValue })
    const selectedItemLabel = selectedLabel
      ? selectedLabel
      : get(items, [selectedItemIndex, 'label'], null)
    const disabled = size(items) <= 1

    return (
      <Manager>
        <Reference>
          {({ ref }) => {
            return (
              <Container ref={ref} type={type}>
                {this.renderPickerButton(selectedItemLabel, disabled)}
              </Container>
            )
          }}
        </Reference>
        {this.state.opened && (
          <Popper
            placement={this.getPlacement()}
            modifiers={{
              flip: { enabled: false },
              shift: this.getShiftModifier(),
              offset: this.getOffsetModifier(selectedItemIndex)
            }}>
            {({ ref, style, scheduleUpdate }) => {
              scheduleUpdate()
              return (
                <StyledDropdown ref={ref} style={style}>
                  <Options
                    focused={focused}
                    items={items}
                    type={type}
                    alignment={alignment}
                    selectedItemIndex={selectedItemIndex}
                    selectedValue={selectedValue}
                    onSelect={this.onDropdownSelect}
                    onClose={this.onDropdownClose}
                  />
                </StyledDropdown>
              )
            }}
          </Popper>
        )}
      </Manager>
    )
  }
}
