import React from 'react'
import { Icon } from './Icon'
import { colors } from '../colors/colors'

export const SearchIcon = ({ fill, ...props }) => (
  <Icon
    {...props}
    width={16}
    height={16}
    fill={fill}
    paths={[
      'M10.917 9.667h-.659l-.233-.225a5.393 5.393 0 0 0 1.308-3.525 5.416 5.416 0 1 0-5.416 5.416 5.393 5.393 0 0 0 3.525-1.308l.225.233v.659l4.166 4.158 1.242-1.242-4.158-4.166zm-5 0a3.745 3.745 0 0 1-3.75-3.75 3.745 3.745 0 0 1 3.75-3.75 3.745 3.745 0 0 1 3.75 3.75 3.745 3.745 0 0 1-3.75 3.75z'
    ]}
  />
)

SearchIcon.defaultProps = {
  fill: colors.ICON_GRAY
}
