import React, { useEffect } from 'react'
import Header from './Header'
import { Navigator } from './Navigator'
import { ErrorBoundary } from '../components/ErrorBoundary'
import { useGetUser } from '../hooks/queries'

export const App = ({ children }) => {
  const { request: getUser } = useGetUser()

  useEffect(() => {
    getUser()
  }, [])

  return (
    <Navigator>
      <Header />
      <ErrorBoundary>{children}</ErrorBoundary>
    </Navigator>
  )
}
