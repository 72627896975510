import React, { PureComponent } from 'react'
import {
  HeaderContainer,
  RecipeText,
  ColorTitle,
  Style,
  Type,
  BatchTitle,
  Status,
  Date,
  Number
} from './Styled.js'

export class BatchesListHeader extends PureComponent {
  render() {
    return (
      <HeaderContainer>
        <Number>
          <RecipeText>#</RecipeText>
        </Number>
        <BatchTitle>
          <ColorTitle>Color</ColorTitle>
          <RecipeText>Title</RecipeText>
        </BatchTitle>
        <Style>
          <RecipeText>Style</RecipeText>
        </Style>
        <Type>
          <RecipeText>Method</RecipeText>
        </Type>
        <Status>
          <RecipeText>Status</RecipeText>
        </Status>
        <Date>
          <RecipeText>Date</RecipeText>
        </Date>
      </HeaderContainer>
    )
  }
}
