import { isDate, isEqual } from 'lodash'
import React, { PureComponent } from 'react'
import { Editor, Title, InputContainer } from './Styled'
import { DatePicker } from '../datePicker/DatePicker'
import PropTypes from 'prop-types'

const toDateObject = dateString => new Date(dateString)

export class DateEditor extends PureComponent {
  static displayName = 'DateEditor'
  static propTypes = {
    title: PropTypes.string,
    value: PropTypes.string,
    onChange: PropTypes.func
  }

  state = {
    value: null,
    focused: false
  }

  onChange = date => {
    this.setState({ value: date })
  }

  onFocus = () => {
    this.setState({ focused: true })
  }

  onBlur = () => {
    this.setState({ focused: false })
    this.change()
  }

  change = () => {
    if (
      isDate(this.state.value) &&
      !isEqual(this.state.value, toDateObject(this.props.value))
    ) {
      this.props.onChange(this.state.value.toISOString())
    }
  }

  componentDidUpdate(prevProps, prevState) {
    if (
      prevProps.value != null &&
      !isEqual(toDateObject(prevProps.value), toDateObject(this.props.value))
    ) {
      if (isEqual(toDateObject(this.props.value), this.state.value)) {
        //eslint-disable-next-line
        this.setState({ value: null })
      }
    }
  }

  render() {
    const { focused } = this.state
    const { title } = this.props
    const value =
      this.state.value == null
        ? toDateObject(this.props.value)
        : this.state.value

    return (
      <Editor focused={focused}>
        <InputContainer>
          <Title focused={focused}>{title}</Title>
          <DatePicker
            disabledKeyboardNavigation
            showMonthDropdown
            showYearDropdown
            dropdownMode="select"
            selected={value}
            onChange={this.onChange}
            onFocus={this.onFocus}
            onBlur={this.onBlur}
          />
        </InputContainer>
      </Editor>
    )
  }
}
