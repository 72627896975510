import { assign } from 'lodash'
import {
  userGetQuery,
  userEditAction,
  userEditUnitSettingsAction
} from '../actions/user'

export default function user(state = {}, action) {
  switch (action.type) {
    case userGetQuery.types.success: {
      return action.payload.result
    }

    case userEditAction.types.request: {
      const { changes } = action.payload
      return {
        ...state,
        changes
      }
    }

    case userEditUnitSettingsAction.types.request: {
      const { changes } = action.payload
      const unitSettings = assign({}, state.unitSettings, changes)
      return {
        ...state,
        unitSettings
      }
    }
  }

  return state
}
