import React, { Fragment, PureComponent } from 'react'
import { convert } from '@brewcomputer/utils'
import { renderNumberValueEditor, ToolTitle } from './ToolsUtils'

export class PressureUnitsTool extends PureComponent {
  constructor(props) {
    super(props)
    this.state = this._calculateState(100, 'kPa')
  }

  _convert = (value, from, to) => {
    return from == to ? value : convert(value, from, to, 3)
  }

  _calculateState = (value, from) => {
    return {
      kpa: this._convert(value, from, 'kPa'),
      bar: this._convert(value, from, 'bar'),
      atm: this._convert(value, from, 'atm'),
      psi: this._convert(value, from, 'psi')
    }
  }

  _onChangeKpa = value => {
    this.setState(this._calculateState(value, 'kPa'))
  }

  _onChangeBar = value => {
    this.setState(this._calculateState(value, 'bar'))
  }

  _onChangeAtm = value => {
    this.setState(this._calculateState(value, 'atm'))
  }

  _onChangePsi = value => {
    this.setState(this._calculateState(value, 'psi'))
  }

  render() {
    return (
      <Fragment>
        <ToolTitle text={'Pressure Units'} />

        {renderNumberValueEditor(
          'Kilopascals (kPa)',
          this.state.kpa,
          this._onChangeKpa
        )}
        {renderNumberValueEditor(
          'Bars (bar)',
          this.state.bar,
          this._onChangeBar
        )}
        {renderNumberValueEditor(
          'Atmospheres (atm)',
          this.state.atm,
          this._onChangeAtm
        )}
        {renderNumberValueEditor(
          'Pounds per Square Inch (psi)',
          this.state.psi,
          this._onChangePsi
        )}
      </Fragment>
    )
  }
}
