import React, { useRef, useEffect } from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { Popup } from '../popup/Popup'
import { ClearButton } from '../buttons/ClearButton'
import { textStyles } from '../texts/textStyles'
import { colors } from '../colors/colors'
import { AccentButton } from '..'

const Container = styled.div`
  align-items: stretch;
  background-color: ${colors.WHITE};
`

const Header = styled.div`
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding: 32px 24px 24px 24px;
`

const Title = styled.div`
  ${textStyles.H2};
`

const Section = styled.div`
  border-top: 1px solid ${colors.DIVIDER};
  padding: 0 24px 32px 24px;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-end;
`

const Button = styled(AccentButton)`
  margin-top: 24px;
`

export const Modal = props => {
  const { visible, title, button, onButtonPress, children, onClose } = props

  const popupRef = useRef(null)

  useEffect(
    () => {
      if (visible) {
        popupRef.current.show()
      } else {
        popupRef.current.hide()
      }
    },
    [visible]
  )

  return (
    <Popup type="window" ref={popupRef} onHideAnimationEnd={onClose}>
      <Container>
        <Header>
          <Title>{title}</Title>
          <ClearButton accessibilityLabel="Close" onPress={onClose} />
        </Header>
        {children}
        <Section>
          {button != null && (
            <Button
              title={button.title}
              accessibilityLabel={button.label}
              onPress={onButtonPress}
              disabled={button.disabled}
              negative={button.negative}
            />
          )}
        </Section>
      </Container>
    </Popup>
  )
}

Modal.propTypes = {
  visible: PropTypes.bool,
  title: PropTypes.string,
  button: PropTypes.shape({
    title: PropTypes.string,
    label: PropTypes.string,
    disabled: PropTypes.bool,
    negative: PropTypes.bool
  }),
  onButtonPress: PropTypes.func,
  children: PropTypes.any,
  onClose: PropTypes.func
}
