import React from 'react'
import { Container, Background, Title, TextMessage } from './Styled'
import { Link } from 'react-router-dom'
import { links } from '../../links'
import { batchNotFound } from '../../texts'

export const BatchNotFound = () => (
  <Container>
    <Background />
    <Title>{batchNotFound.title}</Title>
    <TextMessage marginBottom="35px">
      {batchNotFound.message}
      <Link to={links.recipes}>{batchNotFound.link}</Link>
    </TextMessage>
  </Container>
)
