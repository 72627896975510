import { createCommandActions, createQueryActions } from './common'

export const BATCH_CHECK_LIST_EDITED_OPTIMISTIC =
  'BATCH_CHECK_LIST_EDITED_OPTIMISTIC'

export const batchCheckListEditOptimistic = ({ recipeId, state }) => ({
  type: BATCH_CHECK_LIST_EDITED_OPTIMISTIC,
  payload: {
    recipeId,
    state
  }
})

export const BATCHES_VIEW_MODEL_LOAD = 'BATCHES_VIEW_MODEL_LOAD'

export const batchesViewModelLoad = ({
  aggregateIds,
  result,
  error,
  initial
}) => ({
  type: BATCHES_VIEW_MODEL_LOAD,
  payload: {
    batchId: aggregateIds[0],
    result,
    error,
    initial
  }
})

export const BATCHES_EVENT_RECEIVED = 'BATCHES_EVENT_RECEIVED'

export const batchesEventReceived = ({ aggregateId, type, payload }) => ({
  type: BATCHES_EVENT_RECEIVED,
  payload: {
    payload,
    type,
    aggregateId
  }
})

export const batchesGetQuery = createQueryActions('BATCHES_GET')

export const batchesCountQuery = createQueryActions('BATCHES_COUNT')

export const batchCreateAction = createCommandActions(
  'BATCH_CREATE',
  ({ aggregateId, payload, error }) => ({
    recipe: payload,
    recipeId: aggregateId,
    error
  })
)

export const batchEditAction = createCommandActions(
  'BATCH_EDIT',
  ({ aggregateId, payload, error }) => ({
    ...payload,
    recipeId: aggregateId,
    error
  })
)

export const batchEditMeasuredAction = createCommandActions(
  'BATCH_EDIT_MEASURED',
  ({ aggregateId, payload, error }) => ({
    ...payload,
    recipeId: aggregateId,
    error
  })
)

export const batchRemoveAction = createCommandActions(
  'BATCH_REMOVE',
  ({ aggregateId, payload, error }) => ({
    recipeId: aggregateId,
    error
  })
)

export const batchChangeStatusAction = createCommandActions(
  'BATCH_CHANGE_STATUS',
  ({ aggregateId, payload, error }) => ({
    recipeId: aggregateId,
    status: payload.status,
    error
  })
)

export const batchCheckListCheckAction = createCommandActions(
  'BATCH_CHECK_LIST_CHECK',
  ({ aggregateId, payload, error }) => ({
    ...payload,
    recipeId: aggregateId,
    error
  })
)

export const batchIngredientCheckAction = createCommandActions(
  'BATCH_INGREDIENT_CHECK',
  ({ aggregateId, payload, error }) => ({
    ...payload,
    recipeId: aggregateId,
    error
  })
)

export const batchProcedureCheckAction = createCommandActions(
  'BATCH_PROCEDURE_CHECK',
  ({ aggregateId, payload, error }) => ({
    ...payload,
    recipeId: aggregateId,
    error
  })
)
