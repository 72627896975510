import React, { PureComponent } from 'react'
import { clearButtonPropTypes } from './propTypes'
import { colors } from '../colors/colors'
import { SearchClearIcon } from '../icons/SearchClearIcon'
import styled from 'styled-components'

const StyledButton = styled.button`
  background-color: transparent;
  & svg path {
    transition-duration: 200ms;
  }
  :hover svg path {
    fill: ${colors.NEGATIVE_RED};
  }
  :active svg path {
    fill: ${colors.NEGATIVE_RED};
  }
  :focus svg path {
    fill: ${colors.NEGATIVE_RED};
  }
`

export class ClearButton extends PureComponent {
  static propTypes = clearButtonPropTypes

  render() {
    const { accessibilityLabel, onPress, ...buttonProps } = this.props
    return (
      <StyledButton
        aria-label={accessibilityLabel}
        onClick={onPress}
        {...buttonProps}>
        <SearchClearIcon />
      </StyledButton>
    )
  }
}
