import {
  includes,
  omit,
  omitBy,
  forEach,
  isArray,
  isNil,
  map,
  isPlainObject,
} from 'lodash'

const batchCheckListFields = [
  'batch_available',
  'batch_added',
  'batch_completed',
]

const calculatedFields = ['ranges', 'volumes', 'color_css']

const internalFields = [
  'authorId',
  'batchId',
  'hasBatch',
  'from',
  'shared',
  'published',
  'brewsCount',
  'clonesCount',
  'rating',
  'lastModified',
  'images',
]

const userRelatedFields = ['equipment']

export const removeOldBeerJSONFields = (recipe) => ({
  ...recipe,
  mash: isNil(recipe.mash)
    ? undefined
    : omit(recipe.mash, 'sparge_temperature'),
  fermentation: isNil(recipe.fermentation)
    ? undefined
    : omit(recipe.fermentation, 'type'),
})

export const recursiveRemoveFields = (recipe, fields) => {
  let result = isPlainObject(recipe) ? omit(recipe, fields) : recipe

  forEach(result, (value, key) => {
    if (isPlainObject(value)) {
      result[key] = recursiveRemoveFields(value, fields)
    } else if (isArray(value)) {
      result[key] = map(value, (item) => recursiveRemoveFields(item, fields))
    }
  })
  return result
}

export const omitFields = (recipe, fields) =>
  omitBy(
    recipe,
    (fieldValue, fieldName) => isNil(fieldValue) || includes(fields, fieldName)
  )

export const prepareForCloning = (recipe) =>
  omitFields(
    recursiveRemoveFields(recipe, ['units', ...batchCheckListFields]),
    ['batch', ...internalFields, ...userRelatedFields, ...calculatedFields]
  )

export const prepareForBrewing = (recipe) =>
  omitFields(recursiveRemoveFields(recipe, ['units']), [
    'batch',
    ...internalFields,
    ...calculatedFields,
  ])

// TODO: strict validation
export const prepareForValidation = (recipe) =>
  omitFields(
    removeOldBeerJSONFields(
      recursiveRemoveFields(recipe, ['id', 'units', ...batchCheckListFields])
    ),
    ['batch', ...userRelatedFields, ...internalFields, ...calculatedFields]
  )

export const prepareForExport = (recipe) =>
  omitFields(
    removeOldBeerJSONFields(
      recursiveRemoveFields(recipe, ['id', 'units', ...batchCheckListFields])
    ),
    ['batch', ...userRelatedFields, ...internalFields, ...calculatedFields]
  )
