import React, { useCallback } from 'react'
import PropTypes from 'prop-types'
import {
  InputContainer,
  HiddenCheckbox,
  StyledCheckbox,
  Icon,
  Text,
  Label
} from './Styled'

const InputCheckbox = ({ className, checked, disabled, onChange }) => {
  return (
    <InputContainer className={className}>
      <HiddenCheckbox
        checked={checked}
        disabled={disabled}
        onChange={onChange}
      />
      <StyledCheckbox checked={checked}>
        <Icon viewBox="0 0 24 24">
          <polyline points="20 6 9 17 4 12" />
        </Icon>
      </StyledCheckbox>
    </InputContainer>
  )
}

export const Checkbox = ({ className, checked, disabled, label, onChange }) => {
  const onCheck = useCallback(event => {
    if (onChange) {
      onChange({ checked: event.target.checked })
    }
  })

  return (
    <Label checked={checked} fitted={label == null} className={className}>
      <InputCheckbox checked={checked} disabled={disabled} onChange={onCheck} />
      {label != null && <Text>{label}</Text>}
    </Label>
  )
}

Checkbox.displayName = 'Checkbox'
Checkbox.propTypes = {
  className: PropTypes.string,
  checked: PropTypes.bool,
  label: PropTypes.string,
  disabled: PropTypes.bool,
  onChange: PropTypes.func
}
