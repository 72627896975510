import { css } from 'styled-components'
import { colors } from '../colors/colors'
import { textStyles } from '../texts/textStyles'

export const containerCardStyle = css`
  padding: 14px 44px 14px 14px;
  background-color: ${colors.WHITE};
  border-left-width: 4px;
  border-left-color: ${props => props.colorCSS};
`

export const containerRowStyle = css`
  padding: 16px 44px 16px 24px;
  background-color: ${colors.WHITE};
  flex-direction: row;
  align-items: center;
`

export const recipeNameStyle = css`
  ${textStyles.body1};
  color: ${colors.TABLE_BLACK};
  padding-right: 12px;
`

export const recipeTextStyle = css`
  ${textStyles.body2};
  color: ${colors.TABLE_GRAY};
`
