import { isNil } from 'lodash'
import React from 'react'
import PropTypes from 'prop-types'
import { Icon } from './Icon'
import { colors } from '../colors/colors'

export const DropdownArrow = props => {
  let color = props.opened ? colors.DROPDOWN_ICON_ACTIVE : colors.DROPDOWN_ICON
  if (!isNil(props.color)) {
    color = props.color
  }
  return (
    <Icon
      width={8}
      height={5}
      fill={color}
      paths={['M0.94 0L4 3.09042L7.06 0L8 0.951417L4 5L0 0.951417L0.94 0Z']}
    />
  )
}

DropdownArrow.propTypes = {
  opened: PropTypes.bool
}
