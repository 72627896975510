import { css } from 'styled-components'
import { textStyles } from '../texts/textStyles'
import { colors } from '../colors/colors'

export const containerStyle = css`
  flex-direction: row;
  align-items: flex-start;
  margin: 10px 0;
`

export const avatarStyle = css`
  width: 32px;
  height: 32px;
  border-radius: 16px;
  margin-right: 12px;
`

export const textsStyle = css`
  align-items: flex-start;
  white-space: pre-wrap;
`

export const infoStyle = css`
  color: ${colors.LINK_GRAY};
  ${textStyles.smallText};
`

export const messageStyle = css`
  color: ${colors.BLACK};
  ${textStyles.body2};
`

export const editorStyle = css`
  margin: 10px 0;
`
