import styled from 'styled-components'
import { Card } from '../card/Card'
import { titleStyle } from './style'

export const Container = styled(Card)`
  margin-bottom: 24px;
  flex-direction: row;
  flex-wrap: wrap;
`

export const Items = styled.div`
  flex-direction: row;
  flex-wrap: wrap;
`

export const Title = styled.div`
  ${titleStyle};
`
