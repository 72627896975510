import figmaColors from './figmaColors'

export const colors = {
  BACKGROUND: figmaColors.gray_100,
  WHITE: figmaColors.white,
  BLACK: figmaColors.black,
  BACKGROUND_PAGE: figmaColors.blue_gray_50,
  DIVIDER: figmaColors.blue_gray_50,
  ACCENT_BLUE: figmaColors.primary_500,
  ACCENT_BLUE_SHADOW: 'rgba(16, 156, 241, 0.24)',
  ACCENT_BLUE_HOVER: figmaColors.primary_400,
  ACCENT_BLUE_HOVER_SHADOW: 'rgba(52, 175, 249, 0.2)',
  ACCENT_BLUE_ACTIVE: figmaColors.primary_600,
  ACCENT_BLUE_ACTIVE_SHADOW: 'rgba(9, 142, 223, 0.3)',
  ACCENT_GRAY: figmaColors.blue_gray_500,
  ACCENT_GRAY_HOVER: figmaColors.blue_gray_400,
  ACCENT_GRAY_ACTIVE: figmaColors.blue_gray_600,
  BUTTON_RED: figmaColors.red_500,
  BUTTON_RED_SHADOW: 'rgba(251, 92, 65, 0.24)',
  BUTTON_RED_HOVER: figmaColors.red_400,
  BUTTON_RED_HOVER_SHADOW: 'rgba(247, 104, 91, 0.2)',
  BUTTON_RED_ACTIVE: figmaColors.red_600,
  BUTTON_RED_ACTIVE_SHADOW: 'rgba(238, 84, 65, 0.3)',
  BUTTON_DISABLED: figmaColors.blue_gray_200,
  DARK_BLUE: figmaColors.dark_blue_900,
  GRAY: figmaColors.blue_gray_300,
  GRAY_SHADOW: 'rgba(194, 207, 224, 0.24)',
  ICON_GRAY: figmaColors.blue_gray_300,
  ICON_GRAY_HOVER: figmaColors.blue_500,
  TEXT_LINE: figmaColors.blue_gray_100,
  TEXT_HELP_GRAY: figmaColors.blue_gray_400,
  FILTER_GRAY: figmaColors.blue_gray_600,
  LINK_GRAY: figmaColors.blue_gray_700,
  TEXT_GRAY: figmaColors.blue_gray_900,
  TABLE_BLACK: figmaColors.black,
  TABLE_GRAY: figmaColors.blue_gray_600,
  PLACEHOLDER: figmaColors.gray_500,
  DROPDOWN_ICON: figmaColors.blue_gray_400,
  DROPDOWN_ICON_ACTIVE: figmaColors.primary_500,
  NEGATIVE_RED: figmaColors.red_500,
  GREEN: figmaColors.green_400,
  STYLE_BAR_FILL: figmaColors.blue_gray_500,
  STYLE_BAR_BACKGROUND: figmaColors.blue_gray_100,
  LOOKUP_OPTION_HOVER: figmaColors.primary_50,
  LOOKUP_OPTION_SELECT: figmaColors.blue_gray_50,
  LOOKUP_OPTION_HIGHLIGHT: figmaColors.primary_600,
  EDITOR_READ_ONLY: figmaColors.gray_50,
  INPUT_DISABLED: figmaColors.gray_600,
  CHAT_OWN_MESSAGE_BACKGROUND: figmaColors.blue_gray_600,
  CHAT_MESSAGE_BACKGROUND: figmaColors.primary_500,
  CHAT_OWN_INFO_COLOR: figmaColors.blue_gray_100,
  CHAT_INFO_COLOR: figmaColors.primary_100
}
