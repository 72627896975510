import React, { useState } from 'react'
import { useStaticResolver } from '@resolve-js/react-hooks'
import {
  GoogleAuthLink,
  EmailAuthLink,
  NoLoginAuthLink,
} from '@brewcomputer/ui'
import { landingPage } from '../../texts'
import { EmailAuth } from './EmailAuth'
import { LandingFooter } from './LandingFooter'
import styled, { createGlobalStyle } from 'styled-components'
import TitleConstructor from '../TitleConstructor'
import { google, anonymous } from '../../../auth/endpoints'

export const Global = createGlobalStyle`
  .page > nav {
    background-color: #FCF2BF;
  }
  .page {
    background-color: #F9E06C;
  }
  .page {
    .main-path {
      fill: #192A3E;
    }
  }
  @media (max-width: 576px) {
   .producthunt-badge {
     display: none;
   }
  }
`

export const Container = styled.div`
  flex-grow: 1;
  flex-shrink: 1;
  align-items: center;
`

export const Title = styled.h1`
  margin: 0;
  font-size: 38px;
  line-height: 57px;
  font-weight: bold;
  text-align: center;
  color: #212121;

  @media (max-width: 576px) {
    margin-top: 24px;
    margin-bottom: 12px;
    font-size: 24px;
    line-height: 30px;
  }

  @media (min-width: 577px) and (max-width: 992px) {
    margin-top: 32px;
    margin-bottom: 16px;
    font-size: 32px;
    line-height: 42px;
  }

  @media (min-width: 993px) and (max-width: 1200px) {
    margin-top: 40px;
    margin-bottom: 20px;
    font-size: 38px;
    line-height: 42px;
  }

  @media (min-width: 1200px) {
    margin-top: 48px;
    margin-bottom: 24px;
    font-size: 38px;
    line-height: 42px;
  }
`

export const Text = styled.div`
  font-size: 18px;
  line-height: 24px;
  max-width: 500px;
  text-align: center;
  margin-bottom: 24px;

  @media (max-width: 576px) {
    margin-bottom: 24px;
    font-size: 15px;
    line-height: 20px;
  }

  @media (min-width: 577px) and (max-width: 992px) {
    max-width: 370px;
    margin-bottom: 32px;
    font-size: 20px;
    line-height: 28px;
  }

  @media (min-width: 993px) and (max-width: 1200px) {
    max-width: 565px;
    margin-bottom: 32px;
    font-size: 20px;
    line-height: 28px;
  }

  @media (min-width: 1200px) {
    max-width: 565px;
    margin-bottom: 32px;
    font-size: 24px;
    line-height: 34px;
  }
`

export const Links = styled.div`
  @media (min-width: 992px) {
    flex-direction: row;
    a {
      margin-right: 16px;
    }
  }
`

export const Image = styled.div`
  margin-top: 46px;
  width: 100%;
  align-items: center;
  img {
    max-width: 100%;
  }

  @media (max-width: 576px) {
    margin-top: 12px;
  }

  @media (min-width: 577px) and (max-width: 992px) {
    margin-top: 20px;
  }

  @media (min-width: 993px) and (max-width: 1200px) {
    margin-top: 20px;
  }

  @media (min-width: 1200px) {
    margin-top: 20px;
  }
`

export const LandingPage = () => {
  const resolveStatic = useStaticResolver()
  const src = resolveStatic('/image.png')
  const srcSet = `${src}, ${resolveStatic('/image@2x.png')} 2x`

  const [modalVisible, setModalVisible] = useState(false)
  const showModal = () => {
    setModalVisible(true)
  }
  const hideModal = () => {
    setModalVisible(false)
  }

  return (
    <Container>
      <Global />
      <Title>{landingPage.title}</Title>
      <TitleConstructor pageTitle={landingPage.title} />
      <Text>{landingPage.message}</Text>
      <Links>
        <NoLoginAuthLink
          title={landingPage.authButtons.noLogin}
          href={anonymous.auth_link}
        />
        <GoogleAuthLink
          title={landingPage.authButtons.google}
          href={google.auth_link}
        />
        <EmailAuthLink
          title={landingPage.authButtons.email}
          onPress={showModal}
        />
      </Links>
      <Image>
        <img src={src} srcSet={srcSet} alt={landingPage.alt} />
      </Image>
      <LandingFooter />
      <EmailAuth visible={modalVisible} onClose={hideModal} />
    </Container>
  )
}
