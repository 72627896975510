import React, { Fragment } from 'react'
import jwt from 'jsonwebtoken'
import { AccentButton } from '@brewcomputer/ui'
import { AuthContainer, AuthTitle, Email, TextMessage } from './Styled'
import { emailAuth } from '../../texts'
import { useSendMagicLink } from '../../hooks/auth'
import { useParams } from 'react-router'
import { useSelector } from 'react-redux'
import { Loader } from './EmailAuth'

const getTitle = ({ success, error }) => {
  if (success) {
    return emailAuth.success.title
  }
  if (error) {
    return emailAuth.error.title
  }
  return emailAuth.expired.title
}

const getMessage = ({ success, error }, email) => {
  if (success) {
    const [message1, message2] = emailAuth.success.messages
    return (
      <Fragment>
        <span>
          {message1}
          <Email>{email}.</Email>
        </span>
        <span>{message2}</span>
      </Fragment>
    )
  }
  if (error) {
    return emailAuth.error.message
  }
  return emailAuth.expired.message
}

export const AuthTokenExpired = () => {
  const { token } = useParams()
  const { email, userId } = jwt.decode(token)
  const sendEmail = useSendMagicLink()
  const onPress = () => {
    sendEmail({ email, userId })
  }
  const { loading, success, error } = useSelector(state => state.auth)
  const showButton = !success && !error
  return (
    <AuthContainer>
      <AuthTitle>{getTitle({ success, error })}</AuthTitle>
      {loading ? (
        <Loader />
      ) : (
        <Fragment>
          <TextMessage marginBottom="36px">
            {getMessage({ success, error }, email)}
          </TextMessage>
          {showButton && <AccentButton title="Resend" onPress={onPress} />}
        </Fragment>
      )}
    </AuthContainer>
  )
}
