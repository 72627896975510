import React, { PureComponent } from 'react'
import { FeedbackIcon, colors } from '@brewcomputer/ui'
import Counter from '../../containers/UnreadFeedbackCount'
import styled from 'styled-components'

const Container = styled.div`
  position: relative;
`

const Badge = styled(Counter)`
  position: absolute;
  right: 0;
  top: 0;
  background-color: ${colors.NEGATIVE_RED};
  color: ${colors.WHITE};
  font-size: 8px;
  line-height: 12px;
  min-width: 12px;
  padding: 0;
  border-radius: 50%;
`

export class FeedbackNotificationIcon extends PureComponent {
  render() {
    const { id, fill } = this.props
    return (
      <Container>
        <FeedbackIcon id={id} fill={fill} />
        <Badge />
      </Container>
    )
  }
}
