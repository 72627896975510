import { map, isEmpty, capitalize } from 'lodash'
import PropTypes from 'prop-types'
import React from 'react'
import { ResponsiveTable } from '../responsiveTable/ResponsiveTable'
import { RecipeDataSection } from '../recipeView/RecipeDataSection'
import {
  getBoilStepFields,
  getFermentationStepFields,
  getMashStepFields
} from '@brewcomputer/utils'
import { ProcedureItem } from './ProcedureItem'
import { getColumnTitles, getKeyByFieldTitle } from './common'

const stepsDataExtractors = {
  mash: getMashStepFields,
  boil: getBoilStepFields,
  fermentation: getFermentationStepFields
}

const getStepsData = (procedureType, profile) =>
  map(profile[`${procedureType}_steps`], (step, i) =>
    stepsDataExtractors[procedureType](step, i)
  )

export const ProcedureSteps = ({
  procedure,
  procedureType,
  onEditProcedure
}) => {
  if (isEmpty(procedure)) {
    return null
  }

  const renderRow = (fields, itemIndex, visibleColumnsCount) => (
    <ProcedureItem
      key={getKeyByFieldTitle(fields, 'Step', itemIndex)}
      fields={fields}
      visibleColumnsCount={visibleColumnsCount}
      onEditProcedure={onEditProcedure}
    />
  )

  const title = `${capitalize(procedureType)} Steps`
  const stepsData = getStepsData(procedureType, procedure)

  return (
    <RecipeDataSection caption={title}>
      <ResponsiveTable
        columnTitles={getColumnTitles(stepsData)}
        data={stepsData}
        expandedColumnIndex={procedureType === 'mash' ? 2 : 1}
        renderRow={renderRow}
      />
    </RecipeDataSection>
  )
}

ProcedureSteps.propTypes = {
  procedure: PropTypes.object,
  procedureType: PropTypes.oneOf(['mash', 'boil', 'fermentation']),
  onEditProcedure: PropTypes.func
}

ProcedureSteps.displayName = 'ProcedureSteps'
