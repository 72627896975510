import { has, isNumber } from 'lodash'
import { beautifyUnit } from './index'

export const isMeasurable = measurable =>
  has(measurable, 'value') &&
  isNumber(measurable.value) &&
  has(measurable, 'unit')

export const measurableToString = measurable => {
  if (!isMeasurable(measurable)) {
    return `N/A`
  }
  return `${measurable.value} ${beautifyUnit(measurable.unit)}`
}

export const getMashStepDescription = ({
  type,
  step_time,
  step_temperature,
  end_temperature,
  infuse_temperature,
  amount
}) => {
  switch (type) {
    case 'decoction':
      return `Decoct ${measurableToString(amount)} of mash and boil it`

    case 'temperature': {
      if (isMeasurable(amount) && amount.value > 0) {
        return `Add ${measurableToString(
          amount
        )} of water and heat to ${measurableToString(end_temperature)}`
      }
      return `Heat to ${measurableToString(
        step_temperature
      )} over ${measurableToString(step_time)}`
    }

    case 'sparge': {
      return `Fly sparge with ${measurableToString(
        amount
      )} of water at ${measurableToString(step_temperature)}`
    }

    case 'infusion':
    default:
      return `Add ${measurableToString(
        amount
      )} of water at ${measurableToString(infuse_temperature)}`
  }
}

export const round = (number, digits = 0) => {
  if (typeof number === 'number') {
    return Number(number.toFixed(digits))
  } else {
    return number
  }
}
