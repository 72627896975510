import React from 'react'
import styled from 'styled-components'
import colors from '../colors/figmaColors'
import { IconLink } from './IconLink'
import { ShareIcon } from '../icons/ShareIcon'

const Button = styled.button`
  background-color: transparent;
  :disabled {
    cursor: not-allowed;
  }
`

export const ShareLink = ({ shared, disabled, onPress }) => (
  <Button disabled={disabled} onClick={onPress}>
    <IconLink
      title={shared ? 'Shared' : 'Share'}
      Icon={<ShareIcon shared={shared} />}
      textColor={shared ? colors.blue_500 : colors.blue_gray_500}
      textColorActive={shared ? colors.blue_700 : colors.blue_gray_700}
    />
  </Button>
)
