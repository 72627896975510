import {
  createCommandActions,
  createQueryActions,
  createViewModelAction
} from './common'

export const RECIPE_VIEW_MODEL_LOAD_REQUEST = 'RECIPE_VIEW_MODEL_LOAD_REQUEST'
export const RECIPE_VIEW_MODEL_LOAD_SUCCESS = 'RECIPE_VIEW_MODEL_LOAD_SUCCESS'
export const RECIPE_VIEW_MODEL_LOAD_FAILURE = 'RECIPE_VIEW_MODEL_LOAD_FAILURE'

// queries
export const recipesPublicGetQuery = createQueryActions('RECIPES_PUBLIC_GET')

export const recipesPublicCountQuery = createQueryActions(
  'RECIPES_PUBLIC_COUNT'
)

export const recipesMyGetQuery = createQueryActions('RECIPES_MY_GET')

export const recipesMyCountQuery = createQueryActions('RECIPES_MY_COUNT')

export const recipesSearch = createQueryActions('RECIPES_SEARCH')

export const RECIPES_SEARCH_CLEAR = 'RECIPES_SEARCH_CLEAR'

export const recipesSearchClear = () => ({
  type: RECIPES_SEARCH_CLEAR
})

export const RECIPES_GO_TO_PAGE = 'RECIPES_GO_TO_PAGE'

export const recipesGoToPage = page => ({
  type: RECIPES_GO_TO_PAGE,
  payload: {
    page
  }
})

export const RECIPE_CHANGED_OPTIMISTIC = 'RECIPE_CHANGED_OPTIMISTIC'

export const recipeChangeOptimistic = ({ recipeId, recipe }) => ({
  type: RECIPE_CHANGED_OPTIMISTIC,
  payload: {
    recipeId,
    recipe
  }
})

// view-model
export const recipesViewModelLoad = createViewModelAction(
  'RECIPES_VIEW_MODEL',
  ({ aggregateIds, result, error }) => ({
    recipeId: aggregateIds[0],
    recipe: result,
    error
  })
)

//commands
export const recipeCreateAction = createCommandActions(
  'RECIPE_CREATE',
  ({ aggregateId, payload, error }) => ({
    recipe: payload,
    recipeId: aggregateId,
    error
  })
)

export const recipeEditAction = createCommandActions(
  'RECIPE_EDIT',
  ({ aggregateId, payload, error }) => ({
    ...payload,
    recipeId: aggregateId,
    error
  })
)

export const recipeRemoveAction = createCommandActions(
  'RECIPE_REMOVE',
  ({ aggregateId, error }) => ({
    recipeId: aggregateId,
    error
  })
)

export const recipeScaleAction = createCommandActions(
  'RECIPE_SCALE',
  ({ aggregateId, payload, error }) => ({
    ...payload,
    recipeId: aggregateId,
    error
  })
)

export const recipeEditBeerStyleAction = createCommandActions(
  'RECIPE_EDIT_BEER_STYLE',
  ({ aggregateId, payload, error }) => ({
    ...payload,
    recipeId: aggregateId,
    error
  })
)

export const recipeAddIngredientAction = createCommandActions(
  'RECIPE_ADD_INGREDIENT',
  ({ aggregateId, payload, error }) => ({
    id: payload.id,
    data: payload.data,
    ingredientType: payload.ingredientType,
    recipeId: aggregateId,
    error
  })
)

export const recipeEditIngredientAction = createCommandActions(
  'RECIPE_EDIT_INGREDIENT',
  ({ aggregateId, payload, error }) => ({
    id: payload.id,
    changes: payload.changes,
    ingredientType: payload.ingredientType,
    recipeId: aggregateId,
    error
  })
)

export const recipeRemoveIngredientAction = createCommandActions(
  'RECIPE_REMOVE_INGREDIENT',
  ({ aggregateId, payload, error }) => ({
    id: payload.id,
    ingredientType: payload.ingredientType,
    recipeId: aggregateId,
    error
  })
)

export const recipeEditProcedureAction = createCommandActions(
  'RECIPE_EDIT_PROCEDURE',
  ({ aggregateId, payload, error }) => ({
    data: payload.data,
    procedureType: payload.procedureType,
    recipeId: aggregateId,
    error
  })
)

export const recipeEditEquipmentAction = createCommandActions(
  'RECIPE_EDIT_EQUIPMENT',
  ({ aggregateId, payload, error }) => ({
    ...payload,
    recipeId: aggregateId,
    error
  })
)

export const recipeEditPrivacyAction = createCommandActions(
  'RECIPE_EDIT_PRIVACY',
  ({ aggregateId, payload, error }) => ({
    ...payload,
    recipeId: aggregateId,
    error
  })
)
