import { concat, join, map, uniq } from 'lodash'
import React from 'react'
import { Helmet } from 'react-helmet-async'
import { useStaticResolver } from '@resolve-js/react-hooks'

const joinIngredients = (ingredients) => {
  if (ingredients == null) {
    return ''
  }

  const fermentables = map(
    ingredients.fermentable_additions,
    ({ name }) => name
  )
  const hops = uniq(map(ingredients.hop_additions, ({ name }) => name))
  const cultures = map(
    ingredients.culture_additions,
    ({ name, laboratory = '', product_id = '' }) =>
      `${name} ${laboratory} ${product_id}`
  )

  return join(concat([], fermentables, hops, cultures), ', ')
}

export const RecipeJsonLd = ({ recipe, image }) => {
  const resolveStatic = useStaticResolver()
  const defaultImage = resolveStatic('/og-image.png')
  const recipeImage = image || defaultImage

  const title = `${recipe.name} - Brewcomputer`
  const ingredients = joinIngredients(recipe.ingredients)
  const ogDescription = `${recipe.name} by ${recipe.author} | Brewcomputer - Beer brewing tools online`
  const description = `${recipe.name} (${recipe.style?.name} ${recipe.style?.category}) by ${recipe.author}, ${ingredients}`
  const keywords = `${recipe.style?.name}, ${recipe.style?.category}, ${ingredients}`

  return (
    <Helmet
      meta={[
        {
          name: 'og:title',
          content: title,
        },
        {
          name: 'og:description',
          content: ogDescription,
        },
        {
          name: 'og:image',
          content: recipeImage,
        },
        {
          name: 'description',
          content: description,
        },
        {
          name: 'keywords',
          content: keywords,
        },
      ]}
    />
  )
}
