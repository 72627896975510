import { includes, get } from 'lodash'
import { all, takeEvery } from 'redux-saga/effects'

import { logout } from '../../../auth/endpoints'

import { recipeCreateAction } from '../actions/recipe'
import { batchCreateAction } from '../actions/batch'
import { userAddEquipmentAction } from '../actions/user'

import { getBatchLink, getRecipeLink, links } from '../../links'

function* navigate(history, action) {
  switch (action.type) {
    case recipeCreateAction.types.request:
      history.push(getRecipeLink(action.payload.recipeId))
      break

    case batchCreateAction.types.request:
      history.push(getBatchLink(action.payload.recipeId))
      break

    case userAddEquipmentAction.types.request: {
      if (!includes(history, links.equipment)) {
        history.push(links.equipment)
      }
      break
    }

    default:
      break
  }
}

function isUnauthorized(action) {
  return (
    includes(action.type, 'FAILURE') &&
    get(action, 'payload.error.code') === 401
  )
}

function* logOutUnauthorized() {
  window.location.replace(logout.logout_link)
}

export default function*(history) {
  yield all([
    yield takeEvery(
      [
        recipeCreateAction.types.request,
        batchCreateAction.types.request,
        userAddEquipmentAction.types.request
      ],
      navigate,
      history
    ),
    yield takeEvery(isUnauthorized, logOutUnauthorized, history)
  ])
}
