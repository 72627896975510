import { isEmpty } from 'lodash'
import React, { PureComponent } from 'react'
import PropTypes from 'prop-types'
import styled, { css } from 'styled-components'
import { links } from '../../links'
import { LeftSideLink, NavLinks, UserProfileLink } from './Links'
import { Page, Popup, Alert, colors } from '@brewcomputer/ui'
import { ToolsPanel } from '../tools/ToolsPanel'
import { Feedback } from '../feedback/Feedback'
import { Footer } from '../specialPages/Footer'
import { titles } from '../../texts/index'
import TitleConstructor from '../TitleConstructor'

const StyledPopup = styled(Popup)``

const Container = styled.div`
  flex: 1;
  flex-direction: column;
  padding-bottom: 55px;
  padding-top: 44px;

  ${StyledPopup} {
    top: 44px;
    bottom: 55px;
  }

  @media (min-width: 992px) {
    padding-top: 60px;
    padding-bottom: 0;

    ${StyledPopup} {
      top: 60px;
      bottom: 0;
    }
  }
`

const navBarStyle = css`
  display: flex;
  position: fixed;
  z-index: 1000;
  left: 0;
  right: 0;
  background-color: ${colors.WHITE};
  flex-direction: row;

  @media print {
    display: none;
  }
`

const TopNavBar = styled.nav`
  ${navBarStyle};
  top: 0;
  height: 44px;
  padding: 0 16px;
  justify-content: space-between;
  border-bottom: 1px solid ${colors.DIVIDER};

  @media (min-width: 992px) {
    height: 60px;
    padding: 0 32px;
  }
`

const BottomNavBar = styled.nav`
  ${navBarStyle};
  height: 55px;
  bottom: 0;
  border-top: 1px solid ${colors.DIVIDER};
  & > a {
    width: 25%;
  }

  @media (min-width: 992px) {
    display: none;
  }
`

const TopNavContainer = styled.div`
  flex: 1;
  flex-direction: row;
  @media (max-width: 992px) {
    display: none;
  }
`

const Title = styled.div`
  flex: 1;
  text-align: center;
  justify-content: center;
  font-size: 15px;
  line-height: 22px;
  padding-left: 32px;
  color: ${colors.BLACK};
  @media (min-width: 992px) {
    display: none;
  }
`

export class Navigator extends PureComponent {
  static displayName = 'Navigator'
  static propTypes = {
    location: PropTypes.object,
    user: PropTypes.object,
    tabs: PropTypes.object,
    alert: PropTypes.object,
    isAuthorized: PropTypes.bool,
    isAdmin: PropTypes.bool,
    isAnonymous: PropTypes.bool,
    onChangeLocation: PropTypes.func,
    hideAlert: PropTypes.func
  }

  constructor(props) {
    super(props)
    this.toolsPopupRef = React.createRef()
    this.feedbackPopupRef = React.createRef()
    this.state = {
      isToolsPopupVisible: false,
      isFeedbackPopupVisible: false,
      showFeedbackInput: false
    }
    this._historyUnlisten = null
  }

  componentDidMount() {
    this.props.onChangeLocation(this.props.history.location)
    this._historyUnlisten = this.props.history.listen(
      this.props.onChangeLocation
    )
  }

  componentWillUnmount() {
    if (this._historyUnlisten) {
      this._historyUnlisten()
    }
  }

  getTitle = () => {
    const { location } = this.props

    switch (location.pathname) {
      case links.settings:
        return titles.Settings
      case links.batches:
        return titles.BatchesList
      case links.recipes:
        return titles.RecipesList
      case links.myRecipes:
        return titles.MyRecipesList
      case links.equipment:
        return titles.EquipmentProfile
      default:
        return ''
    }
  }

  onFeedbackShow = () => {
    this.setState({ isFeedbackPopupVisible: true })
  }

  onFeedbackShowAnimationEnd = () => {
    this.setState({ showFeedbackInput: true })
  }

  onFeedbackHide = () => {
    this.setState({ showFeedbackInput: false })
  }

  onFeedbackHideAnimationEnd = () => {
    this.setState({ isFeedbackPopupVisible: false })
  }

  onToolsPopupShow = () => {
    this.setState({ isToolsPopupVisible: true })
  }

  onToolsPopupHide = () => {
    this.setState({ isToolsPopupVisible: false })
  }

  onFeedbackButtonPress = () => {
    if (this.state.isToolsPopupVisible) {
      this.toolsPopupRef.current.hide()
    }
    this.feedbackPopupRef.current.toggle()
  }

  onFeedbackPanelCloseButtonPress = () => {
    this.feedbackPopupRef.current.toggle()
  }

  onToolsButtonPress = () => {
    if (this.state.isFeedbackPopupVisible) {
      this.feedbackPopupRef.current.hide()
    }
    this.toolsPopupRef.current.toggle()
  }

  onToolsPanelCloseButtonPress = () => {
    this.toolsPopupRef.current.toggle()
  }

  render() {
    const {
      location,
      tabs,
      children,
      user,
      alert,
      isAuthorized,
      isAdmin,
      isAnonymous,
      hideAlert
    } = this.props
    const {
      isToolsPopupVisible,
      isFeedbackPopupVisible,
      showFeedbackInput
    } = this.state

    return (
      <Container>
        <TopNavBar id="top-nav-bar">
          <LeftSideLink tabs={tabs} />
          <TopNavContainer>
            <NavLinks
              showAdminLink={isAdmin}
              showFeedbackButton={isAuthorized}
              location={location}
              tabs={tabs}
              placement={'top'}
              isToolsButtonActive={isToolsPopupVisible}
              onToolsButtonPress={this.onToolsButtonPress}
              isFeedbackButtonActive={isFeedbackPopupVisible}
              onFeedbackButtonPress={this.onFeedbackButtonPress}
            />
          </TopNavContainer>
          <Title>{this.getTitle()}</Title>
          <TitleConstructor pageTitle={this.getTitle()} />
          <UserProfileLink avatar={user.avatar} isAnonymous={isAnonymous} />
        </TopNavBar>
        <Page>
          {!isEmpty(alert) && (
            <Alert
              type={alert.type}
              title={alert.title}
              message={alert.message}
              onClose={hideAlert}
            />
          )}
          {children}
          <StyledPopup
            ref={this.toolsPopupRef}
            onShowAnimationEnd={this.onToolsPopupShow}
            onHide={this.onToolsPopupHide}>
            <ToolsPanel
              onCloseButtonPress={this.onToolsPanelCloseButtonPress}
            />
          </StyledPopup>
          {isAuthorized && (
            <StyledPopup
              ref={this.feedbackPopupRef}
              onHide={this.onFeedbackHide}
              onHideAnimationEnd={this.onFeedbackHideAnimationEnd}
              onShow={this.onFeedbackShow}
              onShowAnimationEnd={this.onFeedbackShowAnimationEnd}>
              {isFeedbackPopupVisible && (
                <Feedback
                  author={user}
                  treeId={user.id}
                  isAuthorized={isAuthorized}
                  showInput={showFeedbackInput}
                  onClose={this.onFeedbackPanelCloseButtonPress}
                />
              )}
            </StyledPopup>
          )}
        </Page>
        <Footer />
        <BottomNavBar id="bottom-nav-bar">
          <NavLinks
            showFeedbackButton={isAuthorized}
            location={location}
            tabs={tabs}
            placement={'bottom'}
            isToolsButtonActive={isToolsPopupVisible}
            onToolsButtonPress={this.onToolsButtonPress}
            isFeedbackButtonActive={isFeedbackPopupVisible}
            onFeedbackButtonPress={this.onFeedbackButtonPress}
          />
        </BottomNavBar>
      </Container>
    )
  }
}
