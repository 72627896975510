import { set, isEmpty } from 'lodash'
import React, { useState, useCallback, useEffect } from 'react'
import PropTypes from 'prop-types'
import { AccentButton, Card, Label, MeasurableEditor } from '@brewcomputer/ui'
import styled, { css } from 'styled-components'

export const Container = styled(Card)`
  flex-grow: 1;
  flex-direction: row;
  justify-content: space-around;
  border-radius: 4px;
  margin-top: 14px;
  padding: 10px 20px;

  @media (max-width: 576px) {
    flex-direction: column;
    align-items: stretch;
  }
`

const Row = styled.div`
  flex-direction: row;
  align-items: flex-end;
  @media (max-width: 576px) {
    flex-direction: column;
    align-items: stretch;
    margin-bottom: 24px;
  }
`

const responsiveStyles = css`
  margin-left: 16px;
  @media (max-width: 576px) {
    margin-top: 8px;
    margin-left: 0;
    align-self: flex-start;
  }
`

const ScaleLabel = styled(Label)`
  ${responsiveStyles};
`

const ScaleButton = styled(AccentButton)`
  ${responsiveStyles};
`

const scaleButtonStates = {
  hidden: 'HIDDEN',
  button: 'BUTTON',
  label: 'LABEL'
}

const labelTimeout = 2000

export const BatchInfo = ({
  batchSize = {},
  efficiency = { brewhouse: {} },
  valueReadOnly,
  onEditRecipe,
  onScaleRecipe
}) => {
  if (isEmpty(batchSize) || isEmpty(efficiency.brewhouse)) {
    return null
  }

  const [scaleButtonState, setScaleButtonState] = useState(
    scaleButtonStates.hidden
  )
  const [lastBatchSize, setLastBatchSize] = useState(batchSize)

  const onChange = useCallback((path, value) => {
    const changes = {}
    set(changes, path, value)
    onEditRecipe(changes)
  }, [])

  const onChangeBatchSize = useCallback(
    value => {
      setLastBatchSize(batchSize)
      setScaleButtonState(scaleButtonStates.button)
      onChange('batch_size', value)
    },
    [batchSize]
  )

  const onChangeEfficiency = useCallback(value => {
    onChange('efficiency.brewhouse', value)
  }, [])

  const scaleRecipe = useCallback(
    () => {
      onScaleRecipe({
        from: { batch_size: lastBatchSize },
        to: { batch_size: batchSize }
      })
      setScaleButtonState(scaleButtonStates.label)
    },
    [batchSize, lastBatchSize]
  )

  useEffect(
    () => {
      if (scaleButtonState === scaleButtonStates.label) {
        setTimeout(() => {
          setScaleButtonState(scaleButtonStates.hidden)
        }, labelTimeout)
      }
    },
    [scaleButtonState]
  )

  const scaleButtonTitle = `Scale ingredients`

  return (
    <Container>
      <Row>
        <MeasurableEditor
          title="Batch Size"
          value={batchSize.value}
          unit={batchSize.unit}
          units={batchSize.units}
          onChange={onChangeBatchSize}
          valueReadOnly={valueReadOnly}
        />
        {scaleButtonState === scaleButtonStates.button && (
          <ScaleButton
            title={scaleButtonTitle}
            accessibilityLabel={scaleButtonTitle}
            size="small"
            onPress={scaleRecipe}
          />
        )}
        {scaleButtonState === scaleButtonStates.label && (
          <ScaleLabel title="Scaled!" />
        )}
      </Row>
      <MeasurableEditor
        title="Brewhouse Efficiency"
        value={efficiency.brewhouse.value}
        unit={efficiency.brewhouse.unit}
        units={efficiency.brewhouse.units}
        onChange={onChangeEfficiency}
        valueReadOnly={valueReadOnly}
      />
    </Container>
  )
}

BatchInfo.propTypes = {
  valueReadOnly: PropTypes.bool,
  batchSize: PropTypes.object,
  efficiency: PropTypes.object,
  onEditRecipe: PropTypes.func,
  onScaleRecipe: PropTypes.func
}

BatchInfo.displayName = 'BatchInfo'
