import createCommentsModuleReducer from '@resolve-js/module-comments/lib/client/reducers/comments'

import saga from './redux/sagas/index.js'
import alert from './redux/reducers/alert.js'
import auth from './redux/reducers/auth.js'
import recipes from './redux/reducers/recipes.js'
import publicRecipes from './redux/reducers/publicRecipes.js'
import myRecipes from './redux/reducers/myRecipes.js'
import recipesSearch from './redux/reducers/recipesSearch.js'
import batches from './redux/reducers/batches.js'
import user from './redux/reducers/user.js'
import equipment from './redux/reducers/equipment.js'
import navigation from './redux/reducers/navigation.js'
import batchCheckLists from './redux/reducers/batchCheckLists'

import cachedUnitSettings from './redux/middlewares/cachedUnitSettings'
import convertRecipes from './redux/middlewares/convertRecipes'
import checkListOptimistic from './redux/middlewares/checkListOptimistic'

import devtools from './redux/enhancers/devtools'

const getRedux = (
  { commentsModule: getCommentsOptions, feedbacksModule: getFeedbacksOptions },
  history
) => {
  const {
    reducerName: commentsReducerName,
    ...commentsOptions
  } = getCommentsOptions()

  const {
    reducerName: feedbacksReducerName,
    ...feedbackOptions
  } = getFeedbacksOptions()

  return {
    sagas: [saga.bind(null, history)],
    reducers: {
      jwt: (jwt = null) => jwt,
      [commentsReducerName]: createCommentsModuleReducer(commentsOptions),
      [feedbacksReducerName]: createCommentsModuleReducer(feedbackOptions),
      alert,
      auth,
      recipes,
      publicRecipes,
      myRecipes,
      batches,
      recipesSearch,
      user,
      equipment,
      navigation,
      batchCheckLists
    },
    middlewares: [cachedUnitSettings, convertRecipes, checkListOptimistic],
    enhancers: [devtools]
  }
}

export default getRedux
