import { get, isNumber, map } from 'lodash'
import React, { useCallback } from 'react'
import PropTypes from 'prop-types'
import { UnitPicker } from '../unitPicker/UnitPicker'
import styled from 'styled-components'
import { textStyles } from '../texts/textStyles'
import { Card } from '../card/Card'
import { colors } from '../colors/colors'
import figmaColors from '../colors/figmaColors'

const List = styled.div`
  flex-wrap: wrap;
  flex-direction: row;
  margin: 0 -8px;
`

const Row = styled.div`
  flex-direction: row;
  align-items: center;
`

const Text = styled.div`
  ${textStyles.body2};
  color: ${colors.BLACK};
`

const RangeText = styled.div`
  margin-top: -6px;
  ${textStyles.body2};
  color: ${colors.ACCENT_GRAY};
`

const Caption = styled(Text)`
  padding: 0 10px;
`

const Display = styled(Card)`
  box-sizing: border-box;
  position: relative;
  align-items: center;
  padding: 10px 0;
  border: 2px solid ${props => props.borderColor};
  background-color: ${props => get(props, 'backgroundColor', colors.WHITE)};
  width: 14%;
  margin: 8px;
  flex-grow: 1;
  flex-shrink: 1;

  @media (min-width: 577px) and (max-width: 992px) {
    width: 30%;
  }
  @media (max-width: 576px) {
    width: 45%;
  }
`

const Warning = styled.div`
  position: absolute;
  padding: 0 4px;
  top: 8px;
  right: 8px;
  color: ${props => props.color};
  cursor: help;
  &::before {
    content: '!';
  }
`

function getWarningProps(caption, value, min, max) {
  const noWarning = { color: 'transparent', title: null }

  if (!isNumber(min) || !isNumber(max) || !isNumber(value)) {
    return noWarning
  }

  const warningBorder = (max - min) * 0.2
  if (value <= min - warningBorder || value >= max + warningBorder) {
    return {
      color: figmaColors.red_500,
      title: `The ${caption} does not match the selected style.`
    }
  }
  if (value <= min || value >= max) {
    return {
      color: figmaColors.amber_500,
      title: `The ${caption} deviates from the selected style.`
    }
  }
  return noWarning
}

const stringifyValue = value =>
  isNumber(value) ? value.toString() : String.fromCharCode(0x2013)

const getRangeText = (minStyleValue, maxStyleValue) => {
  if (!isNumber(minStyleValue) || !isNumber(maxStyleValue)) {
    return null
  }
  return `${minStyleValue} ${String.fromCharCode(0x2013)} ${maxStyleValue}`
}

const Stat = ({
  caption,
  value,
  unit,
  units,
  path,
  minStyleValue,
  maxStyleValue,
  onChangeUnit
}) => {
  const { color: warningColor, title: warningTitle } = getWarningProps(
    caption,
    value,
    minStyleValue,
    maxStyleValue
  )
  const rangeText = getRangeText(minStyleValue, maxStyleValue)
  const onChange = useCallback(u => onChangeUnit({ [path]: u }), [path])

  return (
    <Display key={caption} borderColor={warningColor}>
      <Row>
        <Caption>{caption}</Caption>
        <Text>{stringifyValue(value)}</Text>
        <UnitPicker
          showValue
          units={units}
          selectedUnit={unit}
          onChange={onChange}
        />
      </Row>
      {rangeText && <RangeText>{rangeText}</RangeText>}
      {warningTitle && <Warning title={warningTitle} color={warningColor} />}
    </Display>
  )
}

export const Stats = ({ stats, color, onChangeUnit }) => {
  return (
    <List>
      {map(stats, props => (
        <Stat
          key={props.key}
          path={props.key}
          {...props}
          onChangeUnit={onChangeUnit}
        />
      ))}
      <Display borderColor={'transparent'} backgroundColor={color} />
    </List>
  )
}

Stats.propTypes = {
  stats: PropTypes.arrayOf(
    PropTypes.shape({
      key: PropTypes.string,
      caption: PropTypes.string,
      value: PropTypes.number,
      minStyleValue: PropTypes.number,
      maxStyleValue: PropTypes.number,
      unit: PropTypes.string
    })
  ).isRequired,
  color: PropTypes.string,
  onChangeUnit: PropTypes.func
}
