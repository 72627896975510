const RECIPE_CREATED = 'RECIPE_CREATED'
const RECIPE_EDITED = 'RECIPE_EDITED'
const RECIPE_REMOVED = 'RECIPE_REMOVED'

const RECIPE_PRIVACY_EDITED = 'RECIPE_PRIVACY_EDITED'

const RECIPE_BATCH_EDITED = 'RECIPE_BATCH_EDITED'
const RECIPE_BATCH_CHECK_LIST_EDITED = 'RECIPE_BATCH_CHECK_LIST_EDITED'
const RECIPE_BATCH_STATUS_CHANGED = 'RECIPE_BATCH_STATUS_CHANGED'
const RECIPE_BATCH_MEASURED_EDITED = 'RECIPE_BATCH_MEASURED_EDITED'

const RECIPE_BATCH_CHECK_LIST_CHECKED = 'RECIPE_BATCH_CHECK_LIST_CHECKED'
const RECIPE_INGREDIENT_CHECK_LIST_CHECKED =
  'RECIPE_INGREDIENT_CHECK_LIST_CHECKED'
const RECIPE_PROCEDURE_CHECK_LIST_CHECKED =
  'RECIPE_PROCEDURE_CHECK_LIST_CHECKED'

const RECIPE_INGREDIENT_ADDED = 'RECIPE_INGREDIENT_ADDED'
const RECIPE_INGREDIENT_EDITED = 'RECIPE_INGREDIENT_EDITED'
const RECIPE_INGREDIENT_REMOVED = 'RECIPE_INGREDIENT_REMOVED'

const RECIPE_PROCEDURE_ADDED = 'RECIPE_PROCEDURE_ADDED'
const RECIPE_PROCEDURE_EDITED = 'RECIPE_PROCEDURE_EDITED'
const RECIPE_PROCEDURE_REMOVED = 'RECIPE_PROCEDURE_REMOVED'

const RECIPE_EQUIPMENT_EDITED = 'RECIPE_EQUIPMENT_EDITED'
const RECIPE_BEER_STYLE_EDITED = 'RECIPE_BEER_STYLE_EDITED'

const RECIPE_SCALED = 'RECIPE_SCALED'

const RECIPE_IMAGE_ADDED = 'RECIPE_IMAGE_ADDED'
const RECIPE_IMAGE_REMOVED = 'RECIPE_IMAGE_REMOVED'

const BEER_STYLE_CREATED = 'BEER_STYLE_CREATED'
const INGREDIENT_CREATED = 'INGREDIENT_CREATED'
const TEMPLATE_PROCEDURE_CREATED = 'TEMPLATE_PROCEDURE_CREATED'

const USER_CREATED = 'USER_CREATED'
const USER_UPDATED = 'USER_UPDATED'
const USER_ROLE_EDITED = 'USER_ROLE_EDITED'
const USER_EMAIL_EDITED = 'USER_EMAIL_EDITED'
const USER_EDITED = 'USER_EDITED'
const USER_PREFERENCES_EDITED = 'USER_PREFERENCES_EDITED'
const USER_UNIT_SETTINGS_EDITED = 'USER_UNIT_SETTINGS_EDITED'
const USER_EQUIPMENT_ADDED = 'USER_EQUIPMENT_ADDED'
const USER_EQUIPMENT_EDITED = 'USER_EQUIPMENT_EDITED'
const USER_EQUIPMENT_REMOVED = 'USER_EQUIPMENT_REMOVED'
const USER_DELETED = 'USER_DELETED'

const FEEDBACK_CREATED = 'FEEDBACK_CREATED'
const FEEDBACK_UPDATED = 'FEEDBACK_UPDATED'
const FEEDBACK_REMOVED = 'FEEDBACK_REMOVED'

module.exports = {
  RECIPE_CREATED,
  RECIPE_EDITED,
  RECIPE_REMOVED,
  RECIPE_PRIVACY_EDITED,
  RECIPE_INGREDIENT_ADDED,
  RECIPE_INGREDIENT_EDITED,
  RECIPE_INGREDIENT_REMOVED,
  RECIPE_PROCEDURE_ADDED,
  RECIPE_PROCEDURE_EDITED,
  RECIPE_PROCEDURE_REMOVED,
  RECIPE_BEER_STYLE_EDITED,
  RECIPE_EQUIPMENT_EDITED,
  // batch
  RECIPE_BATCH_EDITED,
  RECIPE_BATCH_STATUS_CHANGED,
  RECIPE_BATCH_MEASURED_EDITED,
  // check list
  RECIPE_BATCH_CHECK_LIST_EDITED,
  RECIPE_BATCH_CHECK_LIST_CHECKED,
  RECIPE_INGREDIENT_CHECK_LIST_CHECKED,
  RECIPE_PROCEDURE_CHECK_LIST_CHECKED,
  // scale recipe
  RECIPE_SCALED,
  //images
  RECIPE_IMAGE_ADDED,
  RECIPE_IMAGE_REMOVED,
  // styles
  BEER_STYLE_CREATED,
  //ingredients
  INGREDIENT_CREATED,
  // templates
  TEMPLATE_PROCEDURE_CREATED,
  // user
  USER_CREATED,
  USER_UPDATED,
  USER_ROLE_EDITED,
  USER_EMAIL_EDITED,
  USER_EDITED,
  USER_PREFERENCES_EDITED,
  USER_UNIT_SETTINGS_EDITED,
  USER_EQUIPMENT_ADDED,
  USER_EQUIPMENT_EDITED,
  USER_EQUIPMENT_REMOVED,
  USER_DELETED,
  // feedback
  FEEDBACK_CREATED,
  FEEDBACK_UPDATED,
  FEEDBACK_REMOVED,
}
