import shortId from 'shortid'
import {
  recipeAddIngredientAction,
  recipeCreateAction,
  recipeEditAction,
  recipeEditBeerStyleAction,
  recipeEditEquipmentAction,
  recipeEditIngredientAction,
  recipeEditPrivacyAction,
  recipeEditProcedureAction,
  recipeRemoveAction,
  recipeRemoveIngredientAction,
  recipeScaleAction,
} from '../redux/actions/recipe'
import { addAdditionDefaults } from '../../common/defaults'
import { defaultEquipment } from '../../common/defaults/equipment'
import {
  prepareForBrewing,
  prepareForCloning,
  recursiveRemoveFields,
} from '@brewcomputer/utils'
import {
  batchChangeStatusAction,
  batchCreateAction,
  batchEditAction,
  batchCheckListCheckAction,
  batchIngredientCheckAction,
  batchProcedureCheckAction,
  batchRemoveAction,
  batchEditMeasuredAction,
} from '../redux/actions/batch'
import {
  userAddEquipmentAction,
  userEditAction,
  userEditEquipmentAction,
  userEditUnitSettingsAction,
  userRemoveEquipmentAction,
} from '../redux/actions/user'

import { useReduxCommand } from '@resolve-js/redux'

export const useCreateRecipe = (recipe, user) => {
  const newRecipe = {
    ...recipe,
    created: new Date(),
    author: user.name,
    authorId: user.id,
  }

  const { execute } = useReduxCommand(
    () => ({
      aggregateName: 'Recipe',
      type: 'recipeCreate',
      aggregateId: shortId.generate(),
      payload: newRecipe,
    }),
    { actions: recipeCreateAction }
  )

  return execute
}

export const useImportRecipe = (user) => {
  const { execute } = useReduxCommand(
    (recipe) => ({
      aggregateName: 'Recipe',
      type: 'recipeImport',
      aggregateId: shortId.generate(),
      payload: { ...recipe, authorId: user.id },
    }),
    { actions: recipeCreateAction }
  )
  return execute
}

export const useCloneRecipe = (recipe, user) => {
  const clonedRecipe = {
    ...prepareForCloning(recipe),
    created: new Date(),
    author: user.name,
    authorId: user.id,
  }

  const { execute } = useReduxCommand(
    () => ({
      aggregateName: 'Recipe',
      type: 'recipeClone',
      aggregateId: shortId.generate(),
      payload: clonedRecipe,
    }),
    { actions: recipeCreateAction }
  )
  return execute
}

export const useBatchCreate = (
  recipe,
  user,
  batchesCount,
  isOwnRecipe = false
) => {
  const filteredRecipe = isOwnRecipe
    ? prepareForBrewing(recipe)
    : prepareForCloning(recipe)

  const batchRecipe = {
    ...filteredRecipe,
    name: `${recipe.name} Batch`,
    batch: {
      number: batchesCount + 1,
      name: `${recipe.name} Batch`,
      date: new Date(),
      author: user.name,
    },
  }
  const { execute } = useReduxCommand(
    () => ({
      aggregateName: 'Recipe',
      type: 'recipeBatchCreate',
      aggregateId: shortId.generate(),
      payload: batchRecipe,
    }),
    { actions: batchCreateAction }
  )
  return execute
}

export const useRemoveBatch = () => {
  const { execute } = useReduxCommand(
    (batchId) => ({
      type: 'recipeRemove',
      aggregateId: batchId,
      aggregateName: 'Recipe',
    }),
    { actions: batchRemoveAction }
  )
  return execute
}

export const useEditRecipe = (recipeId) => {
  const { execute } = useReduxCommand(
    (changes) => ({
      type: 'recipeEdit',
      aggregateId: recipeId,
      aggregateName: 'Recipe',
      payload: changes,
    }),
    { actions: recipeEditAction }
  )
  return execute
}

export const useScaleRecipe = (recipeId) => {
  const { execute } = useReduxCommand(
    (changes) => ({
      type: 'recipeScale',
      aggregateId: recipeId,
      aggregateName: 'Recipe',
      payload: changes,
    }),
    { actions: recipeScaleAction }
  )
  return execute
}

export const useEditRecipePrivacy = (recipeId) => {
  const { execute } = useReduxCommand(
    (settings) => ({
      aggregateName: 'Recipe',
      type: 'recipePrivacyEdit',
      aggregateId: recipeId,
      payload: settings,
    }),
    { actions: recipeEditPrivacyAction }
  )
  return execute
}

export const useRemoveRecipe = () => {
  const { execute } = useReduxCommand(
    (recipeId) => ({
      type: 'recipeRemove',
      aggregateId: recipeId,
      aggregateName: 'Recipe',
      payload: {},
    }),
    { actions: recipeRemoveAction }
  )
  return execute
}

export const useAddIngredient = (recipeId, ingredientType) => {
  const { execute } = useReduxCommand(
    (data) => ({
      aggregateName: 'Recipe',
      type: 'recipeIngredientAdd',
      aggregateId: recipeId,
      payload: {
        id: shortId.generate(),
        data: addAdditionDefaults(data, ingredientType),
        ingredientType,
      },
    }),
    { actions: recipeAddIngredientAction }
  )
  return execute
}

export const useEditIngredient = (recipeId, predefinedIngredientType) => {
  const { execute } = useReduxCommand(
    ({ id, changes, ingredientType }) => ({
      aggregateName: 'Recipe',
      type: 'recipeIngredientEdit',
      aggregateId: recipeId,
      payload: {
        id,
        changes,
        ingredientType: predefinedIngredientType || ingredientType,
      },
    }),
    { actions: recipeEditIngredientAction }
  )
  return execute
}

export const useRemoveIngredient = (recipeId, ingredientType) => {
  const { execute } = useReduxCommand(
    (id) => ({
      aggregateName: 'Recipe',
      type: 'recipeIngredientRemove',
      aggregateId: recipeId,
      payload: {
        id,
        ingredientType,
      },
    }),
    { actions: recipeRemoveIngredientAction }
  )
  return execute
}

export const useRecipeAddImage = (recipeId) => {
  const { execute } = useReduxCommand((uploadId) => ({
    aggregateName: 'Recipe',
    type: 'recipeAddImage',
    aggregateId: recipeId,
    payload: {
      uploadId,
    },
  }))
  return execute
}

export const useRecipeRemoveImage = (recipeId) => {
  const { execute } = useReduxCommand((uploadId) => ({
    aggregateName: 'Recipe',
    type: 'recipeRemoveImage',
    aggregateId: recipeId,
    payload: {
      uploadId,
    },
  }))
  return execute
}

export const useEditBeerStyle = (recipeId) => {
  const { execute } = useReduxCommand(
    (beerStyle) => ({
      aggregateName: 'Recipe',
      type: 'recipeBeerStyleEdit',
      aggregateId: recipeId,
      payload: beerStyle,
    }),
    { actions: recipeEditBeerStyleAction }
  )
  return execute
}

export const useEditProcedure = (recipeId, procedureType) => {
  const { execute } = useReduxCommand(
    (data) => ({
      aggregateName: 'Recipe',
      type: 'recipeProcedureEdit',
      aggregateId: recipeId,
      payload: {
        data,
        procedureType,
      },
    }),
    { actions: recipeEditProcedureAction }
  )
  return execute
}

export const useEditRecipeEquipment = (recipeId) => {
  const { execute } = useReduxCommand(
    (equipment) => ({
      aggregateName: 'Recipe',
      type: 'recipeEquipmentEdit',
      aggregateId: recipeId,
      payload: equipment,
    }),
    { actions: recipeEditEquipmentAction }
  )
  return execute
}

export const useBatchEdit = (batchId) => {
  const { execute } = useReduxCommand(
    (changes) => ({
      aggregateName: 'Recipe',
      type: 'recipeBatchEdit',
      aggregateId: batchId,
      payload: changes,
    }),
    { actions: batchEditAction }
  )
  return execute
}

export const useRecipeBatchMeasuredEdit = (batchId) => {
  const { execute } = useReduxCommand(
    (changes) => ({
      aggregateName: 'Recipe',
      type: 'recipeBatchMeasuredEdit',
      aggregateId: batchId,
      payload: recursiveRemoveFields(changes, 'units'),
    }),
    { actions: batchEditMeasuredAction }
  )
  return execute
}

export const useRecipeBatchCheckListSetChecked = (batchId) => {
  const { execute } = useReduxCommand(
    (changes) => ({
      aggregateId: batchId,
      aggregateName: 'Recipe',
      type: 'recipeBatchCheckListSetChecked',
      payload: changes,
    }),
    { actions: batchCheckListCheckAction }
  )
  return execute
}

export const useRecipeIngredientCheckListSetChecked = (batchId) => {
  const { execute } = useReduxCommand(
    ({ id, ingredientType, changes }) => ({
      aggregateId: batchId,
      aggregateName: 'Recipe',
      type: 'recipeIngredientCheckListSetChecked',
      payload: { id, ingredientType, changes },
    }),
    { actions: batchIngredientCheckAction }
  )
  return execute
}

export const useRecipeProcedureCheckListSetChecked = (batchId) => {
  const { execute } = useReduxCommand(
    ({ procedureType, steps }) => ({
      aggregateId: batchId,
      aggregateName: 'Recipe',
      type: 'recipeProcedureCheckListSetChecked',
      payload: { procedureType, steps },
    }),
    { actions: batchProcedureCheckAction }
  )
  return execute
}

export const useBatchChangeStatus = (batchId) => {
  const { execute } = useReduxCommand(
    (status) => ({
      aggregateName: 'Recipe',
      type: 'recipeBatchStatusChange',
      aggregateId: batchId,
      payload: { status },
    }),
    { actions: batchChangeStatusAction }
  )
  return execute
}

export const useAddEquipment = (userId) => {
  const { execute } = useReduxCommand(
    () => ({
      aggregateName: 'User',
      type: 'userAddEquipment',
      aggregateId: userId,
      payload: {
        id: shortId.generate(),
        data: defaultEquipment,
      },
    }),
    { actions: userAddEquipmentAction }
  )
  return execute
}

export const useEditEquipment = (userId) => {
  const { execute } = useReduxCommand(
    ({ id, changes }) => ({
      aggregateName: 'User',
      type: 'userEditEquipment',
      aggregateId: userId,
      payload: {
        id,
        changes,
      },
    }),
    { actions: userEditEquipmentAction }
  )
  return execute
}

export const useUserRemoveEquipment = (userId) => {
  const { execute } = useReduxCommand(
    (id) => ({
      aggregateName: 'User',
      type: 'userRemoveEquipment',
      aggregateId: userId,
      payload: { id },
    }),
    { actions: userRemoveEquipmentAction }
  )
  return execute
}

export const useUserEdit = (userId) => {
  const { execute } = useReduxCommand(
    (changes) => ({
      aggregateName: 'User',
      type: 'userEdit',
      aggregateId: userId,
      payload: changes,
    }),
    { actions: userEditAction }
  )
  return execute
}

export const useUserEditUnitSettings = (userId) => {
  const { execute } = useReduxCommand(
    (changes) => ({
      aggregateName: 'User',
      type: 'userEditUnitSettings',
      aggregateId: userId,
      payload: changes,
    }),
    { actions: userEditUnitSettingsAction }
  )
  return execute
}
