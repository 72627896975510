import { css } from 'styled-components'

export const textStyles = {
  H1: css`
    font-weight: 500;
    font-size: 24px;
    line-height: 36px;
  `,
  H2: css`
    font-weight: 500;
    font-size: 16px;
    line-height: 26px;
  `,
  H3: css`
    font-weight: 600;
    font-size: 16px;
    line-height: 26px;
  `,
  body1: css`
    font-weight: normal;
    font-size: 15px;
    line-height: 24px;
  `,
  body2: css`
    font-weight: normal;
    font-size: 13px;
    line-height: 20px;
  `,
  title: css`
    font-weight: 600;
    font-size: 13px;
    line-height: 20px;
  `,
  title2: css`
    font-weight: 500;
    font-size: 13px;
    line-height: 19px;
  `,
  title3: css`
    font-weight: normal;
    font-size: 13px;
    line-height: 19px;
  `,
  smallText: css`
    font-weight: 500;
    font-size: 11px;
    line-height: 16px;
  `
}
