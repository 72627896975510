import { map, orderBy } from 'lodash'
import styled from 'styled-components'
import React, { useEffect } from 'react'
import { LoadingIndicator } from '../LoadingIndicator'
import { textStyles, colors, Avatar } from '@brewcomputer/ui'
import { Link } from 'react-router-dom'
import { useSelector } from 'react-redux'
import { useGetUserActivity } from '../../hooks/queries'
import { listItemStyle } from './styles'

const UserLink = styled(Link)`
  height: 64px;
  ${listItemStyle};
`

const UserProfile = styled.div`
  flex-direction: row;
  align-items: center;
  width: 40%;
`

const UserName = styled.div`
  ${textStyles.body1};
  color: ${colors.BLACK};
  font-weight: 500;
  margin-left: 24px;
`

const UserFields = styled.div`
  width: 60%;
  ${textStyles.body2};
  color: ${colors.TABLE_GRAY};
  margin-top: 5px;
  flex-direction: row;
`

const UserField = styled.div`
  width: 25%;
  justify-content: center;
`

const Date = styled.h2`
  padding: 8px 0;
`

export const MonthlyData = ({ data }) => {
  const sorted = orderBy(
    map(data.users, (user, userId) => ({ userId, ...user })),
    'count',
    'desc'
  )

  return (
    <div>
      <Date>{`${data.month + 1} - ${data.year}`}</Date>
      {map(sorted, (user) => (
        <UserLink key={user.userId} to={`/admin/user/${user.userId}`}>
          <UserProfile>
            <Avatar src={user.avatar} size={32} alt={user.name} />
            <UserName>{user.name}</UserName>
          </UserProfile>
          <UserFields>
            <UserField>{user.count}</UserField>
          </UserFields>
        </UserLink>
      ))}
    </div>
  )
}

export const AdminUserActivity = () => {
  const { request: getUserStats, selector } = useGetUserActivity({})

  useEffect(() => {
    getUserStats()
  }, [])

  const { data, status } = useSelector(selector)

  if (status !== 'ready') {
    return <LoadingIndicator />
  }

  return (
    <div>
      {map(data, (monthlyData) => (
        <MonthlyData key={monthlyData.id} data={monthlyData} />
      ))}
    </div>
  )
}
