import styled from 'styled-components'
import { SocialLink } from '@brewcomputer/ui'
import React from 'react'
import { ProductHuntBadge } from './ProductHuntBadge'

const SocialLinksContainer = styled.div`
  flex-direction: row;
  align-items: center;
  justify-content: center;
`

export const SocialLinks = () => (
  <SocialLinksContainer>
    <ProductHuntBadge />
    <SocialLink type="twitter" href="https://twitter.com/BrewComputer" />
    <SocialLink
      type="facebook"
      href="https://www.facebook.com/Brewcomputer-102076398442062"
    />
    <SocialLink
      type="instagram"
      href="https://www.instagram.com/brewcomputer/"
    />
  </SocialLinksContainer>
)
