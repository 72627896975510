import React, { Fragment, PureComponent } from 'react'
import { convert } from '@brewcomputer/utils'
import {
  LayoutGroup,
  renderMeasurableFieldEditor,
  ToolTitle
} from './ToolsUtils'

export class WeightToVolumeTool extends PureComponent {
  constructor(props) {
    super(props)
    const obj = {
      currentContainerWeight: { value: 20, unit: 'kg' },
      emptyContainerWeight: { value: 2.5, unit: 'kg' },
      specificGravity: { value: 1.01, unit: 'sg' },
      estimatedVolume: { value: 0, unit: 'l' }
    }
    this._calculate(obj)
    this.state = { obj: obj }
  }

  _calculate = obj => {
    const currentContainerWeight = convert(
      obj.currentContainerWeight.value,
      obj.currentContainerWeight.unit,
      'g',
      10
    )
    const emptyContainerWeight = convert(
      obj.emptyContainerWeight.value,
      obj.emptyContainerWeight.unit,
      'g',
      10
    )
    const specificGravity = convert(
      obj.specificGravity.value,
      obj.specificGravity.unit,
      'sg',
      10
    )
    obj.estimatedVolume.value = convert(
      (currentContainerWeight - emptyContainerWeight) / specificGravity,
      'ml',
      obj.estimatedVolume.unit,
      2
    )
  }

  _onChange = obj => {
    const newObj = Object.assign({}, obj)
    this._calculate(newObj)
    this.setState({ obj: newObj })
  }

  render() {
    return (
      <Fragment>
        <ToolTitle text={'Weight to Volume'} />

        <LayoutGroup caption={'Container Weight'}>
          {renderMeasurableFieldEditor(
            'Current Container Weight',
            this.state.obj,
            'currentContainerWeight',
            false,
            this._onChange
          )}
          {renderMeasurableFieldEditor(
            'Empty Container Weight',
            this.state.obj,
            'emptyContainerWeight',
            false,
            this._onChange
          )}
          {renderMeasurableFieldEditor(
            'Specific Gravity',
            this.state.obj,
            'specificGravity',
            false,
            this._onChange
          )}
        </LayoutGroup>

        <LayoutGroup caption={'Estimated Volume'}>
          {renderMeasurableFieldEditor(
            'Estimated Volume',
            this.state.obj,
            'estimatedVolume',
            true,
            null
          )}
        </LayoutGroup>
      </Fragment>
    )
  }
}
