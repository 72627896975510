import React, { Fragment, PureComponent } from 'react'
import { convert } from '@brewcomputer/utils'
import { renderNumberValueEditor, ToolTitle } from './ToolsUtils'

export class GravityUnitsTool extends PureComponent {
  constructor(props) {
    super(props)
    this.state = this._calculateState(1.01, 'sg')
  }

  _convert = (value, from, to) => {
    return from == to ? value : convert(value, from, to, 3)
  }

  _calculateState = (value, from) => {
    return {
      sg: this._convert(value, from, 'sg'),
      plato: this._convert(value, from, 'plato'),
      brix: this._convert(value, from, 'brix')
    }
  }

  _onChangeSg = value => {
    this.setState(this._calculateState(value, 'sg'))
  }

  _onChangePlato = value => {
    this.setState(this._calculateState(value, 'plato'))
  }

  _onChangeBrix = value => {
    this.setState(this._calculateState(value, 'brix'))
  }

  render() {
    return (
      <Fragment>
        <ToolTitle text={'Gravity Units'} />

        {renderNumberValueEditor(
          'Specific Gravity (SG)',
          this.state.sg,
          this._onChangeSg
        )}
        {renderNumberValueEditor(
          'Plato (Plato)',
          this.state.plato,
          this._onChangePlato
        )}
        {renderNumberValueEditor(
          'Brix (Bx)',
          this.state.brix,
          this._onChangeBrix
        )}
      </Fragment>
    )
  }
}
