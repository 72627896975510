import PropTypes from 'prop-types'

export const inputPropTypes = {
  placeholder: PropTypes.string,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  disabled: PropTypes.bool,
  multiline: PropTypes.bool,
  autoFocus: PropTypes.bool,
  type: PropTypes.oneOf(['text', 'number', 'date', 'email']),
  fontSize: PropTypes.oneOf(['small', 'normal', 'large', 'huge']),
  textAlign: PropTypes.oneOf(['left', 'right', 'center', 'justify']),
  selectOnFocus: PropTypes.bool,
  onChange: PropTypes.func,
  onFocus: PropTypes.func,
  onBlur: PropTypes.func,
  onSubmit: PropTypes.func,
  inputProps: PropTypes.object
}

export const inputDefaultProps = {
  fontSize: 'normal',
  textAlign: 'left'
}
