import React, { useState } from 'react'
import { isEmpty } from 'lodash'
import styled from 'styled-components'
import { AccentButton, Checkbox } from '@brewcomputer/ui'
import { AuthContainer, AuthTitle, NameInput } from './Styled'
import { Link } from 'react-router-dom'
import { email } from '../../../auth/endpoints'
import { links } from '../../links'

const Form = styled.form`
  display: flex;
  flex-direction: column;
  max-width: 360px;
`

const Confirmation = styled(Checkbox)`
  background-color: transparent;
  margin-bottom: 16px;
  padding: 0;
`

const Message = (
  <span>
    I have read, understand and accept
    <Link to={links.privacyPolicy}> Privacy Policy</Link> and
    <Link to={links.termsOfUse}> Terms of Use.</Link>
  </span>
)

export const Register = () => {
  const [checked, setChecked] = useState(false)
  const [name, setName] = useState('')

  const toggleChecked = () => {
    setChecked(!checked)
  }
  const onChangeName = text => {
    setName(text)
  }

  const disabled = !checked || isEmpty(name)

  return (
    <AuthContainer>
      <AuthTitle>Create a new account</AuthTitle>
      <Form action={email.auth_link_register} method="POST">
        <NameInput
          autoFocus
          name="name"
          placeholder="Enter your name"
          value={name}
          onChange={onChangeName}
        />
        <Confirmation
          checked={checked}
          onChange={toggleChecked}
          label={Message}
        />
        <AccentButton title="Create an account" disabled={disabled} />
      </Form>
    </AuthContainer>
  )
}
