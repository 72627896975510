import React from 'react'
import { colors } from '../colors/colors'

export const ImportIcon = () => (
  <svg width="20" height="20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fill="#109CF1"
      d="M0 18h2v2H0zM4 18h16v2H4zM0 14h2v2H0zM4 14h16v2H4zM11 8H9V0h2v8z"
    />
    <path d="M10 13L6 7h8l-4 6z" fill={colors.ACCENT_BLUE} />
  </svg>
)
