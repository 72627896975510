import { css } from 'styled-components'
import { colors } from '@brewcomputer/ui'

export const listItemStyle = css`
  background-color: ${colors.WHITE};
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 0 24px;
  border-top: 1px solid ${colors.DIVIDER};
`
