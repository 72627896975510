import React, { useEffect, useState, useCallback } from 'react'
import { find, map, get, filter } from 'lodash'
import { useSelector } from 'react-redux'
import { useGetBeerStyles } from '../../hooks/queries'
import { Lookup } from '@brewcomputer/ui'
import { isMatch } from './common'

export const BeerStyleLookup = ({
  selectedStyleId,
  currentStyleName,
  onSelect,
}) => {
  const { request: getBeerStyles, selector } = useGetBeerStyles()

  useEffect(() => {
    getBeerStyles()
  }, [])

  const { data: beerStyles } = useSelector(selector)

  const items = map(beerStyles, (beerStyle) => ({
    label: beerStyle.name,
    value: beerStyle.style_id,
    group: beerStyle.category,
  }))

  const title = get(
    find(items, { value: selectedStyleId }),
    'label',
    currentStyleName || 'Select a Beer Style'
  )

  const [filterString, setFilterString] = useState('')
  const filteredItems = filter(
    items,
    (item) =>
      isMatch(item.label, filterString) || isMatch(item.group, filterString)
  )

  const onSelectStyle = useCallback((styleId) => {
    onSelect(find(beerStyles, { style_id: styleId }))
  })

  return (
    <Lookup
      text={title}
      items={filteredItems}
      value={selectedStyleId}
      onChange={onSelectStyle}
      showArrow
      showGroups
      search
      searchPlaceholder="Select a beer style"
      searchQuery={filterString}
      onSearchChange={setFilterString}
    />
  )
}

BeerStyleLookup.displayName = 'BeerStyleLookup'
