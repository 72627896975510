import { get, has } from 'lodash'
import {
  batchChangeStatusAction,
  batchCheckListCheckAction,
  batchIngredientCheckAction,
  batchProcedureCheckAction,
  batchCheckListEditOptimistic
} from '../actions/batch'

import viewModel from '../../../domain/view-models/batch.projection'

import {
  RECIPE_BATCH_STATUS_CHANGED,
  RECIPE_BATCH_CHECK_LIST_CHECKED,
  RECIPE_INGREDIENT_CHECK_LIST_CHECKED,
  RECIPE_PROCEDURE_CHECK_LIST_CHECKED
} from '../../../domain/events'

const optimisticCheckListActions = {
  [batchCheckListCheckAction.types.request]: RECIPE_BATCH_CHECK_LIST_CHECKED,
  [batchIngredientCheckAction.types
    .request]: RECIPE_INGREDIENT_CHECK_LIST_CHECKED,
  [batchProcedureCheckAction.types
    .request]: RECIPE_PROCEDURE_CHECK_LIST_CHECKED,
  [batchChangeStatusAction.types.request]: RECIPE_BATCH_STATUS_CHANGED
}

const checkListOptimistic = ({ getState, dispatch }) => next => action => {
  if (has(optimisticCheckListActions, action.type)) {
    const { recipeId, ...rest } = action.payload
    const prevState = get(getState(), ['batchCheckLists', recipeId], {})
    const eventName = optimisticCheckListActions[action.type]
    const state = viewModel[eventName](prevState, { payload: rest })
    dispatch(batchCheckListEditOptimistic({ recipeId, state }))
  }
  next(action)
}

export default checkListOptimistic
