import { get, map, pick, concat, forEach, isEmpty } from 'lodash'
import {
  filterByUse,
  orderByTiming,
  measurableToString,
  getBoilAdditionLabel,
  getFermentationAdditionLabel,
  getCultureLabel,
  getMashStepLabel
} from './utils'
import {
  createBoilCheckList,
  createEquipmentCheckList,
  createFermentationCheckList,
  createMashMeasuredCheckList,
  createPreBoilCheckList,
  createPreBrewCheckList,
  createPreFermentationCheckList,
  createWaterCheckList
} from './checkLists'

export const getBatchId = routeProps => get(routeProps, 'match.params.batchId')

export const getBatchRecipe = (state, batchId) =>
  get(state, ['recipes', batchId], {})

export const getBatchInfo = batchId => state => {
  const batchRecipe = getBatchRecipe(state, batchId)
  return pick(batchRecipe.batch, ['name', 'author', 'date', 'number', 'notes'])
}

export const getBatchWaterAdditions = batchId => state =>
  get(state, ['recipes', batchId, 'volumes', 'total_volume'], null)

const pickStats = recipe =>
  pick(recipe, [
    'alcohol_by_volume',
    'ibu_estimate',
    'original_gravity',
    'final_gravity',
    'color_estimate'
  ])

export const getBatchRecipeStats = batchId => state => {
  const batchRecipe = getBatchRecipe(state, batchId)
  return pickStats(batchRecipe)
}

export const getBatchRecipePreview = batchId => state => {
  const batchRecipe = getBatchRecipe(state, batchId)

  const recipeFields = {
    name: batchRecipe.name,
    style: get(batchRecipe, 'style.name'),
    equipment: batchRecipe.equipment
  }

  forEach(pickStats(batchRecipe), (value, key) => {
    recipeFields[key] = measurableToString(value)
  })

  if (!isEmpty(batchRecipe.equipment)) {
    const { maximum_volume, boil_rate_per_hour, loss } = get(
      batchRecipe,
      'equipment.equipment_items.brew_kettle',
      {}
    )
    recipeFields.equipment = {
      name: batchRecipe.equipment.name,
      maximum_volume: measurableToString(maximum_volume),
      boil_rate_per_hour: measurableToString(boil_rate_per_hour),
      loss: measurableToString(loss)
    }
  }

  return recipeFields
}

const ingredientTypes = {
  fermentable_additions: 'fermentable',
  hop_additions: 'hop',
  culture_additions: 'culture',
  miscellaneous_additions: 'misc'
}

const getBatchCheckList = ({ batchId, state }) =>
  get(state, ['batchCheckLists', batchId, 'checkList'], {})

const getBatchRecipeIngredients = ({ batchId, state }) => {
  const ingredients = get(state, ['recipes', batchId, 'ingredients'], {})
  const ingredientList = map(ingredients, (ingredientGroup, ingredientPath) => {
    const ingredientType = ingredientTypes[ingredientPath]
    return map(ingredientGroup, ingredient => ({
      ...ingredient,
      ingredientType
    }))
  })
  return concat(...ingredientList)
}

const isIngredientAvailable = ({ batchId, state, ingredientType, id }) =>
  get(
    state,
    ['batchCheckLists', batchId, ingredientType, id, 'batch_available'],
    false
  )

export const getIngredientCheckList = batchId => state => {
  const ingredients = getBatchRecipeIngredients({ batchId, state })
  return map(ingredients, ({ id, name, amount, ingredientType }) => ({
    key: `${ingredientType}:${id}`,
    label: `${name} - ${measurableToString(amount)}`,
    checked: isIngredientAvailable({
      batchId,
      state,
      ingredientType,
      id
    })
  }))
}

export const getWaterCheckList = batchId => state => {
  const batchCheckList = getBatchCheckList({ batchId, state })
  const totalWater = getBatchWaterAdditions(batchId)(state)
  return createWaterCheckList(batchCheckList, totalWater)
}

export const getEquipmentCheckList = batchId => state => {
  const batchCheckList = getBatchCheckList({ batchId, state })
  return createEquipmentCheckList(batchCheckList)
}

export const getPreBrewCheckList = batchId => state => {
  const batchCheckList = getBatchCheckList({ batchId, state })
  return createPreBrewCheckList(batchCheckList)
}

export const getMashCheckList = batchId => state => {
  const batchCheckList = getBatchCheckList({ batchId, state })
  const mashStepsCheckList = getMashStepsCheckList(batchId)(state)
  const mashMeasuredCheckList = createMashMeasuredCheckList(batchCheckList)
  return [...mashStepsCheckList, ...mashMeasuredCheckList]
}

export const getBoilCheckList = batchId => state => {
  const batchCheckList = getBatchCheckList({ batchId, state })
  const { boil_time, pre_boil_size } = get(
    state,
    ['recipe', batchId, 'boil'],
    {}
  )
  const preBoilCheckList = createPreBoilCheckList(
    batchCheckList,
    pre_boil_size,
    boil_time
  )
  const boilAdditionsCheckList = getBoilAdditionsCheckList(batchId)(state)
  const boilCheckList = createBoilCheckList(batchCheckList)
  return [...preBoilCheckList, ...boilAdditionsCheckList, ...boilCheckList]
}

export const getFermentationCheckList = batchId => state => {
  const batchCheckList = getBatchCheckList({ batchId, state })
  const culture = getBatchCulture(batchId)(state)
  const cultureItem = {
    key: `culture:${culture.id}`,
    label: getCultureLabel(culture),
    checked: isAdditionAdded({
      batchId,
      state,
      addition: { ingredientType: 'culture', id: culture.id }
    })
  }
  return [
    ...createPreFermentationCheckList(batchCheckList),
    cultureItem,
    ...createFermentationCheckList(batchCheckList)
  ]
}

const isAdditionAdded = ({ batchId, state, addition }) =>
  get(
    state,
    [
      'batchCheckLists',
      batchId,
      addition.ingredientType,
      addition.id,
      'batch_added'
    ],
    false
  )

export const getBoilAdditionsCheckList = batchId => state => {
  const ingredients = getBatchRecipeIngredients({ batchId, state })
  const boilAdditions = orderByTiming(filterByUse(ingredients, 'boil'))
  return map(boilAdditions, addition => ({
    key: `${addition.ingredientType}:${addition.id}`,
    label: getBoilAdditionLabel(addition),
    checked: isAdditionAdded({
      batchId,
      state,
      addition
    })
  }))
}

export const getFermentationAdditionsCheckList = batchId => state => {
  const additions = filterByUse(
    getBatchRecipeIngredients({ batchId, state }),
    'fermentation'
  )
  return map(additions, addition => ({
    key: `${addition.ingredientType}:${addition.id}`,
    label: getFermentationAdditionLabel(addition),
    checked: isAdditionAdded({
      batchId,
      state,
      addition
    })
  }))
}

export const getBatchCulture = batchId => state =>
  get(state, ['recipes', batchId, 'ingredients', 'culture_additions', 0], {})

export const getCalculatedBatch = batchId => state =>
  get(state, ['recipes', batchId, 'batch'], {})

const isMashStepChecked = ({ batchId, state, index }) =>
  get(
    state,
    ['batchCheckLists', batchId, 'mash', index, 'batch_completed'],
    false
  )

export const getMashStepsCheckList = batchId => state => {
  const mashSteps = get(state, ['recipes', batchId, 'mash', 'mash_steps'], [])
  return map(mashSteps, (step, index) => ({
    key: `mash:${index}`,
    label: getMashStepLabel(step),
    checked: isMashStepChecked({ batchId, state, index })
  }))
}

export const getFermentationProfile = batchId => state => {
  const profile = get(state, ['recipes', batchId, 'fermentation'], {
    fermentation_steps: []
  })
  return {
    name: profile.name,
    steps: map(profile.fermentation_steps, step => ({
      name: step.name,
      time: step.step_time,
      temperature: step.end_temperature
    }))
  }
}
