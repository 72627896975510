import React, { PureComponent } from 'react'
import { propTypes } from './propTypes'
import { UnitPicker } from '../unitPicker/UnitPicker'
import styled from 'styled-components'
import { colors } from '../colors/colors'
import { textStyles } from '../texts/textStyles'

const Wrapper = styled.div`
  flex: 1;
  align-items: center;
`

const BarContainer = styled.div`
  position: relative;
  margin: 4px 0;
`

const Bar = styled.div`
  overflow: hidden;
  align-self: center;
  position: relative;
  width: 14px;
  height: 300px;
  border-radius: 2px;
  background: ${colors.STYLE_BAR_BACKGROUND};
  margin: 0 12px;
`

const Range = styled.div`
  position: absolute;
  left: 0;
  right: 0;
  background: ${colors.STYLE_BAR_FILL};
  top: ${props => props.position}%;
  height: ${props => props.percentage}%;
`

const Mark = styled.div`
  position: absolute;
  left: 10px;
  right: 10px;
  height: 2px;
  background: ${props => (props.inRange ? colors.BLACK : colors.NEGATIVE_RED)};
  top: ${props => props.position}%;
  transition: top 150ms ease-out 0s;
`

const Text = styled.div`
  margin-bottom: 2px;
  ${textStyles.smallText};
  text-align: center;
`

const BoundariesText = styled.div`
  ${textStyles.smallText};
  text-align: center;
  margin-top: 6px;
  line-height: 11px;
  white-space: pre;
`

const UnitPickerContainer = styled.div`
  margin-top: -6px;
`

export class Indicator extends PureComponent {
  static propTypes = propTypes

  render() {
    const {
      inRange,
      rangePercentage,
      rangePosition,
      markPosition,
      boundaries,
      caption,
      value,
      unit,
      units,
      onChangeUnit
    } = this.props

    return (
      <Wrapper>
        <Text>{caption}</Text>
        <Text>{value}</Text>
        <UnitPickerContainer>
          <UnitPicker
            showValue
            selectedUnit={unit}
            units={units}
            onChange={onChangeUnit}
          />
        </UnitPickerContainer>
        <BarContainer>
          <Bar>
            <Range position={rangePosition} percentage={rangePercentage} />
          </Bar>
          <Mark position={markPosition} inRange={inRange} />
        </BarContainer>
        {boundaries && <BoundariesText>{boundaries}</BoundariesText>}
      </Wrapper>
    )
  }
}
