import React, { useState, useCallback } from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { Modal } from '../modal/Modal'
import { Checkbox } from '../checkbox/Checkbox'
import { textStyles } from '../texts/textStyles'
import { colors } from '../colors/colors'

const Section = styled.div`
  border-top: 1px solid ${colors.DIVIDER};
  padding: 24px 0;
  flex-direction: column;
  justify-content: flex-start;
  align-items: stretch;
`

Section.displayName = 'Section'

const Message = styled.div`
  ${textStyles.body2};
  padding: 0 24px;
`

const CheckboxBlock = styled(Checkbox)`
  margin-top: 12px;
  padding: 8px 24px;
`

export const Confirm = props => {
  const {
    visible,
    title,
    message,
    confirmationMessage,
    buttonTitle,
    onClose,
    onConfirm
  } = props

  const [confirmed, setConfirmed] = useState(false)

  const onCheck = useCallback(({ checked }) => {
    setConfirmed(checked)
  }, [])

  const reset = useCallback(() => {
    setConfirmed(false)
    onClose()
  }, [])

  return (
    <Modal
      visible={visible}
      title={title}
      button={{
        title: buttonTitle,
        label: buttonTitle,
        disabled: !confirmed,
        negative: true
      }}
      onButtonPress={onConfirm}
      onClose={reset}>
      <Section>
        <Message>{message}</Message>
        <CheckboxBlock
          checked={confirmed}
          label={confirmationMessage}
          onChange={onCheck}
        />
      </Section>
    </Modal>
  )
}

Confirm.propTypes = {
  visible: PropTypes.bool,
  title: PropTypes.string,
  message: PropTypes.string,
  confirmationMessage: PropTypes.string,
  buttonTitle: PropTypes.string,
  onConfirm: PropTypes.func,
  onClose: PropTypes.func
}

Confirm.displayName = 'Confirm'
