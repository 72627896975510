import React from 'react'
import PropTypes from 'prop-types'
import { map } from 'lodash'
import { ResponsiveEditor } from '../responsiveTable/ResponsiveEditor'
import { ResponsiveRow } from '../responsiveTable/ResponsiveRow'

export const ProcedureItem = ({
  fields,
  visibleColumnsCount,
  onEditProcedure
}) => (
  <ResponsiveRow visibleColumnsCount={visibleColumnsCount}>
    {map(fields, column => (
      <ResponsiveEditor
        key={column.title}
        title={column.title}
        {...column.data}
        path={column.path}
        disabled={column.readOnly}
        onChange={onEditProcedure}
      />
    ))}
  </ResponsiveRow>
)

ProcedureItem.propTypes = {
  fields: PropTypes.array,
  visibleColumnsCount: PropTypes.number,
  onEditProcedure: PropTypes.func
}
