import PropTypes from 'prop-types'

export const deleteButtonPropTypes = {
  onPress: PropTypes.func
}

export const accentButtonPropTypes = {
  title: PropTypes.string,
  accessibilityLabel: PropTypes.string,
  disabled: PropTypes.bool,
  negative: PropTypes.bool,
  onPress: PropTypes.func,
  size: PropTypes.oneOf(['small', 'normal']),
  showArrow: PropTypes.bool
}

export const importButtonPropTypes = {
  accessibilityLabel: PropTypes.string,
  onImport: PropTypes.func
}

export const clearButtonPropTypes = {
  accessibilityLabel: PropTypes.string,
  onPress: PropTypes.func
}

export const accentButtonDefaultProps = {
  size: 'normal'
}
