import { isFunction } from 'lodash'
import React, { PureComponent } from 'react'
import PropTypes from 'prop-types'
import styled, { keyframes, createGlobalStyle } from 'styled-components'
import { colors } from '../colors/colors'

const SHADOW_OPACITY = 0.5

const fadeIn = keyframes`
	from { opacity: 0.0; }
	to { opacity: ${SHADOW_OPACITY}; }
`

const fadeOut = keyframes`
	from { opacity: ${SHADOW_OPACITY}; }
	to { opacity: 0.0; }
`

const slideIn = keyframes`
	from { transform: translate(300px, 0); }
	to { transform: translate(0px, 0); }
`

const slideOut = keyframes`
	from { transform: translate(0px, 0); }
	to { transform: translate(300px, 0); }
`

const BodyScroll = createGlobalStyle`
  body {
    overflow: ${props => (props.disabled ? 'hidden' : 'auto')};
  }
`

const Main = styled.div`
  position: fixed;
  left: 0;
  top: 0;
  bottom: 0;
  width: 100%;
  overflow: hidden;
  z-index: 1000;
  display: ${props => props.display};
  ${props => props.justify && 'justify-content: center;'};
`

const Shadow = styled.div`
  width: 100%;
  height: 100%;
  background-color: ${colors.BLACK};
  opacity: ${SHADOW_OPACITY};
  animation: ${props => props.animationName} 300ms ease;
`

const Panel = styled.div`
  position: absolute;
  width: ${props => props.width}px;
  height: 100%;
  right: 0;
  background-color: ${colors.WHITE};
  border-top: 1px solid ${colors.BACKGROUND};
  flex-direction: column;
  overflow: auto;
  animation: ${props => props.animationName} 300ms ease;
`

const Window = styled.div`
  position: absolute;
  align-self: center;
  width: 437px;
  overflow: auto;
  border-radius: 4px;
  box-shadow: 0px 6px 18px rgba(0, 0, 0, 0.06);
  background-color: ${colors.WHITE};
  animation: ${props => props.animationName} 100ms ease;
  @media (max-width: 576px) {
    width: 100%;
  }
`

export const popupStates = {
  hidden: 'hidden',
  visible: 'visible',
  hiding: 'hiding'
}

export class Popup extends PureComponent {
  static propTypes = {
    type: PropTypes.oneOf(['window', 'sidebar']),
    panelWidth: PropTypes.number,
    onShow: PropTypes.func,
    onHide: PropTypes.func,
    onShowAnimationEnd: PropTypes.func,
    onHideAnimationEnd: PropTypes.func,
    className: PropTypes.string
  }

  static defaultProps = {
    panelWidth: 300,
    type: 'sidebar'
  }

  constructor(props) {
    super(props)
    this.state = { state: popupStates.hidden }
  }

  show = () => {
    const { onShow } = this.props
    const { state } = this.state
    if (state === popupStates.hidden) {
      this.setState({ state: popupStates.visible }, () => {
        document.addEventListener('keyup', this.onKeyPress)
        if (isFunction(onShow)) {
          onShow()
        }
      })
    }
  }

  hide = () => {
    const { onHide } = this.props
    const { state } = this.state
    if (state === popupStates.visible) {
      this.setState({ state: popupStates.hiding }, () => {
        document.removeEventListener('keyup', this.onKeyPress)
        if (isFunction(onHide)) {
          onHide()
        }
      })
    }
  }

  onShadowPress = () => {
    const { state } = this.state
    if (state === popupStates.visible) {
      this.hide()
    }
  }

  onKeyPress = e => {
    if (this.state.state === popupStates.visible && e.keyCode === 27) {
      this.hide()
    }
  }

  onAnimationEnd = () => {
    const { onShowAnimationEnd, onHideAnimationEnd } = this.props
    const { state } = this.state
    if (state === popupStates.hiding) {
      this.setState({ state: popupStates.hidden }, () => {
        if (isFunction(onHideAnimationEnd)) {
          onHideAnimationEnd()
        }
      })
    } else {
      if (isFunction(onShowAnimationEnd)) {
        onShowAnimationEnd()
      }
    }
  }

  toggle = () => {
    const { state } = this.state
    if (state === popupStates.visible) {
      this.hide()
    } else {
      this.show()
    }
  }

  render() {
    const { panelWidth, className, type, children } = this.props
    const { state } = this.state
    return (
      <Main
        className={className}
        justify={type === 'window'}
        display={state === popupStates.hidden ? 'none' : null}>
        <Shadow
          onClick={this.onShadowPress}
          animationName={state === popupStates.hiding ? fadeOut : fadeIn}
        />
        <BodyScroll disabled={state === popupStates.visible} />

        {type === 'sidebar' ? (
          <Panel
            width={panelWidth}
            animationName={state === popupStates.hiding ? slideOut : slideIn}
            onAnimationEnd={this.onAnimationEnd}>
            {children}
          </Panel>
        ) : (
          <Window
            animationName={state === popupStates.hiding ? fadeOut : fadeIn}
            onAnimationEnd={this.onAnimationEnd}>
            {children}
          </Window>
        )}
      </Main>
    )
  }
}
