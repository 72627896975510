import React, { PureComponent } from 'react'
import { accentButtonPropTypes, accentButtonDefaultProps } from './propTypes'
import {
  accentButtonStyle,
  accentButtonTitleStyle,
  arrowContainerStyle
} from './style'
import { colors } from '../colors/colors'
import styled, { css } from 'styled-components'
import { DropdownArrow } from '../icons/DropdownArrow'

const styleForSmall = css`
  box-shadow: none;
  :hover,
  :focus {
    background-color: ${colors.ACCENT_GRAY_HOVER};
    box-shadow: none;
  }
  :active {
    background-color: ${colors.ACCENT_GRAY_ACTIVE};
    box-shadow: none;
  }
`

const styleForNegative = css`
  box-shadow: 0px 4px 10px ${colors.BUTTON_RED_SHADOW};
  :hover,
  :focus {
    background-color: ${colors.BUTTON_RED_HOVER};
    box-shadow: 0px 8px 16px ${colors.BUTTON_RED_HOVER_SHADOW};
  }
  :active {
    background-color: ${colors.BUTTON_RED_ACTIVE};
    box-shadow: 0px 8px 16px ${colors.BUTTON_RED_ACTIVE_SHADOW};
  }
`

const styleForNormal = css`
  box-shadow: 0px 4px 10px ${colors.ACCENT_BLUE_SHADOW};
  :hover,
  :focus {
    background-color: ${colors.ACCENT_BLUE_HOVER};
    box-shadow: 0px 8px 16px ${colors.ACCENT_BLUE_HOVER_SHADOW};
  }
  :active {
    background-color: ${colors.ACCENT_BLUE_ACTIVE};
    box-shadow: 0px 8px 16px ${colors.ACCENT_BLUE_ACTIVE_SHADOW};
  }
`

const switchStyle = ({ size, negative }) => {
  if (size === 'small') {
    return styleForSmall
  }
  if (negative) {
    return styleForNegative
  }
  return styleForNormal
}

const StyledButton = styled.button`
  ${accentButtonStyle};
  transition-duration: 200ms;
  ${switchStyle};
  :disabled {
    cursor: not-allowed;
    background-color: ${colors.GRAY};
    box-shadow: none;
  }
`

const StyledTitle = styled.span`
  ${accentButtonTitleStyle};
`

const ArrowContainer = styled.div`
  ${arrowContainerStyle};
`

export class AccentButton extends PureComponent {
  static propTypes = accentButtonPropTypes
  static defaultProps = accentButtonDefaultProps

  _buttonRef = React.createRef()

  focus = () => {
    if (this._buttonRef) {
      this._buttonRef.current.focus()
    }
  }

  render() {
    const {
      title,
      showArrow,
      accessibilityLabel,
      onPress,
      htmlTitle,
      ...buttonProps
    } = this.props
    return (
      <StyledButton
        title={htmlTitle}
        ref={this._buttonRef}
        aria-label={accessibilityLabel}
        showArrow={showArrow}
        onClick={onPress}
        {...buttonProps}>
        <StyledTitle>{title}</StyledTitle>
        {showArrow && (
          <ArrowContainer>
            <DropdownArrow color={colors.WHITE} />
          </ArrowContainer>
        )}
      </StyledButton>
    )
  }
}
