import React from 'react'
import { Link } from 'react-router-dom'
import { AccentButton } from '@brewcomputer/ui'
import { AuthContainer, AuthTitle, TextMessage } from './Styled'
import { links } from '../../links'
import { emailAuth } from '../../texts'

export const AuthError = () => (
  <AuthContainer>
    <AuthTitle>{emailAuth.error.title}</AuthTitle>
    <TextMessage marginBottom="36px">{emailAuth.error.message}</TextMessage>
    <Link to={links.index}>
      <AccentButton title="Try again" />
    </Link>
  </AuthContainer>
)
