import React from 'react'
import styled from 'styled-components'
import figmaColors from '../colors/figmaColors'

const TabBar = styled.nav`
  display: flex;
  flex-direction: row;
  align-items: center;
  align-self: flex-start;
  background-color: ${figmaColors.white};
  border-radius: 4px;
  padding: 6px;
  margin-bottom: 10px;
`

export const Tabs = ({ children }) => {
  return <TabBar>{children}</TabBar>
}

Tabs.displayName = 'Tabs'
