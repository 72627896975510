import { map } from 'lodash'
import PropTypes from 'prop-types'
import React, { PureComponent } from 'react'
import { Card } from '../card/Card'
import { Buttons, Controls, UnitsButton, StyledForm } from './Styled'
import { ControlsTitle } from '../titles/Styled'
import { SelectEditor } from '../editors/SelectEditor'

export class UnitSettings extends PureComponent {
  static propTypes = {
    unitSettings: PropTypes.arrayOf(
      PropTypes.shape({
        path: PropTypes.string,
        caption: PropTypes.string,
        value: PropTypes.string,
        units: PropTypes.array
      })
    ),
    onChange: PropTypes.func,
    onSwitchToSystem: PropTypes.func
  }

  onChangeUnit = path => unit => {
    this.props.onChange({ [path]: unit })
  }

  switchToSystem = system => () => {
    this.props.onSwitchToSystem(system)
  }

  render() {
    const { unitSettings } = this.props
    return (
      <Card>
        <Controls>
          <ControlsTitle>Switch all units to</ControlsTitle>
          <Buttons>
            <UnitsButton
              title={'Metric units'}
              size={'small'}
              onPress={this.switchToSystem('metric')}
            />
            <UnitsButton
              title={'US units'}
              size={'small'}
              onPress={this.switchToSystem('us')}
            />
            <UnitsButton
              title={'Imperial units'}
              size={'small'}
              onPress={this.switchToSystem('imperial')}
            />
          </Buttons>
        </Controls>
        <StyledForm type={'4-columns'}>
          {map(unitSettings, ({ path, caption, value, units }) => (
            <SelectEditor
              singleLine
              key={path}
              title={caption}
              selectedValue={value}
              items={units}
              onChange={this.onChangeUnit(path)}
            />
          ))}
        </StyledForm>
      </Card>
    )
  }
}
