import { css } from 'styled-components'
import { colors } from '../colors/colors'

const getBorderColor = props =>
  props.focused
    ? colors.ACCENT_BLUE
    : props.isBorderHidden
      ? 'transparent'
      : colors.TEXT_LINE

const getBackground = props =>
  props.readOnly ? colors.EDITOR_READ_ONLY : 'transparent'

export const editorStyle = css`
  flex-direction: row;
  align-items: flex-end;
  border-color: ${getBorderColor};
  background-color: ${getBackground};
  border-bottom-width: 1px;
`

export const titleStyle = css`
  font-size: 11px;
  line-height: 11px;
  color: ${props =>
    props.focused ? colors.ACCENT_BLUE : colors.TEXT_HELP_GRAY};
`
