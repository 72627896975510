import { isEmpty, some } from 'lodash'
import React, { PureComponent } from 'react'
import PropTypes from 'prop-types'
import { Manager, Reference, Popper } from 'react-popper'
import { AccentButton } from '../buttons/AccentButton'
import { Filter } from './Filter'
import { Options } from './Options'
import { Dropdown, onKeyPressed, getPrevValue, getNextValue } from './Common'

export class LookupButton extends PureComponent {
  _buttonRef = React.createRef()

  focus = () => {
    if (this._buttonRef) {
      this._buttonRef.current.focus()
    }
  }

  render() {
    const { innerRef, title, showArrow, onPress } = this.props
    return (
      <div ref={innerRef}>
        <AccentButton
          ref={this._buttonRef}
          showArrow={showArrow}
          size="small"
          title={title}
          tabIndex={0}
          onPress={onPress}
        />
      </div>
    )
  }
}

export class Lookup extends PureComponent {
  static propTypes = {
    items: PropTypes.arrayOf(
      PropTypes.shape({
        group: PropTypes.string,
        label: PropTypes.string,
        value: PropTypes.string,
        isFixed: PropTypes.bool,
      })
    ),
    showArrow: PropTypes.bool,
    showGroups: PropTypes.bool,

    text: PropTypes.string,
    value: PropTypes.string,
    onChange: PropTypes.func,

    search: PropTypes.bool,
    searchQuery: PropTypes.string,
    searchPlaceholder: PropTypes.string,
    noResultsMessage: PropTypes.string,
    onSearchChange: PropTypes.func,
  }

  static defaultProps = {
    showArrow: false,
    search: false,
  }

  constructor(props) {
    super(props)
    this.state = {
      opened: false,
      hoveredValue: null,
    }
    this._popperRef = null
    this._optionsRef = React.createRef()
    this._buttonRef = React.createRef()
  }

  getPopperRef = (ref) => {
    this._popperRef = ref
  }

  open = () => {
    this.setState({ opened: true, hoveredValue: this.props.value }, () => {
      document.addEventListener('keydown', this.onKeyPress, false)
      document.addEventListener('click', this.onClick, false)
    })
  }

  close = (shouldReturnFocus = false) => {
    this.setState({ opened: false }, () => {
      document.removeEventListener('keydown', this.onKeyPress, false)
      document.removeEventListener('click', this.onClick, false)
      if (this._buttonRef && shouldReturnFocus) {
        this._buttonRef.current.focus()
      }
    })
  }

  select = (value) => {
    this.props.onChange(value)
    this.close(true)
  }

  onClick = (e) => {
    if (this._popperRef && !this._popperRef.contains(e.target)) {
      this.close()
    }
  }

  hoverValue = (hoveredValue) => {
    if (this.state.hoveredValue !== hoveredValue) {
      this.setState({ hoveredValue })
    }
  }

  hoverPrevValue = () => {
    const prevValue = getPrevValue(this.props.items, this.state.hoveredValue)
    if (prevValue) {
      this.hoverValue(prevValue)
      this._optionsRef.current.scrollToItem(prevValue, false)
    }
  }

  hoverNextValue = () => {
    const nextValue = getNextValue(this.props.items, this.state.hoveredValue)
    if (nextValue) {
      this.hoverValue(nextValue)
      this._optionsRef.current.scrollToItem(nextValue, false)
    }
  }

  selectHoveredValue = () => {
    const { items } = this.props
    const { hoveredValue } = this.state

    if (some(items, { value: hoveredValue })) {
      this.select(hoveredValue)
    }
  }

  onKeyPress = (e) => {
    onKeyPressed(
      e,
      this.close,
      this.selectHoveredValue,
      this.hoverPrevValue,
      this.hoverNextValue
    )
  }

  renderButton = ({ ref }) => (
    <LookupButton
      ref={this._buttonRef}
      title={this.props.text}
      showArrow={this.props.showArrow}
      onPress={this.open}
      innerRef={ref}
    />
  )

  renderDropdown = ({ ref, style }) => {
    const {
      items,
      value,
      search,
      searchQuery,
      searchPlaceholder,
      onSearchChange,
      showGroups,
    } = this.props
    const { hoveredValue } = this.state

    return (
      <Dropdown ref={ref} style={style}>
        {search && (
          <Filter
            query={searchQuery}
            placeholder={searchPlaceholder}
            isItemsEmpty={isEmpty(items)}
            onChange={onSearchChange}
          />
        )}
        <Options
          ref={this._optionsRef}
          highlightedText={searchQuery}
          showGroups={showGroups}
          items={items}
          selectedValue={value}
          hoveredValue={hoveredValue}
          onOptionSelect={this.select}
          onOptionHover={this.hoverValue}
        />
      </Dropdown>
    )
  }

  render() {
    const { opened } = this.state
    return (
      <Manager>
        <Reference>{this.renderButton}</Reference>
        {opened && (
          <Popper
            innerRef={this.getPopperRef}
            modifiers={{ flip: { boundariesElement: 'document' } }}
            placement="bottom-start">
            {this.renderDropdown}
          </Popper>
        )}
      </Manager>
    )
  }
}
