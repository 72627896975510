import React from 'react'
import {
  Row,
  Section,
  Separator,
  ResponsiveContainer,
  RecipeDataContainer,
} from './Styled'

export const TopControls = ({ Left, Right }) => {
  return (
    <Row>
      <Section>{Left}</Section>
      <Section>{Right}</Section>
    </Row>
  )
}

export const Responsive = ({ children, Sidebar }) => {
  return (
    <ResponsiveContainer>
      <RecipeDataContainer>{children}</RecipeDataContainer>
      <Separator />
      {Sidebar}
    </ResponsiveContainer>
  )
}
