import { get, isEmpty } from 'lodash'

export const getRecipe = (recipeId) => (state) => {
  return get(state, ['recipes', recipeId], {})
}

export const getRecipeEquipment = (recipeId) => (state) => {
  return get(state, ['recipes', recipeId, 'equipment'], {})
}

export const getIngredients = (recipeId, ingredientType) => (state) =>
  get(state, ['recipes', recipeId, 'ingredients', ingredientType], [])

export const getProcedure = (recipeId, procedureType) => (state) =>
  get(state, ['recipes', recipeId, procedureType], null)

const getStat = (recipe, caption, key) => ({
  caption,
  key,
  ...get(recipe, key, {}),
  ...get(recipe, ['ranges', key], {}),
})

export const getStats = (recipeId) => (state) => {
  const recipe = get(state, ['recipes', recipeId], {})
  return [
    getStat(recipe, 'OG', 'original_gravity'),
    getStat(recipe, 'FG', 'final_gravity'),
    getStat(recipe, 'ABV', 'alcohol_by_volume'),
    getStat(recipe, 'IBU', 'ibu_estimate'),
    getStat(recipe, 'Color', 'color_estimate'),
  ]
}

export const getBatch = (batchId) => (state) => {
  const recipe = getRecipe(batchId)(state)
  const isLoading = isEmpty(recipe) || recipe.isLoading
  const { isError, isCreated } = recipe
  const status = get(recipe, 'batch.status')
  const name = get(recipe, 'batch.name')
  return {
    isLoading,
    isCreated,
    isError,
    status,
    name,
  }
}
