import { get, map, orderBy } from 'lodash'
import {
  convertMeasurable,
  convertAmount,
  convertCultureAmount,
  convertMiscAmount,
} from './convertMeasurable'

const getIngredientGroup = (recipe, ingredientGroup) =>
  get(recipe, ['ingredients', ingredientGroup], [])

export const convertFermentable =
  ({ fermentable_amount, color, gravity }) =>
  (fermentable) => ({
    ...fermentable,
    amount: convertAmount(fermentable.amount, fermentable_amount),
    color: convertMeasurable(fermentable.color, color),
    yield: {
      ...fermentable.yield,
      potential: convertMeasurable(fermentable.yield.potential, gravity),
    },
  })

export const convertHop =
  ({ hop_amount, time }) =>
  (hop) => ({
    ...hop,
    amount: convertAmount(hop.amount, hop_amount),
    timing: {
      ...hop.timing,
      time: convertMeasurable(hop.timing.time, time),
    },
  })

export const convertCulture = (culture) => ({
  ...culture,
  amount: convertCultureAmount(culture.amount),
})

export const convertMisc = (misc) => {
  const convertedMisc = {
    ...misc,
    amount: convertMiscAmount(misc.amount),
  }
  if (misc.timing != null) {
    convertedMisc.timing = {
      ...misc.timing,
      time: convertMeasurable(misc.timing.time),
    }
  }
  return convertedMisc
}

const orderHopsByUse = (hop) => {
  const use = get(hop, 'timing.use')
  switch (use) {
    case 'add_to_mash':
      return 1
    case 'add_to_boil':
      return 2
    case 'add_to_fermentation':
      return 3
    default:
      return 4
  }
}

const orderHopsByTime = (hop) => {
  const time = get(hop, 'timing.time', null)
  return convertMeasurable(time, 'min').value
}

export const convertIngredients = (
  recipe,
  unitSettings,
  convertForCalculation = false
) => {
  const converted = {}
  converted.fermentable_additions = map(
    getIngredientGroup(recipe, 'fermentable_additions'),
    convertFermentable(unitSettings)
  )

  const hopAdditions = map(
    getIngredientGroup(recipe, 'hop_additions'),
    convertHop(unitSettings)
  )
  converted.hop_additions = orderBy(
    hopAdditions,
    [orderHopsByUse, orderHopsByTime],
    ['asc', 'desc']
  )

  if (convertForCalculation) {
    converted.culture_additions = getIngredientGroup(
      recipe,
      'culture_additions'
    )
    converted.miscellaneous_additions = getIngredientGroup(
      recipe,
      'miscellaneous_additions'
    )
    return converted
  }

  converted.culture_additions = map(
    getIngredientGroup(recipe, 'culture_additions'),
    convertCulture
  )
  converted.miscellaneous_additions = map(
    getIngredientGroup(recipe, 'miscellaneous_additions'),
    convertMisc
  )

  if (getIngredientGroup(recipe, 'water_additions') != null) {
    converted.water_additions = getIngredientGroup(recipe, 'water_additions')
  }
  return converted
}
