import { includes, startsWith } from 'lodash'
import React, { PureComponent, Fragment } from 'react'
import PropTypes from 'prop-types'
import {
  BackLink as BackLinkUI,
  Logo as LogoUI,
  BatchesIcon,
  BottomLink,
  ProfilesIcon,
  RecipesIcon,
  ToolsIcon,
  TopLink,
  Avatar,
  colors,
  LoginIcon
} from '@brewcomputer/ui'
import { Link, NavLink, Route, Switch } from 'react-router-dom'
import { links, getBatchLink } from '../../links'
import styled, { css } from 'styled-components'
import { FeedbackNotificationIcon } from '../feedback/Notification'

export const recipeDetailedPath = '/recipe/'
export const batchDetailedPath = '/batch/'
export const batchRecipePath = `${links.batchRecipe}:recipeId`

export const getRecipesListLink = (location, tabs) =>
  includes(location.pathname, recipeDetailedPath)
    ? tabs.recipes.lastTab
    : tabs.recipes.lastPage

export const getBatchesListLink = (location, tabs) =>
  includes(location.pathname, batchDetailedPath)
    ? links.batches
    : tabs.batches.lastPage

const StyledButton = styled.button`
  background-color: transparent;
  width: ${props => props.width};
  margin-left: ${props => props.marginLeft};
  align-items: center;
  justify-content: center;
  & ${TopLink} {
    box-sizing: border-box;
    flex-grow: 1;
  }
`

const ButtonTitle = styled.div`
  margin-left: ${props => (props.type === 'bottom' ? 0 : 10)}px;
`

export class NavBarButton extends PureComponent {
  render() {
    const { isActive, title, Icon, type, onPress, marginLeft } = this.props
    const Wrapper = type === 'bottom' ? BottomLink : TopLink
    const iconFillColor = type === 'top' ? colors.ACCENT_BLUE : undefined
    const buttonWidth = type === 'bottom' ? '25%' : null
    return (
      <StyledButton
        className={isActive ? 'active' : ''}
        marginLeft={marginLeft}
        width={buttonWidth}
        onClick={onPress}>
        <Wrapper>
          <Icon id={type} fill={iconFillColor} />
          <ButtonTitle type={type}>{title}</ButtonTitle>
        </Wrapper>
      </StyledButton>
    )
  }
}
NavBarButton.displayName = 'NavBarButton'

export const NavBarLink = ({ to, pattern, title, Icon, type = 'top' }) => {
  const Wrapper = type === 'bottom' ? BottomLink : TopLink
  const isActive = (match, location) => startsWith(location.pathname, pattern)
  return (
    <NavLink isActive={isActive} exact to={to}>
      <Wrapper>
        {type === 'bottom' && <Icon />}
        {title}
      </Wrapper>
    </NavLink>
  )
}
NavBarLink.displayName = 'NavBarLink'

export class NavLinks extends PureComponent {
  static propTypes = {
    placement: PropTypes.oneOf(['top', 'bottom']),
    location: PropTypes.object,
    showAdminLink: PropTypes.bool
  }

  render() {
    const {
      showAdminLink,
      showFeedbackButton,
      placement,
      location,
      tabs,
      isToolsButtonActive,
      onToolsButtonPress,
      isFeedbackButtonActive,
      onFeedbackButtonPress
    } = this.props
    return (
      <Fragment>
        <NavBarLink
          key={'recipes'}
          to={getRecipesListLink(location, tabs)}
          pattern={'/recipe'}
          title={'Recipes'}
          Icon={RecipesIcon}
          type={placement}
        />
        <NavBarLink
          key={'batches'}
          to={getBatchesListLink(location, tabs)}
          pattern={'/batch'}
          title={'Batches'}
          Icon={BatchesIcon}
          type={placement}
        />
        <NavBarLink
          key={'equipment'}
          to={links.equipment}
          pattern={'/equipment'}
          title={'Equipment'}
          Icon={ProfilesIcon}
          type={placement}
        />
        {showAdminLink && (
          <NavBarLink
            key={'admin'}
            to={links.admin}
            pattern={'/admin'}
            title={'Admin'}
            Icon={ProfilesIcon}
            type={placement}
          />
        )}
        <NavBarButton
          key={'tools'}
          isActive={isToolsButtonActive}
          title={'Tools'}
          Icon={ToolsIcon}
          type={placement}
          onPress={onToolsButtonPress}
          marginLeft={'auto'}
        />
        {showFeedbackButton && (
          <NavBarButton
            key={'feedback'}
            isActive={isFeedbackButtonActive}
            title={'Feedback'}
            Icon={FeedbackNotificationIcon}
            type={placement}
            onPress={onFeedbackButtonPress}
          />
        )}
      </Fragment>
    )
  }
}

const leftSideLinkContainer = css`
  min-width: 200px;
  flex-direction: row;
`

const LogoContainer = styled.div`
  ${leftSideLinkContainer};
  @media (max-width: 992px) {
    display: none;
  }
`

export class Logo extends PureComponent {
  render() {
    return (
      <LogoContainer>
        <Link to={links.index}>
          <LogoUI />
        </Link>
      </LogoContainer>
    )
  }
}

const BackButtonContainer = styled.div`
  ${leftSideLinkContainer};
`

export class BackLink extends PureComponent {
  static displayName = 'BackLink'
  static propTypes = {
    to: PropTypes.string,
    title: PropTypes.string
  }
  render() {
    const { to, title } = this.props
    return (
      <BackButtonContainer>
        <Link to={to}>
          <BackLinkUI>{title}</BackLinkUI>
        </Link>
      </BackButtonContainer>
    )
  }
}

export class LeftSideLink extends PureComponent {
  render() {
    const { tabs } = this.props
    return (
      <Switch>
        <Route
          path={recipeDetailedPath}
          render={({ location }) => (
            <BackLink
              to={getRecipesListLink(location, tabs)}
              title={'Recipes'}
            />
          )}
        />
        <Route
          path={batchRecipePath}
          render={({ match }) => (
            <BackLink
              to={getBatchLink(match.params.recipeId)}
              title={'Batch'}
            />
          )}
        />
        <Route
          path={batchDetailedPath}
          render={() => <BackLink to={links.batches} title={'Batches'} />}
        />
        <Route path="/" component={Logo} />
      </Switch>
    )
  }
}

export const renderTabLink = (title, href, children) => (
  <NavLink exact title={title} to={href} activeClassName="active">
    {children}
  </NavLink>
)

const StyledLink = styled(NavLink)`
  display: flex;
  height: 100%;
  justify-content: center;
  align-items: center;
  @media (min-width: 992px) {
    margin-left: 16px;
    &.active {
      border-bottom: 2px solid ${colors.ACCENT_BLUE};
    }
  }
`

const FixedWidthLink = styled(StyledLink)`
  width: 32px;
`

const SignInLink = styled(TopLink)`
  padding: 0 8px 0 0;
`

export const UserProfileLink = ({ avatar, isAnonymous }) => {
  if (isAnonymous) {
    return (
      <StyledLink to={links.settings}>
        <SignInLink>
          <LoginIcon />
          {'Sign in'}
        </SignInLink>
      </StyledLink>
    )
  }

  return (
    <FixedWidthLink to={links.settings}>
      <Avatar src={avatar} alt="Settings" size={32} />
    </FixedWidthLink>
  )
}

UserProfileLink.displayName = 'UserProfileLink'
