import { get } from 'lodash'
import React, { PureComponent } from 'react'
import PropTypes from 'prop-types'
import { RecipeListItem, RecipesListHeader } from '@brewcomputer/ui'
import { beautifyMeasurable, capitalizeWords } from './common'
import { NoRecipes, NoPublicRecipes } from '../specialPages/EmptyList'
import { List } from './List'

export class RecipesListView extends PureComponent {
  renderItem = recipe => (
    <RecipeListItem
      name={recipe.name}
      author={recipe.author}
      type={capitalizeWords(recipe.type)}
      beerStyle={get(recipe, 'style.name', 'No Style')}
      abv={recipe.alcohol_by_volume}
      ibu={recipe.ibu_estimate}
      og={beautifyMeasurable(recipe.original_gravity)}
      fg={beautifyMeasurable(recipe.final_gravity)}
      color={recipe.color_css}
      shared={recipe.shared}
      published={recipe.published}
      isPublicRecipe={this.props.isPublicRecipes}
    />
  )

  render() {
    const { recipes, isPublicRecipes, onRemoveRecipe } = this.props
    return (
      <List
        data={recipes}
        allowDelete={!isPublicRecipes}
        type="recipe"
        renderItem={this.renderItem}
        ListEmpty={isPublicRecipes ? <NoPublicRecipes /> : <NoRecipes />}
        ListHeader={<RecipesListHeader isPublicRecipe={isPublicRecipes} />}
        onRemoveItem={onRemoveRecipe}
      />
    )
  }
}

RecipesListView.propTypes = {
  recipes: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
      authorId: PropTypes.string,
      name: PropTypes.string,
      style: PropTypes.shape({
        name: PropTypes.string
      }),
      type: PropTypes.string
    })
  ),
  userId: PropTypes.string,
  isPublicRecipes: PropTypes.bool,
  onRemoveRecipe: PropTypes.func
}
