import React, { Fragment, useEffect, useCallback } from 'react'
import { useSelector, shallowEqual } from 'react-redux'
import { Switch, Route, Redirect } from 'react-router-dom'
import { getBatchLink } from '../links'
import { LoadingIndicator } from '../components/LoadingIndicator'
import { BatchNotFound } from '../components/specialPages/BatchNotFound'
import { getBatchId } from '../components/batchView/selectors'
import { BatchHeader } from '../components/batchView/BatchHeader'
import { BatchPlanningView } from '../components/batchView/BatchPlanningView'
import { BatchBrewingView } from '../components/batchView/BatchBrewingView'
import { BatchFermentationView } from '../components/batchView/BatchFermentationView'
import {
  useBatchChangeStatus,
  useBatchEdit,
  useRecipeBatchMeasuredEdit,
  useEditRecipeEquipment
} from '../hooks/commands'
import { useRecipesViewModel, useBatchesViewModel } from '../hooks/viewModel'
import { useCountBatches } from '../hooks/queries'
import { getBatch } from '../selectors/recipe'

const BatchView = props => {
  const batchId = getBatchId(props)

  const { isLoading, isCreated, isError, status, name } = useSelector(
    getBatch(batchId),
    shallowEqual
  )

  const {
    connect: connectRecipe,
    dispose: disposeRecipe
  } = useRecipesViewModel(batchId)
  const { connect: connectBatch, dispose: disposeBatch } = useBatchesViewModel(
    batchId
  )
  const { request: countBatches } = useCountBatches()

  useEffect(() => {
    countBatches()
  }, [])

  useEffect(
    () => {
      if (!isCreated) {
        connectRecipe()
        connectBatch()
      }
      return () => {
        disposeRecipe()
        disposeBatch()
      }
    },
    [isCreated]
  )

  const batchEdit = useBatchEdit(batchId)
  const batchEditMeasured = useRecipeBatchMeasuredEdit(batchId)
  const batchChangeStatus = useBatchChangeStatus(batchId)
  const editEquipment = useEditRecipeEquipment(batchId)

  const setStatus = useCallback(
    nextStatus => () => {
      if (nextStatus !== status) {
        batchChangeStatus(nextStatus)
      }
    },
    [status]
  )

  const editName = useCallback(value => {
    batchEdit({ name: value })
  })

  if (isLoading) {
    return <LoadingIndicator />
  }
  if (isError) {
    return <BatchNotFound />
  }

  return (
    <Fragment>
      <BatchHeader
        batchId={batchId}
        name={name}
        status={status}
        onEditName={editName}
      />
      <Switch>
        <Route
          path={getBatchLink(batchId, 'planning')}
          render={routeProps => (
            <BatchPlanningView
              {...routeProps}
              batchId={batchId}
              onEditBatch={batchEdit}
              onEditEquipment={editEquipment}
              onMount={setStatus('planning')}
            />
          )}
        />
        <Route
          path={getBatchLink(batchId, 'brewing')}
          render={routeProps => (
            <BatchBrewingView
              {...routeProps}
              batchId={batchId}
              onEditBatchMeasured={batchEditMeasured}
              onMount={setStatus('brewing')}
            />
          )}
        />
        <Route
          path={getBatchLink(batchId, 'fermentation')}
          render={routeProps => (
            <BatchFermentationView
              {...routeProps}
              batchId={batchId}
              onEditBatch={batchEdit}
              onEditBatchMeasured={batchEditMeasured}
              onMount={setStatus('fermentation')}
            />
          )}
        />
        <Redirect to={getBatchLink(batchId, 'planning')} />
      </Switch>
    </Fragment>
  )
}
export default BatchView
