import React, { Fragment, PureComponent } from 'react'
import { convert, round } from '@brewcomputer/utils'
import {
  LayoutGroup,
  renderMeasurableFieldEditor,
  renderNumberFieldEditor,
  ToolTitle
} from './ToolsUtils'

export class CarbonationTool extends PureComponent {
  constructor(props) {
    super(props)
    const obj = {
      beerData: {
        volume: { value: 25, unit: 'l' },
        targetCO2: 2.5,
        temperature: { value: 5, unit: 'C' },
        currentCO2: 0.75
      },
      bottlingCarbonation: {
        tableSugar: { value: 5, unit: 'g' },
        cornSugar: { value: 5, unit: 'g' },
        brownSugar: { value: 5, unit: 'g' },
        dme: { value: 5, unit: 'g' },
        mapleSyrup: { value: 5, unit: 'g' }
      },
      keggedCarbonation: {
        pressure: { value: 1.5, unit: 'bar' },
        cornSugar: { value: 5, unit: 'g' }
      }
    }
    this._calculate(obj)
    this.state = { obj: obj }
  }

  _calculate = obj => {
    const volume = convert(
      obj.beerData.volume.value,
      obj.beerData.volume.unit,
      'l',
      10
    )
    const targetCO2 = obj.beerData.targetCO2
    const temperature = convert(
      obj.beerData.temperature.value,
      obj.beerData.temperature.unit,
      'C',
      10
    )
    const currentCO2 =
      0.000860382 * temperature * temperature -
      0.05952024 * temperature +
      1.7077392
    const tableSugar = (volume * (targetCO2 - currentCO2) * 2) / 0.5
    const cornSugar = (volume * (targetCO2 - currentCO2) * 2) / 0.5 / 0.91
    const brownSugar = (volume * (targetCO2 - currentCO2) * 2) / 0.5 / 0.89
    const dme = (volume * (targetCO2 - currentCO2) * 2) / 0.5 / 0.68
    const mapleSyrup = (volume * (targetCO2 - currentCO2) * 2) / 0.5 / 0.77
    const n = 2617.25 / (temperature + 273.15) - 10.73797
    const pressure = (targetCO2 * 2) / (10 * Math.pow(Math.E, n)) - 1.013

    obj.beerData.currentCO2 = round(currentCO2, 2)
    obj.bottlingCarbonation.tableSugar.value = convert(
      tableSugar,
      'g',
      obj.bottlingCarbonation.tableSugar.unit,
      2
    )
    obj.bottlingCarbonation.cornSugar.value = convert(
      cornSugar,
      'g',
      obj.bottlingCarbonation.cornSugar.unit,
      2
    )
    obj.bottlingCarbonation.brownSugar.value = convert(
      brownSugar,
      'g',
      obj.bottlingCarbonation.brownSugar.unit,
      2
    )
    obj.bottlingCarbonation.dme.value = convert(
      dme,
      'g',
      obj.bottlingCarbonation.dme.unit,
      2
    )
    obj.bottlingCarbonation.mapleSyrup.value = convert(
      mapleSyrup,
      'g',
      obj.bottlingCarbonation.mapleSyrup.unit,
      2
    )
    obj.keggedCarbonation.cornSugar.value = convert(
      cornSugar / 2,
      'g',
      obj.keggedCarbonation.cornSugar.unit,
      2
    )
    obj.keggedCarbonation.pressure.value = convert(
      pressure,
      'bar',
      obj.keggedCarbonation.pressure.unit,
      2
    )
  }

  _onChange = obj => {
    const newObj = Object.assign({}, obj)
    this._calculate(newObj)
    this.setState({ obj: newObj })
  }

  render() {
    const { obj } = this.state
    return (
      <Fragment>
        <ToolTitle text={'Carbonation'} />

        <LayoutGroup caption={'Beer'}>
          {renderMeasurableFieldEditor(
            'Volume',
            obj,
            'beerData.volume',
            false,
            this._onChange
          )}
          {renderNumberFieldEditor(
            'Target Volume of CO2',
            obj,
            'beerData.targetCO2',
            this._onChange,
            false
          )}
          {renderMeasurableFieldEditor(
            'Temperature',
            obj,
            'beerData.temperature',
            false,
            this._onChange
          )}
          {renderNumberFieldEditor(
            'Current Volume of CO2',
            obj,
            'beerData.currentCO2',
            null,
            true
          )}
        </LayoutGroup>
        <LayoutGroup caption={'Bottling Carbonation'}>
          {renderMeasurableFieldEditor(
            'Table Sugar',
            obj,
            'bottlingCarbonation.tableSugar',
            true,
            null
          )}
          {renderMeasurableFieldEditor(
            'Corn Sugar',
            obj,
            'bottlingCarbonation.cornSugar',
            true,
            null
          )}
          {renderMeasurableFieldEditor(
            'Brown Sugar',
            obj,
            'bottlingCarbonation.brownSugar',
            true,
            null
          )}
          {renderMeasurableFieldEditor(
            'Dried Malt Extract',
            obj,
            'bottlingCarbonation.dme',
            true,
            null
          )}
          {renderMeasurableFieldEditor(
            'Maple Syrup',
            obj,
            'bottlingCarbonation.mapleSyrup',
            true,
            null
          )}
        </LayoutGroup>
        <LayoutGroup caption={'Kegged Carbonation'}>
          {renderMeasurableFieldEditor(
            'Pressure',
            obj,
            'keggedCarbonation.pressure',
            true,
            null
          )}
          {renderMeasurableFieldEditor(
            'Corn Sugar',
            obj,
            'keggedCarbonation.cornSugar',
            true,
            null
          )}
        </LayoutGroup>
      </Fragment>
    )
  }
}
