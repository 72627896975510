import PropTypes from 'prop-types'
import React, { PureComponent } from 'react'
import {
  Container,
  RecipeName,
  RecipeText,
  Stat,
  StatTitle,
  Color,
  Cell
} from './Styled.js'

const measurablePropType = PropTypes.shape({
  unit: PropTypes.string,
  value: PropTypes.number
})

export class RecipeListItem extends PureComponent {
  static propTypes = {
    color: PropTypes.string,
    name: PropTypes.string,
    beerStyle: PropTypes.string,
    type: PropTypes.string,
    author: PropTypes.string,
    shared: PropTypes.bool,
    published: PropTypes.bool,
    abv: measurablePropType,
    ibu: measurablePropType,
    og: measurablePropType,
    fg: measurablePropType,
    isPublicRecipe: PropTypes.bool
  }

  stringifyMeasurable = (measurable, showUnit = true) => {
    if (measurable == null || measurable.value == null) {
      return '-'
    }
    if (showUnit) {
      return `${measurable.value} ${measurable.unit}`
    }
    return measurable.value
  }

  render() {
    const {
      color,
      name,
      abv,
      ibu,
      og,
      fg,
      beerStyle,
      type,
      author,
      shared,
      published,
      isPublicRecipe
    } = this.props
    return (
      <Container colorCSS={color}>
        <Color colorCSS={color} />
        <Cell relativeWidth={33}>
          <RecipeName>{name}</RecipeName>
        </Cell>
        <Cell relativeWidth={25} direction="column">
          <RecipeText>{beerStyle}</RecipeText>
          <RecipeText>{`(${type})`}</RecipeText>
        </Cell>
        <Cell relativeWidth={37}>
          <Stat>
            <StatTitle>ABV</StatTitle>
            <RecipeText>{this.stringifyMeasurable(abv)}</RecipeText>
          </Stat>
          <Stat>
            <StatTitle>IBU</StatTitle>
            <RecipeText>{this.stringifyMeasurable(ibu, false)}</RecipeText>
          </Stat>
          <Stat>
            <StatTitle>OG</StatTitle>
            <RecipeText>{this.stringifyMeasurable(og)}</RecipeText>
          </Stat>
          <Stat>
            <StatTitle>FG</StatTitle>
            <RecipeText>{this.stringifyMeasurable(fg)}</RecipeText>
          </Stat>
        </Cell>
        {isPublicRecipe ? (
          <Cell relativeWidth={15}>
            <RecipeText>{author}</RecipeText>
          </Cell>
        ) : (
          <Cell relativeWidth={15} direction="column">
            {shared && <RecipeText>Shared</RecipeText>}
            {published && <RecipeText>Published</RecipeText>}
            {!shared && !published && <RecipeText>Private</RecipeText>}
          </Cell>
        )}
      </Container>
    )
  }
}

RecipeListItem.displayName = 'RecipeListItem'
