const _ = require('lodash')
const validate = require('@beerjson/beerjson/js/validation')

const validateRecipe = beerjson => {
  const isValid = validate(beerjson)
  return {
    isValid,
    errors: _.map(validate.errors, error => {
      let message = error.message
      if (error.keyword === 'enum' && !_.isNull(error.params.allowedValues)) {
        message += ':\r\n' + _.join(error.params.allowedValues, ', ')
      }
      if (
        error.keyword === 'additionalProperties' &&
        !_.isNull(error.params.additionalProperties)
      ) {
        message += ':\r\n' + error.params.additionalProperty
      }
      let result = `${_.replace(error.dataPath, '.beerjson.recipes[0].', '')} ${
        error.keyword
      } ${message}.`
      return result
    })
  }
}

module.exports = validateRecipe
