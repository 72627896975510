import PropTypes from 'prop-types'
import React, { PureComponent } from 'react'
import {
  HeaderContainer,
  RecipeText,
  ColorTitle,
  Stat,
  Cell
} from './Styled.js'

export class RecipesListHeader extends PureComponent {
  static propTypes = {
    isPublicRecipe: PropTypes.bool
  }
  render() {
    const lastColumnTitle = this.props.isPublicRecipe ? 'Author' : 'Shared'
    return (
      <HeaderContainer>
        <ColorTitle>Color</ColorTitle>
        <Cell relativeWidth={33}>
          <RecipeText>Title</RecipeText>
        </Cell>
        <Cell relativeWidth={25}>
          <RecipeText>Style</RecipeText>
        </Cell>
        <Cell relativeWidth={37}>
          <Stat>
            <RecipeText>ABV</RecipeText>
          </Stat>
          <Stat>
            <RecipeText>IBU</RecipeText>
          </Stat>
          <Stat>
            <RecipeText>OG</RecipeText>
          </Stat>
          <Stat>
            <RecipeText>FG</RecipeText>
          </Stat>
        </Cell>
        <Cell relativeWidth={15}>
          <RecipeText>{lastColumnTitle}</RecipeText>
        </Cell>
      </HeaderContainer>
    )
  }
}
