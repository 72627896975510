import React, { Fragment, PureComponent } from 'react'
import { isEmpty, map, size, slice } from 'lodash'
import { CollapsibleView, DropdownArrow } from '@brewcomputer/ui'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { ClearButton } from '@brewcomputer/ui'
import { colors } from '@brewcomputer/ui'

const CollapsedTR = styled.tr`
  background-color: transparent;
`

const CollapsedTD = styled.td`
  padding: 0;
`

const ButtonsCell = styled.td`
  /* collapse width to fit content */
  width: 1px;
  padding: 0;
`

const Buttons = styled.div`
  flex-direction: row;
  align-items: stretch;
  height: 42px;
`

const TR = styled.tr`
  background-color: ${colors.WHITE};
  z-index: 100;
  box-shadow: ${props =>
    props.shadow ? '0 1px 1px 0 rgba(0, 0, 0, 0.1)' : 'none'};
  height: 42px;
`

const TD = styled.td`
  min-width: 90px;
  padding: 0 15px 2px 24px;
  @media (max-width: 576px) {
    padding: 0 4px 2px 12px;
  }
`

const DropdownButton = styled.button`
  align-self: stretch;
  justify-content: center;
  background-color: transparent;
  padding: 0 19px;
`

DropdownButton.displayName = 'DropdownButton'

const CollapsedContainer = styled.div`
  position: absolute;
  left: 0;
  right: 0;
  flex-direction: row;
  flex-wrap: wrap;
  margin: 2px 0;
  padding: 12px;
  background-color: ${colors.WHITE};
  border-bottom: 3px solid ${colors.BACKGROUND};
`

export const CollapsedCell = styled.div`
  box-sizing: border-box;
  width: 25%;
  padding: 5px 12px;
  flex-direction: row;
  align-items: center;

  @media (max-width: 576px) {
    width: 100%;
  }
`

const Content = styled.div`
  width: 100%;
`

const DeleteButton = styled(ClearButton)`
  padding: 10px 0;
  align-items: center;
  justify-content: center;
  width: 45px;
`

DeleteButton.displayName = 'DeleteButton'

export class ResponsiveRow extends PureComponent {
  constructor(props) {
    super(props)
    this.state = { collapsed: true }
  }

  toggleCollapsed = e => {
    e.stopPropagation()
    this.setState({ collapsed: !this.state.collapsed })
  }

  render() {
    const {
      children,
      visibleColumnsCount,
      showDeleteButton,
      onPressDelete
    } = this.props

    if (isEmpty(children)) {
      return null
    }

    const visibleChildren = slice(children, 0, visibleColumnsCount)
    const collapsedChildren = slice(children, visibleColumnsCount)

    const showCollapseButton = visibleColumnsCount < size(children)

    const expandedAndEmpty =
      !this.state.collapsed && size(collapsedChildren) <= 0

    const collapsed = this.state.collapsed || expandedAndEmpty

    return (
      <Fragment>
        <TR shadow={!collapsed}>
          {map(visibleChildren, (child, index) => (
            <TD key={index}>{child}</TD>
          ))}
          <ButtonsCell>
            <Buttons>
              {showDeleteButton && <DeleteButton onPress={onPressDelete} />}
              {showCollapseButton && (
                <DropdownButton
                  title={collapsed ? 'Expand' : 'Collapse'}
                  aria-expanded={collapsed ? 'false' : 'true'}
                  onClick={this.toggleCollapsed}>
                  <DropdownArrow opened={!collapsed} />
                </DropdownButton>
              )}
            </Buttons>
          </ButtonsCell>
        </TR>

        <CollapsedTR>
          <CollapsedTD colSpan={6}>
            <CollapsibleView collapsed={collapsed}>
              <CollapsedContainer>
                {map(collapsedChildren, (child, index) => (
                  <CollapsedCell key={index}>
                    <Content>
                      {React.cloneElement(child, { collapsed: true })}
                    </Content>
                  </CollapsedCell>
                ))}
              </CollapsedContainer>
            </CollapsibleView>
          </CollapsedTD>
        </CollapsedTR>
      </Fragment>
    )
  }
}

ResponsiveRow.propTypes = {
  visibleColumnsCount: PropTypes.number,
  showDeleteButton: PropTypes.bool,
  onPressDelete: PropTypes.func
}
