import Sticky from 'react-stickynode'
import React from 'react'
import styled from 'styled-components'
import { Card, SectionTitle, StyleBars as StyleBarsUI } from '@brewcomputer/ui'

const StyleBarsContainer = styled.div`
  min-width: 250px;
`

const StyleBarsCard = styled(Card)`
  padding: 20px 20px 40px 20px;
  margin-top: 14px;
`

const StyleBarsTitle = styled(SectionTitle)`
  text-align: center;
  white-space: nowrap;
  margin-bottom: 11px;
`

export const StyleBars = ({ title, data, onChangeUnit }) => {
  if (data == null) {
    return null
  }

  return (
    <StyleBarsContainer>
      <Sticky top="#top-nav-bar">
        <StyleBarsCard>
          <StyleBarsTitle>{title}</StyleBarsTitle>
          <StyleBarsUI data={data} onChangeUnit={onChangeUnit} />
        </StyleBarsCard>
      </Sticky>
    </StyleBarsContainer>
  )
}
