import React from 'react'
import { Page } from './PolicyPage'
import ScrollToTop from './ScrollToTop'
import { Link } from 'react-router-dom'
import { links } from '../../links'
import TitleConstructor from '../TitleConstructor'
import { titles } from '../../texts/index'

export const PrivacyPolicy = () => {
  return (
    <Page>
      <ScrollToTop />
      <TitleConstructor pageTitle={titles.PrivacyPolicy} />
      <h1 id="DevSoft-Baltic-OÜ-Privacy-Policy">
        DevSoft Baltic OÜ Privacy Policy
      </h1>
      <p>Effective Date: 5/25/2018, Last Modified: 5/24/2018</p>
      <h2 id="1-Introduction">1. Introduction</h2>

      <p>
        This Privacy Policy describes how DevSoft Baltic OÜ collects and uses
        Personal Data about you through the use of our Website, our Product, and
        through email, text, and other electronic communications between you and
        DevSoft Baltic OÜ.
      </p>

      <p>
        DevSoft Baltic OÜ (“
        <strong>DevSoft Baltic</strong>” or “<strong>we</strong>
        ”) respect your privacy and are committed to protecting it through our
        compliance with this policy.
      </p>
      <p>
        This Privacy Policy (our “<strong>Privacy Policy</strong>
        ”) describes the types of information we may collect from you or that
        you may provide when you visit the{' '}
        <Link to={links.index} target="_blank" rel="noopener">
          https://brewcomputer.com
        </Link>{' '}
        website (a “<strong>Website</strong>
        ”) or using our BrewComputer (a “<strong>Product</strong>
        ”).
      </p>
      <p>This policy applies to information we collect:</p>
      <ul>
        <li>on our Website and through the use of our Product;</li>
        <li>
          in email, text, and other electronic messages between you and our
          Website (including our technical support services);
        </li>
      </ul>
      <p>It does not apply to information collected by:</p>
      <ul>
        <li>
          us offline or through any other means, including on any other website
          operated by DevSoft Baltic or any third-party; or
        </li>
        <li>
          any third party, including through any application or content that may
          link to or be accessible from or on the Website or with our Product.
        </li>
      </ul>
      <p>
        Please read this policy carefully to understand our policies and
        practices regarding your information and how we will treat it. If you do
        not agree with our policies and practices, your choice is not to use our
        Website and/or our Product. By accessing or using this Website, you
        agree to this Privacy Policy. This Privacy Policy may change from time
        to time (see <a href="#changes">Changes to Our Privacy Policy</a>
        ). Your continued use of this Website after we make changes is deemed to
        be acceptance of those changes, so please check this Privacy Policy
        periodically for updates.
      </p>
      <h2 id="2-Data-Controller-Data-Protection-Officer-and-Representative">
        2. Data Controller, Data Protection Officer, and Representative
      </h2>

      <p>
        DevSoft Baltic is the data controller of the Personal Data you provide
        on the Website. DevSoft Baltic has appointed a Data Protection Officer
        and a representative in the European Union.
      </p>

      <p>
        DevSoft Baltic is the data controller of your Personal Data. DevSoft
        Baltic has appointed a Data Protection Officer and a representative in
        the European Union in compliance with the General Data Protection
        Regulation. DevSoft Baltic, its Data Protection Officer, or its
        representative may be contacted in any manner set forth below in the “
        <a href="#contact">Contact Information</a>” Section of this Privacy
        Policy.
      </p>
      <h2 id="3-Children-Under-the-Age-of-18">
        3. Children Under the Age of 18
      </h2>

      <p>
        Our Website is not intended for children under the age of 18 and
        children under the age of 18 are not permitted to use our Website. We
        will remove any information about a child under the age of 18 if we
        become aware of it.
      </p>

      <p>
        Our Website is not intended for children under 18 years of age. No one
        under age 18 may provide any information to or on the Website. We do not
        knowingly collect Personal Data from children under 18. If you are under
        18, do not register on the Website, make any purchases through the
        Website, use any of the interactive or public comment features of our
        Website, or provide any information about yourself to us, including your
        name, address, telephone number, email address, or any screen name or
        user name you may use. If we learn we have collected or received
        Personal Data from a child under 18 without verification of parental
        consent, we will delete that information. If you believe we might have
        any information from or about a child under 18, please contact us in any
        manner set forth below in the “
        <a href="#contact">Contact Information</a>” Section of this Privacy
        Policy.
      </p>
      <h2 id="4-Information-We-Collect-About-You-and-How-We-Collect-It">
        4. Information We Collect About You and How We Collect It
      </h2>

      <p>
        We collect information different types of information about you,
        including information that may directly identify you, information that
        is about you but individually does not personally identify you, and
        information that we combine with our other users. This includes
        information that we collect directly from you or through automated
        collection technologies.
      </p>

      <h2 id="Generally">Generally</h2>
      <p>
        We collect several types of information from and about users of our
        Product and Website, specifically information:
      </p>
      <ul>
        <li>
          by which you may be personally identified, such as name, e-mail
          address and any other identifier by which you may be contacted online
          or offline (“
          <strong>Personal Data</strong>
          ”);
        </li>
        <li>
          that is about you but individually does not identify you, such as
          Website traffic data, location data (through IP address), logs,
          referring/exit pages, date and time of your visit to our Website,
          error information, clickstream data, and other communication data and
          the resources that you access and use on the Website; and/or
        </li>
        <li>
          about your internet connection, the equipment you use to access our
          Website and usage details.
        </li>
      </ul>
      <p>We collect this information:</p>
      <ul>
        <li>directly from you when you provide it to us;</li>
        <li>
          automatically as you navigate through the site. Information collected
          automatically may include usage details, IP addresses, and information
          collected through cookies, web beacons, and other tracking
          technologies; and
        </li>
        <li>From third parties, for example, OAuth Providers.</li>
      </ul>
      <h2 id="Information-You-Provide-to-Us">Information You Provide to Us</h2>
      <p>The information we collect on or through our Website:</p>
      <ul>
        <li>
          information that you provide by filling in forms on our Website. This
          includes information provided at the time of registering to use our
          Website, or requesting further services (such as technical support).
          We may also ask you for information when you report a problem with our
          Customer Support System;
        </li>
        <li>
          records and copies of your correspondence (including email addresses),
          if you contact us; and
        </li>
        <li>
          your responses to quizzes and surveys that we might ask you to
          complete for research purposes.
        </li>
      </ul>
      <p>
        The Personal Data we collect from you is required to enter into a
        contract with DevSoft Baltic, for DevSoft Baltic to perform under the
        contract, to provide you with our Product and services. If you refuse to
        provide such Personal Data or withdraw your consent to our processing of
        Personal Data, then in some cases we may not be able to enter into the
        contract or fulfill our obligations to you under it.
      </p>
      <p>
        You also may provide information to be published or displayed
        (hereinafter, “<strong>posted</strong>
        ”) on public areas of the Website or transmitted to other users of the
        Website or third parties (collectively, “
        <strong>User Contributions</strong>
        ”). If you consent, your User Contributions may include technical
        support tickets that you make publicly available to our user community.
        Your User Contributions are posted on and transmitted to others at your
        own risk. Although we limit access to certain pages, please be aware
        that no security measures are perfect or impenetrable. Additionally, we
        cannot control the actions of other users of the Website with whom you
        may choose to share your User Contributions. Therefore, we cannot and do
        not guarantee that your User Contributions will not be viewed by
        unauthorized persons.
      </p>
      <h2 id="Information-We-Collect-Through-Automatic-Data-Collection-Technologies">
        Information We Collect Through Automatic Data Collection Technologies
      </h2>
      <p>
        As you navigate through and interact with our Website, we may use
        automatic data collection technologies to collect certain information
        about your equipment, browsing actions, and patterns, specifically:
      </p>
      <ul>
        <li>
          details of your visits to our Website, specifically traffic data,
          location data (through IP addresses), logs, referring/exit pages, date
          and time of your visit to our Website, error information, clickstream
          data, and other communication data and the resources that you access
          and use on the Website; and
        </li>
        <li>
          Information about your computer and internet connection, specifically
          your IP address, operating system, and browser type.
        </li>
      </ul>
      <p>
        The information we collect automatically may include Personal Data or we
        may maintain it or associate it with Personal Data we collect in other
        ways. It helps us to improve our Website and to deliver a better and
        more personalized service by enabling us to:
      </p>
      <ul>
        <li>estimate our audience size and usage patterns;</li>
        <li>
          store information about your preferences, allowing us to customize our
          Website according to your individual interests;
        </li>
        <li>recognize you when you use and return to our Website.</li>
      </ul>
      <p>
        Details about the automated data collection technologies and the
        specific cookies that we use can be found in our{' '}
        <Link to={links.cookiePolicy} target="_blank" rel="noopener">
          Cookie Policy
        </Link>
        .
      </p>
      <h2 id="5-Lawful-Basis-for-Processing-Your-Personal-Data">
        5. Lawful Basis for Processing Your Personal Data
      </h2>

      <p>
        We have a lawful basis for our processing of your Personal Data,
        including processing for our legitimate interests (when balanced against
        your rights and freedoms), to fulfill our obligations to you under a
        contract with you, and required by law, and with your consent.
      </p>

      <p>
        If you are in the European Union, the processing of your Personal Data
        is lawful only if it is permitted under the applicable data protection
        laws. We have a lawful basis for each of our processing activities
        (except when an exception applies as described below):
      </p>
      <ul>
        <li>
          <strong>Consent</strong>. By using our Website, you consent to our
          collection, use, and sharing of your Personal Data as described in
          this privacy policy. If you do not consent to this privacy policy,
          please do not use the Website.
        </li>
        <li>
          <strong>Legitimate Interests</strong>. We will process your Personal
          Data as necessary for our legitimate interests. Our legitimate
          interests are balanced against your rights and freedoms and we do not
          process your Personal Data if your rights and freedoms outweigh our
          legitimate interests. Specifically, our legitimate interests are to:
          facilitate communication between DevSoft Baltic and you; detect and
          correct bugs and to improve our Website, Product, and services;
          safeguard our IT infrastructure and intellectual property; detect and
          prevent fraud and other crime (including misappropriation of
          intellectual property); promote and market our business; check your
          credit and perform risk assessments; and develop our product and
          services.
        </li>
        <li>
          <strong>To Fulfill Our Obligations to You under our Contract</strong>.
          We process your Personal Data in order to fulfill our obligations to
          you pursuant to our contract with you to deliver our goods and
          services to you. Specifically, we use your contact information to
          provide you with the Product, license keys, and support services that
          you order from us.
        </li>
        <li>
          <strong>As Required by Law</strong>. We may also process your Personal
          Data when we are required or permitted to by law; to comply with
          government inspection, audits, and other valid requests from
          government or other public authorities; to respond to legal process
          such as subpoenas; or as necessary for us to protect our interests or
          otherwise pursue our legal rights and remedies (for instance, when
          necessary to prevent or detect fraud, attacks against our network, or
          other criminal and tortious activities), defend litigation, and manage
          complaints or claims.
        </li>
      </ul>
      <h2 id="6-Special-Categories-of-Information">
        6. Special Categories of Information
      </h2>

      <p>
        We generally do not request you provide and do not process any special
        categories of Personal Data.
      </p>

      <p>
        DevSoft Baltic does not ask you to provide, and we do not knowingly
        collect, any special categories of Personal Data from you, such as
        Personal Data that reveals your racial or ethnic origin, political
        opinions, religious, philosophical beliefs, or trade unions membership,
        or the processing of data concerning your health or data concerning your
        sex life or sexual orientation or history of criminal convictions.
      </p>
      <h2 id="7-Automated-Decision-Making">7. Automated Decision Making</h2>

      <p>
        We generally do not use your Personal Data with any automated decision
        making processes.
      </p>

      <p>
        DevSoft Baltic does not use your Personal Data with any automated
        decision making processes.
      </p>
      <h2 id="8-How-We-Use-Your-Information">8. How We Use Your Information</h2>

      <p>
        We use your Personal Data for various purposes described below,
        including to:
      </p>
      <ul>
        <li>provide our Website to you;</li>
        <li>provide our Product to you;</li>
        <li>provide you with information you request from us;</li>
        <li>enforce our rights arising from contracts;</li>
        <li>notify you about changes; and</li>
        <li>provide you with notices about your account</li>
      </ul>

      <p>
        We use information that we collect about you or that you provide to us,
        including any Personal Data:
      </p>
      <ul>
        <li>to present our Website and their contents to you;</li>
        <li>
          to provide you with information, Product, or services that you request
          from us;
        </li>
        <li>
          to provide you with information about security advisories regarding
          the Product or services;
        </li>
        <li>to fulfill any other purpose for which you provide it;</li>
        <li>
          to carry out our obligations and enforce our rights arising from any
          contracts entered into between you and us, including for billing and
          collection;
        </li>
        <li>
          to notify you about changes or updates to our Website or any Product
          or services that we offer or provide though our Website;
        </li>
        <li>
          to allow you to participate in interactive features on our Website;
        </li>
        <li>
          in any other way we may describe when you provide the information; and
        </li>
        <li>for any other purpose with your consent.</li>
      </ul>
      <p>
        With your consent, we may also use your information to send you
        newsletters and contact you about our own goods and services that may be
        of interest to you, including events and other activities that may be in
        your area or may be of interest to you. If you wish to consent to this
        use, please check the relevant box located on the form on which we
        collect your data. Your choices may be found by first navigating to the
        “Privacy settings” page and then navigating to the “Email Settings”
        section. If you wish to change your choice, you may do so at any time by
        checking or unchecking the relevant boxes in this “Email Settings”
        section or by sending us an email stating your request to{' '}
        <a href="mailto:info@devsoftbaltic.com" target="_blank" rel="noopener">
          info@devsoftbaltic.com
        </a>
        . For more information, see{' '}
        <a href="#choices">
          Choices About How We Use and Disclose Your Information
        </a>
        .
      </p>
      <h2 id="disclosure">9. Disclosure of Your Information</h2>

      <p>
        We do not share, sell, or otherwise disclose your Personal Data for
        purposes other than those outlined in this Privacy Policy. We disclose
        your Personal Data to a few third parties, including:
      </p>

      <ul>
        <li>
          our third-party service providers that we use to support our business,
          such as our payment processors and hosting providers;
        </li>
        <li>
          to a company we merge, acquire, or that buys us, or in the event of
          change in structure of our company of any form;
        </li>
        <li>to comply with our legal obligations;</li>
        <li>to enforce our rights; and</li>
        <li>with your consent.</li>
      </ul>

      <p>
        We do not share, sell, or otherwise disclose your Personal Data for
        purposes other than those outlined in this Privacy Policy. However, we
        may disclose aggregated information about our users, and information
        that does not identify any individual, without restriction.
      </p>
      <p>
        We may disclose Personal Data that we collect or you provide as
        described in this privacy policy:
      </p>
      <ul>
        <li>
          to contractors, service providers, and other third parties we use to
          support our business and who are bound by contractual obligations to
          keep Personal Data confidential and use it only for the purposes for
          which we disclose it to them.
        </li>
        <li>
          to a buyer or other successor in the event of a merger, divestiture,
          restructuring, reorganization, dissolution, or other sale or transfer
          of some or all of Devsoft Baltic OÜ’s assets, whether as a going
          concern or as part of bankruptcy, liquidation, or similar proceeding,
          in which Personal Data held by Devsoft Baltic OÜ about our Website’
          users is among the assets transferred;
        </li>
        <li>
          to fulfill the purpose for which you provide it. For example, if you
          give us a name and/or email address and post in public comments to a
          page, we will transmit your name and/or email address to anybody who
          views that comment page;
        </li>
        <li>
          for any other purpose disclosed by us when you provide the
          information; and
        </li>
        <li>with your consent.</li>
      </ul>
      <p>We may also disclose your Personal Data:</p>
      <ul>
        <li>
          to comply with any court order, law, or legal process, including to
          respond to any government or regulatory request;
        </li>
        <li>
          to enforce or apply our{' '}
          <Link to={links.termsOfUse} target="_blank" rel="noopener">
            terms of use
          </Link>{' '}
          and other agreements, including for billing and collection purposes;
          and
        </li>
        <li>
          if we believe disclosure is necessary or appropriate to protect the
          rights, property, or safety of Devsoft Baltic OÜ, our customers, or
          others. This includes exchanging information with other companies and
          organizations for the purposes of fraud protection and credit risk
          reduction.
        </li>
      </ul>
      <h2 id="choices">
        10. Choices About How We Use and Disclose Your Information
      </h2>

      <p>
        We offer you choices on how you can opt out of our use of tracking
        technology, disclosure of your Personal Data for third-parties to
        advertise to you, our advertising to you, and other targeted
        advertising.
      </p>

      <p>
        We do not control the collection and use of your information collected
        by third parties described above in{' '}
        <a href="#disclosure">Disclosure of Your Information</a>. When possible,
        these organizations are under contractual obligations to use this data
        only for providing the services to us and to maintain this information
        strictly confidential. These third parties may, however, aggregate the
        information they collect with information from their other customers for
        their own purposes.
      </p>
      <p>
        In addition, we strive to provide you with choices regarding the
        Personal Data you provide to us. We have created mechanisms to provide
        you with control over your Personal Data:
      </p>
      <ul>
        <li>
          <strong>Promotional Offers from BrewComputer Team</strong>. We will
          only use your email address to promote our own or third parties’
          Product and services with your express consent. If you wish to consent
          to such use, you can check the relevant box located on the form on
          which we collect your Personal Data or otherwise seek such consent.
          Your choices may be found by first navigating to the “Privacy
          settings” page and then navigating to the “Email Settings” section. If
          you wish to change your choice, you may do so at any time by checking
          or unchecking the relevant boxes in this “Email Settings” section or
          by sending us an email stating your request to{' '}
          <a
            href="mailto:info@devsoftbaltic.com"
            target="_blank"
            rel="noopener">
            info@devsoftbaltic.com
          </a>
          . If we have sent you a promotional email, you may unsubscribe by
          clicking the unsubscribe link at the bottom of the email. This opt out
          does not apply to information provided to DevSoft Baltic as a result
          of a product purchase, support emails, product service experience,
          security advisory messages (security fixes and updates) for the
          Product you have purchased from us, or other transactions.
        </li>
      </ul>
      <h2 id="11-Your-Rights-Regarding-Your-Information-and-Accessing-and-Correcting-Your-Information">
        11. Your Rights Regarding Your Information and Accessing and Correcting
        Your Information
      </h2>

      <p>
        You may have certain rights under applicable data protection laws,
        including the right to access and update your Personal Data, restrict
        how it is used, transfer certain Personal Data to another controller,
        withdraw your consent at any time, and the right to have us erase
        certain Personal Data about you. You also have the right to complain to
        a supervisory authority about our processing of your Personal Data.
      </p>

      <p>
        Applicable data protection laws may provide you with certain rights with
        regards to our processing of your Personal Data.
      </p>
      <ul>
        <li>
          <strong>Access and Update</strong>. You can review and change your
          Personal Data by logging into the Website and visiting the “Manage
          Profile” section of your “My Account” page. You may also notify us
          through the contact information below or through our Website’ live
          chat features of any changes or errors in any Personal Data we have
          about you to ensure that it is complete, accurate, and as current as
          possible. We may also not be able to accommodate your request if we
          believe it would violate any law or legal requirement or cause the
          information to be incorrect.
        </li>
        <li>
          <strong>Restrictions</strong>. You have the right to restrict our
          processing of your Personal Data under certain circumstances. In
          particular, you can request we restrict our use of it if you contest
          its accuracy, if the processing of your Personal Data is determined to
          be unlawful, or if we no longer need your Personal Data for
          processing, but we have retained it as permitted by law.
        </li>
        <li>
          <strong>Portability</strong>. To the extent the Personal Data you
          provide DevSoft Baltic is processed based on your consent, you have
          the right to request that we provide you a copy of, or access to, all
          or part of such Personal Data in structured, commonly used and
          machine-readable format. You also have the right to request that we
          transmit this Personal Data to another controller, when technically
          feasible.
        </li>
        <li>
          <strong>Withdrawal of Consent</strong>. To the extent that our
          processing of your Personal Data is based on your consent, you may
          withdraw your consent at any time by closing your account. Withdrawing
          your consent will not, however, affect the lawfulness of the
          processing based on your consent before its withdrawal, and will not
          affect the lawfulness of our continued processing that is based on any
          other lawful basis for processing your Personal Data.
        </li>
        <li>
          <strong>Right to be Forgotten</strong>. You have the right to request
          that we delete all of your Personal Data. We cannot delete your
          Personal Data except by also deleting your user account and revoking
          your licenses, and we will only delete your account when we no longer
          have a lawful basis for processing your Personal Data or after a final
          determination that your Personal Data was unlawfully processed. We may
          not accommodate a request to erase information if we believe the
          deletion would violate any law or legal requirement or cause the
          information to be incorrect. In all other cases, we will retain your
          Personal Data as set forth in this policy. In addition, we cannot
          completely delete your Personal Data as some data may rest in previous
          backups. These will be retained for the periods set forth in our
          disaster recovery policies. We also cannot delete your User
          Contributions, however proper access and use of information provided
          on the Website, including User Contributions, is governed by our{' '}
          <Link to={links.termsOfUse} target="_blank" rel="noopener">
            Terms of Use
          </Link>
          .
        </li>
        <li>
          <strong>Complaints</strong>. You have the right to lodge a complaint
          with the applicable supervisory authority in the country you live in,
          the country you work in, or the country where you believe your rights
          under applicable data protection laws have been violated. However,
          before doing so, we request that you contact us directly in order to
          give us an opportunity to work directly with you to resolve any
          concerns about your privacy.
        </li>
        <li>
          <strong>How You May Exercise Your Rights</strong>. You may exercise
          any of the above rights by contacting us through any of the methods
          below and through the live chat feature of our Website. If you contact
          us to exercise any of the foregoing rights, we may ask you for
          additional information to verify your identity. We reserve the right
          to limit or deny your request if you have failed to provide sufficient
          information to verify your identity or to satisfy our legal and
          business requirements.
        </li>
      </ul>
      <h2 id="12-Do-Not-Track-Signals">12. Do Not Track Signals</h2>

      <p>
        We do not track you over time and across other third-party websites or
        services and therefore We currently do not honor do-not-track signals
        that may be sent by some browsers.
      </p>

      <p>
        We do not track you over time and across third-party websites or other
        online services for any purpose. Some web browsers permit you to
        broadcast a signal to websites and online services indicating a
        preference that they “do not track” your online activities. At this
        time, we do not honor such signals and we do not modify what information
        we collect or how we use that information based upon whether such signal
        is broadcast or received.
      </p>
      <h2 id="13-Data-Security">13. Data Security</h2>

      <p>
        Information transmitted over the Internet is not completely secure, but
        We do our best to protect your Personal Data. You can help protect your
        Personal Data and other information by keeping your password to our
        Website confidential.
      </p>

      <p>
        We have implemented measures designed to secure your Personal Data from
        accidental loss and from unauthorized access, use, alteration, and
        disclosure.
      </p>
      <p>
        The safety and security of your information also depends on you. Where
        we have given you (or where you have chosen) a password for access to
        certain parts of our Website, you are responsible for keeping this
        password confidential. We ask you not to share your password with
        anyone. We urge you to be careful about giving out information in public
        areas of the Website like message boards. The information you share in
        public areas may be viewed by any user of the Website.
      </p>
      <p>
        Unfortunately, the transmission of information via the internet is not
        completely secure. Although we do our best to protect your Personal
        Data, we cannot guarantee the security of your Personal Data transmitted
        to our Website. Any transmission of Personal Data is at your own risk.
        We are not responsible for circumvention of any privacy settings or
        security measures contained on the Website.
      </p>
      <h2 id="14-Consent-to-Processing-of-Personal-Data-in-the-United-States">
        14. Consent to Processing of Personal Data in the United States
      </h2>

      <p>
        We may process your Personal Data outside of your home country,
        including to the United States. We only do this when we are legally
        permitted to do so and when we have appropriate safeguards in place to
        protect your Personal Data.
      </p>

      <p>
        If you are a resident of the European Economic Area (“
        <strong>EEA</strong>
        ”), in order to provide our Website, Product, and services to you, we
        may send and store your Personal Data outside of the EEA, including to
        the United States. Accordingly, your Personal Data may be transferred
        outside the country where you reside or are located, including to
        countries that may not or do not provide an adequate level of protection
        for your Personal Data.
      </p>
      <p>
        Your Personal Data is transferred by DevSoft Baltic to another country
        only if it is required or permitted under applicable data protection law
        and provided that there are appropriate safeguards in place to protect
        your Personal Data. By using our Website, you represent that you have
        read and understood the above and hereby consent to the storage and
        processing of Personal Data that you provide directly to us on our
        Website. To ensure your Personal Data processed by other recipients is
        treated in accordance with this Privacy Policy, DevSoft Baltic uses Data
        Processing Agreements between DevSoft Baltic and all other recipients of
        your data that include, where applicable, the Standard Contractual
        Clauses adopted by the European Commission (the “
        <strong>Standard Contractual Clauses</strong>
        ”). The European Commission has determined that the transfer of Personal
        Data pursuant to the Standard Contractual Clauses provides for an
        adequate level of protection of your Personal Data. Under these Standard
        Contractual Clauses, you have the same rights as if your data was not
        transferred to such third party. You may request a copy of the Data
        Processing Agreement by contacting us through the contact methods below.
      </p>
      <h2 id="15-Data-Retention-Periods">15. Data Retention Periods</h2>
      <p>
        We retain your Personal Data for as long as you keep your account open.
        In some instances, we may keep it after you close your account, for
        example we may keep it:
      </p>
      <ul>
        <li>on our backup and disaster recovery systems;</li>
        <li>for as long as necessary to protect our legal interests; and</li>
        <li>and to comply with other legal requirements.</li>
      </ul>
      <p>
        DevSoft Baltic will retain your personal data for the entire time that
        you keep your account open. After you close your account and request
        your information to be deleted, we may retain your Personal Data:
      </p>
      <ul>
        <li>for as long as necessary to comply with any legal requirement;</li>
        <li>
          on our backup and disaster recovery systems in accordance with our
          backup and disaster recovery policies and procedures;
        </li>
        <li>
          for as long as necessary to protect our legal interests or otherwise
          pursue our legal rights and remedies; and
        </li>
        <li>
          for data that has been aggregated or otherwise rendered anonymous in
          such a manner that you are no longer identifiable, indefinitely.
        </li>
      </ul>
      <h2 id="changes">16. Changes to Our Privacy Policy</h2>
      <p>
        We will post any changes to our Privacy Policy on our Website. If we
        make material changes to our Privacy Policy, we may notify you of such
        changes through your contact information and invite you to review (and
        accept, if necessary) the changes.
      </p>
      <p>
        We may change this Privacy Policy at any time. It is our policy to post
        any changes we make to our Privacy Policy on this page with a notice
        that the Privacy Policy has been updated on the Website’ home page. If
        we make material changes to how we treat our users’ Personal Data, we
        will notify you by email to the email address specified in your account
        and through a notice on the Website’ home page. The date this Privacy
        Policy was last revised is identified at the top of the page. You are
        responsible for ensuring we have an up-to-date active and deliverable
        email address for you, and for periodically visiting our Website and
        this Privacy Policy to check for any changes.
      </p>
      <h2 id="contact">17. Contact Information</h2>
      <p>
        If you have any questions, concerns, complaints or suggestions regarding
        our Privacy Policy, have any requests related to your Personal Data
        pursuant to applicable laws, or otherwise need to contact us, you must
        contact us at the contact information below or through the “Contact”
        page on our Website.
      </p>
      <h2 id="To-Contact-DevSoft-Baltic-Controller">
        To Contact DevSoft Baltic (Controller)
      </h2>
      <p>
        <strong>Reg. code:</strong>
      </p>
      <p>12851967</p>
      <p>
        <strong>VAT:</strong>
      </p>
      <p>EE101817626</p>
      <p>
        <strong>Legal address:</strong>
      </p>
      <p>
        Laeva tn 2, Kesklinna district, Tallinn city,
        <br /> Harju county, 10111, Republic of Estonia
      </p>
      <p>
        <strong>Office address:</strong>
      </p>
      <p>
        Technopolis campus office 10B3, Lõõtsa 8, Tallinn city,
        <br /> Harju county, 11415, Republic of Estonia
      </p>
      <p>
        <strong>EMail:</strong>
      </p>
      <p>
        <a href="mailto:info@devsoftbaltic.com" target="_blank" rel="noopener">
          info@devsoftbaltic.com
        </a>
      </p>
      <p>
        <strong>Phone:</strong>
      </p>
      <p>+372 5380 4575</p>
    </Page>
  )
}
