import { css } from 'styled-components'
import { colors } from '../colors/colors'

export const containerStyle = css`
  background-color: ${colors.WHITE};
  flex-direction: row;
  align-items: center;
  padding-right: 11px;
  border-radius: 4px;
  overflow: hidden;
  border-width: 1px;
  border-color: ${props => (props.focused ? colors.TEXT_LINE : 'transparent')};
`

export const searchIconStyle = css`
  position: absolute;
  top: 13px;
  left: 19px;
`

export const inputStyle = css`
  padding: 10px 11px 10px 46px;
  color: ${colors.BLACK};
`
