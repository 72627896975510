import { ls } from './common'
import { unitSettings as defaultUnitSettings } from '../../common/defaults'

const unitSettingsPath = 'unit_settings'

export const getCachedUnitSettings = () => {
  const unitSettings = ls.getItem(unitSettingsPath)
  return unitSettings != null ? unitSettings : defaultUnitSettings
}

export const setCachedUnitSettings = unitSettings => {
  return ls.setItem(unitSettingsPath, unitSettings)
}
