import styled, { css } from 'styled-components'
import { formStyle } from './style'

export const StyledForm = styled.div`
  ${formStyle};
`

const switchFormType = ({ type }) => {
  switch (type) {
    case '4-columns-1': {
      return css`
        @media (max-width: 576px) {
          width: 100%;
        }
        @media (min-width: 577px) and (max-width: 992px) {
          width: 33%;
        }
        @media (min-width: 992px) {
          width: 25%;
        }
      `
    }
    case '4-columns': {
      return css`
        @media (max-width: 576px) {
          width: 50%;
        }
        @media (min-width: 577px) and (max-width: 992px) {
          width: 33%;
        }
        @media (min-width: 992px) {
          width: 25%;
        }
      `
    }
    case '3-columns': {
      return css`
        width: 50%;
        @media (min-width: 992px) {
          width: 33%;
        }
      `
    }
    default: {
      return css`
        padding-right: 0;
        width: 100%;
      `
    }
  }
}

export const InputContainer = styled.div`
  padding-right: 24px;
  margin-bottom: 16px;
  box-sizing: border-box;
  ${switchFormType};
`
