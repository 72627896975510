import { get, isEmpty, map, startCase } from 'lodash'
import React, { PureComponent } from 'react'
import styled from 'styled-components'
import { textStyles, Logo } from '@brewcomputer/ui'
import {
  getFermentableFields,
  getCultureFields,
  getHopFields,
  getMiscFields
} from '@brewcomputer/utils'
import { RecipeNotFound } from '../specialPages/RecipeNotFound'
import { LoadingIndicator } from '../LoadingIndicator'
import TitleConstructor from '../TitleConstructor'
import { TablePrintable, getUnitLabel } from './TablePrintable'

const Row = styled.div`
  flex-direction: row;
  margin-top: 24px;
`
const Column = styled.div`
  margin-right: 24px;
  width: 50%;
`

const Page = styled.div`
  background-color: rgb(255, 255, 255);
  padding: 25px 25px 25px 35px;
  width: 210mm;
  height: 297mm;
  align-self: center;
`
const RecipeName = styled.div`
  ${textStyles.H1};
  background-color: transparent;
`
const Label = styled.span`
  ${textStyles.body2};
  background-color: transparent;
`

RecipeName.displayName = 'RecipeName'

export class RecipePrintable extends PureComponent {
  render() {
    const { recipe, user, stats } = this.props

    if (isEmpty(recipe) || recipe.isLoading) {
      return <LoadingIndicator />
    }

    if (recipe.isError || user.isError) {
      return <RecipeNotFound />
    }

    const fermentables = map(
      get(recipe, 'ingredients.fermentable_additions', []),
      getFermentableFields
    )

    const hops = map(get(recipe, 'ingredients.hop_additions', []), getHopFields)

    const cultures = map(
      get(recipe, 'ingredients.culture_additions', []),
      getCultureFields
    )

    const miscs = map(
      get(recipe, 'ingredients.miscellaneous_additions', []),
      getMiscFields
    )

    return (
      <Page>
        <TitleConstructor pageTitle={recipe.name} />
        <RecipeName>{recipe.name}</RecipeName>
        <Row>
          <Column>
            <Label>{`Recipe by ${recipe.author}`}</Label>
            <Label>{`Style: ${recipe.style.name}`}</Label>
            <Label>{`TYPE: ${startCase(recipe.type)}`}</Label>
            <Label>{`Batch Size: ${recipe.batch_size.value} ${
              recipe.batch_size.unit
            }`}</Label>
            <Label>{`Efficiency: ${recipe.efficiency.brewhouse.value} ${
              recipe.efficiency.brewhouse.unit
            }`}</Label>
          </Column>

          <Column>
            {map(stats, ({ caption, value, unit, units, path }) => (
              <Label key={caption}>{`${caption} ${value} ${getUnitLabel(
                units,
                unit
              )}`}</Label>
            ))}
          </Column>
          <Column>
            <Logo />
          </Column>
        </Row>
        <TablePrintable
          data={fermentables}
          caption="Fermentables"
          visibleColumns={['Amount', 'Name', 'Type', 'Color', 'Potential']}
        />
        <TablePrintable
          data={hops}
          caption="Hops"
          visibleColumns={['Amount', 'Name', 'Alpha', 'Use', 'Time']}
        />
        <TablePrintable
          data={cultures}
          caption="Yeast Culture"
          visibleColumns={['Amount', 'Name', 'Type', 'Attenuation', 'Form']}
        />
        <TablePrintable
          data={miscs}
          caption="Miscellaneous"
          visibleColumns={['Amount', 'Name', 'Type', 'Use', 'Time']}
        />
      </Page>
    )
  }
}
