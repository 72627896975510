import React, { Fragment } from 'react'
import { TabLink, Tabs } from '@brewcomputer/ui'
import { Route, Switch } from 'react-router-dom'
import { links } from '../../links'

import { AdminUserList } from './AdminUserList'
import { AdminUserActivity } from './AdminUserActivity'
import { AdminUserPage } from './AdminUserPage'
import { AdminMAU } from './AdminMAU'
import { AdminUserFeedback } from './AdminUserFeedback'
import { AdminUploads } from './AdminUploads'
import { renderTabLink } from '../navigator/Links'

const routeLinks = {
  admin: links.admin,
  mau: `${links.admin}/mau`,
  activity: `${links.admin}/user-activity`,
  feedback: `${links.admin}/feedback`,
  uploads: `${links.admin}/uploads`,
}

export const AdminPage = () => {
  return (
    <Fragment>
      <Tabs>
        <TabLink
          title="All Users"
          href={routeLinks.admin}
          renderLink={renderTabLink}
        />
        <TabLink title="MAU" href={routeLinks.mau} renderLink={renderTabLink} />
        <TabLink
          title="User Activity"
          href={routeLinks.activity}
          renderLink={renderTabLink}
        />
        <TabLink
          title="Feedback"
          href={routeLinks.feedback}
          renderLink={renderTabLink}
        />
        <TabLink
          title="Uploads"
          href={routeLinks.uploads}
          renderLink={renderTabLink}
        />
      </Tabs>
      <Switch>
        <Route path={routeLinks.mau} component={AdminMAU} />
        <Route path={routeLinks.activity} component={AdminUserActivity} />
        <Route path={routeLinks.feedback} component={AdminUserFeedback} />
        <Route path={routeLinks.uploads} component={AdminUploads} />
        <Route path={`${links.admin}/user/:userId`} component={AdminUserPage} />
        <Route path={routeLinks.admin} component={AdminUserList} />
      </Switch>
    </Fragment>
  )
}
