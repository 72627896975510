import React from 'react'
import { colors } from '@brewcomputer/ui'
import { AccentButton } from '@brewcomputer/ui'
import { Container, Background, Title, TextMessage } from './Styled'
import { wrongPage } from '../../texts'

const onPress = () => {
  location.reload()
}

export const WrongPage = () => (
  <Container>
    <Background backgroundColor={colors.BACKGROUND_PAGE} />
    <Title>{wrongPage.title}</Title>
    <TextMessage marginBottom="35px">{wrongPage.message}</TextMessage>
    <AccentButton title="Reload" onPress={onPress} />
  </Container>
)
