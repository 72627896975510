import React from 'react'
import PropTypes from 'prop-types'
import { CheckListTitle, CheckListContainer } from './Controls'
import { CheckList } from '../checkList/CheckList'

export const BatchCheckList = ({ title, checkList, onCheckItem }) => (
  <CheckListContainer>
    <CheckListTitle>{title}</CheckListTitle>
    <CheckList list={checkList} onCheckItem={onCheckItem} />
  </CheckListContainer>
)

BatchCheckList.propTypes = {
  title: PropTypes.string,
  checkList: PropTypes.array,
  onCheckItem: PropTypes.func
}

BatchCheckList.displayName = 'BatchCheckList'
