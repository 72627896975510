import React from 'react'
import { useStaticResolver } from '@resolve-js/react-hooks'
import { Helmet } from 'react-helmet-async'

const Header = () => {
  const resolveStatic = useStaticResolver()

  const meta = [
    {
      name: 'viewport',
      content: 'width=device-width, initial-scale=1'
    },
    {
      name: 'msapplication-TileColor',
      content: 'fff6dc'
    },
    {
      name: 'theme-color',
      content: '#ffffff'
    },
    {
      name: 'description',
      content: 'Beer brewing tools online'
    },
    {
      name: 'og:title',
      content: 'Brewcomputer'
    },
    {
      name: 'og:type',
      content: 'website'
    },
    {
      name: 'og:description',
      content: 'Beer brewing tools online'
    },
    {
      name: 'og:image',
      content: resolveStatic('/og-image-horizontal.png')
    }
  ]

  const faviconLink = {
    rel: 'icon',
    type: 'image/x-icon',
    href: resolveStatic('/favicon.ico')
  }

  const stylesheetLink = {
    rel: 'stylesheet',
    href: resolveStatic('/styles.css')
  }
  const fontLink = {
    rel: 'stylesheet',
    href:
      'https://fonts.googleapis.com/css?family=Poppins:400,500,600&display=swap'
  }
  const appleTouchIconLink = {
    rel: 'apple-touch-icon',
    sizes: '180x180',
    href: resolveStatic('/apple-touch-icon.png')
  }
  const iconLink = {
    rel: 'icon',
    type: 'image/png',
    sizes: '32x32',
    href: resolveStatic('/favicon-32x32.png')
  }
  const manifestLink = {
    rel: 'manifest',
    href: resolveStatic('/site.webmanifest')
  }
  const maskIconLink = {
    rel: 'mask-icon',
    href: resolveStatic('/safari-pinned-tab.svg')
  }

  return (
    <Helmet
      link={[
        faviconLink,
        stylesheetLink,
        fontLink,
        appleTouchIconLink,
        iconLink,
        manifestLink,
        maskIconLink
      ]}
      htmlAttributes={{ lang: 'en' }}
      meta={meta}
    />
  )
}

export default Header
