import { getMeasurable } from './units'
import { getMashStepDescription } from '../utils'

export function getMashProfileFields(profile) {
  return [
    {
      title: 'Name',
      path: 'name',
      data: {
        text: profile.name
      }
    },
    {
      title: 'Grain Temp',
      path: 'grain_temperature',
      data: { measurable: getMeasurable(profile, 'grain_temperature') }
    }
  ]
}

export function getMashStepFields(step, stepNumber) {
  return [
    {
      title: 'Step',
      path: `mash_steps[${stepNumber}].name`,
      data: {
        text: step.name
      }
    },
    {
      title: 'Description',
      path: `mash_steps[${stepNumber}].description`,
      readOnly: true,
      data: {
        text: getMashStepDescription(step)
      }
    },
    {
      title: 'Step Temp',
      path: `mash_steps[${stepNumber}].step_temperature`,
      data: { measurable: getMeasurable(step, 'step_temperature') }
    },
    {
      title: 'Time',
      path: `mash_steps[${stepNumber}].step_time`,
      data: { measurable: getMeasurable(step, 'step_time') }
    }
  ]
}

export function getBoilProfileFields(profile) {
  return [
    {
      title: 'Name',
      path: 'name',
      data: {
        text: profile.name
      }
    },
    {
      title: 'Pre Boil Size',
      readOnly: true,
      path: 'pre_boil_size',
      data: { measurable: getMeasurable(profile, 'pre_boil_size') }
    },
    {
      title: 'Boil Time',
      path: 'boil_time',
      data: { measurable: getMeasurable(profile, 'boil_time') }
    }
  ]
}

export function getBoilStepFields(step, stepNumber) {
  return [
    {
      title: 'Step',
      path: `boil_steps[${stepNumber}].name`,
      data: {
        text: step.name
      }
    },
    {
      title: 'Time',
      path: `boil_steps[${stepNumber}].step_time`,
      data: { measurable: getMeasurable(step, 'step_time') }
    },
    {
      title: 'Start Temp',
      path: `boil_steps[${stepNumber}].start_temperature`,
      data: { measurable: getMeasurable(step, 'start_temperature') }
    },
    {
      title: 'End Temp',
      path: `boil_steps[${stepNumber}].end_temperature`,
      data: { measurable: getMeasurable(step, 'end_temperature') }
    }
  ]
}

export function getFermentationProfileFields(profile) {
  return [
    {
      title: 'Name',
      path: 'name',
      data: {
        text: profile.name
      }
    }
  ]
}

export function getFermentationStepFields(step, stepNumber) {
  return [
    {
      title: 'Step',
      path: `fermentation_steps[${stepNumber}].name`,
      data: {
        text: step.name
      }
    },
    {
      title: 'Time',
      path: `fermentation_steps[${stepNumber}].step_time`,
      data: { measurable: getMeasurable(step, 'step_time') }
    },
    {
      title: 'Start Temp',
      path: `fermentation_steps[${stepNumber}].start_temperature`,
      data: { measurable: getMeasurable(step, 'start_temperature') }
    },
    {
      title: 'End Temp',
      path: `fermentation_steps[${stepNumber}].end_temperature`,
      data: { measurable: getMeasurable(step, 'end_temperature') }
    }
  ]
}
