import { map } from 'lodash'
import React from 'react'
import PropTypes from 'prop-types'

export const Icon = ({ width, height, fill, paths, style, className }) => {
  const svgPaths = map(paths, (path, index) => {
    return <path key={index} fill={fill} d={path} />
  })
  return (
    <svg style={style} className={className} width={width} height={height}>
      {svgPaths}
    </svg>
  )
}

Icon.propTypes = {
  width: PropTypes.number,
  height: PropTypes.number,
  fill: PropTypes.string,
  paths: PropTypes.arrayOf(PropTypes.string),

  style: PropTypes.any
}
