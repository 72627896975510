import PropTypes from 'prop-types'
import { isEmpty } from 'lodash'
import React, { PureComponent } from 'react'
import styled from 'styled-components'
import figmaColors from '../colors/figmaColors'
import { ChatInput } from './ChatInput'
import { ChatIcon } from '../icons/ChatIcon'
import { textStyles } from '../texts/textStyles'

const Container = styled.div`
  flex: 1;
  overflow: hidden;
  justify-content: space-between;
  background-color: ${figmaColors.blue_gray_50};
`

const MessageList = styled.div`
  padding: 12px;
  overflow: auto;
`

const EmptyChat = styled.div`
  flex: 1;
  align-items: center;
  justify-content: center;
`

const EmptyChatMessage = styled.div`
  ${textStyles.H2};
  color: ${figmaColors.blue_gray_900};
  padding: 12px 32px;
  text-align: center;
`

export class Chat extends PureComponent {
  static propTypes = {
    children: PropTypes.any,
    showInput: PropTypes.bool,
    emptyChatMessage: PropTypes.string,
    onSendMessage: PropTypes.func
  }

  static defaultProps = {
    showInput: true
  }

  render() {
    const { children, emptyChatMessage, onSendMessage, showInput } = this.props
    return (
      <Container>
        {isEmpty(children) ? (
          <EmptyChat>
            <ChatIcon />
            <EmptyChatMessage>{emptyChatMessage}</EmptyChatMessage>
          </EmptyChat>
        ) : (
          <MessageList>{children}</MessageList>
        )}
        <ChatInput showInput={showInput} onSendMessage={onSendMessage} />
      </Container>
    )
  }
}
