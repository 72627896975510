import React from 'react'
import { Helmet } from 'react-helmet-async'
import { isEmpty } from 'lodash'

const TitleConstructor = ({ pageTitle }) => (
  <Helmet
    title={isEmpty(pageTitle) ? `Brewcomputer` : `${pageTitle} - Brewcomputer`}
  />
)

export default TitleConstructor
