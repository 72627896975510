import { map, has, isEmpty } from 'lodash'
import PropTypes from 'prop-types'
import React, { PureComponent, Fragment } from 'react'
import { Stats } from '@brewcomputer/ui'
import { StyleBars } from './StyleBars'
import { convertStat } from '@brewcomputer/utils'
import { ResponsiveContainer, RecipeDataContainer, Separator } from './Styled'

export class RecipeLayout extends PureComponent {
  state = {
    stats: null,
  }

  getCurrentStats = () => {
    if (isEmpty(this.state.stats)) {
      return this.props.stats
    }
    return this.state.stats
  }

  onChangeUnit = (changes) => {
    const convertedStats = map(this.getCurrentStats(), (stat, index) => {
      if (has(changes, stat.key)) {
        return convertStat(this.props.stats[index], changes[stat.key])
      }
      return stat
    })
    this.setState({ stats: convertedStats })
  }

  render() {
    const { color, children } = this.props
    const stats = this.getCurrentStats()

    return (
      <Fragment>
        <Stats stats={stats} color={color} onChangeUnit={this.onChangeUnit} />
        <ResponsiveContainer>
          <RecipeDataContainer>{children}</RecipeDataContainer>
          <Separator />
          <StyleBars
            title="Style Guide Comparison"
            data={stats}
            onChangeUnit={this.onChangeUnit}
          />
        </ResponsiveContainer>
      </Fragment>
    )
  }
}

RecipeLayout.propTypes = {
  stats: PropTypes.array,
  color: PropTypes.string,
}
