import React from 'react'
import styled, { css } from 'styled-components'
import { size_1, size_3, size_4, size_8 } from '../spacing/spacing'
import { textStyles, colors } from '../index'
import figmaColors from '../colors/figmaColors'
import { GoogleIcon, EmailIcon, AnonymousIcon } from '../icons'

const Title = styled.div`
  align-self: stretch;
  justify-content: center;
  padding: ${size_3} ${size_8} ${size_3} ${size_4};
  color: ${figmaColors.white};
  ${textStyles.body2};
  font-weight: 600;
  transition-duration: 200ms;
  border-left: 1px solid ${figmaColors.blue_400};
`

const linkStyles = css`
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-bottom: ${size_3};
  border-radius: ${size_1};
  overflow: hidden;
  cursor: pointer;
  background-color: ${figmaColors.blue_500};
  transition-duration: 200ms;

  box-shadow: 0px 4px 10px ${colors.ACCENT_BLUE_SHADOW};
  :hover,
  :focus {
    background-color: ${colors.ACCENT_BLUE_HOVER};
    box-shadow: 0px 8px 16px ${colors.ACCENT_BLUE_HOVER_SHADOW};
    ${Title} {
      border-left: 1px solid ${figmaColors.blue_300};
    }
  }
  :active {
    background-color: ${colors.ACCENT_BLUE_ACTIVE};
    box-shadow: 0px 8px 16px ${colors.ACCENT_BLUE_ACTIVE_SHADOW};
  }
`

const Link = styled.a`
  ${linkStyles};
`

const Button = styled.button`
  ${linkStyles};
`

const IconContainer = styled.div`
  padding: ${size_3} ${size_4};
`

export const AuthLink = ({ Icon, title, href, onPress }) => {
  const Wrapper = href != null ? Link : Button
  return (
    <Wrapper href={href} onClick={onPress}>
      <IconContainer>{Icon}</IconContainer>
      <Title>{title}</Title>
    </Wrapper>
  )
}

export const NoLoginAuthLink = ({ title, href }) => (
  <AuthLink title={title} Icon={<AnonymousIcon />} href={href} />
)

export const GoogleAuthLink = ({ title, href }) => (
  <AuthLink title={title} Icon={<GoogleIcon />} href={href} />
)

export const EmailAuthLink = ({ title, onPress }) => (
  <AuthLink title={title} Icon={<EmailIcon />} onPress={onPress} />
)
