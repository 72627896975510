import React from 'react'
import { Redirect, Route, Switch } from 'react-router-dom'
import { links } from './links'
import { Page } from '@brewcomputer/ui'
import { App } from './containers/App'
import { BatchList } from './containers/BatchList'
import RecipeCalculator, { getRecipeId } from './containers/RecipeCalculator'
import { RecipesList } from './containers/RecipesList'
import BatchView from './containers/BatchView'
import { Settings } from './containers/Settings'
import RecipePrintable from './containers/RecipePrintable'
import { UserEquipment } from './components/equipment/UserEquipment'
import { PageNotFound } from './components/specialPages/PageNotFound'

import { AdminPage } from './components/admin/AdminPage'

import { Unauthorized } from './components/specialPages/Unauthorized'
import { LandingPage } from './components/specialPages/LandingPage'
import { PrivacyPolicy } from './components/policyPages/PrivacyPolicy'
import { TermsOfUse } from './components/policyPages/TermsOfUse'
import { CookiePolicy } from './components/policyPages/CookiePolicy'
import { AuthTokenExpired } from './components/specialPages/AuthTokenExpired'
import { AuthError } from './components/specialPages/AuthError'
import { Register } from './components/specialPages/Register'
import { NeedLogin } from './components/specialPages/NeedLogin'
import { needLogin } from './texts'

export const Router = ({ isAuthorized, isAdmin, isAnonymous }) => {
  if (!isAuthorized) {
    return (
      <Unauthorized>
        <Switch>
          <Route exact path={links.index} component={LandingPage} />

          <Route path={links.termsOfUse} component={TermsOfUse} />
          <Route path={links.privacyPolicy} component={PrivacyPolicy} />
          <Route path={links.cookiePolicy} component={CookiePolicy} />

          <Route path={links.authEmailRegister} component={Register} />
          <Route path={links.authError} component={AuthError} />
          <Route
            path={`${links.authTokenExpired}:token`}
            component={AuthTokenExpired}
          />

          {/* TODO: view-model update workaround */}
          <Route
            path={`${links.recipe}:recipeId`}
            render={(routeProps) => (
              <Page>
                <RecipeCalculator
                  key={getRecipeId(routeProps)}
                  {...routeProps}
                  unauthorized
                />
              </Page>
            )}
          />
          {/* TODO: view-model update workaround */}

          <Redirect from={'*'} to={links.index} />
        </Switch>
      </Unauthorized>
    )
  }

  if (isAnonymous) {
    return (
      <App>
        <Switch>
          <Route path={links.authEmailRegister} component={Register} />
          <Route path={links.recipes} component={RecipesList} />
          <Route
            path={`${links.recipePrintable}:recipeId`}
            component={RecipePrintable}
          />

          {/* TODO: view-model update workaround */}
          <Route
            path={`${links.recipe}:recipeId`}
            render={(routeProps) => (
              <RecipeCalculator key={getRecipeId(routeProps)} {...routeProps} />
            )}
          />
          {/* TODO: view-model update workaround */}

          <Route
            path={links.batches}
            render={() => <NeedLogin title={needLogin.batch} />}
          />
          <Route
            path={links.equipment}
            render={() => <NeedLogin title={needLogin.equipment} />}
          />
          <Route
            path={links.settings}
            render={() => <NeedLogin title={needLogin.settings} />}
          />

          <Redirect exact from={links.index} to={links.recipes} />

          <Route path={links.termsOfUse} component={TermsOfUse} />
          <Route path={links.privacyPolicy} component={PrivacyPolicy} />
          <Route path={links.cookiePolicy} component={CookiePolicy} />

          <Route path={'*'} component={PageNotFound} />
        </Switch>
      </App>
    )
  }

  return (
    <App>
      <Switch>
        <Route path={links.authEmailRegister} component={Register} />
        <Route path={links.recipes} component={RecipesList} />
        <Route
          path={`${links.recipePrintable}:recipeId`}
          component={RecipePrintable}
        />

        {/* TODO: view-model update workaround */}
        <Route
          path={`${links.recipe}:recipeId`}
          render={(routeProps) => (
            <RecipeCalculator key={getRecipeId(routeProps)} {...routeProps} />
          )}
        />
        <Route
          path={`${links.batchRecipe}:recipeId`}
          render={(routeProps) => (
            <RecipeCalculator key={getRecipeId(routeProps)} {...routeProps} />
          )}
        />
        {/* TODO: view-model update workaround */}

        <Route path={`${links.batch}:batchId`} component={BatchView} />
        <Route path={links.batches} component={BatchList} />
        <Route path={links.equipment} component={UserEquipment} />
        <Route path={links.settings} component={Settings} />

        {isAdmin && <Route path={`${links.admin}`} component={AdminPage} />}

        <Redirect exact from={links.index} to={links.recipes} />
        <Route path={links.termsOfUse} component={TermsOfUse} />
        <Route path={links.privacyPolicy} component={PrivacyPolicy} />
        <Route path={links.cookiePolicy} component={CookiePolicy} />
        <Redirect from={links.auth} to={links.recipes} />
        <Route path={'*'} component={PageNotFound} />
      </Switch>
    </App>
  )
}
