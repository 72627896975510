import React, { Fragment } from 'react'
import PropTypes from 'prop-types'
import { Ingredients } from '../ingredients/Ingredients'
import { Procedure } from '../procedure/Procedure'
import { RecipeEquipment } from '../equipment/RecipeEquipment'
import { LoadingIndicator } from '../LoadingIndicator'

export const RecipeData = ({ recipeId, isUserRegistered, isLoading }) => {
  if (isLoading) {
    return <LoadingIndicator />
  }

  return (
    <Fragment>
      <Ingredients recipeId={recipeId} ingredientType="fermentable_additions" />
      <Ingredients recipeId={recipeId} ingredientType="hop_additions" />
      <Ingredients recipeId={recipeId} ingredientType="culture_additions" />
      <Ingredients
        recipeId={recipeId}
        ingredientType="miscellaneous_additions"
      />
      <Ingredients
        recipeId={recipeId}
        ingredientType="water_additions"
        replace
      />
      <Procedure recipeId={recipeId} procedureType="mash" />
      <Procedure recipeId={recipeId} procedureType="boil" />
      <Procedure recipeId={recipeId} procedureType="fermentation" />
      <RecipeEquipment recipeId={recipeId} showLookup={isUserRegistered} />
    </Fragment>
  )
}

RecipeData.propTypes = {
  recipeId: PropTypes.string.isRequired,
  isLoading: PropTypes.bool,
  isAnonymous: PropTypes.bool,
}
