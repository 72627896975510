import { get, includes, toLower, filter, isEmpty } from 'lodash'
import React, { useEffect, useState, Fragment } from 'react'
import { useSelector } from 'react-redux'
import { useGetMyBatches, useCountBatches } from '../hooks/queries'
import { PageTitle } from '@brewcomputer/ui'
import { BatchesListView } from '../components/lists/BatchesListView'
import { LoadingIndicator } from '../components/LoadingIndicator'
import { RecipeListControls } from './RecipeListControls'
import { searchPlaceholders } from '../texts'
import { useRemoveBatch } from '../hooks/commands'

export const BatchList = () => {
  const batches = useSelector(state => state.batches.data)
  const showLoading = useSelector(
    state => state.batches.isLoading && isEmpty(batches)
  )

  const { request: getMyBatches } = useGetMyBatches()
  const { request: countBatches } = useCountBatches()
  const removeBatch = useRemoveBatch()

  useEffect(() => {
    getMyBatches()
    countBatches()
  }, [])

  const [search, setSearch] = useState('')

  const filteredBatches = filter(batches, batch => {
    const batchName = get(batch, 'info.name', '')
    return includes(toLower(batchName), toLower(search))
  })

  return (
    <Fragment>
      <PageTitle title={'My Batches'} />
      <RecipeListControls
        showButtons={false}
        searchPlaceholder={searchPlaceholders.batches}
        onSearch={setSearch}
      />
      {showLoading ? (
        <LoadingIndicator />
      ) : (
        <BatchesListView
          batches={filteredBatches}
          onRemoveBatch={removeBatch}
        />
      )}
    </Fragment>
  )
}
