import React, { Fragment, useCallback } from 'react'
import PropTypes from 'prop-types'
import { ProcedureProfile } from './ProcedureProfile'
import { ProcedureSteps } from './ProcedureSteps'
import { useEditProcedure } from '../../hooks/commands'
import { merge } from 'lodash'
import { useSelector } from 'react-redux'
import { getProcedure } from '../../selectors/recipe'

export const Procedure = ({ recipeId, procedureType }) => {
  const profile = useSelector(getProcedure(recipeId, procedureType))
  const editProcedure = useEditProcedure(recipeId, procedureType)
  const onEditProcedure = useCallback(
    changes => {
      editProcedure(merge({}, profile, changes))
    },
    [profile]
  )
  return (
    <Fragment>
      <ProcedureProfile
        recipeId={recipeId}
        procedure={profile}
        procedureType={procedureType}
        onEditProcedure={onEditProcedure}
        onReplaceProcedure={editProcedure}
      />
      <ProcedureSteps
        recipeId={recipeId}
        procedure={profile}
        procedureType={procedureType}
        onEditProcedure={onEditProcedure}
      />
    </Fragment>
  )
}

Procedure.propTypes = {
  recipeId: PropTypes.string,
  procedureType: PropTypes.oneOf(['mash', 'boil', 'fermentation'])
}
