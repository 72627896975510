import produce from 'immer'
import { remove } from 'lodash'
import {
  recipesMyGetQuery,
  recipesMyCountQuery,
  recipeRemoveAction
} from '../actions/recipe'

export const initialState = {
  isLoading: true,
  data: [],
  count: 0
}

const reducer = produce((draft, action) => {
  switch (action.type) {
    case recipesMyGetQuery.types.request: {
      draft.isLoading = true
      return draft
    }
    case recipesMyGetQuery.types.success: {
      draft.data = action.payload.result
      draft.isLoading = false
      return draft
    }
    case recipesMyGetQuery.types.failure: {
      draft.isLoading = false
      return draft
    }
    case recipesMyCountQuery.types.success: {
      draft.count = action.payload.result
      return draft
    }
    case recipeRemoveAction.types.request: {
      const { recipeId } = action.payload
      remove(draft.data, { id: recipeId })
      return draft
    }
  }
}, initialState)

export default reducer
