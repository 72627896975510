const { getBrewOrigin } = require('../domain/secret/env')

const google = {
  module_path: 'auth/google',
  auth_link: '/api/auth/google',
  get_callback_url: () => `${getBrewOrigin()}/api/auth/google`
}

const email = {
  module_path_login: 'auth/email/login',
  module_path_register: 'auth/email/register',

  auth_link_login: '/api/auth/email/login',
  auth_link_register: '/api/auth/email/register',

  email_sender_url: '/api/auth/email/send-verification',

  get_magic_link: token =>
    `${getBrewOrigin()}/api/auth/email/login?token=${token}`
}

const anonymous = {
  module_path: 'auth/no-login',
  auth_link: '/api/auth/no-login'
}

const test = {
  module_path: 'auth/test',
  auth_link: '/api/auth/test',
  get_test_login_url: (origin, username) =>
    `${origin}/api/auth/test?username=${username}`
}

const logout = {
  module_path: 'auth/logout',
  logout_link: '/api/auth/logout'
}

module.exports = {
  google,
  email,
  anonymous,
  test,
  logout
}
