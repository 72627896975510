import React from 'react'
import { colors } from '../colors/colors'

export const RecipesIcon = ({ fill, ...props }) => (
  <svg
    {...props}
    width="24"
    height="24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M18 2h-2.268A2 2 0 0 0 14 1h-4a2 2 0 0 0-1.732 1H6a2 2 0 0 0-2 2v16a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V4a2 2 0 0 0-2-2zM9 3a1 1 0 0 1 1-1h4a1 1 0 1 1 0 2h-4a1 1 0 0 1-1-1zM7 8h10v2H7V8zm10 4H7v2h10v-2zM7 16h10v2H7v-2z"
      fill={fill}
    />
  </svg>
)

RecipesIcon.defaultProps = {
  fill: colors.GRAY
}
