import React from 'react'
import { colors } from '@brewcomputer/ui'
import { Link } from 'react-router-dom'
import { links } from '../../links'
import { Container, Background, Title, TextMessage } from './Styled'
import { pageNotFound } from '../../texts'

const Icon404 = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="#000000"
    fillOpacity="0.02"
    viewBox="0 0 1200 453">
    <path d="M0 370.551v-89.964l203.205-278.7h115.758v274.925h53.475v93.739h-53.475v81.785H210.126v-81.785H0zm218.933-244.098L109.467 276.812h109.466V126.453zm202.498 98.772c0 69.622 14.05 124.775 42.151 165.458 28.101 40.683 72.978 61.024 134.631 61.024 61.235 0 105.902-20.341 134.003-61.024 28.52-40.683 42.78-95.836 42.78-165.458 0-69.204-14.26-123.937-42.78-164.2C704.115 20.34 659.448 0 598.213 0c-61.653 0-106.53 20.341-134.631 61.025-28.101 40.263-42.151 94.996-42.151 164.2zm246.615 0c0 39.844-4.824 70.671-14.47 92.48-9.227 21.39-27.681 32.085-55.363 32.085-28.1 0-46.974-10.695-56.62-32.085-9.647-21.809-14.47-52.636-14.47-92.48 0-39.425 4.823-69.833 14.47-91.223 9.646-21.39 28.52-32.085 56.62-32.085 27.682 0 46.136 10.695 55.363 32.085 9.646 20.971 14.47 51.378 14.47 91.223zm159.516 145.326v-89.964l203.208-278.7h115.75v274.925H1200v93.739h-53.48v81.785h-108.83v-81.785H827.562zm218.928-244.098L937.028 276.812h109.462V126.453z" />
  </svg>
)

export const PageNotFound = () => (
  <Container>
    <Background backgroundColor={colors.BACKGROUND_PAGE} />
    <Title>{pageNotFound.title}</Title>
    <TextMessage marginBottom="80px">
      {pageNotFound.message1}
      <Link to={links.recipes}>{pageNotFound.link}</Link>
      {pageNotFound.message2}
    </TextMessage>
    <Icon404 />
  </Container>
)
