import React, { PureComponent } from 'react'
import styled from 'styled-components'
import { colors } from '../colors/colors'

export const Container = styled.div`
  position: relative;
  flex: 1;
  flex-direction: column;
  align-items: center;
  background-color: ${colors.BACKGROUND};

  @media (max-width: 576px) {
    padding-left: 8px;
    padding-right: 8px;
  }

  @media (min-width: 577px) and (max-width: 992px) {
    padding-left: 32px;
    padding-right: 32px;
  }

  @media (min-width: 992px) {
    padding-left: 52px;
    padding-right: 52px;
  }
`

const Content = styled.main`
  display: flex;
  flex-direction: column;
  width: 100%;
  max-width: 1200px;
  padding-top: 16px;
`

export class Page extends PureComponent {
  render() {
    return (
      <Container className="page">
        <Content>{this.props.children}</Content>
      </Container>
    )
  }
}
