import { map, isEmpty } from 'lodash'
import styled from 'styled-components'
import React, { useEffect } from 'react'
import { LoadingIndicator } from '../LoadingIndicator'
import {
  Card,
  textStyles,
  colors,
  BatchesIcon,
  FeedbackIcon,
  CopyIcon,
  Avatar,
} from '@brewcomputer/ui'
import { Link } from 'react-router-dom'
import { getRecipeLink } from '../../links'
import { Feedback } from '../feedback/Feedback'
import { useGetUserStats } from '../../hooks/queries'
import { useSelector } from 'react-redux'

const Label = styled.div`
  ${textStyles.smallText};
  border-radius: 4px;
  color: ${colors.WHITE};
  background-color: ${colors.GREEN};
  width: 68px;
  height: 22px;
  align-items: center;
  justify-content: center;
`

const RecipeLabels = styled.div`
  flex-direction: row;
  margin-bottom: 4px;
`

const Labels = ({ recipe }) => {
  const labels = []
  if (recipe.isBatch) {
    labels.push('Batch')
  }
  if (recipe.isClone) {
    labels.push('Clone')
  }
  if (recipe.shared) {
    labels.push('Shared')
  }
  if (recipe.published) {
    labels.push('Published')
  }
  if (isEmpty(labels)) {
    return null
  }
  return (
    <RecipeLabels>
      {map(labels, (label) => (
        <Label key={label}>{label}</Label>
      ))}
    </RecipeLabels>
  )
}

const Container = styled.div`
  flex-direction: row;
  align-items: flex-start;
  justify-content: center;
`

const Title = styled.div`
  ${textStyles.body1};
  font-weight: 500;
  color: ${colors.BLACK};
  padding: 24px;
`

const UserName = styled.div`
  ${textStyles.body1};
  color: ${colors.BLACK};
  font-weight: 500;
  margin-top: 24px;
`

const UserField = styled.div`
  ${textStyles.body2};
  color: ${colors.TABLE_GRAY};
  margin-top: 24px;
`

const Profile = styled(Card)`
  padding: 24px 0;
  min-width: 200px;
  align-items: center;
`

const Stat = styled.div`
  flex-direction: row;
  align-items: center;
  svg {
    margin-right: 6px;
    path {
      fill: ${colors.TABLE_GRAY};
    }
  }
`

const RecipeStats = styled.div`
  margin-top: 8px;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
`

const Recipe = styled(Link)`
  padding: 12px 24px;
  ${textStyles.body2};
  color: ${colors.TABLE_GRAY};
  border-top: 1px solid ${colors.DIVIDER};
`

const Recipes = styled(Card)`
  min-width: 320px;
  margin: 0 24px;
`

const FeedbackContainer = styled(Card)`
  min-width: 320px;
`

export const AdminUserPage = ({ match }) => {
  const { request: getUserStats, selector } = useGetUserStats({
    userId: match.params.userId,
  })

  useEffect(() => {
    getUserStats()
  }, [])

  const { data: user, status } = useSelector(selector)
  const admin = useSelector((state) => state.user)

  if (status !== 'ready') {
    return <LoadingIndicator />
  }

  return (
    <Container>
      <Profile>
        <Avatar src={user.avatar} size={100} alt={user.name} />
        <UserName>{user.name}</UserName>
        <UserField>{user.brewery}</UserField>
        <UserField>{user.info}</UserField>
      </Profile>
      <Recipes>
        <Title>Recipes</Title>
        {map(user.recipes, (recipe, id) => (
          <Recipe key={id} to={getRecipeLink(id)} recipe={recipe}>
            <Labels recipe={recipe} />
            <span>{recipe.name}</span>
            <RecipeStats>
              <Stat>
                <FeedbackIcon />
                {recipe.commentCount}
              </Stat>
              <Stat>
                <BatchesIcon />
                {recipe.brewsCount}
              </Stat>
              <Stat>
                <CopyIcon />
                {recipe.clonesCount}
              </Stat>
            </RecipeStats>
          </Recipe>
        ))}
      </Recipes>
      <FeedbackContainer>
        <Title>Feedback</Title>
        <Feedback
          author={admin}
          treeId={user.id}
          isAuthorized={true}
          showInput={true}
          hideTitle
        />
      </FeedbackContainer>
    </Container>
  )
}
