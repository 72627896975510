import { withRouter } from 'react-router'
import { connect } from 'react-redux'
import { Navigator as NavigatorComponent } from '../components/navigator/Navigator'
import { isEmpty } from 'lodash'
import { onChangeLocation } from '../redux/actions/navigation'
import { hideAlert } from '../redux/actions/alert'
import { isAdmin, isAnonymous } from '../../domain/users'

const mapStateToProps = state => ({
  isAuthorized: !isEmpty(state.jwt),
  isAdmin: isAdmin(state.user),
  isAnonymous: isAnonymous(state.user),
  user: state.user,
  tabs: state.navigation,
  alert: state.alert
})

const mapDispatchToProps = dispatch => ({
  onChangeLocation: location => dispatch(onChangeLocation(location)),
  hideAlert: () => dispatch(hideAlert())
})

export const Navigator = withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(NavigatorComponent)
)
