import { map, set } from 'lodash'
import PropTypes from 'prop-types'
import React, { PureComponent } from 'react'
import { Form } from '../form/Form'
import { Container, Title } from './Styled'
import { MeasurableEditor } from '../editors/MeasurableEditor'

export class EquipmentItem extends PureComponent {
  static displayName = 'EquipmentItem'

  static propTypes = {
    type: PropTypes.string,
    fields: PropTypes.arrayOf(
      PropTypes.shape({
        caption: PropTypes.string,
        path: PropTypes.string,
        data: PropTypes.object
      })
    ),
    onChange: PropTypes.func
  }

  onChange = path => data => {
    const changes = {}
    set(changes, path, data)
    this.props.onChange(changes)
  }

  render() {
    const [title, ...fields] = this.props.fields
    return (
      <Container>
        <Title>{title.data.text}</Title>
        <Form type={'3-columns'}>
          {map(fields, ({ caption, data, path }, index) => (
            <MeasurableEditor
              key={index}
              title={caption}
              value={data.value}
              unit={data.unit}
              unitLabel={data.unit}
              units={data.units}
              onChange={this.onChange(path)}
              singleLine={true}
            />
          ))}
        </Form>
      </Container>
    )
  }
}
