import React, { useState } from 'react'
import { Container, Title } from './Styled'
import { EmailAuthLink, GoogleAuthLink, Card } from '@brewcomputer/ui'
import { landingPage } from '../../texts'
import { google } from '../../../auth/endpoints'
import { EmailAuth } from './EmailAuth'
import styled from 'styled-components'

export const Links = styled.div`
  margin-top: 16px;
  @media (min-width: 992px) {
    flex-direction: row;
    a {
      margin-right: 16px;
    }
  }
`

export const StyledCard = styled(Card)`
  padding: 16px;
  margin-bottom: 16px;
  align-items: center;
  justify-content: center;
`

export const StyledTitle = styled(Title)`
  margin: 0;
`

export const NeedLogin = ({ title, inset = false }) => {
  const [modalVisible, setModalVisible] = useState(false)
  const showModal = () => {
    setModalVisible(true)
  }
  const hideModal = () => {
    setModalVisible(false)
  }

  const Wrapper = inset ? StyledCard : Container
  const Message = inset ? StyledTitle : Title

  return (
    <Wrapper>
      <Message>{title}</Message>
      <Links>
        <GoogleAuthLink
          title={landingPage.authButtons.google}
          href={google.auth_link}
        />
        <EmailAuthLink
          title={landingPage.authButtons.email}
          onPress={showModal}
        />
      </Links>
      <EmailAuth visible={modalVisible} onClose={hideModal} />
    </Wrapper>
  )
}

NeedLogin.displayName = 'NeedLogin'
