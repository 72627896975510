import { SHOW_ALERT, HIDE_ALERT } from '../actions/alert'
import { LOCATION_CHANGE } from '../actions/navigation'

export default function alert(state = {}, action) {
  switch (action.type) {
    case SHOW_ALERT: {
      const { type, title, message } = action.payload
      return {
        type,
        title,
        message
      }
    }
    case LOCATION_CHANGE:
    case HIDE_ALERT: {
      return {}
    }
  }

  return state
}
