import { isEmpty, trim } from 'lodash'
import React, { PureComponent } from 'react'
import PropTypes from 'prop-types'
import { Container, StyledIcon, StyledInput } from './Styled'
import { ClearButton } from '../buttons/ClearButton'
import { colors } from '../colors/colors'

export class Search extends PureComponent {
  static propTypes = {
    lastQuery: PropTypes.string,
    label: PropTypes.string,
    placeholder: PropTypes.string,
    timeout: PropTypes.number,
    onSearch: PropTypes.func
  }

  constructor(props) {
    super(props)
    this._timeout = null
    this._inputRef = React.createRef()
    this.state = {
      value: props.lastQuery || '',
      focused: false
    }
  }

  onFocus = () => {
    this.setState({ focused: true })
  }

  onBlur = () => {
    this.setState({ focused: false })
  }

  onChange = value => {
    const { timeout, onSearch } = this.props

    const req = trim(value)
    if ((!isEmpty(req) && req !== trim(this.state.value)) || isEmpty(value)) {
      if (this._timeout) {
        clearTimeout(this._timeout)
      }
      this._timeout = setTimeout(() => {
        onSearch(trim(value))
      }, timeout)
    }
    this.setState({ value })
  }

  clear = () => {
    if (!isEmpty(this.state.value)) {
      this.onChange('')
      this._inputRef.current.focus()
    }
  }

  render() {
    const { placeholder, label } = this.props
    const { value, focused } = this.state
    return (
      <Container focused={focused}>
        <StyledInput
          ref={this._inputRef}
          placeholder={placeholder}
          value={value}
          inputProps={{ 'aria-label': label }}
          onFocus={this.onFocus}
          onSubmit={this.onChange}
          onBlur={this.onBlur}
          onChange={this.onChange}
        />
        <StyledIcon
          focused={focused}
          fill={focused ? colors.GRAY : colors.ICON_GRAY}
        />
        {!isEmpty(value) && (
          <ClearButton
            accessibilityLabel={'Clear search'}
            onPress={this.clear}
          />
        )}
      </Container>
    )
  }
}

Search.displayName = 'Search'
