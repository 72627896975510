import {
  recipesPublicCountQuery,
  recipesPublicGetQuery,
  recipesSearch,
  recipesMyGetQuery,
  recipesMyCountQuery,
} from '../redux/actions/recipe'
import { batchesCountQuery, batchesGetQuery } from '../redux/actions/batch'
import { useReduxReadModel } from '@resolve-js/redux'
import { userGetQuery } from '../redux/actions/user'

export const useGetPublicRecipes = ({ pageNumber, pageSize, ids }) =>
  useReduxReadModel(
    {
      name: 'Recipes',
      resolver: 'getPublicRecipes',
      args: { pageNumber, pageSize, ids },
    },
    [],
    {
      actions: recipesPublicGetQuery,
    }
  )

export const useCountPublicRecipes = ({ ids }) =>
  useReduxReadModel(
    {
      name: 'Recipes',
      resolver: 'countPublicRecipes',
      args: { ids },
    },
    0,
    { actions: recipesPublicCountQuery }
  )

export const useSearchRecipes = () =>
  useReduxReadModel(
    (query) => ({
      name: 'Search',
      resolver: 'find',
      args: { query },
    }),
    [],
    { actions: recipesSearch }
  )

export const useGetMyRecipes = () =>
  useReduxReadModel(
    {
      name: 'Recipes',
      resolver: 'getMyRecipes',
      args: {},
    },
    [],
    { actions: recipesMyGetQuery }
  )

export const useCountMyRecipes = () =>
  useReduxReadModel(
    {
      name: 'Recipes',
      resolver: 'countMyRecipes',
      args: {},
    },
    0,
    { actions: recipesMyCountQuery }
  )

export const useGetMyBatches = () =>
  useReduxReadModel(
    {
      name: 'Recipes',
      resolver: 'getMyBatchRecipes',
      args: {},
    },
    [],
    { actions: batchesGetQuery }
  )

export const useCountBatches = () =>
  useReduxReadModel(
    {
      name: 'Recipes',
      resolver: 'countBatches',
      args: {},
    },
    0,
    { actions: batchesCountQuery }
  )

export const useGetIngredients = (ingredientType) =>
  useReduxReadModel(
    {
      name: 'Ingredients',
      resolver: ingredientType,
      args: {},
    },
    [],
    {
      selectorId: ingredientType,
    }
  )

export const useGetTemplates = (templateType) =>
  useReduxReadModel(
    {
      name: 'Templates',
      resolver: templateType,
      args: {},
    },
    [],
    {
      selectorId: templateType,
    }
  )

export const useGetBeerStyles = () =>
  useReduxReadModel(
    {
      name: 'BeerStyles',
      resolver: 'beerStyles',
      args: {},
    },
    [],
    { selectorId: 'beerStyles' }
  )

export const useGetAllUserStats = ({ userId }) =>
  useReduxReadModel(
    {
      name: 'UserStats',
      resolver: 'allUserStats',
      args: { userId },
    },
    {}
  )

export const useGetUserStats = ({ userId }) =>
  useReduxReadModel(
    {
      name: 'UserStats',
      resolver: 'userStats',
      args: { userId },
    },
    {}
  )

export const useGetUserActivity = () =>
  useReduxReadModel(
    {
      name: 'UserStats',
      resolver: 'userActivity',
      args: {},
    },
    {}
  )

export const useGetUserFeedback = () =>
  useReduxReadModel(
    {
      name: 'UserStats',
      resolver: 'userFeedback',
      args: {},
    },
    {}
  )

export const useGetUploads = () =>
  useReduxReadModel(
    {
      name: 'UserStats',
      resolver: 'uploads',
      args: {},
    },
    {}
  )

export const useGetUser = () =>
  useReduxReadModel(
    { name: 'Users', resolver: 'user', args: {} },
    {},
    { actions: userGetQuery }
  )
