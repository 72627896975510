import React from 'react'
import { Icon } from './Icon'
import { colors } from '../colors/colors'

export const SearchClearIcon = ({ fill, ...props }) => (
  <Icon
    {...props}
    width={20}
    height={20}
    fill={fill}
    paths={[
      'M4 4.913L4.913 4 16 15.087l-.913.913z',
      'M15.087 4l.913.913L4.913 16 4 15.087z'
    ]}
  />
)

SearchClearIcon.defaultProps = {
  fill: colors.ICON_GRAY
}
