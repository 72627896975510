export const ls = {
  getItem: key => {
    if (global.localStorage != null) {
      return JSON.parse(localStorage.getItem(key))
    } else {
      console.warn('')
      return null
    }
  },
  setItem: (key, value) => {
    if (global.localStorage != null) {
      localStorage.setItem(key, JSON.stringify(value))
    } else {
      console.warn('')
    }
  }
}
