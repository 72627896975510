export const ranges = {
  original_gravity: {
    caption: 'OG',
    sg: {
      minimum: 1.026,
      maximum: 1.18
    },
    plato: {
      minimum: 6.5,
      maximum: 40
    },
    brix: {
      minimum: 6.0,
      maximum: 41
    }
  },
  final_gravity: {
    caption: 'FG',
    sg: {
      minimum: 1.0,
      maximum: 1.085
    },
    plato: {
      minimum: 0.5,
      maximum: 20.5
    },
    brix: {
      minimum: 0.5,
      maximum: 21.3
    }
  },
  alcohol_by_volume: {
    caption: 'ABV',
    '%': {
      minimum: 2.4,
      maximum: 14
    }
  },
  international_bitterness_units: {
    caption: 'IBU',
    IBUs: {
      minimum: 0,
      maximum: 120
    }
  },
  color: {
    caption: 'Color',
    SRM: {
      minimum: 2,
      maximum: 40
    },
    Lovi: {
      minimum: 2,
      maximum: 30
    },
    EBC: {
      minimum: 4,
      maximum: 79
    }
  }
}
