import defaultStyle from './styles'

export const untitledRecipeName = 'Untitled Recipe'

export const defaultRecipe = {
  name: untitledRecipeName,
  type: 'all grain',
  style: defaultStyle,
  batch_size: {
    value: 20,
    unit: 'l'
  },
  efficiency: {
    brewhouse: {
      value: 72,
      unit: '%'
    }
  },
  ingredients: {
    fermentable_additions: [],
    hop_additions: [],
    culture_additions: [],
    miscellaneous_additions: []
  }
}
