import React from 'react'
import PropTypes from 'prop-types'
import { Icon } from './Icon'

export const TrashIcon = ({ fill }) => (
  <Icon
    width={15}
    height={19}
    fill={fill}
    paths={[
      'M10 5h1v11h-1zM7 5h1v11H7zM4 5h1v11H4zM0 2h1v1H0zM9 1a1 1 0 0 1 1 1H5a1 1 0 0 1 1-1h3m0-1H6a2 2 0 0 0-2 2v1h7V2a2 2 0 0 0-2-2zM14 2h1v1h-1z',
      'M13 3v13a2 2 0 0 1-2 2H4a2 2 0 0 1-2-2V3h11m1-1H1v14a3 3 0 0 0 3 3h7a3 3 0 0 0 3-3V2z'
    ]}
  />
)

TrashIcon.propTypes = {
  fill: PropTypes.string
}
