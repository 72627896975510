import { find } from 'lodash'
import React, { useState, useEffect, useCallback, useRef } from 'react'
import PropTypes from 'prop-types'
import { UnitPicker } from '../unitPicker/UnitPicker'
import { Editor, Title, InputContainer } from './Styled'
import { Input } from '../input/Input'

const highlightDuration = 500

export const MeasurableEditor = ({
  title,
  value,
  unit,
  units,
  autoFocus,
  valueReadOnly,
  valueZeroEmpty,
  changeOnConvert,
  singleLine,
  onChange
}) => {
  const _input = useRef(null)
  let _focusTimer = null
  let _highlightTimer = null

  const [highlighted, setHighlighted] = useState(false)
  const [focused, setFocused] = useState(false)
  const [editorValue, setEditorValue] = useState(null)
  const [editorUnit, setEditorUnit] = useState(null)

  const onChangeValue = useCallback(nextValue => {
    setEditorValue(nextValue)
  })

  const currentValue = editorValue == null ? value : editorValue
  const currentUnit = editorUnit == null ? unit : editorUnit

  const onChangeUnit = useCallback(nextUnit => {
    if (focused) {
      setEditorUnit(nextUnit)
      onChange({ value: Number(currentValue), unit: nextUnit })
      _input.current.focus()
    } else {
      const converted = find(units, { unit: nextUnit })
      if (converted) {
        setEditorValue(converted.value)
        setEditorUnit(converted.unit)
        if (changeOnConvert) {
          onChange({
            value: converted.value,
            unit: converted.unit
          })
        }
      }
    }
  })

  const onSaveValue = useCallback(() => {
    const numberValue = Number(currentValue)
    if (numberValue !== value || currentUnit !== unit) {
      onChange({
        value: numberValue,
        unit: currentUnit
      })
    } else {
      setEditorValue(null)
      setEditorUnit(null)
    }
  })

  const setFocus = useCallback(() => {
    setFocused(true)
  })

  const setTimer = useCallback(() => {
    if (focused) {
      _focusTimer = setTimeout(() => {
        setFocused(false)
        onSaveValue()
      }, 200)
    }
  })

  const clearTimer = useCallback(() => {
    if (_focusTimer) {
      clearTimeout(_focusTimer)
    }
  })

  const mountRef = useRef(false)

  useEffect(
    () => {
      if (mountRef.current && editorValue == null && editorUnit == null) {
        setHighlighted(true)
        _highlightTimer = setTimeout(() => {
          setHighlighted(false)
        }, highlightDuration)
      }

      if (
        (editorValue != null && value === Number(editorValue)) ||
        (editorUnit != null && unit === editorUnit)
      ) {
        setEditorValue(null)
        setEditorUnit(null)
      }

      return () => clearTimeout(_highlightTimer)
    },
    [value, unit]
  )

  useEffect(() => {
    mountRef.current = true
  }, [])

  return (
    <Editor
      tabIndex={-1}
      highlighted={highlighted}
      focused={focused}
      readOnly={valueReadOnly}
      onFocus={clearTimer}
      onBlur={setTimer}>
      <InputContainer singleLine={singleLine}>
        <Title focused={focused}>{title}</Title>
        <Input
          ref={_input}
          value={`${currentValue === 0 && valueZeroEmpty ? '' : currentValue}`}
          disabled={valueReadOnly}
          type="number"
          textAlign="right"
          selectOnFocus={true}
          maxLength={8}
          onChange={onChangeValue}
          onFocus={setFocus}
          onSubmit={onSaveValue}
          inputProps={{ min: '0', inputMode: 'decimal' }}
        />
      </InputContainer>
      <UnitPicker
        focusOnSelect={false}
        showValue={!focused}
        selectedUnit={currentUnit}
        units={units}
        onChange={onChangeUnit}
      />
    </Editor>
  )
}

MeasurableEditor.propTypes = {
  title: PropTypes.string,
  value: PropTypes.number,
  unit: PropTypes.string,
  units: PropTypes.arrayOf(
    PropTypes.shape({
      unit: PropTypes.string,
      label: PropTypes.string,
      value: PropTypes.number
    })
  ),
  autoFocus: PropTypes.bool,
  valueReadOnly: PropTypes.bool,
  valueZeroEmpty: PropTypes.bool,
  changeOnConvert: PropTypes.bool,
  singleLine: PropTypes.bool,
  onChange: PropTypes.func
}

MeasurableEditor.displayName = 'MeasurableEditor'
