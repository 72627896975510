import { map } from 'lodash'
import PropTypes from 'prop-types'
import React, { PureComponent } from 'react'
import { EquipmentItem } from '../equipmentItem/EquipmentItem'
import { TextEditor } from '../editors/TextEditor'
import { DeleteButton } from '../buttons/DeleteButton'
import { Items, Title, Container } from './Styled'

export class EquipmentSet extends PureComponent {
  static displayName = 'EquipmentSet'
  static propTypes = {
    id: PropTypes.string,
    name: PropTypes.string,
    equipmentItems: PropTypes.object,
    onChange: PropTypes.func,
    onRemove: PropTypes.func
  }

  onChange = changes => {
    this.props.onChange({ id: this.props.id, changes })
  }

  onChangeName = name => {
    this.props.onChange({ id: this.props.id, changes: { name } })
  }

  onRemoveEquipment = () => {
    this.props.onRemove(this.props.id)
  }

  render() {
    const { name, equipmentItems } = this.props
    return (
      <Container>
        <Title>
          <TextEditor
            textAlign="left"
            fontSize="large"
            title="Name of Equipment Set"
            value={name}
            isBorderHidden={true}
            onChange={this.onChangeName}
          />
          <DeleteButton
            onPress={this.onRemoveEquipment}
            accessibilityLabel="Delete Equipment Set"
          />
        </Title>
        <Items>
          {map(equipmentItems, (item, key) => (
            <EquipmentItem
              key={key}
              type={key}
              fields={item}
              onChange={this.onChange}
            />
          ))}
        </Items>
      </Container>
    )
  }
}
