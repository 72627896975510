import { isEmpty, map } from 'lodash'
import styled from 'styled-components'
import PropTypes from 'prop-types'
import React from 'react'
import { CollapsedCell } from '../responsiveTable/ResponsiveRow'
import { ResponsiveEditor } from '../responsiveTable/ResponsiveEditor'
import { getEquipmentProfileData } from '@brewcomputer/utils'
import { RecipeDataSection } from '../recipeView/RecipeDataSection'
import { EquipmentLookup } from '../lookups/EquipmentLookup'
import { useSelector } from 'react-redux'
import { useAddEquipment, useEditRecipeEquipment } from '../../hooks/commands'
import { getRecipeEquipment } from '../../selectors/recipe'

export const Container = styled.div`
  flex-direction: row;
  flex-wrap: wrap;
  padding: 12px;
  background-color: #fff;
`

export const EditorContainer = styled(CollapsedCell)`
  & > div {
    width: 100%;
  }
`

export const RecipeEquipment = ({ recipeId, showLookup }) => {
  const equipmentProfile = useSelector(getRecipeEquipment(recipeId))
  const userId = useSelector((state) => state.user.id)
  const editEquipment = useEditRecipeEquipment(recipeId)
  const addEquipment = useAddEquipment(userId)

  if (!showLookup && isEmpty(equipmentProfile)) {
    return null
  }

  return (
    <RecipeDataSection
      caption="Equipment Profile"
      button={
        showLookup && (
          <EquipmentLookup onSelect={editEquipment} onCreate={addEquipment} />
        )
      }>
      {!isEmpty(equipmentProfile) && (
        <Container>
          {map(
            getEquipmentProfileData(equipmentProfile),
            ({ data, caption, path }, index) => (
              <EditorContainer key={index}>
                <ResponsiveEditor
                  collapsed
                  title={caption}
                  {...data}
                  path={path}
                  disabled
                />
              </EditorContainer>
            )
          )}
        </Container>
      )}
    </RecipeDataSection>
  )
}

RecipeEquipment.propTypes = {
  recipeId: PropTypes.string.isRequired,
  showLookup: PropTypes.bool,
}
