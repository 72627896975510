import { css } from 'styled-components'
import { colors } from '../colors/colors'
import { textStyles } from '../texts/textStyles'

export const pageTitleText = css`
  ${textStyles.H1};
  color: ${colors.BLACK};
`

export const pageTitleContainer = css`
  margin-bottom: 8px;
`
