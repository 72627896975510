import styled from 'styled-components'
import { colors } from '../colors/colors'
import { SearchIcon } from '../icons/SearchIcon'
import { Input } from '../input/Input'
import { containerStyle, searchIconStyle, inputStyle } from './styles'

export const Container = styled.div`
  ${containerStyle};
  position: relative;
  border-style: solid;
  transition-duration: 200ms;
`

export const StyledIcon = styled(SearchIcon)`
  pointer-events: none;
  ${searchIconStyle};
  & path {
    transition-duration: 200ms;
  }
`

export const StyledInput = styled(Input)`
  ${inputStyle};
  box-sizing: border-box;
  ::placeholder {
    color: ${colors.GRAY};
  }
`
