import React, { PureComponent } from 'react'
import { colors } from '../colors/colors'
import styled from 'styled-components'

const Button = styled.button`
  align-items: center;
  justify-content: center;
  background-color: transparent;

  &:hover svg path {
    fill: ${colors.NEGATIVE_RED};
  }
`

export class ClearFilterButton extends PureComponent {
  render() {
    return (
      <Button onClick={this.props.onPress}>
        <svg
          width="10"
          height="9"
          viewBox="0 0 10 9"
          fill="none"
          xmlns="http://www.w3.org/2000/svg">
          <path
            d="M1.26367 6.06348L2.30078 5.02637L5.63184 8.35742L8.97168 5.02637L10.0352 6.06348L6.69531 9.40332L10.0352 12.7344L8.97168 13.7979L5.63184 10.4668L2.30078 13.7979L1.26367 12.7344L4.59473 9.40332L1.26367 6.06348Z"
            transform="translate(-1 -5)"
            fill={colors.ICON_GRAY}
          />
        </svg>
      </Button>
    )
  }
}
