import { capitalize, get, map } from 'lodash'
import fermentableJSON from '@beerjson/beerjson/json/fermentable'
import hopJSON from '@beerjson/beerjson/json/hop'
import cultureJSON from '@beerjson/beerjson/json/culture'
import miscJSON from '@beerjson/beerjson/json/misc'
import { getMeasurable } from './units'

const createPickerItems = (items) =>
  map(items, (item) => ({
    value: item,
    label: capitalize(item),
  }))

const getFermentableType = () =>
  createPickerItems(
    get(fermentableJSON, 'definitions.FermentableBase.properties.type.enum', [])
  )

const getFermentableGroup = () =>
  createPickerItems(
    get(
      fermentableJSON,
      'definitions.FermentableBase.properties.grain_group.enum',
      []
    )
  )

const getHopForm = () =>
  createPickerItems(
    get(hopJSON, 'definitions.HopVarietyBase.properties.form.enum', [])
  )

const getCultureType = () =>
  createPickerItems(
    get(cultureJSON, 'definitions.CultureBase.properties.type.enum', [])
  )

const getCultureForm = () =>
  createPickerItems(
    get(cultureJSON, 'definitions.CultureBase.properties.form.enum', [])
  )

const getMiscType = () =>
  createPickerItems(
    get(miscJSON, 'definitions.MiscellaneousBase.properties.type.enum', [])
  )

const hopUseMap = {
  add_to_mash: 'Mash',
  add_to_boil: 'Boil',
  add_to_fermentation: 'Dry Hop',
  add_to_package: 'Package',
}
export const getHopUse = () =>
  map(hopUseMap, (label, value) => ({
    value,
    label,
  }))

const miscUseMap = {
  add_to_mash: 'Mash',
  add_to_boil: 'Boil',
  add_to_fermentation: 'Fermentation',
  add_to_package: 'Package',
}
export const getMiscUse = () =>
  map(miscUseMap, (label, value) => ({
    value,
    label,
  }))

const getMeasurableField = (ingredient, path, title) => ({
  title: title || capitalize(path),
  path,
  data: { measurable: getMeasurable(ingredient, path) },
})

const getSelectField = (ingredient, path, items, title) => ({
  title: title || capitalize(path),
  path,
  data: {
    selectedValue: get(ingredient, path),
    items,
  },
})

const getTextField = (ingredient, path, title) => ({
  title: title || capitalize(path),
  path,
  data: { text: ingredient[path] },
})

export function getFermentableFields(fermentable) {
  return [
    getTextField(fermentable, 'name'),
    getMeasurableField(fermentable, 'amount'),
    getSelectField(fermentable, 'type', getFermentableType()),
    getMeasurableField(fermentable, 'color'),
    getMeasurableField(fermentable, 'yield.potential', 'Potential'),
    getMeasurableField(fermentable, 'yield.fine_grind', 'Fine Grind'),
    getMeasurableField(
      fermentable,
      'yield.fine_coarse_difference',
      'Fine Coarse Diff'
    ),
    getSelectField(fermentable, 'grain_group', getFermentableGroup(), 'Group'),
    getTextField(fermentable, 'origin'),
    getTextField(fermentable, 'supplier'),
  ]
}

export function getHopFields(hop) {
  return [
    getTextField(hop, 'name'),
    getMeasurableField(hop, 'amount'),
    getMeasurableField(hop, 'alpha_acid', 'Alpha'),
    getSelectField(hop, 'timing.use', getHopUse(), 'Use'),
    getMeasurableField(hop, 'timing.time', 'Time'),
    getSelectField(hop, 'form', getHopForm()),
    getMeasurableField(hop, 'beta_acid', 'Beta'),
    getTextField(hop, 'origin'),
  ]
}

export function getCultureFields(culture) {
  return [
    getTextField(culture, 'name'),
    {
      title: 'Amount',
      path: 'amount',
      data: { measurable: getMeasurable(culture, 'amount') },
      disableConversion: true,
    },
    getMeasurableField(culture, 'attenuation'),
    getSelectField(culture, 'type', getCultureType()),
    getSelectField(culture, 'form', getCultureForm()),
  ]
}

export function getMiscFields(misc) {
  return [
    getTextField(misc, 'name'),
    getSelectField(misc, 'type', getMiscType()),
    {
      title: 'Amount',
      path: 'amount',
      data: { measurable: getMeasurable(misc, 'amount') },
      disableConversion: true,
    },
    getSelectField(misc, 'timing.use', getMiscUse(), 'Use'),
    getMeasurableField(misc, 'timing.time', 'Time'),
  ]
}

export function getWaterProfileFields(waterProfile) {
  return [
    getTextField(waterProfile, 'name'),
    getMeasurableField(waterProfile, 'calcium'),
    getMeasurableField(waterProfile, 'magnesium'),
    getMeasurableField(waterProfile, 'sodium'),
    getMeasurableField(waterProfile, 'chloride'),
    getMeasurableField(waterProfile, 'sulfate'),
    getMeasurableField(waterProfile, 'bicarbonate'),
  ]
}
