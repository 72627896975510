import { css } from 'styled-components'
import { colors } from '../colors/colors'
import { textStyles } from '../texts/textStyles'

export const containerStyle = css`
  padding-top: 24px;
  padding-left: 24px;
  padding-bottom: 8px;
  border-color: ${colors.DIVIDER};
  border-top-width: 1px;
`
export const titleStyle = css`
  ${textStyles.H2};
  margin-bottom: 19px;
  color: ${colors.BLACK};
`
