import { get, isEmpty } from 'lodash'
import { convertMeasurable } from './convertMeasurable'

const convertValue = (obj, key, unit) => {
  const defaultValue = { value: 0, unit }
  const value = get(obj, key, defaultValue)
  return convertMeasurable(value, unit)
}

export const convertBatchMeasuredValues = (measuredValues, unitSettings) => {
  if (isEmpty(unitSettings)) {
    return measuredValues
  }

  const { gravity, volume } = unitSettings

  return {
    pH: get(measuredValues, 'pH', { value: 0, unit: 'pH' }),
    sparge_gravity: convertValue(measuredValues, 'sparge_gravity', gravity),
    pre_boil_gravity: convertValue(measuredValues, 'pre_boil_gravity', gravity),
    original_gravity: convertValue(measuredValues, 'original_gravity', gravity),
    final_gravity: convertValue(measuredValues, 'final_gravity', gravity),
    pre_boil_size: convertValue(measuredValues, 'pre_boil_size', volume),
    batch_size: convertValue(measuredValues, 'batch_size', volume)
  }
}

export const convertBatchStats = (batchStats, unitSettings) => {
  if (isEmpty(unitSettings)) {
    return batchStats
  }

  const { gravity, color } = unitSettings

  return {
    ...batchStats,
    original_gravity: convertValue(batchStats, 'original_gravity', gravity),
    final_gravity: convertValue(batchStats, 'final_gravity', gravity),
    color_estimate: convertValue(batchStats, 'color_estimate', color)
  }
}

export const convertBatch = (batch, unitSettings) => {
  return {
    ...batch,
    measuredValues: convertBatchMeasuredValues(
      batch.measuredValues,
      unitSettings
    ),
    batchStats: convertBatchStats(batch.batchStats, unitSettings)
  }
}
