import { getMeasurable } from './units'

export function getEquipmentProfileFields(equipment) {
  return {
    name: {
      caption: 'Name',
      path: 'name',
      data: {
        text: equipment.name
      }
    },
    equipment_items: {
      hlt: [
        {
          caption: 'Name',
          path: 'equipment_items.hlt.name',
          data: {
            text: equipment.equipment_items.hlt.name
          }
        },
        {
          caption: 'Volume',
          path: 'equipment_items.hlt.maximum_volume',
          data: getMeasurable(equipment, 'equipment_items.hlt.maximum_volume')
        },
        {
          caption: 'Loss',
          path: 'equipment_items.hlt.loss',
          data: getMeasurable(equipment, 'equipment_items.hlt.loss')
        }
      ],
      mash_tun: [
        {
          caption: 'Name',
          path: 'equipment_items.mash_tun.name',
          data: {
            text: equipment.equipment_items.mash_tun.name
          }
        },
        {
          caption: 'Volume',
          path: 'equipment_items.mash_tun.maximum_volume',
          data: getMeasurable(
            equipment,
            'equipment_items.mash_tun.maximum_volume'
          )
        },
        {
          caption: 'Loss',
          path: 'equipment_items.mash_tun.loss',
          data: getMeasurable(equipment, 'equipment_items.mash_tun.loss')
        }
      ],
      brew_kettle: [
        {
          caption: 'Name',
          path: 'equipment_items.brew_kettle.name',
          data: {
            text: equipment.equipment_items.brew_kettle.name
          }
        },
        {
          caption: 'Volume',
          path: 'equipment_items.brew_kettle.maximum_volume',
          data: getMeasurable(
            equipment,
            'equipment_items.brew_kettle.maximum_volume'
          )
        },
        {
          caption: 'Evap. Per Hour',
          path: 'equipment_items.brew_kettle.boil_rate_per_hour',
          data: getMeasurable(
            equipment,
            'equipment_items.brew_kettle.boil_rate_per_hour'
          )
        },
        {
          caption: 'Loss',
          path: 'equipment_items.brew_kettle.loss',
          data: getMeasurable(equipment, 'equipment_items.brew_kettle.loss')
        }
      ],
      fermenter: [
        {
          caption: 'Name',
          path: 'fermenter.name',
          data: {
            text: equipment.equipment_items.fermenter.name
          }
        },
        {
          caption: 'Fermenter',
          path: 'equipment_items.fermenter.maximum_volume',
          data: getMeasurable(
            equipment,
            'equipment_items.fermenter.maximum_volume'
          )
        },
        {
          caption: 'Loss',
          path: 'equipment_items.fermenter.loss',
          data: getMeasurable(equipment, 'equipment_items.fermenter.loss')
        }
      ]
    }
  }
}

export function getEquipmentProfileData(profile) {
  const equipmentProfile = getEquipmentProfileFields(profile)
  return [
    equipmentProfile.name,
    {
      caption: 'Max Boil Volume',
      path: 'equipment_items.brew_kettle.maximum_volume',
      data: {
        measurable: getMeasurable(
          profile,
          'equipment_items.brew_kettle.maximum_volume'
        )
      }
    },
    {
      caption: 'Mash Loss',
      path: 'equipment_items.mash_tun.loss',
      data: {
        measurable: getMeasurable(profile, 'equipment_items.mash_tun.loss')
      }
    },
    {
      caption: 'Evaporation',
      path: 'equipment_items.brew_kettle.boil_rate_per_hour',
      data: {
        measurable: getMeasurable(
          profile,
          'equipment_items.brew_kettle.boil_rate_per_hour'
        )
      }
    },
    {
      caption: 'Brew Kettle Loss',
      path: 'equipment_items.brew_kettle.loss',
      data: {
        measurable: getMeasurable(profile, 'equipment_items.brew_kettle.loss')
      }
    },
    {
      caption: 'Fermenter Loss',
      path: 'equipment_items.fermenter.loss',
      data: {
        measurable: getMeasurable(profile, 'equipment_items.fermenter.loss')
      }
    }
  ]
}
