import React from 'react'
import { LinkContainer } from '../Styled'
import {
  ExportLink as ExportLinkUI,
  PrintLink as PrintLinkUI,
  ShareLink as ShareLinkUI
} from '@brewcomputer/ui'
import { getRecipeExportLink, getRecipePrintableLink } from '../../../links'
import { Link } from 'react-router-dom'

export const ExportLink = ({ recipeId }) => (
  <LinkContainer>
    <ExportLinkUI to={getRecipeExportLink(recipeId)} />
  </LinkContainer>
)

ExportLink.displayName = 'ExportLink'

export const PrintLink = ({ recipeId }) => (
  <LinkContainer>
    <Link to={getRecipePrintableLink(recipeId)} target={'_blank'}>
      <PrintLinkUI />
    </Link>
  </LinkContainer>
)

PrintLink.displayName = 'PrintLink'

export const ShareLink = ({ shared, disabled, onPress }) => (
  <LinkContainer>
    <ShareLinkUI shared={shared} disabled={disabled} onPress={onPress} />
  </LinkContainer>
)

ShareLink.displayName = 'ShareLink'
