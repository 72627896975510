import { map, get, find } from 'lodash'
import React, { PureComponent } from 'react'
import { Picker } from '../picker/Picker'
import PropTypes from 'prop-types'

export class UnitPicker extends PureComponent {
  static propTypes = {
    showValue: PropTypes.bool,
    units: PropTypes.arrayOf(
      PropTypes.shape({
        unit: PropTypes.string,
        label: PropTypes.string,
        value: PropTypes.number
      })
    ),
    selectedUnit: PropTypes.string
  }

  getPickerItems = () => {
    const { units, showValue } = this.props
    return map(units, ({ value, unit, label }) => ({
      label: showValue && value != null ? [`${value}`, label] : label,
      value: unit
    }))
  }

  render() {
    const { selectedUnit, units, ...props } = this.props
    const unitLabel = get(
      find(units, { unit: selectedUnit }),
      'label',
      selectedUnit
    )

    return (
      <Picker
        {...props}
        type={'unit'}
        selectedValue={selectedUnit}
        selectedLabel={unitLabel}
        items={this.getPickerItems()}
      />
    )
  }
}
