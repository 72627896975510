import React, { PureComponent } from 'react'
import { isEmpty } from 'lodash'
import { SearchIcon } from '../icons/SearchIcon'
import { ClearFilterButton } from '../buttons/ClearFilterButton'
import { textStyles } from '../texts/textStyles'
import figmaColors from '../colors/figmaColors'
import PropTypes from 'prop-types'
import styled from 'styled-components'

const Input = styled.input`
  display: flex;
  flex-grow: 1;
  flex-shrink: 1;
  min-width: 0;
  background-color: ${figmaColors.blue_gray_50};
  padding: 7px 35px 7px 12px;
  color: #232323;
  ${textStyles.body2};
  border-bottom: 2px solid ${figmaColors.blue_gray_100};

  @media (max-width: 576px) {
    font-size: 16px;
  }

  &:focus {
    border-bottom-color: ${props =>
      props.isItemsEmpty ? figmaColors.red_500 : figmaColors.blue_500};
  }

  &::placeholder {
    color: ${figmaColors.blue_gray_400};
    opacity: 1;
  }

  &:-ms-input-placeholder {
    color: ${figmaColors.blue_gray_400};
  }

  &::-ms-input-placeholder {
    color: ${figmaColors.blue_gray_400};
  }
`

const Label = styled.label`
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
  width: 35px;
  display: flex;
  align-self: stretch;
  justify-content: center;
  align-items: center;
  cursor: pointer;

  & button {
    height: 100%;
    width: 100%;
  }
`

const Container = styled.div`
  position: relative;
  overflow: hidden;
  background-color: ${figmaColors.white};
  align-items: stretch;
  flex-shrink: 0;
`

export class Filter extends PureComponent {
  componentWillUnmount() {
    this.clear()
  }

  clear = () => {
    this.props.onChange('')
  }

  onInputTextChange = e => {
    this.props.onChange(e.target.value)
  }

  onFilterClearButtonPress = e => {
    e.stopPropagation()
    e.nativeEvent.stopImmediatePropagation()
    this.clear()
  }

  renderButton = query => {
    if (isEmpty(query)) {
      return <SearchIcon fill={figmaColors.primary_500} />
    }
    return <ClearFilterButton onPress={this.onFilterClearButtonPress} />
  }

  render() {
    const { query, isItemsEmpty, placeholder } = this.props

    return (
      <Container>
        <Input
          id={'filterEditor'}
          autoFocus={true}
          value={query}
          isItemsEmpty={isItemsEmpty}
          placeholder={placeholder}
          onChange={this.onInputTextChange}
        />
        <Label htmlFor={'filterEditor'}>{this.renderButton(query)}</Label>
      </Container>
    )
  }
}

Filter.propTypes = {
  query: PropTypes.string,
  placeholder: PropTypes.string,
  isItemsEmpty: PropTypes.bool,
  onChange: PropTypes.func
}
