import PropTypes from 'prop-types'
import styled, { css } from 'styled-components'
import { textStyles } from '../texts/textStyles'
import { colors } from '../colors/colors'

const navBarStyle = css`
  color: ${colors.ACCENT_BLUE};
  padding: 0 20px;
  height: 100%;
  border-bottom: 2px solid transparent;
  .active > & {
    border-bottom: 2px solid ${colors.ACCENT_BLUE};
  }
  &:hover {
    color: ${colors.ACCENT_BLUE_HOVER};
  }
`

const tabBarStyle = css`
  color: ${colors.DARK_BLUE};
  padding: 12px 20px 23px 20px;
  border-bottom: 4px solid transparent;
  .active > & {
    border-bottom: 4px solid ${colors.DARK_BLUE};
  }
  @media (max-width: 576px) {
    padding: 12px 10px 12px 10px;
    border-bottom-width: 2px;
    .active > & {
      border-bottom-width: 2px;
    }
  }
`

const filterStyle = css`
  color: ${colors.ACCENT_GRAY};
  background-color: ${colors.WHITE};
  padding: 0 12px;
  height: 28px;
  border: 1px solid ${colors.ACCENT_GRAY};
  .active > & {
    color: ${colors.WHITE};
    background-color: ${colors.ACCENT_GRAY};
  }
`

const getLinkStyle = ({ type }) => {
  switch (type) {
    case 'navBar':
      return navBarStyle
    case 'tabBar':
      return tabBarStyle
    case 'filter':
      return filterStyle
  }
}

export const TopLink = styled.div`
  flex-direction: row;
  align-items: center;
  justify-content: center;
  ${textStyles.body2};
  font-weight: 500;
  transition-duration: 200ms;
  ${getLinkStyle};
`

TopLink.propTypes = {
  type: PropTypes.oneOf(['tabBar', 'navBar', 'filter'])
}

TopLink.defaultProps = {
  type: 'navBar'
}
