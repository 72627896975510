import React, { PureComponent } from 'react'
import PropTypes from 'prop-types'
import { TextEditor } from '@brewcomputer/ui/src/editors/TextEditor'
import { Avatar, Card, textStyles, colors } from '@brewcomputer/ui'
import { Form } from '@brewcomputer/ui/src/form/Form'
import styled from 'styled-components'
import { logout } from '../../../auth/endpoints'

const Container = styled(Card)`
  padding: 24px 0 8px 24px;
  margin-bottom: 22px;
`

const User = styled.div`
  flex-grow: 1;
  flex-shrink: 0;
  align-items: center;
  justify-content: center;
`

const StyledAvatar = styled(Avatar)`
  margin: 0 100px 16px 100px;
`

const Email = styled.div`
  ${textStyles.body2};
  color: ${colors.BLACK};
  word-break: break-all;
`

const EditorContainer = styled.div`
  margin-bottom: 16px;
`

const Link = styled.a`
  margin: 8px 0;
  display: flex;
  ${textStyles.body2};
  color: ${colors.ACCENT_BLUE};
  text-decoration: underline;
`

export class UserProfile extends PureComponent {
  static propTypes = {
    user: PropTypes.object,
    onEditUser: PropTypes.func
  }

  onEditName = name => {
    this.props.onEditUser({ name })
  }

  onEditBrewery = brewery => {
    this.props.onEditUser({ brewery })
  }

  onEditInfo = info => {
    this.props.onEditUser({ info })
  }

  render() {
    const { user } = this.props

    return (
      <Container>
        <Form type="4-columns-1">
          <User>
            <StyledAvatar src={user.avatar} size={100} alt={user.name} />
            <Email>{user.email}</Email>
            <Link href={logout.logout_link} rel="noreferrer">
              Sign Out
            </Link>
          </User>
          <div>
            <EditorContainer>
              <TextEditor
                title="Name"
                value={user.name}
                textAlign={'left'}
                onChange={this.onEditName}
              />
            </EditorContainer>
            <EditorContainer>
              <TextEditor
                title="Brewery"
                value={user.brewery}
                textAlign={'left'}
                onChange={this.onEditBrewery}
              />
            </EditorContainer>
            <EditorContainer>
              <TextEditor
                title="Info"
                value={user.info}
                textAlign={'left'}
                onChange={this.onEditInfo}
              />
            </EditorContainer>
          </div>
        </Form>
      </Container>
    )
  }
}
