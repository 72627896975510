export const links = {
  index: '/',
  auth: '/auth',
  admin: '/admin',
  recipes: '/recipes',
  myRecipes: '/recipes/my',
  batches: '/batches',
  recipe: '/recipe/',
  recipePrintable: '/recipe/printable/',
  batch: '/batch/',
  batchRecipe: '/batch/recipe/',
  equipment: '/equipment',
  settings: '/settings',
  authEmailRegister: '/auth/email/register',
  authError: '/auth/error',
  authTokenExpired: '/auth/expired/',
  authSuccess: '/auth/success',
  privacyPolicy: '/privacy',
  cookiePolicy: '/cookie-policy',
  termsOfUse: '/terms'
}

export const getBatchLink = (batchId, status = 'planning') => {
  return `${links.batch}${batchId}/${status}`
}

export const getRecipeLink = recipeId => {
  return `${links.recipe}${recipeId}`
}

export const getRecipePrintableLink = recipeId => {
  return `${links.recipePrintable}${recipeId}`
}

export const getBatchRecipeLink = id => {
  return `${links.batchRecipe}${id}`
}

export const getRecipeExportLink = recipeId => {
  return `/api/export-recipe?id=${recipeId}`
}
