import PropTypes from 'prop-types'
import React, { PureComponent } from 'react'
import {
  Container,
  RecipeName,
  RecipeText,
  Style,
  Type,
  Color,
  BatchTitle,
  Status,
  Date,
  Number
} from './Styled.js'

export class BatchListItem extends PureComponent {
  static propTypes = {
    number: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    color: PropTypes.string,
    name: PropTypes.string,
    beerStyle: PropTypes.string,
    type: PropTypes.string,
    status: PropTypes.string,
    date: PropTypes.string
  }

  render() {
    const { number, color, name, beerStyle, type, status, date } = this.props
    return (
      <Container colorCSS={color}>
        <Number>
          <RecipeText>{number}</RecipeText>
        </Number>
        <BatchTitle>
          <Color colorCSS={color} />
          <RecipeName>{name}</RecipeName>
        </BatchTitle>
        <Style>
          <RecipeText>{beerStyle}</RecipeText>
        </Style>
        <Type>
          <RecipeText>{type}</RecipeText>
        </Type>
        <Status>
          <RecipeText>{status}</RecipeText>
        </Status>
        <Date>
          <RecipeText>{date}</RecipeText>
        </Date>
      </Container>
    )
  }
}

BatchListItem.displayName = 'BatchListItem'
