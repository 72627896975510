import React, { useEffect } from 'react'
import {
  Tab,
  Column,
  BatchMeasured,
  BatchCheckList
} from '@brewcomputer/ui/src/batchView/BatchView'
import {
  getCalculatedBatch,
  getPreBrewCheckList,
  getMashCheckList,
  getBoilCheckList
} from './selectors'
import { useSelector } from 'react-redux'
import { useCheckItem } from './hooks'

export const BatchBrewingView = ({ batchId, onEditBatchMeasured, onMount }) => {
  const batch = useSelector(getCalculatedBatch(batchId))

  const preBrewCheckList = useSelector(getPreBrewCheckList(batchId))
  const mashCheckList = useSelector(getMashCheckList(batchId))
  const boilCheckList = useSelector(getBoilCheckList(batchId))

  const onCheckItem = useCheckItem(batchId)

  useEffect(() => {
    onMount()
  }, [])

  return (
    <Tab>
      <Column>
        <BatchCheckList
          title="Pre Brew"
          checkList={preBrewCheckList}
          onCheckItem={onCheckItem}
        />
      </Column>
      <Column>
        <BatchCheckList
          title="Mash"
          checkList={mashCheckList}
          onCheckItem={onCheckItem}
        />
        <BatchCheckList
          title="Boil"
          checkList={boilCheckList}
          onCheckItem={onCheckItem}
        />
      </Column>
      <Column>
        <BatchMeasured
          status="brewing"
          measuredValues={batch.measuredValues}
          onEditBatchMeasured={onEditBatchMeasured}
        />
      </Column>
    </Tab>
  )
}

BatchBrewingView.displayName = 'BatchBrewingView'
