import React, { Fragment, useEffect } from 'react'
import { useSelector } from 'react-redux'
import { isEmpty } from 'lodash'
import { PageTitle, SectionTitle } from '@brewcomputer/ui'
import { UnitSettings } from '../components/settings/UnitSettings'
import { UserProfile } from '../components/settings/UserProfile'
import { LoadingIndicator } from '../components/LoadingIndicator'
import styled from 'styled-components'
import { useUserEdit, useUserEditUnitSettings } from '../hooks/commands'
import { useGetUser } from '../hooks/queries'

const StyledSectionTitle = styled(SectionTitle)`
  margin-bottom: 10px;
`

export const Settings = () => {
  const { request: getUser } = useGetUser()
  const user = useSelector((state) => state.user)

  useEffect(() => {
    getUser()
  }, [])

  if (isEmpty(user) || user.isLoading) {
    return <LoadingIndicator />
  }

  const userEdit = useUserEdit(user.id)
  const userEditUnitSettings = useUserEditUnitSettings(user.id)

  return (
    <Fragment>
      <PageTitle title="Settings" />
      <StyledSectionTitle>User Profile</StyledSectionTitle>
      <UserProfile user={user} onEditUser={userEdit} />
      <StyledSectionTitle>Units of Measurement</StyledSectionTitle>
      <UnitSettings user={user} onEditUnitSettings={userEditUnitSettings} />
    </Fragment>
  )
}
