import React, { PureComponent } from 'react'
import { importButtonPropTypes } from './propTypes'
import { ImportIcon } from '../icons/ImportIcon'
import { colors } from '../colors/colors'
import styled from 'styled-components'

const StyledLabel = styled.label`
  display: flex;
  padding: 10px;
  border-radius: 4px;
  border: 1px solid ${colors.ACCENT_BLUE};
  transition-duration: 200ms;
  & svg path {
    transition-duration: 200ms;
  }
  cursor: pointer;
  :hover {
    border-color: ${colors.ACCENT_BLUE_HOVER};
  }
  :hover svg path {
    fill: ${colors.ACCENT_BLUE_HOVER};
  }
  :active {
    border-color: ${colors.ACCENT_BLUE_ACTIVE};
  }
  :active svg path {
    fill: ${colors.ACCENT_BLUE_ACTIVE};
  }
`

const HiddenInput = styled.input`
  display: none;
`

export class ImportButton extends PureComponent {
  static propTypes = importButtonPropTypes

  _inputRef = React.createRef()

  clear = () => {
    if (this._inputRef) {
      this._inputRef.current.value = ''
    }
  }

  onChange = e => {
    e.persist()
    this.props.onImport(e.target.files)
  }

  render() {
    const { accessibilityLabel, title } = this.props
    return (
      <StyledLabel tabIndex={0} aria-label={accessibilityLabel} title={title}>
        <ImportIcon />
        <HiddenInput
          ref={this._inputRef}
          type="file"
          onChange={this.onChange}
        />
      </StyledLabel>
    )
  }
}
