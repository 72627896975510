import { split, includes } from 'lodash'
import { useCallback } from 'react'
import {
  useRecipeBatchCheckListSetChecked,
  useRecipeIngredientCheckListSetChecked,
  useRecipeProcedureCheckListSetChecked
} from '../../hooks/commands'

const separator = ':'
const ingredientTypes = ['fermentable', 'hop', 'culture', 'misc']
const procedureTypes = ['mash', 'boil']

export const useCheckItem = batchId => {
  const checkItem = useRecipeBatchCheckListSetChecked(batchId)
  const checkAddition = useRecipeIngredientCheckListSetChecked(batchId)
  const checkProcedure = useRecipeProcedureCheckListSetChecked(batchId)

  return useCallback(({ key, checked }) => {
    if (!includes(key, separator)) {
      return checkItem({ [key]: checked })
    }
    const [type, id] = split(key, separator)
    if (includes(ingredientTypes, type)) {
      return checkAddition({
        id,
        ingredientType: type,
        changes: { batch_added: checked }
      })
    }
    if (includes(procedureTypes, type)) {
      return checkProcedure({
        procedureType: type,
        steps: { [id]: { batch_completed: checked } }
      })
    }
  })
}

export const useCheckIngredient = batchId => {
  const checkIngredient = useRecipeIngredientCheckListSetChecked(batchId)
  return useCallback(({ key, checked }) => {
    const [ingredientType, id] = split(key, separator)
    checkIngredient({
      id,
      ingredientType,
      changes: { batch_available: checked }
    })
  })
}
