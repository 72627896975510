import { produce } from 'immer'
import {
  EMAIL_AUTH_REQUEST,
  EMAIL_AUTH_SUCCESS,
  EMAIL_AUTH_FAILURE
} from '../actions/auth'

const initialState = {
  loading: false,
  success: false,
  error: null
}

const auth = produce((draft, action) => {
  switch (action.type) {
    case EMAIL_AUTH_REQUEST:
      draft.loading = true
      draft.success = false
      draft.error = null
      break
    case EMAIL_AUTH_SUCCESS:
      draft.loading = false
      draft.success = true
      break
    case EMAIL_AUTH_FAILURE:
      draft.loading = false
      draft.error = action.payload.error
  }
}, initialState)

export default auth
