import { map } from 'lodash'
import React, { PureComponent } from 'react'
import { formPropTypes } from './propTypes'
import { StyledForm, InputContainer } from './Styled'

export class Form extends PureComponent {
  static propTypes = formPropTypes

  render() {
    const children = Array.isArray(this.props.children)
      ? this.props.children
      : [this.props.children]

    const { type, className } = this.props

    return (
      <StyledForm className={className}>
        {map(children, (input, index) => (
          <InputContainer key={index} type={type}>
            {input}
          </InputContainer>
        ))}
      </StyledForm>
    )
  }
}
