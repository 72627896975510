import React, { PureComponent } from 'react'
import { clearButtonPropTypes } from './propTypes'
import { colors } from '../colors/colors'
import { CopyIcon } from '../icons/CopyIcon'
import styled from 'styled-components'

const StyledButton = styled.button`
  background-color: transparent;
  & svg path {
    transition-duration: 200ms;
  }
  :hover svg path {
    fill: ${colors.ICON_GRAY_HOVER};
  }
  :active svg path {
    fill: ${colors.ICON_GRAY_HOVER};
  }
  :focus svg path {
    fill: ${colors.ICON_GRAY_HOVER};
  }
`

export class CopyButton extends PureComponent {
  static propTypes = clearButtonPropTypes

  render() {
    const { accessibilityLabel, onPress, ...buttonProps } = this.props
    return (
      <StyledButton
        ariaLabel={accessibilityLabel}
        onClick={onPress}
        {...buttonProps}>
        <CopyIcon fill={colors.ICON_GRAY} />
      </StyledButton>
    )
  }
}
