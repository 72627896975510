import React from 'react'
import figmaColors from '../colors/figmaColors'

export const AvatarIcon = ({ iconStyle = 'white' }) => {
  if (iconStyle === 'white') {
    return (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="40"
        height="40"
        fill="none">
        <path
          stroke={figmaColors.white}
          strokeWidth="2"
          d="M11 10c0-4.973 4.027-9 9-9s9 4.027 9 9-4.027 9-9 9-9-4.027-9-9zM1 32.5c0-1.295.64-2.504 1.865-3.633 1.23-1.136 2.982-2.123 5.004-2.933C11.914 24.314 16.788 23.5 20 23.5c3.212 0 8.086.814 12.131 2.434 2.022.81 3.774 1.797 5.004 2.933C38.36 29.997 39 31.205 39 32.5V39H1v-6.5z"
        />
      </svg>
    )
  }

  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" fill="none">
      <path
        fill={figmaColors.primary_500}
        fillRule="evenodd"
        d="M13.296 17.413C10.35 17.981 7 19.388 7 21.625V25h18v-3.375c0-2.237-3.35-3.644-6.296-4.212-.996-.192-1.945-.288-2.704-.288-.759 0-1.708.096-2.704.288zm.332-2.087A4.481 4.481 0 0016 16a4.496 4.496 0 004.5-4.5C20.5 9.014 18.486 7 16 7a4.499 4.499 0 00-4.5 4.5c0 1.616.85 3.032 2.128 3.826zM23 23v-1.375-.001c0-.02.002-.179-.34-.495-.363-.335-.962-.693-1.777-1.02-1.634-.654-3.631-.984-4.883-.984-1.252 0-3.249.33-4.883.984-.815.327-1.414.685-1.777 1.02-.342.316-.34.476-.34.495V23h14zM16 9a2.499 2.499 0 00-2.5 2.5c0 1.382 1.118 2.5 2.5 2.5s2.5-1.118 2.5-2.5S17.382 9 16 9z"
        clipRule="evenodd"
      />
    </svg>
  )
}
