import PropTypes from 'prop-types'
import { isEqual } from 'lodash'
import React, { PureComponent } from 'react'
import { UnitSettings as UnitSettingsUI } from '@brewcomputer/ui'
import { getUnitSettingsFields } from '@brewcomputer/utils'

export class UnitSettings extends PureComponent {
  static propTypes = {
    user: PropTypes.object,
    onEditUnitSettings: PropTypes.func
  }

  onChange = changes => {
    this.props.onEditUnitSettings(changes)
  }

  getSystemUnitSettings = system => {
    switch (system) {
      case 'metric': {
        return {
          gravity: 'sg',
          color: 'EBC',
          temperature: 'C',
          volume: 'l',
          fermentable_amount: 'kg',
          hop_amount: 'g'
        }
      }
      case 'us': {
        return {
          gravity: 'sg',
          color: 'SRM',
          temperature: 'F',
          volume: 'gal',
          fermentable_amount: 'lb',
          hop_amount: 'oz'
        }
      }
      case 'imperial': {
        return {
          gravity: 'sg',
          color: 'SRM',
          temperature: 'C',
          volume: 'igal',
          fermentable_amount: 'lb',
          hop_amount: 'oz'
        }
      }
    }
  }

  onSwitchToSystem = system => {
    const { user, onEditUnitSettings } = this.props

    const systemSettings = this.getSystemUnitSettings(system)

    if (systemSettings != null && !isEqual(user.unitSettings, systemSettings)) {
      onEditUnitSettings(systemSettings)
    }
  }

  render() {
    const { user } = this.props

    const unitsSettings = getUnitSettingsFields(user.unitSettings)

    return (
      <UnitSettingsUI
        unitSettings={unitsSettings}
        onChange={this.onChange}
        onSwitchToSystem={this.onSwitchToSystem}
      />
    )
  }
}
