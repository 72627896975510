import React from 'react'
import styled from 'styled-components'
import figmaColors from '../colors/figmaColors'
import { textStyles } from '../texts/textStyles'

export const Styled = styled.div`
  border-radius: 4px;
  padding: 4px 12px;
  background-color: ${figmaColors.blue_gray_50};
  color: ${figmaColors.primary_500};
  ${textStyles.body2};
`

export const Label = ({ title, className }) => (
  <Styled className={className}>{title}</Styled>
)
