import { map } from 'lodash'
import React, { Fragment, PureComponent } from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import {
  optionStyle,
  onKeyPressed,
  getPrevValue,
  getNextValue
} from '../lookup/Common'

const getOptionPadding = ({ type }) => {
  return type === 'unit' ? 8 : 12
}
const getJustifyContent = ({ type, alignment }) => {
  return (type === 'default' && alignment === 'left') || type === 'lookup'
    ? 'flex-start'
    : 'flex-end'
}

const Option = styled.div`
  flex-direction: row;
  align-items: center;
  justify-content: ${getJustifyContent};
  padding: 0 ${getOptionPadding}px;
  ${optionStyle};
`

const Label = styled.span`
  :last-child {
    margin-left: 10px;
  }
`

Option.displayName = 'Option'

export class Options extends PureComponent {
  constructor(props) {
    super(props)
    this.state = {
      hoveredValue: props.selectedValue
    }
  }

  componentDidMount() {
    document.addEventListener('keydown', this.onKeyPress)
  }

  componentWillUnmount() {
    document.removeEventListener('keydown', this.onKeyPress)
  }

  hoverValue = value => {
    if (this.state.hoveredValue !== value) {
      this.setState({ hoveredValue: value })
    }
  }

  hoverPrevValue = () => {
    const prevValue = getPrevValue(this.props.items, this.state.hoveredValue)
    if (prevValue) {
      this.hoverValue(prevValue)
    }
  }

  hoverNextValue = () => {
    const nextValue = getNextValue(this.props.items, this.state.hoveredValue)
    if (nextValue) {
      this.hoverValue(nextValue)
    }
  }

  selectActiveValue = () => {
    this.props.onSelect(this.state.hoveredValue)
  }

  onKeyPress = e => {
    onKeyPressed(
      e,
      this.props.onClose,
      this.selectActiveValue,
      this.hoverPrevValue,
      this.hoverNextValue
    )
  }

  getLabel = data => {
    if (Array.isArray(data)) {
      return map(data, (item, index) => <Label key={index}>{item}</Label>)
    }
    return <span>{data}</span>
  }

  render() {
    const { items, type, alignment, selectedValue, onSelect } = this.props
    const { hoveredValue } = this.state

    return (
      <Fragment>
        {map(items, item => (
          // Do not use the onMouseOver instead of the onMouseMove!
          <Option
            key={item.value}
            type={type}
            alignment={alignment}
            hovered={hoveredValue === item.value}
            selected={selectedValue === item.value}
            onClick={() => onSelect(item.value)}
            onMouseMove={() => this.hoverValue(item.value)}>
            {this.getLabel(item.label)}
          </Option>
        ))}
      </Fragment>
    )
  }
}

Options.propTypes = {
  items: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.oneOfType([PropTypes.string, PropTypes.array]),
      value: PropTypes.string
    })
  ),
  type: PropTypes.oneOf(['default', 'unit', 'lookup']),
  alignment: PropTypes.oneOf(['left', 'right']),
  selectedValue: PropTypes.string,
  onSelect: PropTypes.func,
  onClose: PropTypes.func
}
