import React, { PureComponent } from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import colors from '../colors/figmaColors'
import { textStyles } from '../texts/textStyles'

export const StyledLink = styled.div`
  cursor: pointer;
  padding: 4px;
  flex-direction: row;
  transition-duration: 200ms;
  color: ${props => props.textColor};
  :hover {
    color: ${props => props.textColorActive};
  }
`

export const Title = styled.div`
  ${textStyles.body2};
  margin-right: 9px;
`

export class IconLink extends PureComponent {
  static propTypes = {
    Icon: PropTypes.element,
    title: PropTypes.string,
    textColor: PropTypes.string,
    textColorActive: PropTypes.string
  }

  static defaultProps = {
    textColor: colors.blue_gray_500,
    textColorActive: colors.blue_gray_700
  }

  render() {
    const { Icon, title, textColor, textColorActive } = this.props
    return (
      <StyledLink textColor={textColor} textColorActive={textColorActive}>
        <Title>{title}</Title>
        {Icon}
      </StyledLink>
    )
  }
}
