import React from 'react'
import { Background, Container, TextMessage, Title } from './Styled'
import { noBatches, noEquipment, noPublicRecipes, noRecipes } from '../../texts'
import { Link } from 'react-router-dom'
import { links } from '../../links'

export const EmptyList = ({ title, children }) => (
  <Container>
    <Background />
    <Title>{title}</Title>
    <TextMessage marginBottom="35px">{children}</TextMessage>
  </Container>
)

export const NoPublicRecipes = () => (
  <EmptyList title={noPublicRecipes.title}>
    <p>{noPublicRecipes.message1}</p>
    <p>{noPublicRecipes.message2}</p>
  </EmptyList>
)
NoPublicRecipes.displayName = 'NoPublicRecipes'

export const NoRecipes = () => (
  <EmptyList title={noRecipes.title}>
    {noRecipes.message}
    <Link to={links.recipes}>{noRecipes.link}</Link>
  </EmptyList>
)
NoRecipes.displayName = 'NoRecipes'

export const NoEquipment = () => (
  <EmptyList title={noEquipment.title}>{noEquipment.message}</EmptyList>
)
NoEquipment.displayName = 'NoEquipment'

export const NoBatches = () => (
  <EmptyList title={noBatches.title}>
    {noBatches.message}
    <Link to={links.recipes}>{noBatches.link}</Link>
  </EmptyList>
)
NoBatches.displayName = 'NoBatches'
