import React from 'react'
import { Copyright, Footer, Links } from './Styled'
import { Link } from 'react-router-dom'
import { links } from '../../links'

export const LandingFooter = () => (
  <Footer>
    <Links>
      <Link to={links.termsOfUse}>Terms of Use</Link>
      <Link to={links.privacyPolicy}>Privacy Policy</Link>
    </Links>
    <Copyright>{`© 2020-2021 Devsoft Baltic OÜ. All rights reserved.`}</Copyright>
  </Footer>
)
