import { map, size, maxBy, reduce, reverse, filter, last } from 'lodash'
import styled from 'styled-components'
import React, { useEffect } from 'react'
import { LoadingIndicator } from '../LoadingIndicator'
import { colors } from '@brewcomputer/ui'
import { useSelector } from 'react-redux'
import { useGetUserActivity } from '../../hooks/queries'

const Svg = styled.svg`
  align-self: center;
  padding: 24px;
  overflow: visible;
`

const G = styled.g`
  text {
    font-size: 12px;
  }
`

const rectColors = [colors.ACCENT_BLUE_ACTIVE, colors.ACCENT_BLUE_HOVER]
const titles = [
  'Active Users (More than one session)',
  'New Users (First Session)',
]

const Rect = styled.rect`
  fill: ${(props) => rectColors[props.fillColor]};
`

const barWidth = 36
const padding = 6
const barMaxHeight = 360
const getStackSum = (stack) => reduce(stack, (sum, n) => sum + n, 0)

export const StackedBar = ({ index, values, maxValue, text }) => {
  const barOffset = (barWidth + padding) * index
  const getBarHeight = (value) => (value / maxValue) * barMaxHeight

  const stackedBarData = reduce(
    values,
    (acc, value, i) => {
      const barHeight = getBarHeight(value)
      const prevBar = last(acc)
      const y = prevBar ? prevBar.yOffset - barHeight : barMaxHeight - barHeight

      acc.push({
        value,
        y: y,
        x: barOffset,
        width: barWidth,
        height: barHeight,
        fill: rectColors[i],
        yOffset: y,
      })
      return acc
    },
    []
  )

  const textOffset = barOffset + barWidth / 2

  return (
    <G>
      {map(stackedBarData, ({ value, fill, ...rect }, i) => (
        <Rect key={i} {...rect} style={{ fill }}>
          <title>{`${titles[i]} ${value}`}</title>
        </Rect>
      ))}
      <text x={barOffset} y={barMaxHeight} dy="20px">
        {text}
      </text>
      <text
        textAnchor="middle"
        x={textOffset}
        y={last(stackedBarData).yOffset}
        dy="-5px">
        {getStackSum(values)}
      </text>
    </G>
  )
}

export const Chart = ({ data }) => {
  const maxValue = getStackSum(
    maxBy(data, ({ values }) => getStackSum(values)).values
  )

  return (
    <Svg
      width="760"
      height={barMaxHeight + 20}
      aria-labelledby="title"
      role="img">
      <title id="title">Monthly Active Users</title>
      {map(reverse(data), ({ values, text }, index) => (
        <StackedBar
          key={index}
          index={index}
          values={values}
          text={text}
          maxValue={maxValue}
        />
      ))}
    </Svg>
  )
}

export const AdminMAU = () => {
  const { request, selector } = useGetUserActivity({})

  useEffect(() => {
    request()
  }, [])

  const { data, status } = useSelector(selector)

  if (status !== 'ready') {
    return <LoadingIndicator />
  }

  const mau = map(data, ({ users, month, year }) => {
    const activeUsersCount = size(filter(users, { isActive: true }))
    const allUsersCount = size(users) - activeUsersCount
    return {
      values: [activeUsersCount, allUsersCount],
      text: `${month + 1}.${year}`,
    }
  })

  return <Chart data={mau} />
}
