import React from 'react'
import styled from 'styled-components'

const Link = styled.a`
  display: flex;
  margin-right: 12px;
`

export const ProductHuntBadge = () => (
  <Link
    className="producthunt-badge"
    href="https://www.producthunt.com/posts/brewcomputer?utm_source=badge-featured&utm_medium=badge&utm_souce=badge-brewcomputer"
    target="_blank">
    <img
      src="https://api.producthunt.com/widgets/embed-image/v1/featured.svg?post_id=316778&theme=light"
      alt="BrewComputer - Create, adjust, calculate, brew, and share homebrew recipes. | Product Hunt"
      style={{ width: 165, height: 36 }}
      width="165"
      height="36"
    />
  </Link>
)
