import styled from 'styled-components'
import { colors } from '../colors/colors'
import { pageTitleText, pageTitleContainer } from './style'
import { textStyles } from '../texts/textStyles'

export const TitleText = styled.div`
  ${textStyles.H2};
  color: ${colors.BLACK};
`

export const ControlsTitle = styled.div`
  ${textStyles.body2};
  color: ${colors.FILTER_GRAY};
`

export const PageTitleContainer = styled.div`
  ${pageTitleContainer};
`

export const PageTitleText = styled.h1`
  ${pageTitleText};
`

PageTitleText.displayName = 'PageTitleText'
