import PropTypes from 'prop-types'
import { map, isEmpty, pick } from 'lodash'
import React, { PureComponent } from 'react'
import shortId from 'shortid'
import { CommentsTreeRenderless } from '@resolve-js/module-comments'
import { SectionTitle, Comment, CommentEditor } from '@brewcomputer/ui'
import styled from 'styled-components'
import moment from 'moment'

const Container = styled.div`
  margin-top: 22px;
`

const Title = styled(SectionTitle)`
  margin-bottom: 10px;
`

const getRelativeDate = (date) => moment(date).fromNow()

export class CommentEditorContainer extends PureComponent {
  onCreateComment = (message) => {
    const { author, treeId, onCreateComment } = this.props
    onCreateComment(treeId, {
      commentId: shortId.generate(),
      authorId: author.id,
      content: {
        text: message,
        author: pick(author, ['id', 'avatar', 'name']),
        createdAt: Date.now(),
      },
    })
  }

  render() {
    return (
      <CommentEditor
        placeholder="Write a comment ..."
        onCreateComment={this.onCreateComment}
      />
    )
  }
}

export class Comments extends PureComponent {
  renderComments = ({ comments, createComment }) => {
    const { treeId, author, showEditor } = this.props

    if (isEmpty(comments?.children) && !showEditor) {
      return null
    }

    return (
      <Container>
        <Title>Comments</Title>
        {!isEmpty(comments?.children) &&
          map(comments.children, (comment) => (
            <Comment
              key={comment.commentId}
              author={comment.content.author}
              date={getRelativeDate(comment.content.createdAt)}
              message={comment.content.text}
            />
          ))}
        {showEditor && (
          <CommentEditorContainer
            treeId={treeId}
            author={author}
            onCreateComment={createComment}
          />
        )}
      </Container>
    )
  }

  render() {
    const { treeId } = this.props
    return (
      <CommentsTreeRenderless treeId={treeId}>
        {this.renderComments}
      </CommentsTreeRenderless>
    )
  }
}

Comments.propTypes = {
  treeId: PropTypes.string,
  author: PropTypes.object,
  showEditor: PropTypes.bool,
}

Comment.displayName = 'Comment'
Comments.displayName = 'Comments'
