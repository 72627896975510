import { map, size, filter, includes, toLower, trim } from 'lodash'
import styled from 'styled-components'
import React, { useState, useEffect } from 'react'
import { LoadingIndicator } from '../LoadingIndicator'
import { textStyles, colors, Search, Avatar } from '@brewcomputer/ui'
import { Link } from 'react-router-dom'
import { useSelector } from 'react-redux'
import { useGetAllUserStats } from '../../hooks/queries'
import { listItemStyle } from './styles'

const Title = styled.div`
  height: 48px;
  ${listItemStyle};
`

const Item = styled(Link)`
  height: 64px;
  ${listItemStyle};
`

const UserProfile = styled.div`
  flex-direction: row;
  align-items: center;
  width: 40%;
`

const UserName = styled.div`
  ${textStyles.body1};
  color: ${colors.BLACK};
  font-weight: 500;
  margin-left: 24px;
`

const UserFields = styled.div`
  width: 60%;
  ${textStyles.body2};
  color: ${colors.TABLE_GRAY};
  margin-top: 5px;
  flex-direction: row;
`

const UserField = styled.div`
  width: 25%;
  justify-content: center;
`

export const ListTitle = () => (
  <Title>
    <UserProfile />
    <UserFields>
      <UserField>Brewery</UserField>
      <UserField>Info</UserField>
      <UserField>Auth Provider</UserField>
      <UserField>Recipes</UserField>
      <UserField>Registered</UserField>
    </UserFields>
  </Title>
)

export const UserListItem = ({ user }) => {
  return (
    <Item id={user.id} to={`/admin/user/${user.id}`}>
      <UserProfile>
        <Avatar src={user.avatar} size={32} alt={user.name} />
        <UserName>{user.name}</UserName>
      </UserProfile>
      <UserFields>
        <UserField>{user.brewery}</UserField>
        <UserField>{user.info}</UserField>
        <UserField>{user.authProvider}</UserField>
        <UserField>{size(user.recipes)}</UserField>
        <UserField>
          {new Date(user.registeredAt).toLocaleDateString()}
        </UserField>
      </UserFields>
    </Item>
  )
}

const includesSearchString = (string, searchString) =>
  includes(toLower(trim(string)), toLower(trim(searchString)))

const filterUserStats = (userStats, searchString) => {
  return filter(
    userStats.data,
    (user) =>
      includesSearchString(user.name, searchString) ||
      includesSearchString(user.brewery, searchString) ||
      includesSearchString(user.authProvider, searchString) ||
      includesSearchString(user.authId, searchString)
  )
}

export const AdminUserList = () => {
  const { request: getAllUserStats, selector } = useGetAllUserStats({})

  useEffect(() => {
    getAllUserStats()
  }, [])

  const userStats = useSelector(selector)
  const [searchString, setSearchString] = useState('')

  if (userStats.status !== 'ready' || userStats.data == null) {
    return <LoadingIndicator />
  }

  return (
    <div>
      <Search
        label={'Search'}
        placeholder={'Search ...'}
        onSearch={setSearchString}
      />
      <ListTitle />
      {map(filterUserStats(userStats, searchString), (user, key) => (
        <UserListItem key={key} user={user} />
      ))}
    </div>
  )
}
