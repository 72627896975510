import { map } from 'lodash'
import styled from 'styled-components'
import React, { useEffect } from 'react'
import { LoadingIndicator } from '../LoadingIndicator'
import { textStyles, colors, Avatar } from '@brewcomputer/ui'
import { Link } from 'react-router-dom'
import { useSelector } from 'react-redux'
import { useGetUserFeedback } from '../../hooks/queries'
import { listItemStyle } from './styles'

const Title = styled.div`
  height: 48px;
  ${listItemStyle};
`

const Item = styled(Link)`
  height: 64px;
  ${listItemStyle};
`

const UserProfile = styled.div`
  flex-direction: row;
  align-items: center;
  width: 75%;
`

const UserName = styled.div`
  ${textStyles.body1};
  color: ${colors.BLACK};
  font-weight: 500;
  margin-left: 24px;
`

const UserFields = styled.div`
  width: 25%;
  ${textStyles.body2};
  color: ${colors.TABLE_GRAY};
  margin-top: 5px;
  flex-direction: row;
`

export const ListTitle = () => (
  <Title>
    <UserProfile />
    <UserFields>Last feedback</UserFields>
  </Title>
)

export const UserListItem = ({ user }) => {
  return (
    <Item id={user.id} to={`/admin/user/${user.id}`}>
      <UserProfile>
        <Avatar src={user.avatar} size={32} alt={user.name} />
        <UserName>{user.name}</UserName>
      </UserProfile>
      <UserFields>
        {new Date(user.lastFeedback).toLocaleDateString()}
      </UserFields>
    </Item>
  )
}

export const AdminUserFeedback = () => {
  const { request: getFeedback, selector } = useGetUserFeedback({})

  useEffect(() => {
    getFeedback()
  }, [])

  const userFeedback = useSelector(selector)

  if (userFeedback.status !== 'ready' || userFeedback.data == null) {
    return <LoadingIndicator />
  }

  return (
    <div>
      <ListTitle />
      {map(userFeedback.data, (user, key) => (
        <UserListItem key={key} user={user} />
      ))}
    </div>
  )
}
