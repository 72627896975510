import React, { PureComponent } from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { textStyles, colors } from '..'
import { ListItem, Section, measurableToString } from './Controls'

const Name = styled.div`
  ${textStyles.body2};
  color: ${colors.TABLE_BLACK};
  width: 50%;
`

const Value = styled.div`
  ${textStyles.body2};
  font-weight: bold;
  width: 25%;
  text-align: right;
  color: ${props => (props.equal ? colors.GREEN : colors.TABLE_BLACK)};
`

const Header = ({}) => (
  <ListItem>
    <Name />
    <Value>Recipe</Value>
    <Value>Batch</Value>
  </ListItem>
)

const Stat = ({ name, recipe, batch }) => {
  const isEqual =
    batch != null && recipe != null && batch.value === recipe.value
  return (
    <ListItem>
      <Name>{name}</Name>
      <Value>{measurableToString(recipe)}</Value>
      <Value equal={isEqual}>{measurableToString(batch)}</Value>
    </ListItem>
  )
}

export class BatchSummary extends PureComponent {
  static propTypes = {
    recipeStats: PropTypes.object,
    batchStats: PropTypes.object
  }

  render() {
    const { recipeStats, batchStats } = this.props
    return (
      <Section title="Summary">
        <Header />
        <Stat
          name="Original Gravity"
          recipe={recipeStats.original_gravity}
          batch={batchStats.original_gravity}
        />
        <Stat
          name="Final Gravity"
          recipe={recipeStats.final_gravity}
          batch={batchStats.final_gravity}
        />
        <Stat
          name="ABV"
          recipe={recipeStats.alcohol_by_volume}
          batch={batchStats.alcohol_by_volume}
        />
        <Stat
          name="IBU"
          recipe={recipeStats.ibu_estimate}
          batch={batchStats.ibu_estimate}
        />
        <Stat
          name="Color"
          recipe={recipeStats.color_estimate}
          batch={batchStats.color_estimate}
        />
      </Section>
    )
  }
}
