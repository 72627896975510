import React, { useRef, useCallback } from 'react'
import styled from 'styled-components'
import { AccentButton, ImportButton, Search } from '@brewcomputer/ui'
import { get, isEmpty, includes, join } from 'lodash'
import { useSelector } from 'react-redux'
import { useCreateRecipe, useImportRecipe } from '../hooks/commands'
import validateRecipe from '@brewcomputer/utils/validation'
import { importFromBeerXml } from '@brewcomputer/utils'
import { useAlert } from '../hooks/alert'

const TopControls = styled.div`
  margin-bottom: 10px;
  flex-direction: row;
  & > :first-child {
    flex-grow: 1;
  }
  @media (max-width: 576px) {
    flex-direction: column-reverse;
  }
`

const ActionButtons = styled.div`
  justify-content: flex-end;
  flex-direction: row;
  margin-left: 16px;
  & > :last-child {
    margin-left: 16px;
  }
  @media (max-width: 576px) {
    flex-grow: 1;
    margin-left: 0;
    margin-bottom: 16px;
    & > :first-child {
      flex-grow: 1;
    }
  }
`

const getRecipeData = (fileType, fileContent) => {
  const json = includes(fileType, 'xml')
    ? importFromBeerXml(fileContent)
    : fileContent
  return JSON.parse(json)
}

const readRecipeFile = (file, callback) => {
  const reader = new FileReader()
  reader.onloadend = event => {
    callback(event.target.result)
  }
  const blob = file.slice(0, file.size + 1)
  reader.readAsText(blob)
}

export const RecipeListControls = ({
  showButtons,
  lastQuery,
  searchPlaceholder,
  searchTimeout,
  onSearch
}) => {
  const user = useSelector(state => state.user)

  const { showAlert } = useAlert()
  const importButtonRef = useRef(null)

  const createRecipe = useCreateRecipe({}, user)
  const importRecipe = useImportRecipe(user)

  const onLoadRecipeFile = useCallback(fileList => {
    const file = fileList[0]
    readRecipeFile(file, fileContent => {
      try {
        const recipeData = getRecipeData(file.type, fileContent)
        const { isValid, errors } = validateRecipe(recipeData)

        if (!isValid) {
          throw new Error(`Recipe validation error: ${join(errors, ' ')}`)
        }

        const recipe = get(recipeData, 'beerjson.recipes[0]')
        importRecipe(recipe)

        if (isEmpty(recipe.style)) {
          showAlert({
            type: 'warning',
            title: 'Warning!',
            message:
              'Could not import a beer style. Please select one of the available styles from our list.'
          })
        }

        if (importButtonRef.current) {
          importButtonRef.current.clear()
        }
      } catch (e) {
        showAlert({
          type: 'error',
          title: 'Error!',
          message: e.message
        })
      }
    })
  }, [])

  return (
    <TopControls>
      <Search
        lastQuery={lastQuery}
        label={searchPlaceholder}
        placeholder={searchPlaceholder}
        timeout={searchTimeout}
        onSearch={onSearch}
      />
      {showButtons && (
        <ActionButtons>
          <AccentButton
            accessibilityLabel="Create Recipe"
            title="Create Recipe"
            onPress={createRecipe}
          />
          <ImportButton
            ref={importButtonRef}
            title="Import Recipe"
            accessibilityLabel="Import Recipe"
            onImport={onLoadRecipeFile}
          />
        </ActionButtons>
      )}
    </TopControls>
  )
}
