import produce from 'immer'
import {
  recipesPublicGetQuery,
  recipesPublicCountQuery,
  recipeRemoveAction,
  recipesSearch,
  RECIPES_SEARCH_CLEAR,
  RECIPES_GO_TO_PAGE
} from '../actions/recipe'
import { isEmpty, remove } from 'lodash'

export const initialState = {
  isLoading: true,
  data: [],
  count: 0,
  page: 0
}

const reducer = produce((draft, action) => {
  switch (action.type) {
    case recipesPublicGetQuery.types.request: {
      draft.isLoading = true
      return draft
    }
    case recipesPublicGetQuery.types.success: {
      draft.data = action.payload.result
      draft.isLoading = false
      return draft
    }
    case recipesPublicGetQuery.types.failure: {
      draft.isLoading = false
      return draft
    }
    case recipesPublicCountQuery.types.success: {
      draft.count = action.payload.result
      return draft
    }
    case RECIPES_GO_TO_PAGE: {
      draft.page = action.payload.page
      return draft
    }
    case recipesSearch.types.success: {
      if (isEmpty(action.payload.result)) {
        draft.data = []
        draft.count = 0
      }
      draft.page = 0
      return draft
    }
    case RECIPES_SEARCH_CLEAR: {
      draft.page = 0
      return draft
    }
    case recipeRemoveAction.types.request: {
      const { recipeId } = action.payload
      remove(draft.data, { id: recipeId })
      return draft
    }
  }
}, initialState)

export default reducer
