import { pick, pickBy, includes, get, find, map, startCase } from 'lodash'
import measurables from '@beerjson/beerjson/json/measureable_units.json'

const measurableUnitTypes = pickBy(measurables.definitions, (value, key) =>
  includes(key, 'UnitType')
)

export function getUnitsList(unit) {
  return get(
    find(measurableUnitTypes, type => includes(type.enum, unit)),
    'enum',
    [unit]
  )
}

export const fermentableAmountUnits = ['g', 'kg', 'lb', 'oz']

export const hopAmountUnits = ['g', 'kg', 'lb', 'oz']

export const cultureAmountUnits = ['pkg', 'g', 'oz', 'ml', 'l', 'floz', 'ifloz']

export const miscAmountUnits = [
  'pkg',
  'g',
  'kg',
  'oz',
  'lb',
  'ml',
  'tbsp',
  'floz',
  'ifloz'
]

export const batchVolumeUnits = ['ml', 'l', 'gal', 'bbl', 'igal', 'ibbl']

export function extendMeasurableData(data) {
  return {
    value: get(data, 'value'),
    unit: get(data, 'unit'),
    units: get(data, 'units')
  }
}

export function getMeasurable(ingredient, path) {
  return extendMeasurableData(get(ingredient, path))
}

const degreeSign = String.fromCharCode(0x00b0)

const beautifulUnits = {
  sg: 'SG',
  plato: `${degreeSign}P`,
  brix: `${degreeSign}Bx`,
  Lovi: `${degreeSign}L`,
  C: `${degreeSign}C`,
  F: `${degreeSign}F`
}

export function beautifyUnit(unit) {
  return get(beautifulUnits, unit, unit)
}

const units = {
  volume: batchVolumeUnits,
  fermentable_amount: fermentableAmountUnits,
  hop_amount: hopAmountUnits
}

const getUnitSettingsUnitList = (unit, key) => {
  return units[key] || getUnitsList(unit)
}

export function getUnitSettingsFields(unitSettings) {
  return map(
    pick(unitSettings, [
      'gravity',
      'color',
      'temperature',
      'volume',
      'fermentable_amount',
      'hop_amount'
    ]),
    (value, key) => ({
      path: key,
      caption: startCase(key),
      value,
      units: map(getUnitSettingsUnitList(value, key), unit => ({
        value: unit,
        label: beautifyUnit(unit)
      }))
    })
  )
}
