import { LOCATION_CHANGE } from '../actions/navigation'
import { startsWith } from 'lodash'
import { produce } from 'immer'

const initialState = {
  recipes: {
    lastTab: '/recipes',
    lastPage: '/recipes'
  },
  batches: {
    lastPage: '/batches'
  }
}

const navigation = produce((draft, action) => {
  if (action.type === LOCATION_CHANGE) {
    const { pathname } = action.payload
    if (startsWith(pathname, '/batch')) {
      draft.batches = { lastPage: pathname }
      return draft
    }
    if (startsWith(pathname, '/recipe')) {
      if (pathname === '/recipes/my' || pathname === '/recipes') {
        draft.recipes = { lastPage: pathname, lastTab: pathname }
      } else {
        draft.recipes.lastPage = pathname
      }
      return draft
    }
  }
}, initialState)

export default navigation
