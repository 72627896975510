import { get, isEmpty } from 'lodash'
import React, { useEffect } from 'react'
import { useSelector } from 'react-redux'
import { RecipePrintable } from '../components/recipePrintable/RecipePrintable'
import { useRecipesViewModel } from '../hooks/viewModel'
import { LoadingIndicator } from '../components/LoadingIndicator'
import { RecipeNotFound } from '../components/specialPages/RecipeNotFound'
import { getStats } from '../selectors/recipe'

const getRecipeId = props => get(props, 'match.params.recipeId')
const getRecipe = recipeId => state => get(state, ['recipes', recipeId], null)

const RecipePrintableContainer = props => {
  const recipeId = getRecipeId(props)
  const recipe = useSelector(getRecipe(recipeId))
  const { user } = useSelector(state => {
    return {
      isAuthorized: !isEmpty(state.jwt),
      user: get(state, 'user')
    }
  })

  const stats = useSelector(getStats(recipeId))

  const { connect, dispose } = useRecipesViewModel(recipeId)

  useEffect(() => {
    connect()
    return () => {
      dispose()
    }
  }, [])

  if (isEmpty(recipe) || recipe.isLoading) {
    return <LoadingIndicator />
  }

  if (recipe.isError || user.isError) {
    return <RecipeNotFound />
  }

  return <RecipePrintable user={user} recipe={recipe} stats={stats} />
}
export default RecipePrintableContainer
