import React, { useCallback } from 'react'
import { map } from 'lodash'
import { ResponsiveRow } from '../responsiveTable/ResponsiveRow'
import { ResponsiveEditor } from '../responsiveTable/ResponsiveEditor'

export const Ingredient = ({
  id,
  ingredientType,
  ingredient,
  visibleColumnsCount,
  onEditIngredient,
  onRemoveIngredient
}) => {
  const editIngredient = useCallback(
    changes => {
      onEditIngredient({ id, changes, ingredientType })
    },
    [id, ingredientType, onEditIngredient]
  )
  const removeIngredient = useCallback(
    () => {
      onRemoveIngredient(id)
    },
    [id, onRemoveIngredient]
  )
  return (
    <ResponsiveRow
      visibleColumnsCount={visibleColumnsCount}
      showDeleteButton
      onPressDelete={removeIngredient}>
      {map(ingredient, ({ title, path, data, disableConversion }) => (
        <ResponsiveEditor
          key={title}
          title={title}
          path={path}
          {...data}
          disableConversion={disableConversion}
          onChange={editIngredient}
        />
      ))}
    </ResponsiveRow>
  )
}

Ingredient.displayName = 'Ingredient'
