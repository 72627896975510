import React, { PureComponent } from 'react'
import { colors } from '../colors/colors'

export class TemperatureUnitsIcon extends PureComponent {
  render() {
    return (
      <svg
        width={20}
        height={20}
        fill={'none'}
        viewBox={'0 0 20 20'}
        xmlns={'http://www.w3.org/2000/svg'}>
        <path
          fillRule={'evenodd'}
          clipRule={'evenodd'}
          d={
            'M4 2a2 2 0 012-2h8a2 2 0 012 2v16a2 2 0 01-2 2H6a2 2 0 01-2-2V2zm3 1a1 1 0 011-1h1v12a2 2 0 11-2 2V3zm5-1h-2v2h3V3a1 1 0 00-1-1zm-2 3h3v2h-3V5zm3 3h-3v2h3V8zm-3 3h3v1a1 1 0 01-1 1h-2v-2z'
          }
          fill={colors.ICON_GRAY}
        />
      </svg>
    )
  }
}

export class GravityUnitsIcon extends PureComponent {
  render() {
    return (
      <svg
        width={20}
        height={20}
        fill={'none'}
        viewBox={'0 0 20 20'}
        xmlns={'http://www.w3.org/2000/svg'}>
        <path
          d={
            'M2 3a1 1 0 011-1h14a1 1 0 011 1H2zM2 5h16v2H2zM2 9h16v3H2zM2 14h16v3a1 1 0 01-1 1H3a1 1 0 01-1-1v-3z'
          }
          fill={colors.ICON_GRAY}
        />
      </svg>
    )
  }
}

export class PressureUnitsIcon extends PureComponent {
  render() {
    return (
      <svg
        width={20}
        height={20}
        fill={'none'}
        viewBox={'0 0 20 20'}
        xmlns={'http://www.w3.org/2000/svg'}>
        <path
          fillRule={'evenodd'}
          clipRule={'evenodd'}
          d={
            'M14 17.504c0-.323.164-.62.418-.819a7.2 7.2 0 10-8.837 0c.255.198.419.496.419.819 0 .718-.752 1.174-1.33.748a9 9 0 1110.66 0c-.578.426-1.33-.03-1.33-.748z'
          }
          fill={colors.ICON_GRAY}
        />
        <path
          d={
            'M9.199 3h1.602v3H9.199V3zM2 11.798v-1.596h3v1.596H2zm13 .002v-1.6h3v1.6h-3zm-1.118-4.821l.14.141L11 12.124l-2.121-2.122 5.004-3.023h-.001z'
          }
          fill={colors.ICON_GRAY}
        />
        <path
          d={'M8.942 12.06a1.5 1.5 0 102.121-2.122 1.5 1.5 0 00-2.121 2.122z'}
          fill={colors.ICON_GRAY}
        />
      </svg>
    )
  }
}

export class WeightUnitsIcon extends PureComponent {
  render() {
    return (
      <svg
        width={20}
        height={20}
        fill={'none'}
        viewBox={'0 0 20 20'}
        xmlns={'http://www.w3.org/2000/svg'}>
        <path
          fill={colors.ICON_GRAY}
          d={'M9 4h2v3H9zM3 2h14a2 2 0 01-2 2H5a2 2 0 01-2-2z'}
        />
        <path
          fillRule={'evenodd'}
          clipRule={'evenodd'}
          d={
            'M6.963 7a2 2 0 00-1.906 1.394l-2.642 8.303A1 1 0 003.368 18h13.264a1 1 0 00.953-1.303l-2.642-8.303A2 2 0 0013.039 7H6.963zM10 16a3 3 0 100-6 3 3 0 000 6z'
          }
          fill={colors.ICON_GRAY}
        />
        <path fill={colors.ICON_GRAY} d={'M9.5 12.5h1v1h-1z'} />
        <path
          d={'M11.4 11.247l.353.353-1.253 1.9-1-1 1.9-1.253z'}
          fill={colors.ICON_GRAY}
        />
      </svg>
    )
  }
}

export class VolumeUnitsIcon extends PureComponent {
  render() {
    return (
      <svg
        width={20}
        height={20}
        fill={'none'}
        viewBox={'0 0 20 20'}
        xmlns={'http://www.w3.org/2000/svg'}>
        <path
          fillRule={'evenodd'}
          clipRule={'evenodd'}
          d={
            'M4.96 3.697L4.863 3.6H13.4V5H10v1h3.4v1H11v1h2.4v1H10v1h3.4v1H11v1h2.4v1H10v1h3.4v2a.4.4 0 01-.4.4H7a.4.4 0 01-.4-.4V7.657a5.6 5.6 0 00-1.64-3.96zm-2.253.01C2.077 3.077 2.523 2 3.414 2H15v14a2 2 0 01-2 2H7a2 2 0 01-2-2V7.657a4 4 0 00-1.172-2.829l-1.121-1.12v-.001zM15 2h2.4A1.6 1.6 0 0119 3.6v6h-1.597v-6H15V2z'
          }
          fill={colors.ICON_GRAY}
        />
      </svg>
    )
  }
}

export class WeightToVolumeIcon extends PureComponent {
  render() {
    return (
      <svg
        width={20}
        height={20}
        fill={'none'}
        viewBox={'0 0 20 20'}
        xmlns={'http://www.w3.org/2000/svg'}>
        <path
          fillRule={'evenodd'}
          clipRule={'evenodd'}
          d={
            'M8 2a1 1 0 011-1h2a1 1 0 011 1v1h3a1 1 0 011 1v1h1a1 1 0 011 1v.6h-2V16a2 2 0 01-2 2H6a2 2 0 01-2-2V6.6H2V6a1 1 0 011-1h1V4a1 1 0 011-1h3V2zm1 0h2v1H9V2zM5.6 4.6V16c0 .22.18.4.4.4h8a.4.4 0 00.4-.4V4.6H5.6zM8.5 13V8h-1v5h1zm1-5h1v5h-1V8zm3 5V8h-1v5h1z'
          }
          fill={colors.ICON_GRAY}
        />
      </svg>
    )
  }
}

export class AlcoholIcon extends PureComponent {
  render() {
    return (
      <svg
        width={20}
        height={20}
        fill={'none'}
        viewBox={'0 0 20 20'}
        xmlns={'http://www.w3.org/2000/svg'}>
        <path
          fillRule={'evenodd'}
          clipRule={'evenodd'}
          d={
            'M12.263 16.223a2.8 2.8 0 113.96-3.96 2.8 2.8 0 01-3.96 3.96zm1.273-2.687a1 1 0 101.414 1.414 1 1 0 00-1.414-1.414zM3.777 7.737a2.8 2.8 0 113.96-3.96 2.8 2.8 0 01-3.96 3.96zM5.05 5.05a1 1 0 101.414 1.414A1 1 0 005.05 5.05z'
          }
          fill={colors.ICON_GRAY}
        />
        <path
          d={
            'M2.222 16.364L16.364 2.222l1.414 1.414L3.636 17.778l-1.414-1.414z'
          }
          fill={colors.ICON_GRAY}
        />
      </svg>
    )
  }
}

export class RefractometerIcon extends PureComponent {
  render() {
    return (
      <svg
        width={20}
        height={20}
        fill={'none'}
        viewBox={'0 0 20 20'}
        xmlns={'http://www.w3.org/2000/svg'}>
        <path
          fillRule={'evenodd'}
          clipRule={'evenodd'}
          d={
            'M17 10a7 7 0 11-14 0 7 7 0 0114 0zm2 0a9 9 0 11-18 0 9 9 0 0118 0zm-4 0a5 5 0 11-10 0 5 5 0 0110 0zm-3-1a3 3 0 11-6 0 3 3 0 016 0z'
          }
          fill={colors.ICON_GRAY}
        />
      </svg>
    )
  }
}

export class YeastIcon extends PureComponent {
  render() {
    return (
      <svg
        width={20}
        height={20}
        fill={'none'}
        viewBox={'0 0 20 20'}
        xmlns={'http://www.w3.org/2000/svg'}>
        <path
          fillRule={'evenodd'}
          clipRule={'evenodd'}
          d={
            'M5.5 10.432l-.73.436C3.486 11.636 2.5 13.442 2.5 15c0 .49.098.73.17.847.066.107.174.215.407.32.568.253 1.498.333 2.923.333s2.355-.08 2.923-.334c.233-.104.341-.213.407-.32.072-.115.17-.357.17-.846 0-1.558-.986-3.364-2.27-4.132l-.73-.436V3.5h-1v6.932zM4 3a1 1 0 011-1h2a1 1 0 011 1v6.58c1.766 1.056 3 3.37 3 5.42 0 2.761-2.239 3-5 3s-5-.239-5-3c0-2.05 1.234-4.364 3-5.42V3zm2.302 9.336a.655.655 0 111.31 0 .655.655 0 01-1.31 0zm-1.965.656a.655.655 0 100 1.31.655.655 0 000-1.31zm1.965 1.965a.655.655 0 111.31 0 .655.655 0 01-1.31 0zM14 2h-1v1h1v13a2 2 0 104 0V3h1V2h-5zm3 1h-2v6h2V3z'
          }
          fill={colors.ICON_GRAY}
        />
      </svg>
    )
  }
}

export class CarbonationIcon extends PureComponent {
  render() {
    return (
      <svg
        width={20}
        height={20}
        fill={'none'}
        viewBox={'0 0 20 20'}
        xmlns={'http://www.w3.org/2000/svg'}>
        <circle cx={'3'} cy={'4'} r={'2'} fill={colors.ICON_GRAY} />
        <circle cx={'10'} cy={'5'} r={'2'} fill={colors.ICON_GRAY} />
        <circle cx={'17'} cy={'4'} r={'2'} fill={colors.ICON_GRAY} />
        <circle cx={'10'} cy={'10'} r={'2'} fill={colors.ICON_GRAY} />
        <circle cx={'5'} cy={'10'} r={'2'} fill={colors.ICON_GRAY} />
        <circle cx={'15'} cy={'10'} r={'2'} fill={colors.ICON_GRAY} />
        <circle cx={'10'} cy={'15'} r={'2'} fill={colors.ICON_GRAY} />
        <circle cx={'3'} cy={'16'} r={'2'} fill={colors.ICON_GRAY} />
        <circle cx={'17'} cy={'16'} r={'2'} fill={colors.ICON_GRAY} />
      </svg>
    )
  }
}
