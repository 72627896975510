import React, { Fragment, PureComponent } from 'react'
import PropTypes from 'prop-types'
import { ClearButton, List as ListUI } from '@brewcomputer/ui'
import { Header, Link, Separator } from './common'
import { DeleteConfirmation } from '../DeleteConfirmation'
import { getBatchLink, getRecipeLink } from '../../links'

export class List extends PureComponent {
  constructor(props) {
    super(props)
    this.state = {
      itemToDelete: null
    }
  }

  onConfirm = () => {
    this.props.onRemoveItem(this.state.itemToDelete.id)
    this.setState({
      itemToDelete: null
    })
  }

  onReject = () => {
    this.setState({ itemToDelete: null })
  }

  showConfirmationDialog = ({ id, name }) => e => {
    e.preventDefault()
    this.setState({
      itemToDelete: {
        id,
        name
      }
    })
  }

  renderItem = item => {
    const { renderItem, type, allowDelete } = this.props
    const link =
      type === 'batch'
        ? getBatchLink(item.id, item.batch.status)
        : getRecipeLink(item.id)
    const accessibilityLabel =
      type === 'batch' ? 'Remove batch' : 'Remove recipe'
    return (
      <Link tabIndex={0} key={item.id} to={link}>
        {renderItem(item)}
        {allowDelete && (
          <ClearButton
            tabIndex={0}
            accessibilityLabel={accessibilityLabel}
            onPress={this.showConfirmationDialog(item)}
          />
        )}
      </Link>
    )
  }

  render() {
    const { data, type, ListEmpty, ListHeader, allowDelete } = this.props
    const { itemToDelete } = this.state
    return (
      <Fragment>
        <ListUI
          data={data}
          renderItem={this.renderItem}
          ItemSeparatorComponent={<Separator />}
          ListEmptyComponent={ListEmpty}
          ListHeaderComponent={<Header>{ListHeader}</Header>}
        />
        {allowDelete && (
          <DeleteConfirmation
            visible={itemToDelete != null}
            type={type}
            name={itemToDelete != null ? itemToDelete.name : ''}
            onConfirm={this.onConfirm}
            onReject={this.onReject}
          />
        )}
      </Fragment>
    )
  }
}

List.propTypes = {
  data: PropTypes.array,
  allowDelete: PropTypes.bool,
  type: PropTypes.oneOf(['recipe', 'batch']),
  ListEmpty: PropTypes.any,
  ListHeader: PropTypes.any,
  getLink: PropTypes.func,
  renderItem: PropTypes.func,
  onRemoveItem: PropTypes.func
}

List.defaultProps = {
  allowDelete: true
}
