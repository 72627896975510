import React from 'react'
import styled, { keyframes } from 'styled-components'

const spin = keyframes`
  to { transform: rotate(360deg); }
`

const Spinner = styled.div`
  display: inline-block;
  width: 50px;
  height: 50px;
  border: 4px solid #d1731c;
  border-radius: 50%;
  border-top-color: #f9e06c;
  animation: ${spin} 1s ease-in-out infinite;
`

const Container = styled.div`
  flex: 1;
  padding: 35vh 0;
  justify-content: center;
  align-items: center;
`

export const LoadingIndicator = ({ className }) => (
  <Container className={className}>
    <Spinner />
  </Container>
)
