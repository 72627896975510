import React, { useEffect } from 'react'
import { useReduxViewModel } from '@resolve-js/redux'
import { useSelector } from 'react-redux'

const Badge = ({ className }) => {
  const userId = useSelector(state => state.user.id)

  const { connect, dispose, selector } = useReduxViewModel({
    name: 'UnreadFeedback',
    aggregateIds: [userId],
    args: {}
  })

  const { data: count } = useSelector(selector)

  useEffect(() => {
    connect()
    return () => {
      dispose()
    }
  }, [])

  if (count) {
    return <div className={className}>{count}</div>
  }
  return null
}

export default Badge
