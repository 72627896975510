import React from 'react'
import PropTypes from 'prop-types'
import { PageTitleText, PageTitleContainer } from './Styled'

export const PageTitle = ({ title }) => (
  <PageTitleContainer>
    <PageTitleText>{title}</PageTitleText>
  </PageTitleContainer>
)

PageTitle.propTypes = {
  title: PropTypes.string
}
