import { filter, find, get, orderBy } from 'lodash'
import { beautifyUnit, getMashStepDescription } from '@brewcomputer/utils'

export const filterByUse = (ingredients, use) =>
  filter(
    ingredients,
    ingredient => get(ingredient, 'timing.use') === `add_to_${use}`
  )

export const orderByTiming = ingredients =>
  orderBy(ingredients, [addition => addition.timing.time.value], ['desc'])

export const measurableToString = measurable => {
  if (measurable == null) {
    return ''
  }
  let label = measurable.unit
  if (measurable.units != null) {
    const convertedUnit = find(measurable.units, { unit: measurable.unit })
    if (convertedUnit != null) {
      label = convertedUnit.label
    }
  }
  return `${measurable.value} ${beautifyUnit(label)}`
}

export const getMashStepLabel = step =>
  `${getMashStepDescription(step)} (${measurableToString(
    step.temperature
  )} for ${measurableToString(step.time)})`

export const getBoilAdditionLabel = addition =>
  `Add ${addition.name} ${measurableToString(
    addition.amount
  )} for ${measurableToString(addition.timing.time)}`

export const getCultureLabel = culture => `Pitch ${culture.name}`

export const getFermentationAdditionLabel = addition =>
  `Add ${addition.name} ${measurableToString(addition.amount)}`
