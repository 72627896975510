import styled from 'styled-components'
import { textStyles, colors } from '@brewcomputer/ui'
import React from 'react'
import { Link } from 'react-router-dom'
import { links } from '../../links'
import { SocialLinks } from './SocialLinks'

const StyledFooter = styled.footer`
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding: 32px;
  border-top: 2px solid ${colors.WHITE};
  background-color: ${colors.BACKGROUND};

  @media (max-width: 576px) {
    flex-wrap: wrap-reverse;
    justify-content: center;
  }
`

const Copyright = styled.div`
  flex-direction: row;
  align-items: center;
  color: ${colors.LINK_GRAY};
  ${textStyles.body2};
  white-space: break-spaces;
  text-align: center;
  a {
    text-decoration: underline;
    color: ${colors.LINK_GRAY};
  }

  @media (max-width: 576px) {
    margin-top: 20px;
    flex-wrap: wrap;
    justify-content: center;
  }
`

export const Footer = () => (
  <StyledFooter>
    <Copyright>
      <span>
        {`© 2020-2021 Devsoft Baltic OÜ. All rights reserved. `}
        <Link to={links.termsOfUse}>Terms of Use</Link>
        {' and '}
        <Link to={links.privacyPolicy}>Privacy Policy</Link>
      </span>
    </Copyright>
    <SocialLinks />
  </StyledFooter>
)
