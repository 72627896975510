import React, { PureComponent } from 'react'
import { colors } from '../colors/colors'

export class CopyIcon extends PureComponent {
  static defaultProps = {
    fill: colors.ACCENT_BLUE
  }
  render() {
    const { fill } = this.props
    return (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="30"
        height="30"
        fill="none">
        <path
          fill={fill}
          fillRule="evenodd"
          d="M23 6.6h-9a.4.4 0 00-.4.4v13c0 .22.18.4.4.4h9a.4.4 0 00.4-.4V7a.4.4 0 00-.4-.4zM14 5a2 2 0 00-2 2v13a2 2 0 002 2h9a2 2 0 002-2V7a2 2 0 00-2-2h-9z"
          clipRule="evenodd"
        />
        <path
          fill={fill}
          fillRule="evenodd"
          d="M12 9.6H7a.4.4 0 00-.4.4v13c0 .22.18.4.4.4h9a.4.4 0 00.4-.4v-1H18v1a2 2 0 01-2 2H7a2 2 0 01-2-2V10a2 2 0 012-2h5v1.6z"
          clipRule="evenodd"
        />
        <path
          fill={fill}
          d="M16 10h5v1h-5zM16 13h5v1h-5zM16 16h5v1h-5zM9 13h3v1H9zM9 16h3v1H9zM9 19h3v1H9z"
        />
      </svg>
    )
  }
}
