export const SHOW_ALERT = 'SHOW_ALERT'
export const HIDE_ALERT = 'HIDE_ALERT'

export const showAlert = ({ type, title, message }) => ({
  type: SHOW_ALERT,
  payload: {
    type,
    title,
    message
  }
})

export const hideAlert = () => ({
  type: HIDE_ALERT,
  payload: {}
})
