import React, { PureComponent } from 'react'
import styled from 'styled-components'
import { colors } from '../colors/colors'
import { textStyles } from '../texts/textStyles'
import { BackIcon } from '../icons/BackIcon'

const Link = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  background-color: transparent;
  height: 100%;
`

const Text = styled.div`
  color: ${colors.FILTER_GRAY};
  ${textStyles.body2};
  margin-left: 12px;
`

export class BackLink extends PureComponent {
  render() {
    return (
      <Link>
        <BackIcon />
        <Text>{this.props.children}</Text>
      </Link>
    )
  }
}
