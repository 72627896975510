export const createBatch = (
  {
    number,
    name,
    status: status = 'planning',
    date,
    notes: notes = '',
    measuredValues: measuredValues = {},
    taste: taste = {}
  },
  user
) => {
  return {
    author: user.name,
    number,
    name,
    status,
    date,
    notes,
    measuredValues,
    taste
  }
}
