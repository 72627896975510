import styled, { css } from 'styled-components'
import { titleStyle, editorStyle } from './style'

const highlightedStyle = props =>
  props.highlighted &&
  css`
    background-color: rgba(16, 156, 241, 0.2);
    border-color: rgba(16, 156, 241, 1);
    transition-duration: 0ms;
  `

export const Editor = styled.div`
  ${editorStyle};
  border-bottom-style: solid;
  position: relative;
  transition-duration: 2000ms;
  transition-timing-function: ease-out;
  ${highlightedStyle};
`

export const Title = styled.div`
  ${titleStyle};
  transition-duration: 200ms;
`
Title.displayName = 'Title'

export const InputContainer = styled.label`
  display: flex;
  flex-direction: column;
  width: 100%;
  ${props => (props.singleLine ? singleLineSelectContainerStyle : '')};
`

const singleLineSelectContainerStyle = css`
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
`

export const SelectContainer = styled.div`
  width: 100%;
  ${props => (props.singleLine ? singleLineSelectContainerStyle : '')};
`
