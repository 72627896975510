import React, { Fragment, PureComponent } from 'react'
import { convert } from '@brewcomputer/utils'
import { renderNumberValueEditor, ToolTitle } from './ToolsUtils'

const k_coeff = 273.15

const precision = 1

export class TemperatureUnitsTool extends PureComponent {
  constructor(props) {
    super(props)
    const defaultValue = 23.0
    this.state = {
      c: defaultValue,
      k: (defaultValue + k_coeff).toFixed(precision),
      f: convert(defaultValue, 'C', 'F').toFixed(precision)
    }
  }

  _onChangeCelsius = value => {
    this.setState({
      c: value,
      k: (value + k_coeff).toFixed(precision),
      f: convert(value, 'C', 'F').toFixed(precision)
    })
  }

  _onChangeKelvin = value => {
    this.setState({
      c: (value - k_coeff).toFixed(precision),
      k: value,
      f: convert(value - k_coeff, 'C', 'F').toFixed(precision)
    })
  }

  _onChangeFahrenheit = value => {
    this.setState({
      c: convert(value, 'F', 'C').toFixed(precision),
      k: (convert(value, 'F', 'C') + k_coeff).toFixed(precision),
      f: value
    })
  }

  render() {
    return (
      <Fragment>
        <ToolTitle text={'Temperature Units'} />

        {renderNumberValueEditor(
          'Celsius (C)',
          this.state.c,
          this._onChangeCelsius
        )}
        {renderNumberValueEditor(
          'Kelvin (K)',
          this.state.k,
          this._onChangeKelvin
        )}
        {renderNumberValueEditor(
          'Fahrenheit (F)',
          this.state.f,
          this._onChangeFahrenheit
        )}
      </Fragment>
    )
  }
}
