import React, { useEffect, useCallback } from 'react'
import { map, capitalize } from 'lodash'
import { useSelector } from 'react-redux'
import { Lookup } from '@brewcomputer/ui'
import { useGetTemplates } from '../../hooks/queries'
import { findById } from './common'

export const TemplateLookup = ({ templateType, onSelect }) => {
  const { request: getTemplates, selector } = useGetTemplates(templateType)

  useEffect(() => {
    getTemplates()
  }, [])

  const { data: templates } = useSelector(selector)

  const items = map(templates, template => ({
    label: template.name,
    value: template.id
  }))

  const title = `Select ${capitalize(templateType)}`

  const onSelectTemplate = useCallback(templateId => {
    onSelect(findById(templates, templateId))
  })

  return <Lookup text={title} items={items} onChange={onSelectTemplate} />
}

TemplateLookup.displayName = 'TemplateLookup'
