import { get } from 'lodash'
import React, { PureComponent, Fragment } from 'react'
import PropTypes from 'prop-types'
import { Section, MeasuredValueEditor, Form } from './Controls'

export class BatchMeasured extends PureComponent {
  static propTypes = {
    status: PropTypes.oneOf(['brewing', 'fermentation']),
    measuredValues: PropTypes.object,
    onEditBatchMeasured: PropTypes.func
  }

  onEditMeasuredValue = key => measurable => {
    const { onEditBatchMeasured } = this.props
    onEditBatchMeasured({ [key]: measurable })
  }

  render() {
    const { status, measuredValues } = this.props

    return (
      <Section title={'Measured Values'}>
        <Form>
          {status === 'brewing' && (
            <Fragment>
              <MeasuredValueEditor
                title={'Mash pH'}
                measuredValue={get(measuredValues, 'pH')}
                onChange={this.onEditMeasuredValue('pH')}
              />
              <MeasuredValueEditor
                title={'End of Running Gravity'}
                measuredValue={get(measuredValues, 'sparge_gravity')}
                onChange={this.onEditMeasuredValue('sparge_gravity')}
              />
              <MeasuredValueEditor
                title={'Pre Boil Gravity'}
                measuredValue={get(measuredValues, 'pre_boil_gravity')}
                onChange={this.onEditMeasuredValue('pre_boil_gravity')}
              />
              <MeasuredValueEditor
                title={'Pre Boil Size'}
                measuredValue={get(measuredValues, 'pre_boil_size')}
                onChange={this.onEditMeasuredValue('pre_boil_size')}
              />
            </Fragment>
          )}
          {status === 'fermentation' && (
            <Fragment>
              <MeasuredValueEditor
                title={'Original Gravity'}
                measuredValue={get(measuredValues, 'original_gravity')}
                onChange={this.onEditMeasuredValue('original_gravity')}
              />
              <MeasuredValueEditor
                title={'Final Gravity'}
                measuredValue={get(measuredValues, 'final_gravity')}
                onChange={this.onEditMeasuredValue('final_gravity')}
              />
              <MeasuredValueEditor
                title={'Batch Size'}
                measuredValue={get(measuredValues, 'batch_size')}
                onChange={this.onEditMeasuredValue('batch_size')}
              />
            </Fragment>
          )}
        </Form>
      </Section>
    )
  }
}
