import { convert, convertMeasurable } from '..'
import { includes, forEach, isEmpty, pick, get, set } from 'lodash'
import { ranges } from './defaultRanges'
import { convertStyle } from './convertStyle'

const fixKey = key => {
  switch (key) {
    case 'color_estimate':
      return 'color'
    case 'ibu_estimate':
      return 'international_bitterness_units'
  }
  return key
}

export const calcRange = (key, { unit, value }, style) => {
  key = fixKey(key)
  return {
    minStyleValue: get(style, [key, 'minimum', 'value']),
    maxStyleValue: get(style, [key, 'maximum', 'value']),
    minScaleValue: get(ranges, [key, unit, 'minimum']),
    maxScaleValue: get(ranges, [key, unit, 'maximum'])
  }
}

export const convertStat = (range, unit) => ({
  ...range,
  minStyleValue: convert(range.minStyleValue, range.unit, unit),
  maxStyleValue: convert(range.maxStyleValue, range.unit, unit),
  minScaleValue: get(ranges, [range.key, unit, 'minimum']),
  maxScaleValue: get(ranges, [range.key, unit, 'maximum']),
  ...convertMeasurable({ value: range.value, unit: range.unit }, unit)
})

export const convertStats = (recipe, unitSettings) => {
  const stats = pick(
    recipe,
    'original_gravity',
    'final_gravity',
    'alcohol_by_volume',
    'color_estimate',
    'ibu_estimate'
  )

  if (isEmpty(unitSettings) || isEmpty(stats)) {
    return {}
  }

  const { gravity, color } = unitSettings
  forEach(stats, (measurable, key) => {
    if (includes(key, 'gravity')) {
      stats[key] = convertMeasurable(measurable, gravity)
    }
    if (includes(key, 'color')) {
      stats[key] = convertMeasurable(measurable, color)
    }
    if (!isEmpty(recipe.style)) {
      const style = convertStyle(recipe.style, unitSettings)
      set(stats, ['ranges', key], calcRange(key, stats[key], style))
    }
  })

  return stats
}
