import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import figmaColors from '../colors/figmaColors'
import { TwitterIcon, InstagramIcon, FacebookIcon } from '../icons/SocialIcons'

const Icon = ({ type }) => {
  switch (type) {
    case 'facebook':
      return <FacebookIcon />
    case 'twitter':
      return <TwitterIcon />
    case 'instagram':
      return <InstagramIcon />
    default:
      return null
  }
}

const Link = styled.a`
  padding: 4px;
  display: flex;
  cursor: pointer;
  &:hover {
    .main-path {
      fill: ${figmaColors.primary_500};
    }
  }
`

export const SocialLink = ({ type, href, title }) => {
  return (
    <Link
      title={title}
      href={href}
      rel="nofollow noopener noreferrer"
      target="_blank">
      <Icon type={type} />
    </Link>
  )
}

SocialLink.propTypes = {
  type: PropTypes.oneOf(['twitter', 'facebook', 'instagram']),
  href: PropTypes.string,
  title: PropTypes.string
}
