import React, { Fragment, useCallback } from 'react'
import { MultilineInput } from '@brewcomputer/ui'
import { useEditRecipe } from '../../hooks/commands'
import { untitledRecipeName } from '../../../common/defaults/recipe'

export const RecipeNameEditor = ({ recipeId, name = untitledRecipeName }) => {
  const editRecipe = useEditRecipe(recipeId)
  const editRecipeName = useCallback((input) => {
    editRecipe({ name: input })
  }, [])

  const isUntitled = name === untitledRecipeName

  return (
    <Fragment>
      <MultilineInput
        id="recipe_name"
        value={name}
        autoFocus={isUntitled}
        autoSelect={isUntitled}
        blurOnSubmit={true}
        onChange={editRecipeName}
      />
    </Fragment>
  )
}
