import React, { useCallback } from 'react'
import { TextEditor } from '..'
import { DateEditor } from '../editors/DateEditor'
import { Section, Form } from './Controls'

export const BatchInfo = ({ number, author, date, notes, onEditBatch }) => {
  const onEditInfo = key => useCallback(value => onEditBatch({ [key]: value }))

  return (
    <Section title={'Batch Info'}>
      <Form>
        <TextEditor
          title={'Number'}
          value={number}
          textAlign={'left'}
          onChange={onEditInfo('number')}
        />
        <TextEditor
          title={'Author'}
          value={author}
          textAlign={'left'}
          onChange={onEditInfo('author')}
        />
        <DateEditor
          title={'Brew Date'}
          value={date}
          onChange={onEditInfo('date')}
        />
        <TextEditor
          multiline
          title={'Notes'}
          value={notes}
          textAlign={'left'}
          onChange={onEditInfo('notes')}
        />
      </Form>
    </Section>
  )
}
