import styled, { css } from 'styled-components'
import {
  containerCardStyle,
  containerRowStyle,
  recipeNameStyle,
  recipeTextStyle
} from './style'

export const Container = styled.div`
  @media (max-width: 992px) {
    ${containerCardStyle};
    border-left-style: solid;
  }
  @media (min-width: 992px) {
    ${containerRowStyle};
  }
`

const alignItems = ({ direction }) => {
  if (direction === 'column') {
    return css`
      align-items: flex-start;
      justify-content: center;
    `
  }
  return css`
    align-items: center;
    justify-content: flex-start;
  `
}

export const Cell = styled.div`
  @media (max-width: 992px) {
    flex-direction: row;
    align-items: center;
    margin-top: 4px;
  }
  @media (min-width: 992px) {
    width: ${props => props.relativeWidth}%;
    flex-direction: ${props => props.direction || 'row'};
    ${alignItems};
  }
`

export const HeaderContainer = styled.div`
  ${containerRowStyle};
  padding: 14px 44px 14px 24px;
`

export const RecipeName = styled.div`
  ${recipeNameStyle};
  display: inline;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  flex-grow: 1;
  flex-shrink: 1;
  width: 0;
`

export const RecipeText = styled.div`
  ${recipeTextStyle};
  margin-right: 5px;
`

export const Title = styled.div`
  flex-direction: row;
  @media (min-width: 992px) {
    width: 35%;
  }
`

export const BatchTitle = styled.div`
  flex-direction: row;
  @media (min-width: 992px) {
    width: 60%;
  }
`

const colorStyle = css`
  width: 44px;
  margin-right: 24px;
  flex-shrink: 0;
`

export const Color = styled.div`
  ${colorStyle};
  height: 24px;
  border-radius: 4px;
  background-color: ${props => props.colorCSS};
  @media (max-width: 992px) {
    display: none;
  }
`

export const ColorTitle = styled(RecipeText)`
  ${colorStyle};
`

export const Stats = styled.div`
  flex-direction: row;
  flex-wrap: wrap;
  @media (max-width: 992px) {
    margin-top: 4px;
  }
  @media (min-width: 992px) {
    width: 30%;
  }
`

export const Stat = styled.div`
  flex-direction: row;
  @media (min-width: 992px) {
    width: 25%;
  }
`

export const StatTitle = styled(RecipeText)`
  font-weight: 600;
  margin-right: 5px;
  @media (min-width: 992px) {
    display: none;
  }
`

export const Data = styled.div`
  flex-direction: row;
  @media (max-width: 992px) {
    margin-top: 4px;
  }
  @media (min-width: 992px) {
    align-items: center;
    width: 35%;
  }
`

export const Style = styled.div`
  @media (max-width: 992px) {
    padding-right: 16px;
  }
  @media (min-width: 992px) {
    width: 63%;
  }
`

export const Author = styled.div`
  @media (max-width: 992px) {
    ::before {
      content: 'by';
      margin-right: 5px;
      ${recipeTextStyle};
    }
    flex-direction: row;
    align-items: flex-start;
  }
  @media (min-width: 992px) {
    width: 37%;
  }
`

export const Type = styled.div`
  @media (min-width: 992px) {
    width: 35%;
  }
`

export const Status = styled.div`
  @media (min-width: 992px) {
    width: 35%;
  }
`

export const Number = styled.div`
  @media (min-width: 992px) {
    width: 5%;
  }
  @media (max-width: 992px) {
    display: none;
  }
`

export const Date = styled.div`
  @media (min-width: 992px) {
    width: 35%;
  }
`
