import React, { useEffect } from 'react'
import { useSelector } from 'react-redux'
import {
  getBatchInfo,
  getCalculatedBatch,
  getFermentationAdditionsCheckList,
  getFermentationProfile,
  getBatchRecipeStats,
  getFermentationCheckList
} from './selectors'
import { useCheckItem } from './hooks'
import {
  BatchCheckList,
  BatchFermentation,
  BatchMeasured,
  BatchSummary,
  Column,
  Tab
} from '@brewcomputer/ui/src/batchView/BatchView'

export const BatchFermentationView = ({
  batchId,
  onEditBatch,
  onEditBatchMeasured,
  onMount
}) => {
  const { date } = useSelector(getBatchInfo(batchId))
  const recipeStats = useSelector(getBatchRecipeStats(batchId))
  const batch = useSelector(getCalculatedBatch(batchId))
  const { name, steps } = useSelector(getFermentationProfile(batchId))

  const checkList = useSelector(getFermentationCheckList(batchId))
  const additionsCheckList = useSelector(
    getFermentationAdditionsCheckList(batchId)
  )
  const onCheckItem = useCheckItem(batchId)

  useEffect(() => {
    onMount()
  }, [])

  return (
    <Tab>
      <Column>
        <BatchCheckList
          title="Fermentation"
          checkList={checkList}
          onCheckItem={onCheckItem}
        />
      </Column>

      <Column>
        <BatchFermentation
          name={name}
          date={date}
          steps={steps}
          onEditBatch={onEditBatch}
        />
        <BatchCheckList
          title="Additions"
          checkList={additionsCheckList}
          onCheckItem={onCheckItem}
        />
      </Column>

      <Column>
        <BatchMeasured
          status="fermentation"
          measuredValues={batch.measuredValues}
          onEditBatchMeasured={onEditBatchMeasured}
        />
        <BatchSummary recipeStats={recipeStats} batchStats={batch.batchStats} />
      </Column>
    </Tab>
  )
}

BatchFermentationView.displayName = 'BatchFermentationView'
