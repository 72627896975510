import { css } from 'styled-components'
import { textStyles } from '../texts/textStyles'
import { colors } from '../colors/colors'

export const defaultButtonStyle = css`
  align-self: stretch;
  background-color: transparent;
`

export const buttonStyle = css`
  flex-direction: row;
  align-self: flex-start;
`

export const unitButtonStyle = css`
  align-self: flex-end;
  padding: 0 8px;
  margin-left: 2px;
  border-radius: 4px;
  background-color: ${props => (props.focused ? colors.GRAY : 'transparent')};
`

export const buttonTextStyle = css`
  ${textStyles.body2};
  color: ${colors.TABLE_BLACK};
`
