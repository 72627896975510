import React from 'react'
import { colors } from '../colors/colors'

export const ToolsIcon = ({ fill, id, ...props }) => (
  <svg
    {...props}
    width="24"
    height="24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg">
    <mask id={id} fill="#fff">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M18 6H6V4h12v2zM6 8h12v12H6V8zM4 4v16a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V4a2 2 0 0 0-2-2H6a2 2 0 0 0-2 2zm4 6h2v2H8v-2zm3 0h2v2h-2v-2zm5 0h-2v2h2v-2zm-8 3h2v2H8v-2zm5 0h-2v2h2v-2zm1 0h2v2h-2v-2zm2 3h-2v2h2v-2zm-8 0h5v2H8v-2z"
      />
    </mask>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M18 6H6V4h12v2zM6 8h12v12H6V8zM4 4v16a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V4a2 2 0 0 0-2-2H6a2 2 0 0 0-2 2zm4 6h2v2H8v-2zm3 0h2v2h-2v-2zm5 0h-2v2h2v-2zm-8 3h2v2H8v-2zm5 0h-2v2h2v-2zm1 0h2v2h-2v-2zm2 3h-2v2h2v-2zm-8 0h5v2H8v-2z"
      fill={fill}
    />
    <path
      d="M6 6H4v2h2V6zm12 0v2h2V6h-2zM6 4V2H4v2h2zm12 0h2V2h-2v2zm0 4h2V6h-2v2zM6 8V6H4v2h2zm12 12v2h2v-2h-2zM6 20H4v2h2v-2zM4.01 3.796l-1.99-.203 1.99.203zM10 10h2V8h-2v2zm-2 0V8H6v2h2zm2 2v2h2v-2h-2zm-2 0H6v2h2v-2zm5-2h2V8h-2v2zm-2 0V8H9v2h2zm2 2v2h2v-2h-2zm-2 0H9v2h2v-2zm3-2V8h-2v2h2zm2 0h2V8h-2v2zm-2 2h-2v2h2v-2zm2 0v2h2v-2h-2zm-6 1h2v-2h-2v2zm-2 0v-2H6v2h2zm2 2v2h2v-2h-2zm-2 0H6v2h2v-2zm3-2v-2H9v2h2zm2 0h2v-2h-2v2zm-2 2H9v2h2v-2zm2 0v2h2v-2h-2zm3-2h2v-2h-2v2zm-2 0v-2h-2v2h2zm2 2v2h2v-2h-2zm-2 0h-2v2h2v-2zm0 1v-2h-2v2h2zm2 0h2v-2h-2v2zm-2 2h-2v2h2v-2zm2 0v2h2v-2h-2zm-3-2h2v-2h-2v2zm-5 0v-2H6v2h2zm5 2v2h2v-2h-2zm-5 0H6v2h2v-2zM6 8h12V4H6v4zM4 4v2h4V4H4zm14-2H6v4h12V2zm2 4V4h-4v2h4zm-2 0H6v4h12V6zm2 14V8h-4v12h4zM6 22h12v-4H6v4zM4 8v12h4V8H4zm2 0V4H2v4h4zm0 12V8H2v12h4zm0 0H2a4 4 0 0 0 4 4v-4zm12 0H6v4h12v-4zm0 0v4a4 4 0 0 0 4-4h-4zm0-12v12h4V8h-4zm0-4v4h4V4h-4zm0 0h4a4 4 0 0 0-4-4v4zM6 4h12V0H6v4zm0-.002v.001l-.001.002L6 4c.002-.002.002-.001 0 0h0V0a4 4 0 0 0-3.98 3.593L6 3.998zM6 4v-.002l-3.98-.405C2.008 3.728 2 3.863 2 4h4zm4 4H8v4h2V8zm2 4v-2H8v2h4zm-4 2h2v-4H8v4zm-2-4v2h4v-2H6zm7-2h-2v4h2V8zm2 4v-2h-4v2h4zm-4 2h2v-4h-2v4zm-2-4v2h4v-2H9zm5 2h2V8h-2v4zm2 0v-2h-4v2h4zm0-2h-2v4h2v-4zm-2 0v2h4v-2h-4zm-4 1H8v4h2v-4zm2 4v-2H8v2h4zm-4 2h2v-4H8v4zm-2-4v2h4v-2H6zm5 2h2v-4h-2v4zm2 0v-2H9v2h4zm0-2h-2v4h2v-4zm-2 0v2h4v-2h-4zm5-2h-2v4h2v-4zm2 4v-2h-4v2h4zm-4 2h2v-4h-2v4zm-2-4v2h4v-2h-4zm2 5h2v-4h-2v4zm2 0v-2h-4v2h4zm0-2h-2v4h2v-4zm-2 0v2h4v-2h-4zm-1-2H8v4h5v-4zm2 4v-2h-4v2h4zm-7 2h5v-4H8v4zm-2-4v2h4v-2H6z"
      fill={fill}
      mask={`url(#${id})`}
    />
  </svg>
)

ToolsIcon.defaultProps = {
  id: 'mask_id',
  fill: colors.GRAY
}
