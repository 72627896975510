import produce from 'immer'
import { isEmpty, assign, forEach, get, update, set, unset } from 'lodash'
import { recalcRecipe } from '@brewcomputer/utils'
import {
  recipesMyGetQuery,
  recipesPublicGetQuery,
  recipesViewModelLoad,
  recipeCreateAction,
  recipeRemoveAction,
  RECIPE_CHANGED_OPTIMISTIC
} from '../actions/recipe'
import { defaultRecipe } from '../../../common/defaults'
import {
  batchesGetQuery,
  batchCreateAction,
  batchRemoveAction
} from '../actions/batch'
import { createBatch } from '../../../common/batch'

export const initialState = {}

const updateRecipes = (draft, action) => {
  const recipes = get(action, 'payload.result', [])
  forEach(recipes, recipe => {
    update(draft, recipe.id, (draftRecipe = {}) => assign(draftRecipe, recipe))
  })
}

const assignRecipe = (draft, recipe, recipeId) => {
  const recalculated = recalcRecipe(recipe)
  set(draft, recipeId, recalculated)
  return draft
}

const reducer = produce((draft, action) => {
  switch (action.type) {
    case recipesMyGetQuery.types.success:
    case recipesPublicGetQuery.types.success:
    case batchesGetQuery.types.success: {
      updateRecipes(draft, action)
      return draft
    }

    // view-model
    case recipesViewModelLoad.types.request: {
      const { recipeId } = action.payload
      if (isEmpty(draft[recipeId])) {
        set(draft, [recipeId, 'isLoading'], true)
      }
      return draft
    }

    case recipesViewModelLoad.types.success: {
      const { recipeId, recipe } = action.payload

      // view-model not found
      if (recipe == null) {
        // recipe create command sent but view-model is not updated yet
        if (get(draft, [recipeId, 'isCreated'])) {
          return
        }
        set(draft, recipeId, { isLoading: false, isError: true })
        return draft
      }

      set(draft, recipeId, recipe)
      return draft
    }
    case recipesViewModelLoad.types.failure: {
      const { recipeId } = action.payload
      set(draft, recipeId, { isLoading: false, isError: true })
      return draft
    }

    // commands
    case recipeCreateAction.types.request:
    case batchCreateAction.types.request: {
      const { recipeId } = action.payload
      const recipe = { isLoading: true, isCreated: true }
      set(draft, recipeId, recipe)
      return draft
    }

    case recipeCreateAction.types.success: {
      const { recipeId, recipe } = action.payload
      const newRecipe = {
        ...defaultRecipe,
        ...recipe,
        id: recipeId
      }
      return assignRecipe(draft, newRecipe, recipeId)
    }
    case batchCreateAction.types.success: {
      const { recipeId, recipe } = action.payload
      const batchRecipe = {
        ...recipe,
        id: recipeId,
        hasBatch: true,
        batch: createBatch(recipe.batch, { name: recipe.batch.author })
      }
      return assignRecipe(draft, batchRecipe, recipeId)
    }

    case recipeRemoveAction.types.request:
    case batchRemoveAction.types.request: {
      const { recipeId } = action.payload
      unset(draft, recipeId)
      return draft
    }

    case RECIPE_CHANGED_OPTIMISTIC: {
      const { recipeId, recipe } = action.payload
      set(draft, recipeId, recipe)
      return draft
    }
  }
}, initialState)

export default reducer
