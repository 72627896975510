import { findIndex, size, get } from 'lodash'
import styled, { css } from 'styled-components'
import figmaColors from '../colors/figmaColors'
import { textStyles } from '../texts/textStyles'

export const Dropdown = styled.div`
  z-index: 900;
  background-color: ${figmaColors.blue_gray_500};
  border: 1px solid ${figmaColors.gray_100};
  border-radius: 4px;
  margin: 4px 0;
  overflow-x: hidden;
  box-shadow: 0px 6px 18px rgba(0, 0, 0, 0.3);
`
Dropdown.displayName = 'Dropdown'

export const optionRelativeHeight = 32

export const getOptionColors = ({ selected, hovered }) => css`
  color: ${figmaColors.white};
  background-color: ${hovered
    ? figmaColors.blue_gray_600
    : selected
    ? figmaColors.blue_gray_700
    : 'transparent'};
`

export const optionStyle = css`
  height: ${optionRelativeHeight}px;
  ${textStyles.body2};
  white-space: nowrap;
  color: ${figmaColors.black};
  cursor: pointer;
  transition-duration: 100ms;
  ${getOptionColors};
`

export const catchEvent = (e) => {
  e.preventDefault()
  e.stopPropagation()
}

export const getPrevValue = (items, value) => {
  const currentValueIndex = findIndex(items, { value })
  if (currentValueIndex > 0) {
    const prevValueIndex = currentValueIndex - 1
    return get(items, [prevValueIndex, 'value'], null)
  }
  return null
}

export const getNextValue = (items, value) => {
  const currentValueIndex = findIndex(items, { value })
  if (currentValueIndex < size(items) - 1) {
    const nextValueIndex = currentValueIndex + 1
    return get(items, [nextValueIndex, 'value'], null)
  }
  return null
}

export const onKeyPressed = (
  e,
  onClose,
  onSelect,
  onHoverPrevValue,
  onHoverNextValue
) => {
  // tab/shift+tab
  if (e.keyCode === 9) {
    onClose()
  }

  if (e.ctrlKey || e.shiftKey || e.altKey) {
    return
  }

  switch (e.keyCode) {
    case 27: {
      onClose(true)
      break
    }
    case 13: {
      catchEvent(e)
      onSelect()
      break
    }
    case 38: {
      catchEvent(e)
      onHoverPrevValue()
      break
    }
    case 40: {
      catchEvent(e)
      onHoverNextValue()
    }
  }
}
