import React, { Fragment } from 'react'
import { filter, find, get, has, isNil, map } from 'lodash'
import styled from 'styled-components'
import { textStyles } from '@brewcomputer/ui'

const Text = styled.div`
  ${textStyles.body2};
`

const TableCaption = styled(Text)`
  background-color: transparent;
  ${textStyles.H2};
  color: black;
  margin-top: 24px;
  margin-bottom: 12px;
`

const Table = styled.table`
  border-spacing: 0;
  border-collapse: collapse;
  width: 100%;
  & th,
  tr,
  td {
    border-bottom: 0.7px solid darkgray;
  }
`

const TableHeaderCell = styled.th`
  text-align: left;
  text-transform: uppercase;
  ${textStyles.body2};
  padding: 4px;
`

const TableDataCell = styled.td`
  text-align: left;
  ${textStyles.body2};
  padding: 4px;
  width: 15%;
  &:first-child {
    width: 40%;
  }
`

export const getUnitLabel = (units, unit) =>
  get(find(units, { unit }), 'label', unit)

const getColumnText = data => {
  if (has(data, 'text')) {
    return data.text
  } else if (has(data, 'measurable.value') && data.measurable.value != null) {
    const { value, unit, units } = data.measurable
    return `${value} ${getUnitLabel(units, unit)}`
  } else if (has(data, 'selectedValue')) {
    const item = find(data.items, { value: data.selectedValue })
    return get(item, 'label', '')
  }
  return ''
}

export const TablePrintable = ({ caption, data, visibleColumns }) => {
  const filteredData = map(data, fields =>
    filter(fields, ({ title }) => visibleColumns.includes(title))
  )
  const columnCaptions = map(get(filteredData, '[0]', []), 'title')

  return (
    <Fragment>
      <TableCaption>{caption}</TableCaption>
      <Table>
        <thead>
          <tr>
            {map(columnCaptions, columnTitle => (
              <TableHeaderCell key={columnTitle}>{columnTitle}</TableHeaderCell>
            ))}
          </tr>
        </thead>
        <tbody>
          {map(filteredData, (item, index) => (
            <tr key={index}>
              {!isNil(item) &&
                map(item, column => (
                  <TableDataCell key={column.path}>
                    {getColumnText(column.data)}
                  </TableDataCell>
                ))}
            </tr>
          ))}
        </tbody>
      </Table>
    </Fragment>
  )
}
