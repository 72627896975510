import styled from 'styled-components'

export const Page = styled.div`
  align-self: center;
  max-width: 630px;
  font-family: Poppins, Arial, 'Helvetica Neue', Helvetica, sans-serif;
  font-size: 15px;
  line-height: 27px;
  padding: 15px;
  background-color: #f5f5f5;
  color: #192a3e;

  h1 {
    font-size: 32px;
    line-height: 45px;
    margin-bottom: 24px;
  }
  .title {
    font-size: 37px;
    line-height: 57px;
    margin-bottom: 24px;
  }
  h2 {
    font-size: 24px;
    line-height: 37px;
    margin-top: 39px;
    margin-bottom: 5px;
  }
  h3 {
    font-size: 18px;
    line-height: 20px;
    margin-top: 28px;
    margin-bottom: 9px;
  }
  h4 {
    font-size: 14px;
    line-height: 24px;
    font-weight: bold;
    margin-bottom: 6px;
  }
  p,
  ul,
  ol,
  blockquote,
  pre,
  .alert,
  .note,
  .box,
  .footnotes {
    margin-bottom: 8px;
  }
  ul,
  ol {
    margin-left: 8px;
  }
  li {
    margin-bottom: 8px;
  }
  blockquote {
    padding-left: 15px;
    border-style: solid;
    border-color: rgba(0, 0, 0, 0.15);
    border-width: 0 0 0 6px;
  }
  sub,
  sup {
    padding: 0 1px;
  }
  code,
  pre,
  kbd {
    font-size: 13px;
  }
  code {
    background-color: rgba(0, 0, 0, 0.08);
    padding: 3px 4px;
    border-radius: 6px;
    margin: 0 1px;
  }
  pre {
    padding: 9px;
    border-style: solid;
    border-color: rgba(0, 0, 0, 0.15);
    border-width: 0 0 0 6px;
  }
  kbd {
    background-color: #fff;
    padding: 3px 4px;
    border-radius: 4px;
    box-shadow: 0 0 4px 0 rgba(0, 0, 0, 0.45);
    margin: 0 1px;
  }
  .alert,
  .note,
  .box {
    padding: 15px;
  }
  .footnotes {
    font-size: 11px;
    line-height: 19px;
    padding-top: 15px;
    border-top: 1px dotted rgba(0, 0, 0, 0.15);
  }
  .footnotes p {
    margin-bottom: 15px;
  }
  ul ul,
  ul ol,
  ol ul,
  ol ol,
  blockquote.right p,
  blockquote.left p,
  .alert :last-child,
  .note :last-child,
  .box :last-child,
  .footnotes :last-child {
    margin-bottom: 0;
  }
  @media all and (min-width: 524px) {
     {
      padding: 24px;
    }
  }
`
