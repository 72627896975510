import styled from 'styled-components'
import { containerStyle, titleStyle } from './style'

export const Container = styled.div`
  ${containerStyle};
  width: 50%;

  @media (max-width: 576px) {
    width: 100%;
  }
  border-top-style: solid;
  box-sizing: border-box;
`

export const Title = styled.div`
  ${titleStyle};
`
