import React from 'react'
import PropTypes from 'prop-types'
import styled, { css } from 'styled-components'
import { AvatarIcon } from '..'
import figmaColors from '../colors/figmaColors'

const avatarStyle = css`
  width: ${props => props.size}px;
  height: ${props => props.size}px;
  border-radius: 50%;
  align-self: center;
`

const Img = styled.img`
  ${avatarStyle};
`

const IconContainer = styled.div`
  ${avatarStyle};
  justify-content: center;
  align-items: center;
  background-color: ${props =>
    props.iconStyle === 'white' ? figmaColors.blue_500 : 'transparent'};
`

export const Avatar = ({ src, size, alt, className }) => {
  if (src != null) {
    return <Img className={className} src={src} size={size} alt={alt} />
  }

  const iconStyle = size >= 64 ? 'white' : 'colored'

  return (
    <IconContainer className={className} size={size} iconStyle={iconStyle}>
      <AvatarIcon iconStyle={iconStyle} />
    </IconContainer>
  )
}

Avatar.propTypes = {
  src: PropTypes.string,
  size: PropTypes.number,
  alt: PropTypes.string
}
