export const EMAIL_AUTH_REQUEST = 'EMAIL_AUTH_REQUEST'
export const EMAIL_AUTH_SUCCESS = 'EMAIL_AUTH_SUCCESS'
export const EMAIL_AUTH_FAILURE = 'EMAIL_AUTH_FAILURE'

export const emailAuthRequest = ({ email }) => ({
  type: EMAIL_AUTH_REQUEST,
  payload: {
    email
  }
})

export const emailAuthSuccess = ({ email }) => ({
  type: EMAIL_AUTH_SUCCESS,
  payload: {
    email
  }
})

export const emailAuthFailure = ({ email, error }) => ({
  type: EMAIL_AUTH_FAILURE,
  payload: {
    email,
    error
  }
})
