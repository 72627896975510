import { isNil, isEmpty, throttle } from 'lodash'
import React, { PureComponent } from 'react'
import PropTypes from 'prop-types'
import figmaColors from '../colors/figmaColors'
import { textStyles } from '../texts/textStyles'
import styled, { css } from 'styled-components'

const textAreaBorderWidth = 1

const smallFont = css`
  ${textStyles.smallText};
`

const mediumFont = css`
  padding: 8px 12px;
  ${textStyles.body2};
  @media (max-width: 576px) {
    font-size: 16px;
  }
`

const largeFont = css`
  ${textStyles.H1};
`

const switchFontSize = ({ inputFontSize }) => {
  switch (inputFontSize) {
    case 'small':
      return smallFont
    case 'medium':
      return mediumFont
    case 'large':
      return largeFont
  }
}

const backgroundVisible = css`
  background-color: ${props => props.inputBackgroundColor};
`

const backgroundVisibleOnBlur = css`
  background-color: transparent;
  &:focus {
    background-color: ${props => props.inputBackgroundColor};
  }
`

const switchBackground = ({ inputBackgroundVisibility }) => {
  switch (inputBackgroundVisibility) {
    case 'blur':
      return backgroundVisibleOnBlur
    case 'visible':
      return backgroundVisible
  }
}

const borderVisibleOnFocus = css`
  :focus {
    border-bottom: 1px solid ${figmaColors.primary_500};
  }
`

const switchBorder = ({ inputBorderVisibility }) => {
  switch (inputBorderVisibility) {
    case 'none':
      return null
    case 'focus':
      return borderVisibleOnFocus
  }
}

const TextArea = styled.textarea`
  box-sizing: border-box;
  width: 100%;
  border: ${textAreaBorderWidth}px solid transparent;
  border-radius: 4px;
  background-color: transparent;
  resize: none;
  color: ${figmaColors.black};
  ${switchFontSize} ${switchBackground} ${switchBorder};
`

const Container = styled.div`
  margin-bottom: 8px;
`

export class MultilineInput extends PureComponent {
  static propTypes = {
    id: PropTypes.string,
    value: PropTypes.string,
    fontSize: PropTypes.oneOf(['small', 'medium', 'large']),
    borderVisibility: PropTypes.oneOf(['none', 'focus']),
    backgroundVisibility: PropTypes.oneOf(['blur', 'visible']),
    backgroundColor: PropTypes.string,
    placeholder: PropTypes.string,
    autoFocus: PropTypes.bool,
    autoSelect: PropTypes.bool,
    submitOnBlur: PropTypes.bool,
    blurOnSubmit: PropTypes.bool,
    onChange: PropTypes.func
  }

  static defaultProps = {
    submitOnBlur: true,
    autoFocus: true,
    autoSelect: true,
    blurOnSubmit: false,
    fontSize: 'large',
    borderVisibility: 'none',
    backgroundVisibility: 'blur',
    backgroundColor: figmaColors.white
  }

  constructor(props) {
    super(props)
    this.inputRef = React.createRef()
    this.state = {
      value: null
    }
  }

  setInputHeight = () => {
    if (this.inputRef.current) {
      this.inputRef.current.style.height = 0
      this.inputRef.current.style.height =
        this.inputRef.current.scrollHeight + textAreaBorderWidth * 2 + 'px'
    }
  }

  _handleLayoutChange = throttle(() => {
    this.setInputHeight()
  }, 500)

  componentDidMount() {
    this.setInputHeight()
    window.addEventListener('resize', this._handleLayoutChange)
    if (this.props.autoSelect && this.inputRef.current) {
      this.inputRef.current.select()
    }
  }

  componentDidUpdate() {
    this.setInputHeight()
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this._handleLayoutChange)
  }

  onChange = e => {
    const value = e.target.value
    this.setState({ value })
  }

  onKeyPress = e => {
    if (e.charCode === 13 && !e.shiftKey) {
      e.preventDefault()
      this.onSaveChanges()
      if (this.props.blurOnSubmit) {
        this.inputRef.current.blur()
      }
    }
  }

  onBlur = () => {
    if (this.props.submitOnBlur) {
      this.onSaveChanges()
    }
  }

  onSaveChanges = () => {
    const { onChange } = this.props
    if (this.state.value !== this.props.value && !isEmpty(this.state.value)) {
      onChange(this.state.value)
      this.clear()
    }
  }

  getValue = () => this.state.value

  clear = () => {
    this.setState({ value: null })
  }

  focus = () => {
    if (this.inputRef.current) {
      this.inputRef.current.focus()
    }
  }

  render() {
    const value = isNil(this.state.value) ? this.props.value : this.state.value
    const {
      id,
      autoFocus,
      placeholder,
      className,
      fontSize,
      backgroundVisibility,
      borderVisibility,
      backgroundColor
    } = this.props
    return (
      <Container>
        <TextArea
          id={id}
          ref={this.inputRef}
          className={className}
          value={`${value}`}
          placeholder={placeholder}
          autoFocus={autoFocus}
          inputFontSize={fontSize}
          inputBackgroundVisibility={backgroundVisibility}
          inputBorderVisibility={borderVisibility}
          inputBackgroundColor={backgroundColor}
          onChange={this.onChange}
          onKeyPress={this.onKeyPress}
          onBlur={this.onBlur}
        />
      </Container>
    )
  }
}
