import React from 'react'
import { Page } from './PolicyPage'
import ScrollToTop from './ScrollToTop'
import { Link } from 'react-router-dom'
import { links } from '../../links'
import TitleConstructor from '../TitleConstructor'
import { titles } from '../../texts/index'

export const CookiePolicy = () => {
  return (
    <Page>
      <ScrollToTop />
      <TitleConstructor pageTitle={titles.CookiePolicy} />
      <h1 id="DEVSOFT-BALTIC-OÜ-COOKIE-POLICY">
        DEVSOFT BALTIC OÜ COOKIE POLICY
      </h1>
      <p>Effective Date: 5/25/2018</p>
      <h2 id="1-Introduction">1. Introduction</h2>
      <p>
        This Cookie Policy describes the different types of cookies and similar
        technologies that may be used on the{' '}
        <Link to={links.index} target="_blank" rel="noopener">
          http://brewcomputer.com/
        </Link>{' '}
        website (a "Website”) operated by or for DevSoft Baltic OÜ (“DevSoft
        Baltic” or “we”) when you visit it.
      </p>
      <p>
        We may change this Cookie Policy at any time. It is our policy to post
        any changes we make to our Cookie Policy on this page. The date that
        this Cookie Policy was last revised is identified at the top of this
        page.
      </p>
      <p>
        If you have further questions, please contact us at the Contact
        Information listed in our Privacy Policy.
      </p>
      <h2 id="2-What-are-Cookies-and-Similar-Technologies-Used-on-Our-Website">
        2. What are Cookies and Similar Technologies Used on Our Website
      </h2>
      <p>
        Cookies (or browser cookies). A cookie is a small file placed on the
        hard drive of your computer. Except for essential cookies and preference
        cookies, we only place cookies on your hard drive with your consent. Our
        use of cookies is governed by our Privacy Policy. You can set your
        browser to refuse all or some browser cookies, or to alert you when
        cookies are being sent. However, if you do not consent to our use of
        cookies or select this setting you may be unable to access certain parts
        of our Website. You can find more information about cookies at{' '}
        <a href="http://www.allaboutcookies.org" target="_blank" rel="noopener">
          http://www.allaboutcookies.org
        </a>{' '}
        and{' '}
        <a href="http://youronlinechoices.eu" target="_blank" rel="noopener">
          http://youronlinechoices.eu
        </a>
        .
      </p>
      <p>
        Web Beacons. Pages of our Website and our e-mails may contain small
        electronic files known as web beacons (also referred to as clear gifs,
        pixel tags, and single-pixel gifs) that permit the DevSoft Baltic, for
        example, to count users who have visited those pages or opened an email
        and for other related website statistics (for example, recording the
        popularity of certain website content and verifying system and server
        integrity).
      </p>
      <p>
        You can learn more about your choices and how we use Personal
        Information in Cookies and Web Beacons, if any, by visiting our Privacy
        Policy.
      </p>
      <h2 id="3-Cookies-and-Other-Similar-Technologies-Used-On-Our-Website">
        3. Cookies and Other Similar Technologies Used On Our Website
      </h2>
      <p>
        Our Website uses 1st party cookies (set by DevSoft Baltic), 3rd party
        cookies (which are set by third parties), and other similar
        technologies. Below is a list of the different types of cookies that we
        use on our Website, as of the effective date of this Cookie Policy.
      </p>
      <p>
        Essential Cookies and Similar Technologies: Essential cookies and other
        similar technologies used by us enable you to navigate the Website and
        use its services and features. Without these essential cookies, the
        Website will not perform smoothly and we may not be able to provide the
        Website to you or provide you with certain services or features of our
        Website.
      </p>
      <p>
        Cookie Name Description of purpose When do cookies expire 1st or 3rd
        party cookie?
      </p>
      <p>
        Brewcomputer-jwt (DevSoft Baltic) Maintains your active login session
        Session Cookie 1st party
      </p>
      <p>
        Analytics Cookies and Similar Technologies: Analytics cookies and other
        similar technologies used by us collect information about your use of
        the Website and enable us to improve the way it works and operates.
        Specifically, analytics cookies provide us with information regarding
        what the most frequently visited pages on the Website are, help us
        record and debug any issues you may have with the Website, and show us
        how effective our advertising is. These analytics cookies do not allow
        us to see the usage of a single user but does allow us to see overall
        patterns of usage on the Website. We do not examine this information for
        individually identifying information, but we do use this information to
        understand the traffic on our Website.
      </p>
      <p>
        Cookie Name Description of purpose When do cookies expire 1st or 3rd
        party cookie?
        <br />
        Google Analytics Web traffic tracking.{' '}
        <a
          href="https://www.google.com/policies/privacy"
          target="_blank"
          rel="noopener">
          https://www.google.com/policies/privacy
        </a>{' '}
        and{' '}
        <a
          href="https://policies.google.com/privacy/partners"
          target="_blank"
          rel="noopener">
          https://policies.google.com/privacy/partners
        </a>
        . 24 months 3rd party
      </p>
    </Page>
  )
}
