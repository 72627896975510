import styled, { css } from 'styled-components'
import { colors, textStyles } from '@brewcomputer/ui'

export const Row = styled.div`
  flex-direction: row;
  align-items: center;
  flex-wrap: wrap;
  justify-content: space-between;
`

export const Section = styled.div`
  flex-direction: row;
  align-items: center;
  flex-wrap: wrap;
`

export const ResponsiveContainer = styled.div`
  align-items: flex-start;
  flex-direction: row;
  padding-bottom: 32px;

  @media (max-width: 992px) {
    align-items: stretch;
    flex-direction: column;
    padding-bottom: 20px;
  }
`

export const RecipeDataContainer = styled.div`
  flex-grow: 1;
  flex-shrink: 1;
`

export const Separator = styled.div`
  width: 32px;
  height: 100%;
  flex-shrink: 0;

  @media (max-width: 992px) {
    display: none;
  }
`

const verticalMargin = css`
  margin-top: 5px;
  margin-bottom: 5px;
`

export const AuthorName = styled.div`
  color: ${colors.TEXT_GRAY};
  ${textStyles.body2};
  ${verticalMargin};
`

export const LookupContainer = styled.div`
  ${verticalMargin};
  margin-right: 20px;
`

export const PickerContainer = styled.div`
  ${verticalMargin};
  margin-right: 13px;
  width: 130px;
`

export const LinkContainer = styled.div`
  margin-right: 16px;
  @media (max-width: 576px) {
    margin-right: 12px;
  }
`

export const Label = styled.div`
  color: ${colors.ACCENT_GRAY};
  ${textStyles.body2};
  margin-right: 10px;
`
