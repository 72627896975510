import { convertMeasurable } from '..'

const convertRange = ({ minimum, maximum }, unit) => {
  return {
    minimum: convertMeasurable(minimum, unit),
    maximum: convertMeasurable(maximum, unit)
  }
}

export const convertStyle = (style, { gravity, color }) => {
  return {
    ...style,
    original_gravity: convertRange(style.original_gravity, gravity),
    final_gravity: convertRange(style.final_gravity, gravity),
    color: convertRange(style.color, color)
  }
}
