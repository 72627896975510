import { map, slice, throttle } from 'lodash'
import React, { PureComponent } from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { colors } from '@brewcomputer/ui'
import { TableColumnTitle } from '@brewcomputer/ui'

const getExpandedColumnIndex = ({ expandedColumnIndex }) =>
  expandedColumnIndex || 1

const Table = styled.table`
  border-spacing: 0;
  border-collapse: collapse;
  width: 100%;
  & tbody tr td:nth-child(${getExpandedColumnIndex}) {
    width: 100%;
  }
`

const TableHeader = styled.tr`
  background-color: ${colors.WHITE};
  font-weight: bold;
  border-bottom: 2px solid ${colors.BACKGROUND};
`

const TH = styled.th`
  text-align: left;
  padding: 6px 15px 6px 24px;
  @media (max-width: 576px) {
    padding: 6px 4px 6px 12px;
  }
  &:last-child {
    padding: 0;
  }
`

const Header = ({ columns }) => (
  <thead>
    <TableHeader>
      {map(columns, (column, index) => (
        <TH key={index}>
          <TableColumnTitle>{column}</TableColumnTitle>
        </TH>
      ))}
      <TH />
    </TableHeader>
  </thead>
)

const minColumnWidth = 180
const defaultVisibleColumnsCount = 2

export class ResponsiveTable extends PureComponent {
  constructor(props) {
    super(props)
    this._table = null
    this.state = { visibleColumnsCount: defaultVisibleColumnsCount }
  }

  getRef = ref => {
    this._table = ref
  }

  componentDidMount() {
    window.addEventListener('resize', this._handleScreenSizeChange)
    this.setVisibleColumnsCount()
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this._handleScreenSizeChange)
  }

  setVisibleColumnsCount = () => {
    let visibleColumnsCount = defaultVisibleColumnsCount
    if (this._table && this._table.offsetWidth) {
      visibleColumnsCount = Math.max(
        Math.floor(this._table.offsetWidth / minColumnWidth),
        2
      )
    }
    if (this.state.visibleColumnsCount !== visibleColumnsCount) {
      this.setState({ visibleColumnsCount })
    }
  }

  _handleScreenSizeChange = throttle(() => {
    this.setVisibleColumnsCount()
  }, 800)

  render() {
    const { columnTitles, data, renderRow, expandedColumnIndex } = this.props
    const { visibleColumnsCount } = this.state
    const headerVisibleColumns = slice(columnTitles, 0, visibleColumnsCount)

    return (
      <Table ref={this.getRef} expandedColumnIndex={expandedColumnIndex}>
        <Header columns={headerVisibleColumns} />
        <tbody>
          {map(data, (item, itemIndex) =>
            renderRow(item, itemIndex, visibleColumnsCount)
          )}
        </tbody>
      </Table>
    )
  }
}

ResponsiveTable.propTypes = {
  data: PropTypes.array,
  columnTitles: PropTypes.arrayOf(PropTypes.string),
  renderRow: PropTypes.func,
  expandedColumnIndex: PropTypes.number
}
