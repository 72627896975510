import { assign, defaults } from 'lodash'

export const addAdditionDefaults = (data, type) => {
  switch (type) {
    case 'fermentable_additions':
      return fermentableDefaults(data)
    case 'hop_additions':
      return hopDefaults(data)
    case 'culture_additions':
      return cultureDefaults(data)
    case 'miscellaneous_additions':
      return miscDefaults(data)
    default:
      return data
  }
}

export const fermentableDefaults = (fermentable) =>
  defaults(fermentable, {
    amount: {
      value: 1,
      unit: 'kg',
    },
    timing: {
      use: 'add_to_mash',
    },
  })

export const hopDefaults = (hop) =>
  defaults(hop, {
    amount: {
      value: 10,
      unit: 'g',
    },
    timing: {
      use: 'add_to_boil',
      time: {
        value: 60,
        unit: 'min',
      },
    },
  })

export const cultureDefaults = (culture) => {
  const attenuationValue =
    culture.attenuation != null
      ? (culture.attenuation.minimum.value +
          culture.attenuation.maximum.value) /
        2
      : 70

  return defaults(
    assign({}, culture, {
      attenuation: { value: attenuationValue, unit: '%' },
    }),
    {
      amount: {
        value: 1,
        unit: 'pkg',
      },
    }
  )
}

export const miscDefaults = (misc) => {
  return defaults(misc, {
    amount: {
      value: 1,
      unit: 'pkg',
    },
    timing: {
      use: 'add_to_boil',
      time: {
        value: 15,
        unit: 'min',
      },
    },
  })
}
