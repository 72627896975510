import React, { useCallback } from 'react'
import { map } from 'lodash'
import {
  measurableToString,
  Section,
  TableRow,
  TableText,
  FermentationContainer,
  FermentationSteps
} from './Controls'
import { DateEditor } from '../editors/DateEditor'

export const BatchFermentation = ({ name, date, steps, onEditBatch }) => {
  const onEditDate = useCallback(newDate => onEditBatch({ date: newDate }), [])

  return (
    <FermentationContainer>
      <Section title="Fermentation Start">
        <DateEditor title="Brew Day" value={date} onChange={onEditDate} />
      </Section>
      <Section title="Fermentation Profile">
        <TableText>{name}</TableText>
        <FermentationSteps>
          <TableRow isHeader texts={['Stage', 'Time', 'Temp']} />
          {map(steps, (step, index) => (
            <TableRow
              key={index}
              texts={[
                step.name,
                measurableToString(step.time),
                measurableToString(step.temperature)
              ]}
            />
          ))}
        </FermentationSteps>
      </Section>
    </FermentationContainer>
  )
}
