import React, { PureComponent } from 'react'
import PropTypes from 'prop-types'
import styled, { css } from 'styled-components'
import { textStyles } from '../texts/textStyles'
import { colors } from '../colors/colors'
import { BackIcon } from '../icons/BackIcon'

const Container = styled.div`
  flex-direction: row;
  justify-content: center;
  align-items: center;
  margin: 28px 0;
`

const Text = styled.div`
  ${textStyles.body2};
  margin: 0 25px;
`

const buttonStyle = css`
  align-items: center;
  justify-content: center;
  border-radius: 4px;
  width: 28px;
  height: 28px;
  transition-duration: 200ms;
  background-color: ${colors.ACCENT_GRAY};
  :hover {
    background-color: ${colors.ACCENT_GRAY_HOVER};
  }
  :active {
    background-color: ${colors.ACCENT_GRAY_ACTIVE};
  }
  :disabled {
    background-color: ${colors.BUTTON_DISABLED};
  }
`

const PrevButton = styled.button`
  ${buttonStyle};
`

const NextButton = styled.button`
  ${buttonStyle};
`

const NextIcon = styled(BackIcon)`
  transform: rotate(180deg);
`

export class Pagination extends PureComponent {
  static propTypes = {
    currentPage: PropTypes.number,
    pageSize: PropTypes.number,
    itemsCount: PropTypes.number,
    goToPage: PropTypes.func
  }

  goPrevPage = () => {
    this.props.goToPage(this.props.currentPage - 1)
  }

  goNextPage = () => {
    this.props.goToPage(this.props.currentPage + 1)
  }

  render() {
    const { pageSize, currentPage, itemsCount } = this.props

    if (itemsCount === 0) {
      return null
    }

    const from = pageSize * currentPage + 1
    const to = Math.min(pageSize * (currentPage + 1), itemsCount)
    const pagesCount = Math.ceil(itemsCount / pageSize)
    return (
      <Container>
        <PrevButton onClick={this.goPrevPage} disabled={currentPage < 1}>
          <BackIcon fill={colors.WHITE} />
        </PrevButton>
        <Text>{`${from}-${to} of ${itemsCount}`}</Text>
        <NextButton
          onClick={this.goNextPage}
          disabled={currentPage + 1 >= pagesCount}>
          <NextIcon fill={colors.WHITE} />
        </NextButton>
      </Container>
    )
  }
}
