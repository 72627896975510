import { isEmpty } from 'lodash'
import {
  calcABV,
  calcColor,
  calcBoilGravity,
  bitternessIbuTinseth,
} from 'brewcalc'

const roundMeasurableValue = (measurable, precision) => ({
  value: +measurable.value.toFixed(precision),
  unit: measurable.unit,
})

const calcBatchIBU = (recipe, measuredValues) => {
  const ibu_estimate = bitternessIbuTinseth(
    recipe.ingredients.hop_additions,
    measuredValues.pre_boil_gravity ||
      calcBoilGravity(
        recipe.batch_size,
        recipe.volumes.pre_boil_size,
        measuredValues.original_gravity
      ),
    measuredValues.batch_size
  )
  return roundMeasurableValue(ibu_estimate, 1)
}

export const calcBatchStats = (convertedRecipe, measuredValues) => {
  const batchStats = {}

  const { fermentable_additions, hop_additions } = convertedRecipe.ingredients

  const ogMeasured = !isEmpty(measuredValues.original_gravity)
  if (ogMeasured) {
    batchStats.original_gravity = measuredValues.original_gravity
  }

  const fgMeasured = !isEmpty(measuredValues.final_gravity)
  if (fgMeasured) {
    batchStats.final_gravity = measuredValues.final_gravity
  }

  if (ogMeasured && fgMeasured) {
    const abv = calcABV(
      measuredValues.original_gravity,
      measuredValues.final_gravity
    )
    batchStats.alcohol_by_volume = roundMeasurableValue(abv, 1)
  }

  const batchSizeMeasured = !isEmpty(measuredValues.batch_size)

  if (!isEmpty(fermentable_additions) && batchSizeMeasured) {
    const color_estimate = calcColor(
      fermentable_additions,
      measuredValues.batch_size
    )
    batchStats.color_estimate = roundMeasurableValue(color_estimate, 1)
  }

  if (!isEmpty(hop_additions) && batchSizeMeasured) {
    batchStats.ibu_estimate = calcBatchIBU(convertedRecipe, measuredValues)
  }

  return batchStats
}

export const calcBatch = (payload) => {
  const { batch, ...batchRecipe } = payload

  const batchStats = calcBatchStats(batchRecipe, batch.measuredValues)

  return {
    ...batch,
    measuredValues: batch.measuredValues,
    batchStats,
  }
}
