export const createCommandActions = (
  actionType,
  transformPayload = payload => payload
) => {
  const requestActionType = `${actionType}_COMMAND_REQUEST`
  const successActionType = `${actionType}_COMMAND_SUCCESS`
  const failureActionType = `${actionType}_COMMAND_FAILURE`
  return {
    types: {
      request: requestActionType,
      success: successActionType,
      failure: failureActionType
    },
    request: ({ aggregateId, payload }) => ({
      type: requestActionType,
      payload: transformPayload({ aggregateId, payload })
    }),
    success: ({ aggregateId, payload }) => ({
      type: successActionType,
      payload: transformPayload({ aggregateId, payload })
    }),
    failure: ({ aggregateId, payload, error }) => ({
      type: failureActionType,
      payload: transformPayload({ aggregateId, payload, error })
    })
  }
}

export const createQueryActions = actionType => {
  const requestActionType = `${actionType}_QUERY_REQUEST`
  const successActionType = `${actionType}_QUERY_SUCCESS`
  const failureActionType = `${actionType}_QUERY_FAILURE`
  return {
    types: {
      request: requestActionType,
      success: successActionType,
      failure: failureActionType
    },
    request: query => ({
      type: requestActionType,
      payload: {
        query
      }
    }),
    success: (query, result) => ({
      type: successActionType,
      payload: {
        query,
        result: result.data
      }
    }),
    failure: (query, error) => ({
      type: failureActionType,
      payload: {
        query,
        error
      }
    })
  }
}

export const createViewModelAction = (
  actionType,
  transformPayload = payload => payload
) => {
  const requestActionType = `${actionType}_LOAD_REQUEST`
  const successActionType = `${actionType}_LOAD_SUCCESS`
  const failureActionType = `${actionType}_LOAD_FAILURE`
  return {
    types: {
      request: requestActionType,
      success: successActionType,
      failure: failureActionType
    },
    onRequest: ({ aggregateIds, aggregateArgs }) => ({
      type: requestActionType,
      payload: transformPayload({ aggregateIds, aggregateArgs })
    }),
    onSuccess: ({ aggregateIds, aggregateArgs, result }) => ({
      type: successActionType,
      payload: transformPayload({ aggregateIds, aggregateArgs, result })
    }),
    onFailure: ({ aggregateIds, aggregateArgs, error }) => ({
      type: failureActionType,
      payload: transformPayload({ aggregateIds, aggregateArgs, error })
    })
  }
}
