import { get, set, isFunction, isNil } from 'lodash'
import React, { PureComponent } from 'react'
import styled from 'styled-components'
import { MeasurableEditor } from '@brewcomputer/ui/src/editors/MeasurableEditor'
import { TextEditor } from '@brewcomputer/ui/src/editors/TextEditor'
import { SelectEditor } from '@brewcomputer/ui/src/editors/SelectEditor'
import { DateEditor } from '@brewcomputer/ui/src/editors/DateEditor'
import { convertMeasurable } from '@brewcomputer/utils'
import { SectionTitle, TitleText } from '@brewcomputer/ui'

const LayoutGroupRoot = styled.div`
  margin: 0px 0px ${props => props.marginBottom}px 0px;
`

const LayoutGroupTitle = styled.div`
  margin-bottom: 16px;
`
const LayoutGroupItems = styled.div``

const Title = styled.div`
  margin-bottom: 26px;
`

const EditorWrapper = styled.div`
  margin-bottom: 16px;
`

export class ToolTitle extends PureComponent {
  render() {
    const { text } = this.props
    return (
      <Title>
        <TitleText>{text}</TitleText>
      </Title>
    )
  }
}

export class LayoutGroup extends PureComponent {
  render() {
    const { caption, children, marginBottom } = this.props
    return (
      <LayoutGroupRoot marginBottom={isNil(marginBottom) ? 20 : marginBottom}>
        <LayoutGroupTitle>
          <SectionTitle>{caption}</SectionTitle>
        </LayoutGroupTitle>
        <LayoutGroupItems>{children}</LayoutGroupItems>
      </LayoutGroupRoot>
    )
  }
}

export const renderMeasurableFieldEditor = (
  title,
  obj,
  fieldName,
  valueReadOnly,
  onChange
) => {
  let fieldValue = get(obj, fieldName)
  if (fieldValue.unit !== '%') {
    fieldValue = convertMeasurable(fieldValue, fieldValue.unit)
  }
  return (
    <EditorWrapper>
      <MeasurableEditor
        key={fieldName}
        title={title}
        value={fieldValue.value}
        unit={fieldValue.unit}
        unitLabel={fieldValue.unit}
        units={fieldValue.units}
        valueReadOnly={valueReadOnly}
        onChange={value => {
          let changedObj = Object.assign({}, obj)
          set(changedObj, fieldName, value)
          onChange(changedObj, fieldName)
        }}
      />
    </EditorWrapper>
  )
}

const renderNumberValueEditorInternal = (
  key,
  title,
  value,
  onChange,
  disabled
) => {
  return (
    <EditorWrapper>
      <TextEditor
        key={key}
        type={'number'}
        title={title}
        value={value}
        selectOnFocus={true}
        disabled={disabled}
        onChange={value => {
          onChange(Number(value))
        }}
      />
    </EditorWrapper>
  )
}

export const renderNumberValueEditor = (title, value, onChange, disabled) => {
  return renderNumberValueEditorInternal(null, title, value, onChange, disabled)
}

export const renderNumberFieldEditor = (
  title,
  obj,
  fieldName,
  onChange,
  disabled
) => {
  const fieldValue = get(obj, fieldName)
  const onChangeWrapper = value => {
    if (isFunction(onChange)) {
      let changedObj = Object.assign({}, obj)
      set(changedObj, fieldName, value)
      onChange(changedObj, fieldName)
    }
  }
  return renderNumberValueEditorInternal(
    fieldName,
    title,
    fieldValue,
    onChangeWrapper,
    disabled
  )
}

export const renderPickerFieldEditor = (
  title,
  obj,
  fieldName,
  items,
  onChange
) => {
  const fieldValue = get(obj, fieldName)
  return (
    <EditorWrapper>
      <SelectEditor
        key={fieldName}
        title={title}
        selectedValue={fieldValue}
        alignment={'right'}
        items={items}
        onChange={value => {
          let changedObj = Object.assign({}, obj)
          set(changedObj, fieldName, value)
          onChange(changedObj, fieldName)
        }}
      />
    </EditorWrapper>
  )
}

export const renderDateFieldEditor = (
  title,
  obj,
  fieldName,
  onChange,
  disabled
) => {
  const fieldValue = get(obj, fieldName)
  return (
    <EditorWrapper>
      <DateEditor
        key={fieldName}
        title={title}
        value={fieldValue.toISOString()}
        disabled={disabled}
        onChange={value => {
          let changedObj = Object.assign({}, obj)
          set(changedObj, fieldName, new Date(value))
          onChange(changedObj, fieldName)
        }}
      />
    </EditorWrapper>
  )
}
