import React, { PureComponent } from 'react'
import { Picker } from '../picker/Picker'
import { Editor, Title, SelectContainer } from './Styled'
import PropTypes from 'prop-types'

export class SelectEditor extends PureComponent {
  static displayName = 'SelectEditor'
  static propTypes = {
    title: PropTypes.string,
    selectedValue: PropTypes.string,
    items: PropTypes.arrayOf(
      PropTypes.shape({
        value: PropTypes.string,
        label: PropTypes.string
      })
    ),
    singleLine: PropTypes.bool,
    onChange: PropTypes.func
  }

  state = {
    focused: false
  }

  onFocus = () => {
    this.setState({ focused: true })
  }

  onBlur = () => {
    this.setState({ focused: false })
  }

  render() {
    const { focused } = this.state
    const {
      title,
      selectedValue,
      alignment,
      items,
      singleLine,
      onChange
    } = this.props

    return (
      <Editor focused={focused}>
        <SelectContainer singleLine={singleLine}>
          <Title focused={focused}>{title}</Title>
          <Picker
            type={'default'}
            alignment={alignment}
            focused={false}
            selectedValue={selectedValue}
            items={items}
            onFocus={this.onFocus}
            onBlur={this.onBlur}
            onChange={onChange}
          />
        </SelectContainer>
      </Editor>
    )
  }
}
