import React, { PureComponent } from 'react'
import PropTypes from 'prop-types'
import { Icon } from './Icon'
import { colors } from '../colors/colors'

export class BackIcon extends PureComponent {
  static propTypes = {
    fill: PropTypes.string
  }

  static defaultProps = {
    fill: colors.ACCENT_BLUE
  }

  render() {
    const { fill, className } = this.props
    return (
      <Icon
        className={className}
        width={12}
        height={20}
        fill={fill}
        paths={[
          'M11.67 1.86998L9.9 0.0999756L0 9.99998L9.9 19.9L11.67 18.13L3.54 9.99998L11.67 1.86998Z'
        ]}
      />
    )
  }
}
