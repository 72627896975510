import { isEmpty, capitalize } from 'lodash'
import PropTypes from 'prop-types'
import React from 'react'
import { ResponsiveTable } from '../responsiveTable/ResponsiveTable'
import { RecipeDataSection } from '../recipeView/RecipeDataSection'
import { TemplateLookup } from '../lookups/TemplateLookup'
import {
  getBoilProfileFields,
  getFermentationProfileFields,
  getMashProfileFields
} from '@brewcomputer/utils'
import { getColumnTitles, getKeyByFieldTitle } from './common'
import { ProcedureItem } from './ProcedureItem'

const profileDataExtractors = {
  mash: getMashProfileFields,
  boil: getBoilProfileFields,
  fermentation: getFermentationProfileFields
}

const getProfileData = (procedureType, profile) =>
  isEmpty(profile) ? [] : [profileDataExtractors[procedureType](profile)]

export const ProcedureProfile = ({
  procedure,
  procedureType,
  onEditProcedure,
  onReplaceProcedure
}) => {
  const showProfile = !isEmpty(procedure)

  const renderRow = (fields, itemIndex, visibleColumnsCount) => (
    <ProcedureItem
      key={getKeyByFieldTitle(fields, 'Name', itemIndex)}
      fields={fields}
      visibleColumnsCount={visibleColumnsCount}
      onEditProcedure={onEditProcedure}
    />
  )

  const title = `${capitalize(procedureType)} Profile`
  const profileData = getProfileData(procedureType, procedure)

  return (
    <RecipeDataSection
      caption={title}
      button={
        <TemplateLookup
          templateType={procedureType}
          onSelect={onReplaceProcedure}
        />
      }>
      {showProfile && (
        <ResponsiveTable
          columnTitles={getColumnTitles(profileData)}
          data={profileData}
          renderRow={renderRow}
        />
      )}
    </RecipeDataSection>
  )
}

ProcedureProfile.propTypes = {
  procedure: PropTypes.object,
  procedureType: PropTypes.oneOf(['mash', 'boil', 'fermentation']),
  onEditProcedure: PropTypes.func,
  onReplaceProcedure: PropTypes.func
}

ProcedureProfile.displayName = 'ProcedureProfile'
